import Multilingual from '../../../utils/Multilingual';
import { strikeZoneConfig, ballRadius } from '../constants/base';
import { HomePlate } from '../styled';

// 呈現時，避免球太擁擠，縮小半徑
const getCircle = (x, y, fill, key) => (
  <circle key={key} cx={x} cy={y} r={ballRadius / 2} fill={fill} stroke="black" strokeWidth={1} />
);
const { width, height } = strikeZoneConfig;

export default function JudgeCall({ resolvedPerBall }) {
  return (
    <div style={{ padding: 4 }}>
      <svg width={width / 2} height={height / 2}>
        <g>
          <rect width={width / 2} height={height / 2} stroke="black" fill="none"></rect>
        </g>
        <g>
          <circle cx={16} cy={32} r="8" fill="red" stroke="black" strokeWidth={1}></circle>;
          <line x1={32} x2={48} y1={32} y2={32} stroke="black" strokeWidth={1} />
          <text x={56} y={38}>
            {Multilingual('JUDGE_CALL.S')}
          </text>
          <circle cx={16} cy={64} r="8" fill="green" stroke="black" strokeWidth={1}></circle>
          <line x1={32} x2={48} y1={64} y2={64} stroke="black" strokeWidth={1} />
          <text x={56} y={70}>
            {Multilingual('JUDGE_CALL.B')}
          </text>
          <circle cx={16} cy={96} r="8" fill="blue" stroke="black" strokeWidth={1}></circle>
          <line x1={32} x2={48} y1={96} y2={96} stroke="black" strokeWidth={1} />
          <text x={56} y={102}>
            {Multilingual('NULL')}
          </text>
        </g>
      </svg>
      <h3>{Multilingual('JUDGE_CALL.WORDING')}</h3>
      <div style={{ position: 'relative', width, height }}>
        <svg width={width} height={height}>
          <g>
            <rect width={width} height={height} stroke="black" fill="none"></rect>
          </g>
          <g>
            <rect x={width / 4} y={height / 4} width={width / 2} height={height / 2} stroke="black" fill="none"></rect>
          </g>
          <g>
            {resolvedPerBall.map(({ judgeCall, eZoneResult, eyeZoneResult }, index) => {
              if (!eyeZoneResult && !eZoneResult) {
                if (judgeCall === 'S')
                  return (
                    <circle
                      key={`judge-call-${index}`}
                      cx={width / 2}
                      cy={height / 2}
                      r="8"
                      fill="red"
                      stroke="black"
                      strokeWidth={1}
                    ></circle>
                  );
                if (judgeCall === 'B')
                  return (
                    <circle
                      key={`judge-call-${index}`}
                      cx={width / 2}
                      cy={height / 2}
                      r="8"
                      fill="green"
                      stroke="black"
                      strokeWidth={1}
                    ></circle>
                  );
                return (
                  <circle
                    key={`judge-call-${index}`}
                    cx={width / 2}
                    cy={height / 2}
                    r="8"
                    fill="blue"
                    stroke="black"
                    strokeWidth={1}
                  ></circle>
                );
              }
              const x = eZoneResult ? eZoneResult.x : eyeZoneResult.x;
              const y = eZoneResult ? eZoneResult.y : eyeZoneResult.y;
              if (judgeCall === 'S') return getCircle(x, y, 'red', `judge-call-${index}`);
              if (judgeCall === 'B') return getCircle(x, y, 'green', `judge-call-${index}`);

              return getCircle(x, y, 'blue', `judge-call-${index}`);
            })}
          </g>
        </svg>
      </div>
      <HomePlate className="home-plate-4-print" width={width / 2} />
    </div>
  );
}
