import { useRootState } from '../../useRoot';
import Local from './Local';
import { LocalProvider } from './useLocal';

const Index = () => {
  const { isAdmin } = useRootState();

  if (!isAdmin) {
    return (
      <div>
        <p>-</p>
        <h3>管理員使用</h3>
      </div>
    );
  }

  return (
    <LocalProvider>
      <Local />
    </LocalProvider>
  );
};

export default Index;
