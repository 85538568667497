import { useRef, useEffect } from 'react';
import { is } from 'ramda';
import { isValid as isValidDate } from 'date-fns';

export const MonthlyQuota = 8;
export const AnnualQuota = 120;

export const FreeBallLimit = 60;
export const PremiumBallLimit = 1000;
export const PremiumEditorLimit = 5;

export const parseUserInfo = (apiUserInfo) => {
  const defaultState = {
    publicGameUniqids: [],
    unlistedGameUniqids: [],
    ownedGameUniqids: [],
    premiumQuota: 0,

    editableAmount: 0,
    inSubscription: false,
    inUnlimited: false,
    subscriptionPeriod: null,
    usedFreeTrial: false,
  };

  if (!apiUserInfo) {
    return defaultState;
  }

  let editableAmount = 0;
  if (is(Array, apiUserInfo.publicGameUniqids)) {
    editableAmount += apiUserInfo.publicGameUniqids.length;
  }
  if (is(Array, apiUserInfo.unlistedGameUniqids)) {
    editableAmount += apiUserInfo.unlistedGameUniqids.length;
  }

  let subscriptionPeriod = null;
  let inUnlimited = false;
  let inSubscription = false;
  if (apiUserInfo.subscriptionPeriod) {
    const period = new Date(apiUserInfo.subscriptionPeriod);
    if (isValidDate(period)) {
      subscriptionPeriod = period;
    }
  }
  if (apiUserInfo.unlimitedSubscriptionPeriod) {
    const period = new Date(apiUserInfo.unlimitedSubscriptionPeriod);
    if (isValidDate(period)) {
      if (!subscriptionPeriod || subscriptionPeriod.getTime() < period.getTime()) {
        subscriptionPeriod = period;
      }
      if (period.getTime() > new Date().getTime()) {
        inUnlimited = true;
      }
    }
  }

  if (subscriptionPeriod && subscriptionPeriod.getTime() > new Date().getTime()) {
    inSubscription = true;
  }

  const userInfo = {
    ...defaultState,
    ...apiUserInfo,
  };

  return {
    ...userInfo,
    editableAmount,
    inSubscription,
    inUnlimited,
    subscriptionPeriod,
  };
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
