import classifyPoint from 'robust-point-in-polygon';

// 以 100 x 100 為基礎，規範 RetroSheet Location Codes Diagram 各區域的範圍
// 可做為用戶點擊落點後轉換分析用

const middleCodes = ['8HR', '8XD', '8D', '8', '8S', '1', '1S', '2', '2F'];
const left2RightMapping = {
  78: '89',
  7: 9,
  6: 4,
  56: 34,
  5: 3,
  15: 13,
  25: 23,
};

// [x, y]
const leftCodes = {
  // 全壘打（這部分是自創，沒有參考 Retrosheet）
  '7LHR': [
    [0, 25.2],
    [10.33, 38.81],
    [3.89, 45.42],
    [0, 41.5],
  ],
  '7HR': [
    [0, 0],
    [9.5, 0],
    [21.54, 27.31],
    [10.33, 38.81],
    [0, 25.2],
  ],
  '78HR': [
    [9.5, 0],
    [35, 0],
    [36.92, 11.53],
    [21.54, 27.31],
  ],
  '8HR': [
    [35, 0],
    [50, 0],
    [50, 11.53],
    [36.92, 11.53],
  ],
  // 界外
  '7LDF': [
    [0, 41.5],
    [3.89, 45.42],
    [6.77, 48.29],
    [0, 48.29],
  ],
  '7LF': [
    [0, 48.29],
    [6.77, 48.29],
    [13.7, 55.19],
    [0, 55.19],
  ],
  '7LSF': [
    [0, 55.19],
    [13.7, 55.19],
    [20.76, 62.22],
    [0, 62.22],
  ],
  '5DF': [
    [0, 62.22],
    [20.76, 62.22],
    [31.41, 72.83],
    [0, 72.83],
  ],
  '5F': [
    [0, 72.83],
    [31.41, 72.83],
    [43, 84.37],
    [0, 84.37],
  ],
  '25F': [
    [0, 84.37],
    [43, 84.37],
    [46.55, 87.91],
    [0, 87.91],
  ],
  '2F': [
    [0, 87.91],
    [46.55, 87.91],
    [50, 91.34],
    [50, 100],
    [0, 100],
  ],
  // 場內
  '7LD': [
    [3.89, 45.42],
    [10.33, 38.81],
    [13.28, 42.69],
    [6.77, 48.29],
  ],
  '7L': [
    [6.77, 48.29],
    [13.28, 42.69],
    [19.14, 50.43],
    [13.7, 55.19],
  ],
  '7LS': [
    [13.7, 55.19],
    [19.14, 50.43],
    [25.16, 58.38],
    [20.76, 62.22],
  ],
  '5D': [
    [20.76, 62.22],
    [25.16, 58.38],
    [33.37, 69.21],
    [31.41, 72.83],
  ],
  5: [
    [31.41, 72.83],
    [33.37, 69.21],
    [38.39, 75.96],
    [36.46, 77.86],
  ],
  '5S': [
    [36.46, 77.86],
    [38.39, 75.96],
    [43.98, 83.47],
    [43, 84.37],
  ],
  '7D': [
    [10.33, 38.81],
    [21.54, 27.31],
    [25.17, 35.48],
    [13.28, 42.69],
  ],
  7: [
    [13.28, 42.69],
    [25.17, 35.48],
    [29.18, 44.53],
    [19.14, 50.43],
  ],
  '7S': [
    [19.14, 50.43],
    [29.18, 44.53],
    [33.16, 53.49],
    [25.16, 58.38],
  ],
  '56D': [
    [25.16, 58.38],
    [33.16, 53.49],
    [37.87, 64.11],
    [33.37, 69.21],
  ],
  56: [
    [33.37, 69.21],
    [37.87, 64.11],
    [42.25, 73.91],
    [39.16, 76.99],
  ],
  '56S': [
    [39.16, 76.99],
    [42.25, 43.91],
    [45.97, 82.36],
    [43.98, 83.47],
  ],
  25: [
    [43, 84.37],
    [43.98, 83.47],
    [45.97, 82.36],
    [48.06, 86.81],
    [46.55, 87.91],
  ],
  '78XD': [
    [36.92, 11.53],
    [38.66, 22.24],
    [21.54, 27.31],
  ],
  '78D': [
    [21.54, 27.31],
    [38.66, 22.24],
    [40.09, 31.03],
    [25.17, 35.48],
  ],
  78: [
    [25.17, 35.48],
    [40.09, 31.03],
    [41.68, 40.79],
    [29.18, 44.53],
  ],
  '78S': [
    [29.18, 44.53],
    [41.68, 40.79],
    [43.26, 50.55],
    [33.16, 53.49],
  ],
  '6D': [
    [33.16, 53.49],
    [43.26, 50.55],
    [44.89, 60.54],
    [37.87, 64.11],
  ],
  6: [
    [37.87, 64.11],
    [44.89, 60.54],
    [46.41, 69.73],
    [42.25, 73.91],
  ],
  '6S': [
    [42.25, 73.91],
    [46.41, 69.73],
    [47.69, 77.73],
    [44.41, 78.85],
  ],
  15: [
    [44.41, 78.85],
    [47.69, 77.73],
    [49.35, 81.44],
    [45.97, 82.36],
  ],
  '6MD': [
    [43.26, 50.55],
    [50, 50],
    [50, 59.82],
    [44.89, 60.54],
  ],
  '6M': [
    [44.89, 60.54],
    [50, 59.82],
    [50, 66.2],
    [46.41, 69.73],
  ],
  '6MS': [
    [46.41, 69.73],
    [50, 66.2],
    [50, 77.59],
    [47.69, 77.73],
  ],
  // 切一半到中線
  '8XD': [
    [36.92, 11.53],
    [50, 11.53],
    [50, 22.24],
    [38.66, 22.24],
  ],
  '8D': [
    [38.66, 22.24],
    [50, 22.24],
    [50, 31.03],
    [40.09, 31.03],
  ],
  8: [
    [40.09, 31.03],
    [50, 31.03],
    [50, 40.79],
    [41.68, 40.79],
  ],
  '8S': [
    [41.68, 40.79],
    [50, 40.79],
    [50, 50],
    [43.26, 50.55],
  ],
  1: [
    [47.69, 77.73],
    [50, 77.59],
    [50, 81.44],
    [49.35, 81.44],
  ],
  '1S': [
    [45.97, 82.36],
    [49.35, 81.44],
    [50, 81.44],
    [50, 86.81],
    [48.06, 86.81],
  ],
  2: [
    [46.55, 87.91],
    [48.06, 86.81],
    [50, 86.81],
    [50, 91.34],
  ],
};

const mirrorPoint = (xy) => {
  return [100 - xy[0], xy[1]];
};

const yDelta = -2;

const LocationCodes = {};
Object.keys(leftCodes).forEach((leftCode) => {
  // 原本計算上可能有一些偏誤，整體調整
  leftCodes[leftCode].forEach((point) => {
    if (point[1] === 100) {
      return;
    }

    point[1] = point[1] + yDelta;
    if (point[1] < 0) {
      point[1] = 0;
    }
  });

  // 中線
  if (middleCodes.includes(leftCode)) {
    const newPoints = [...leftCodes[leftCode]];

    // 五個點位的
    if (['2F', '1S'].includes(leftCode)) {
      // 1 mirror 3；0 mirror 4；4 mirror 5
      newPoints.splice(3, 0, mirrorPoint(leftCodes[leftCode][1]));
      newPoints.splice(4, 0, mirrorPoint(leftCodes[leftCode][0]));
      newPoints.splice(5, 0, mirrorPoint(leftCodes[leftCode][4]));
    } else {
      // 四個點位的都是取 0 mirror 2；3 mirror 3
      newPoints.splice(2, 0, mirrorPoint(leftCodes[leftCode][0]));
      newPoints.splice(3, 0, mirrorPoint(leftCodes[leftCode][3]));
    }
    LocationCodes[leftCode] = newPoints;
    return;
  }

  LocationCodes[leftCode] = leftCodes[leftCode];

  const number = leftCode.replace(/[\D]/g, '');
  const code = leftCode.replace(/[\d]/g, '');
  if (!left2RightMapping[number]) {
    return;
  }

  const mirrorPoints = leftCodes[leftCode].map((point) => mirrorPoint(point));
  LocationCodes[`${left2RightMapping[number]}${code}`] = mirrorPoints;
});
// console.log(LocationCodes);

export const getLocationCode = (xy) => {
  if (!Array.isArray(xy) || xy.length !== 2) {
    throw new Error();
  }

  const validPosition = (v) => {
    return v >= 0 && v <= 100;
  };
  if (!validPosition(xy[0]) || !validPosition(xy[1])) {
    throw new Error();
  }

  let locationCode = '?';
  for (let code of Object.keys(LocationCodes)) {
    if (1 === classifyPoint(LocationCodes[code], xy)) {
      continue;
    }

    locationCode = code;
    break;
  }

  return locationCode;
};

export const getLocationCenterPosition = (locationCode) => {
  const getCenter = (locationCodePolygon) => {
    return [
      (Math.min(...locationCodePolygon.map((point) => point[0])) +
        Math.max(...locationCodePolygon.map((point) => point[0]))) /
        2,
      (Math.min(...locationCodePolygon.map((point) => point[1])) +
        Math.max(...locationCodePolygon.map((point) => point[1]))) /
        2,
    ];
  };

  // 這是 Rebas 獨創在 retrosheet 之外的落點，是 6M 跟 4M 的組合
  if (locationCode === '46') {
    const center6M = getCenter(LocationCodes['6M']);
    const center4M = getCenter(LocationCodes['4M']);
    return [(center6M[0] + center4M[0]) / 2, (center6M[1] + center4M[1]) / 2];
  }

  if (!LocationCodes[locationCode]) {
    console.warn(locationCode);
    return [100, 100];
  }

  return getCenter(LocationCodes[locationCode]);
};
