export const exportPitchRows = (PAList, pitches) => {
  const pitchRows = pitches.map((pitchEvent) => {
    const PA = PAList[pitchEvent.PAIndex];
    return {
      // 基本資訊
      inning: PA.inning,
      side: PA.side,
      PA_round: PA.PA_round,
      PA_order: PA.PA_order,
      batter: PA.batter,
      event_index: pitchEvent.eventIndex,
      // 時間軸
      video_signin_at: pitchEvent.video_signin_at,
      video_started_at: pitchEvent.video_started_at,
      video_contact_at: pitchEvent.video_contact_at,
      video_fly_catch_at: pitchEvent.video_fly_catch_at,
      video_ground_catch_at: pitchEvent.video_ground_catch_at,
      video_sb_throw_at: pitchEvent.video_sb_throw_at,
      video_sb_catch_at: pitchEvent.video_sb_catch_at,
      // 投球相關
      velocity: pitchEvent.pitch.velocity,
      type: pitchEvent.pitch.type,
      RPM: pitchEvent.pitch.RPM,
      coord_x: pitchEvent.pitch.coord_x,
      coord_y: pitchEvent.pitch.coord_y,
      // 打擊相關
      EV: pitchEvent.pitch.EV,
      trajectory: pitchEvent.pitch.trajectory,
      hardness: pitchEvent.pitch.hardness,
      location_code: pitchEvent.pitch.location_code,
      location_coord_x: pitchEvent.pitch.location_coord_x,
      location_coord_y: pitchEvent.pitch.location_coord_y,
      fielder_position: pitchEvent.pitch.fielder_position,
    };
  });

  return pitchRows;
};

export const parseInputStrikezoneCSV = (pitches, CSVRows) => {
  if (CSVRows.length === 0) {
    return [];
  }
  const validRows = CSVRows.slice(1).filter((r) => r.length === 25);
  if (validRows.length !== pitches.length) {
    return [];
  }

  return validRows.map((row) => ({
    // 時間軸
    video_signin_at: row[6] ?? '',
    video_started_at: row[7] ?? '',
    video_contact_at: row[8] ?? '',
    video_fly_catch_at: row[9] ?? '',
    video_ground_catch_at: row[10] ?? '',
    video_sb_throw_at: row[11] ?? '',
    video_sb_catch_at: row[12] ?? '',
    pitch: {
      // 投球相關
      velocity: row[13] ?? '',
      type: row[14] ?? '',
      RPM: row[15] ?? '',
      coord_x: row[16] ?? '',
      coord_y: row[17] ?? '',
      // 打擊相關
      EV: row[18] ?? '',
      trajectory: row[19] ?? '',
      hardness: row[20] ?? '',
      location_code: row[21] ?? '',
      location_coord_x: row[22] ?? '',
      location_coord_y: row[23] ?? '',
      fielder_position: row[24] ?? '',
    },
  }));
};
