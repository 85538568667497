import Button from '~~elements/Button';
import Input from '~~elements/Input';
import { validTimeDisplay, timeDisplayReverse } from '../Const';
import { useRootState } from '../../../useRoot';

const ManagerForm = ({ pitches, managerForm, setManagerForm, handleManagerUpdate }) => {
  const { isAdminManager } = useRootState();

  if (!isAdminManager) {
    return <></>;
  }

  return (
    <div className="btns update-btns manager">
      <div>
        公開網址
        <br />
        <Input
          name="public_video_url"
          value={managerForm.public_video_url}
          onChange={(e) => {
            setManagerForm({
              ...managerForm,
              public_video_url: e.target.value,
            });
          }}
        />
      </div>
      <div>
        時間調整（秒）
        <br />
        <Input
          name="public_video_shift"
          value={managerForm.public_video_shift}
          onChange={(e) => {
            setManagerForm({
              ...managerForm,
              public_video_shift: e.target.value,
            });
          }}
        />
        <Button
          width="auto"
          mini
          color="transparentWithBorder"
          disabled={
            !managerForm.public_video_url ||
            !managerForm.public_video_shift ||
            !validTimeDisplay(pitches[0].video_started_at)
          }
          onClick={() => {
            const toShift = parseInt(managerForm.public_video_shift);
            console.log(toShift);
            if (Number.isNaN(toShift)) {
              return;
            }
            const firstBallAt = parseInt(timeDisplayReverse(pitches[0].video_started_at));
            if (Number.isNaN(firstBallAt)) {
              return;
            }
            const t = firstBallAt + toShift;
            const link = `${managerForm.public_video_url}&t=${t}`;
            console.log(link);
            window.open(link, '_blank');
          }}
        >
          測試
        </Button>
      </div>
      <div>
        紀錄狀態
        <br />
        <label>
          <input
            type="checkbox"
            checked={!!managerForm.strikezone_completeness}
            onChange={(e) => {
              e.target.blur();
              setManagerForm({
                ...managerForm,
                strikezone_completeness: managerForm.strikezone_completeness ? 0 : 1,
              });
            }}
          />
          審核完成
        </label>
        <Button width="auto" mini color="red" onClick={handleManagerUpdate}>
          管理者更新
        </Button>
      </div>
    </div>
  );
};

export default ManagerForm;
