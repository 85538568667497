import styled from 'styled-components';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  text-align: start;
  color: white;
  width: 100%;
  font-size: 0.9rem;
  box-shadow: 3px 3px 5px 2px black;

  display: flex;
  flex-direction: column;
  align-items: center;

  #video-embed-iframe-placing {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  i {
    font-size: 0.8rem;
  }
`;

const VideoEmbedYoutube = ({ strikeZoneResizable }) => {
  return (
    <StyledDiv>
      <div style={{ position: 'relative', width: '100%' }}>
        <i>*{Multilingual(`VIDEO_MODE.YOUTUBE_RESIZING_HINT`)}</i>
        <div id="video-embed-iframe-placing"></div>
        {strikeZoneResizable && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          />
        )}
      </div>
    </StyledDiv>
  );
};

export default VideoEmbedYoutube;
