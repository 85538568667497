import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { eyeZoneDefaultXY, setZoneResize } from '../../Const';
import { getResizerCommonStyle } from '../../styled';

const StyledDiv = styled.div`
  z-index: 1000;
  .eye-strike-zone {
    background: rgba(255, 255, 255, 0.1);
    width: ${eyeZoneDefaultXY.width}px;
    height: ${eyeZoneDefaultXY.height}px;
    position: absolute;
    top: ${eyeZoneDefaultXY.y};
    left: ${eyeZoneDefaultXY.x};

    ${getResizerCommonStyle('orange')}
  }
`;

const zoneClass = '.eye-strike-zone';

const debounce = {};

const EyeStrikeStrikeZone = ({
  isOpen,
  resizable,
  setBallWidth,
  setPitchPosition,
  eyeZoneXY,
  setEyeFullZoneXY,
  setEyeZoneXY,
}) => {
  const strikeZoneRef = useRef(null);
  const [refWaiting, setRefWaiting] = useState(0);

  useEffect(() => {
    if (!strikeZoneRef || !strikeZoneRef.current) {
      setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    if (refWaiting === -1) {
      return;
    }
    setRefWaiting(-1);

    setZoneResize(zoneClass, 'eye-strike-zone', debounce, setEyeFullZoneXY, setEyeZoneXY);
  }, [refWaiting, setEyeFullZoneXY, setEyeZoneXY]);

  useEffect(() => {
    const element = document.querySelector(zoneClass);
    element.style.width = eyeZoneXY.width + 'px';
    element.style.height = eyeZoneXY.height + 'px';
    element.style.left = eyeZoneXY.x + 'px';
    element.style.top = eyeZoneXY.y + 'px';
    setBallWidth(eyeZoneXY.width / 6);
  }, [setBallWidth, eyeZoneXY]);

  useEffect(() => {
    if (!strikeZoneRef || !strikeZoneRef.current) {
      setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    const el = document.getElementById('eye-strike-zone-div');
    const setPitch = setPitchPosition('EYE');
    el.addEventListener('click', setPitch);
    return () => {
      el.removeEventListener('click', setPitch);
    };
  }, [refWaiting, setPitchPosition]);

  return (
    <StyledDiv
      ref={strikeZoneRef}
      id="eye-strike-zone-div"
      style={{ display: isOpen ? 'block' : 'none' }}
      resizable={resizable}
    >
      <div className="eye-strike-zone">
        <div className="eye-strike-zone--resizers">
          <div
            className="eye-strike-zone--resizer eye-strike-zone--top-left"
            style={resizable ? {} : { display: 'none' }}
          ></div>
          <div
            className="eye-strike-zone--resizer eye-strike-zone--top-right"
            style={resizable ? {} : { display: 'none' }}
          ></div>
          <div
            className="eye-strike-zone--resizer eye-strike-zone--bottom-left"
            style={resizable ? {} : { display: 'none' }}
          ></div>
          <div
            className="eye-strike-zone--resizer eye-strike-zone--bottom-right"
            style={resizable ? {} : { display: 'none' }}
          ></div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default EyeStrikeStrikeZone;
