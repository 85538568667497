export const PITCH_TYPE = {
  FF: 'FF',
  SI: 'SI',
  FC: 'FC',
  SL: 'SL',
  CU: 'CU',
  CH: 'CH',
  FO: 'FO',
};

export const PITCH_TYPE_NAME = {
  [PITCH_TYPE.FF]: '四縫',
  [PITCH_TYPE.SI]: '二/伸',
  [PITCH_TYPE.FC]: '卡特',
  [PITCH_TYPE.SL]: '滑球',
  [PITCH_TYPE.CU]: '曲球',
  [PITCH_TYPE.CH]: '變速',
  [PITCH_TYPE.FO]: '指叉',
};
