import { Fragment, useEffect, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import Button from '~~elements/Button';
import Select from '~~elements/Select';
import Loading from '~~elements/Loading';
import { FormalGameResource } from '~~apis/resource';
import { useQuery } from '~~utils/CommonUtils';

const Prepare = ({ setLockGame }) => {
  const query = useQuery();
  const querySeasonUniqid = query.get('s');
  const queryGameUniqid = query.get('g');

  const [seasons, setSeasons] = useState([]);

  const [season, setSeason] = useState(null);
  const [games, setGames] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!querySeasonUniqid || !queryGameUniqid) {
      return;
    }

    setLoading(true);
    FormalGameResource.getGameInPlay({ seasonUniqid: querySeasonUniqid, gameUniqid: queryGameUniqid }).then(
      ({ data }) => {
        console.log(data);
        setLockGame({
          seasonUniqid: querySeasonUniqid,
          game: data,
        });
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    FormalGameResource.getSeasons({ leagueUniqid: 'CPBL' }).then(({ data }) => {
      setSeasons(data.seasons.filter((season) => season.year < 2024));
      setLoading(false);
    });
  }, []);

  const handleGamesFetch = () => {
    const seasonUniqid = document.querySelector('#season-select').value;
    query.set({
      s: seasonUniqid,
    });
    setLoading(true);
    FormalGameResource.getGames({ seasonUniqid: seasonUniqid }).then(({ data }) => {
      setSeason(R.find((s) => s.uniqid === seasonUniqid, seasons));
      setGames(data);
      setLoading(false);
    });
  };

  const handleGameFetch = () => {
    const gameUniqid = document.querySelector('#game-select').value;
    query.set({
      g: gameUniqid,
    });
    setLoading(true);
    FormalGameResource.getGameInPlay({ seasonUniqid: season.uniqid, gameUniqid: gameUniqid }).then(({ data }) => {
      console.log(data);
      setLockGame({
        seasonUniqid: season.uniqid,
        game: data,
      });
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <Fragment>
        <Loading width="100%" />
      </Fragment>
    );
  }

  if (games.length === 0) {
    return (
      <Fragment>
        <p>設定賽季</p>
        <Select id="season-select">
          {seasons.map((season) => (
            <option key={season.uniqid} value={season.uniqid}>
              {season.title}
            </option>
          ))}
        </Select>
        <Button width="auto" color="blue" onClick={handleGamesFetch}>
          拉比賽
        </Button>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Button width="auto" color="transparent" onClick={() => setGames([])}>
        上一步
      </Button>
      <p>{season.title} - 設定場次</p>
      <Select id="game-select">
        {R.filter((g) => g.info.status === 'FINISHED', games).map((game) => (
          <option key={game.uniqid} value={game.uniqid}>
            {`G${game.seq} ${moment(game.info.started_at).format('YYYY-MM-DD')} ${game.away.team}vs${game.home.team}`}
          </option>
        ))}
      </Select>
      <Button width="auto" color="blue" onClick={handleGameFetch}>
        拉資料
      </Button>
    </Fragment>
  );
};

export default Prepare;
