import Multilingual from '../../../utils/Multilingual';
import { strikeZoneConfig, ballRadius } from '../constants/base';
import { HomePlate } from '../styled';

// 呈現時，避免球太擁擠，縮小半徑
const getCircle = (x, y, fill, key) => (
  <circle key={key} cx={x} cy={y} r={ballRadius / 2} fill={fill} stroke="black" strokeWidth={1} />
);

const { width, height } = strikeZoneConfig;

export default function BallType({ resolvedPerBall }) {
  return (
    <div style={{ padding: 4 }}>
      <svg width={(width * 3) / 5} height={height / 2}>
        <g>
          <rect width={(width * 3) / 5} height={height / 2} stroke="black" fill="none"></rect>
        </g>
        <g>
          <circle cx={16} cy={16} r="8" fill="red" stroke="black" strokeWidth={1}></circle>;
          <line x1={32} x2={48} y1={16} y2={16} stroke="black" strokeWidth={1} />
          <text x={56} y={22}>
            {Multilingual('PITCH_TYPE.F')}
          </text>
          <circle cx={16} cy={48} r="8" fill="orange" stroke="black" strokeWidth={1}></circle>
          <line x1={32} x2={48} y1={48} y2={48} stroke="black" strokeWidth={1} />
          <text x={56} y={56}>
            {Multilingual('PITCH_TYPE.B')}
          </text>
          <circle cx={16} cy={80} r="8" fill="green" stroke="black" strokeWidth={1}></circle>
          <line x1={32} x2={48} y1={80} y2={80} stroke="black" strokeWidth={1} />
          <text x={56} y={86}>
            {Multilingual('PITCH_TYPE.C')}
          </text>
          <circle cx={16} cy={112} r="8" fill="blue" stroke="black" strokeWidth={1}></circle>
          <line x1={32} x2={48} y1={112} y2={112} stroke="black" strokeWidth={1} />
          <text x={56} y={118}>
            {Multilingual('NULL')}
          </text>
        </g>
      </svg>
      <h3>{Multilingual('PITCH_TYPE.WORDING')}</h3>
      <div style={{ width, height }}>
        <svg width={width} height={height}>
          <g>
            <rect width={width} height={height} stroke="black" fill="none"></rect>
          </g>
          <g>
            <rect
              x={width / 6 + width / 12}
              y={height / 6 + height / 12}
              width={width / 2}
              height={height / 2}
              stroke="black"
              fill="none"
            ></rect>
          </g>
          <g>
            {resolvedPerBall.map(({ ballType, eZoneResult, eyeZoneResult }, index) => {
              const key = `ball-type-${index}`;

              if (!eyeZoneResult && !eZoneResult) {
                if (ballType === 'F')
                  return <circle key={key} cx={150} cy={200} r="8" fill="red" stroke="black" strokeWidth={1}></circle>;
                if (ballType === 'C')
                  return (
                    <circle key={key} cx={150} cy={200} r="8" fill="green" stroke="black" strokeWidth={1}></circle>
                  );
                if (ballType === 'B')
                  return (
                    <circle key={key} cx={150} cy={200} r="8" fill="orange" stroke="black" strokeWidth={1}></circle>
                  );
                return (
                  <circle
                    key={key}
                    cx={width / 2}
                    cy={width / 2}
                    r="8"
                    fill="blue"
                    stroke="black"
                    strokeWidth={1}
                  ></circle>
                );
              }
              const x = eZoneResult ? eZoneResult.x : eyeZoneResult.x;
              const y = eZoneResult ? eZoneResult.y : eyeZoneResult.y;
              if (ballType === 'F') return getCircle(x, y, 'red', key);
              if (ballType === 'C') return getCircle(x, y, 'green', key);
              if (ballType === 'B') return getCircle(x, y, 'orange', key);

              return getCircle(x, y, 'blue', key);
            })}
          </g>
        </svg>
      </div>
      <HomePlate className="home-plate-4-print" width={width / 2} />
    </div>
  );
}
