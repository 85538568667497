import { useRootState } from '../../useRoot';
import Feed from './Feed';

const Index = () => {
  const { realtimeDB } = useRootState();

  return <Feed realtimeDB={realtimeDB} />;
};

export default Index;
