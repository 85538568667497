import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ref } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import { is } from 'ramda';
import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import toast from '~~elements/Toast';
import EStrikeFullZone from './components/EStrikeFullZone';
import EStrikeStrikeZone from './components/EStrikeStrikeZone';
import { eZoneDefaultXY, eFullZoneDefaultXY, eyeZoneDefaultXY, eyeFullZoneDefaultXY, ZoneDeviceKey } from './Const';
import { getBallInitState } from '../StrikezoneGame/constants/base';
import EyeStrikeStrikeZone from './components/EyeStrikeStrikeZone';
import EyeStrikeFullZone from './components/EyeStrikeFullZone';
import Wingman from './components/Wingman';
import CustomNavBar from './components/CustomNavBar';
import { useRootState } from '../../useRoot';
import { usePerBallDispatch, usePerBallState } from './useResultPanel';
import DeviceNotification from './components/DeviceNotification';
import OnSite from './components/OnSite';
import Video from './components/Video';
import Multilingual from './Multilingual';

const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  line-height: 1.2;
  .project-name {
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
  .game-name {
    font-size: 2rem;
    letter-spacing: 2px;
    margin-top: 0.5rem;
  }

  background-color: #fafafa;
  color: #1e1e1e;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BallCursor = styled.div`
  z-index: 1001;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  background-color: transparent;
  ${(props) => (!props.hide ? '' : 'display: none;')}
`;

const parseRealtimeDBValue = (val) => {
  const defaultPrivacy = 'PUBLIC';
  const defaultCameraAngle = 'PITCHER';
  const defaultSpeedUnit = 'KM';
  const defaultState = {
    title: '',
    isPremium: false,
    privacy: defaultPrivacy,
    reference: '',
    description: '',
    away: '',
    home: '',
    cameraAngle: defaultCameraAngle,
    speedUnit: defaultSpeedUnit,
    isLive: false,
    perBall: [getBallInitState()],
    awayPlayers: [],
    homePlayers: [],
    comments: [],
    ownerUniqid: '',
    ownerUserName: '',
    editorUniqids: [],
    gameDate: '',
    createdAt: null,
    updatedAt: null,
  };

  if (is(Array, val.perBall)) {
    const validPerBall = R.filter((ball) => ball !== null && ball !== undefined && ball !== false, val.perBall);
    validPerBall.forEach((b, i) => {
      validPerBall[i] = {
        ...getBallInitState(),
        ...b,
      };
    });
    val.perBall = validPerBall;
  }

  const mergedValue = {
    ...defaultState,
    ...val,
  };
  if (!mergedValue.privacy) {
    mergedValue.privacy = defaultPrivacy;
  }
  if (!mergedValue.cameraAngle) {
    mergedValue.cameraAngle = defaultCameraAngle;
  }
  if (!mergedValue.speedUnit) {
    mergedValue.speedUnit = defaultSpeedUnit;
  }
  return mergedValue;
};

const StrikezoneGameEdit = ({ strikezoneUniqid, refPath, userInfo, complex }) => {
  const { realtimeDB } = useRootState();
  const [gameSnapshot, gameLoading, gameError] = useObject(ref(realtimeDB, refPath));
  const [gameState, setGameState] = useState(parseRealtimeDBValue({}));

  const { showEZone } = usePerBallState();
  const perBallDispatch = usePerBallDispatch();

  const [isOpenWingman, setOpenWingman] = useState(true);

  const [isOpenOnSite, setOpenOnSite] = useState(false);

  const [isOpenVideo, setOpenVideo] = useState(false);

  const [strikeZoneResizable, setStrikeZoneResizable] = useState(true);

  const [eZoneXY, setEZoneXY] = useState({ ...eZoneDefaultXY });
  const [eFullZoneXY, setEFullZoneXY] = useState({ ...eFullZoneDefaultXY });

  const [eyeZoneXY, setEyeZoneXY] = useState({ ...eyeZoneDefaultXY });
  const [eyeFullZoneXY, setEyeFullZoneXY] = useState({ ...eyeFullZoneDefaultXY });

  const [onSiteZoneXY, setOnSiteZoneXY] = useState({ ...eyeZoneDefaultXY });

  const ballCursorRef = useRef(null);
  const [ballRefWaiting, setBallRefWaiting] = useState(0);
  const [ballWidth, setBallWidth] = useState(10);

  useEffect(() => {
    if (complex !== 'true') {
      return;
    }

    perBallDispatch({ type: 'complexMode' });
  }, [complex, perBallDispatch]);

  useEffect(() => {
    perBallDispatch({ type: 'setUserInfo', payload: userInfo });
  }, [userInfo, perBallDispatch]);

  useEffect(() => {
    if (!ballCursorRef || !ballCursorRef.current) {
      setTimeout(() => {
        setBallRefWaiting(ballRefWaiting + 1);
      }, 200);
      return;
    }

    const el = document.getElementById('ball-cursor');
    const cursorTracing = (e) => {
      el.style.top = e.pageY + 'px';
      el.style.left = e.pageX + 'px';
    };
    window.addEventListener('mousemove', cursorTracing);
  }, [ballRefWaiting]);

  useEffect(() => {
    setGameState(parseRealtimeDBValue(!gameSnapshot ? {} : gameSnapshot.val()));
  }, [gameSnapshot]);

  const setPitchPosition = (zoneType) => (e) => {
    if (zoneType !== 'ON_SITE' && strikeZoneResizable) {
      if (!e.target.className.includes('e-strike-zone--resizer ')) {
        toast(Multilingual(`RESIZE_STRIKEZONE.TURN_OFF_HINT`), { status: 'error', second: 1 });
      }
      return;
    }

    const pitchX = e.pageX;
    const pitchY = e.pageY;

    const zoneX = zoneType === 'E' ? eZoneXY : zoneType === 'ON_SITE' ? onSiteZoneXY : eyeZoneXY;
    const value = {
      ...zoneX,
      x: (((pitchX - zoneX.x) * 100) / zoneX.width).toFixed(2),
      y: (((pitchY - zoneX.y) * 100) / zoneX.height).toFixed(2),
    };
    const key = zoneType === 'E' ? 'eZoneResult' : 'eyeZoneResult';
    perBallDispatch({
      type: 'updateZoneResult',
      payload: { key, value },
    });

    if (zoneType !== 'ON_SITE') {
      return;
    }
    const autoFocusSpeedEl = document.querySelector('.ball-edit-modal--speed');
    if (!autoFocusSpeedEl) {
      return;
    }

    autoFocusSpeedEl.style.display = 'block';
    autoFocusSpeedEl.focus();
  };

  const saveDeviceZone = () => {
    window.localStorage.setItem(
      ZoneDeviceKey,
      JSON.stringify({
        eZoneXY,
        eFullZoneXY,
        eyeZoneXY,
        eyeFullZoneXY,
      }),
    );
    toast(Multilingual(`RESIZE_STRIKEZONE.SAVE_SUCCESSFULLY`), { status: 'success', seconed: 2 });
  };

  const loadDeviceZone = () => {
    const zoneDevicePreferenceString = window.localStorage.getItem(ZoneDeviceKey);
    if (!zoneDevicePreferenceString) {
      return;
    }

    const zoneDevicePreference = JSON.parse(zoneDevicePreferenceString);
    if (!zoneDevicePreference) {
      return;
    }

    setEZoneXY({ ...zoneDevicePreference.eZoneXY });
    setEFullZoneXY({ ...zoneDevicePreference.eFullZoneXY });
    setEyeZoneXY({ ...zoneDevicePreference.eyeZoneXY });
    setEyeFullZoneXY({ ...zoneDevicePreference.eyeFullZoneXY });
    toast(Multilingual(`RESIZE_STRIKEZONE.READ_SUCCESSFULLY`), { status: 'success', seconed: 1 });
  };

  const closeOnSite = () => {
    setOpenOnSite(false);
    setOpenWingman(true);
  };

  const openOnSite = () => {
    setOpenOnSite(true);
    setOpenWingman(false);
  };

  const closeVideo = () => {
    setOpenVideo(false);
    setOpenWingman(true);
  };

  const openVideo = () => {
    setOpenVideo(true);
    setOpenWingman(false);
  };

  return (
    <StyledDiv>
      <CustomNavBar privacy={gameState.privacy} strikezoneUniqid={strikezoneUniqid} />
      <h1 className="project-name">{Multilingual(`NAME`)}</h1>
      <h1 className="game-name">{gameState.title}</h1>
      {showEZone && (
        <Fragment>
          <EStrikeFullZone
            isOpen={isOpenVideo}
            resizable={strikeZoneResizable}
            setPitchPosition={setPitchPosition}
            eFullZoneXY={eFullZoneXY}
          />
          <EStrikeStrikeZone
            isOpen={isOpenVideo}
            resizable={strikeZoneResizable}
            setPitchPosition={setPitchPosition}
            eZoneXY={eZoneXY}
            setEFullZoneXY={setEFullZoneXY}
            setEZoneXY={setEZoneXY}
          />
        </Fragment>
      )}
      <EyeStrikeFullZone
        isOpen={isOpenVideo}
        resizable={strikeZoneResizable}
        setPitchPosition={setPitchPosition}
        eyeFullZoneXY={eyeFullZoneXY}
      />
      <EyeStrikeStrikeZone
        isOpen={isOpenVideo}
        resizable={strikeZoneResizable}
        setBallWidth={setBallWidth}
        setPitchPosition={setPitchPosition}
        eyeZoneXY={eyeZoneXY}
        setEyeFullZoneXY={setEyeFullZoneXY}
        setEyeZoneXY={setEyeZoneXY}
      />
      <Wingman
        isOpenWingman={isOpenWingman}
        isOpenOnSite={isOpenOnSite}
        openOnSite={openOnSite}
        isOpenVideo={isOpenVideo}
        openVideo={openVideo}
        strikezoneUniqid={strikezoneUniqid}
        gameState={gameState}
        gameLoading={gameLoading}
        gameError={gameError}
      />
      <BallCursor
        id="ball-cursor"
        ref={ballCursorRef}
        width={ballWidth}
        hide={isMobile || !isOpenVideo || strikeZoneResizable}
      />
      {isOpenOnSite && (
        <OnSite
          isOpen={isOpenOnSite}
          toClose={closeOnSite}
          strikezoneUniqid={strikezoneUniqid}
          gameState={gameState}
          gameLoading={gameLoading}
          gameError={gameError}
          setPitchPosition={setPitchPosition}
          setOnSiteZoneXY={setOnSiteZoneXY}
        />
      )}
      {/* 因為有 YT 或螢幕分享，不要洗掉狀態 */}
      <Video
        isOpen={isOpenVideo}
        toClose={closeVideo}
        strikezoneUniqid={strikezoneUniqid}
        gameState={gameState}
        strikeZoneResizable={strikeZoneResizable}
        setStrikeZoneResizable={setStrikeZoneResizable}
        saveDeviceZone={saveDeviceZone}
        loadDeviceZone={loadDeviceZone}
        gameLoading={gameLoading}
        gameError={gameError}
      />
      <DeviceNotification />
    </StyledDiv>
  );
};

export default StrikezoneGameEdit;
