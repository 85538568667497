import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import { Link } from 'react-router-dom';
import { isValid as isValidDate, format as formatDate } from 'date-fns';
import Multilingual from '../../../utils/Multilingual';

const StyledDiv = styled.div`
  width: calc(100% - 3rem);
  margin: 1rem 1.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid ${COLOR.home};
  text-align: start;

  .strikezone-game {
    &--title {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0;
      padding: 0 0 0.3rem;
    }
    &--tvt {
      color: gray;
      font-size: 0.9rem;
      font-weight: normal;
      margin: 0;
      padding: 0 0 0.3rem;
    }
    &--tags {
      display: flex;
      align-items: center;
      justify-content: start;
      > button:hover {
        cursor: default;
      }
    }
    &--actions {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
`;

const StrikezoneGame = ({ game }) => {
  if (game.perBall.length - 1 <= 10) {
    return <></>;
  }

  return (
    <StyledDiv>
      <h3 className="strikezone-game--title">{game.title}</h3>
      <h4 className="strikezone-game--tvt">
        {`${Multilingual(`GAME.DATE`)}：${
          !game.gameDate || !isValidDate(new Date(game.gameDate))
            ? '-'
            : formatDate(new Date(game.gameDate), 'yyyy/MM/dd')
        } ${game.away} vs ${game.home}`}
      </h4>
      <div className="strikezone-game--tags">
        <Button mini width="unset" color="blue">
          {Multilingual(`GAME.BALL_COUNT`)}：{game.perBall.length - 1}
        </Button>
      </div>
      <div className="strikezone-game--actions">
        <Link to={`/${game.uniqid}`}>
          <Button width="unset" color="blue">
            {Multilingual(`VIEW`)}
          </Button>
        </Link>
      </div>
      {/* {game.description && (
        <span className="strikezone-game--description">
          {game.description}
          參考來源：
          {game.reference.match(/^https:\/\//) ? (
            <a href={game.reference} target="_blank" rel="noreferrer">
              {game.reference}
            </a>
          ) : (
            game.reference
          )}
        </span>
      )} */}
    </StyledDiv>
  );
};

export default StrikezoneGame;
