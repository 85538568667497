import { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '~~elements/Button';
import Input from '~~elements/Input';
import { StrikezoneGameResource } from '~~apis/resource';
import toast from '~~elements/Toast';
import Multilingual from '../Multilingual';
import VideoEmbedYoutube from './VideoEmbedYoutube';
import VideoEmbedScreenShare from './VideoEmbedScreenShare';

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .modes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .control-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .mode-control-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
  }
`;

let screenStream = null;

const VideoEmbedZone = ({ videoWidth, setVideoWidth, strikezoneUniqid, ytIframe, strikeZoneResizable }) => {
  const [embedMode, setEmbedMode] = useState('YOUTUBE');
  const [iframeValue, setIframeValue] = useState('');
  const [screenInShare, setScreenInShare] = useState(false);

  const handleModeChange = (newMode) => () => {
    if (newMode === embedMode) {
      return;
    }

    if (embedMode === 'SCREEN_SHARE') {
      handleStopSharing();
    }
    setEmbedMode(newMode);
  };

  useEffect(() => {
    setIframeValue(!ytIframe ? '' : ytIframe);
  }, [ytIframe]);

  const handleIframeUpdate = () => {
    const updateRemoteValue = () => {
      StrikezoneGameResource.updateStrikezoneGameBasic({
        strikezoneUniqid,
        data: {
          key: 'ytIframe',
          value: iframeValue,
        },
      }).catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      });
    };

    if (iframeValue === '') {
      updateRemoteValue();
      return;
    }

    // 要接受這三種連結
    // https://www.youtube.com/watch?v=Si73cTdFe2c
    // https://youtu.be/Si73cTdFe2c
    // <iframe width="560" height="315" src="https://www.youtube.com/embed/Si73cTdFe2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    const errorToast = () => toast(Multilingual(`VIDEO_MODE.YOUTUBE_FORMAT_ERROR`), { status: 'error', second: 2 });

    let ytValue = iframeValue;
    const iframeMatch = iframeValue.match(/^<iframe.*src="(https:\/\/www.youtube.com\/[^"]+)".*><\/iframe>$/);
    if (iframeMatch) {
      ytValue = iframeMatch[1];
    }

    const ytRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const ytMatch = ytValue.match(ytRegExp);
    let ytID = '';
    if (ytMatch && (ytMatch[2].length === 11 || ytMatch[2].length === 12)) {
      ytID = ytMatch[2];
    }

    if (!ytID) {
      errorToast();
      return;
    }

    const ytIframeLink = `<iframe
      src="https://www.youtube.com/embed/${ytID}"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      >
    </iframe>`;

    const iframeParent = document.getElementById(`video-embed-iframe-placing`);
    iframeParent.innerHTML = '';
    iframeParent.insertAdjacentHTML('afterbegin', ytIframeLink);
    updateRemoteValue();
  };

  const handleSelectScreen = () => {
    if (screenStream) {
      screenStream.getTracks().forEach((track) => {
        track.stop();
      });
      screenStream = null;
    }

    const placingCanvas = document.getElementById(`screen-share-embed-screen-placing`);
    const constraints = { audio: true, video: true };
    navigator.mediaDevices
      .getDisplayMedia(constraints)
      .then((stream) => {
        screenStream = stream;
        placingCanvas.srcObject = screenStream;
        placingCanvas.play();
        setScreenInShare(true);
      })
      .catch((err) => {
        console.log(err);
        // toast(`${Multilingual(`ERROR.UNKNOWN`)} ${err.toString()}`, { status: 'error', second: 3 });
      });
  };

  const handleStopSharing = () => {
    setScreenInShare(false);
    if (!screenStream) {
      return;
    }

    screenStream.getTracks().forEach((track) => {
      track.stop();
    });
    screenStream = null;
  };

  return (
    <StyledDiv>
      <div className="control-btns">
        <div className="modes">
          <Button
            nowrap
            width="unset"
            color={embedMode === 'YOUTUBE' ? 'red' : 'blue'}
            onClick={handleModeChange('YOUTUBE')}
          >
            {Multilingual(`VIDEO_MODE.YOUTUBE`)}
          </Button>
          <Button
            nowrap
            width="unset"
            color={embedMode === 'SCREEN_SHARE' ? 'red' : 'blue'}
            onClick={handleModeChange('SCREEN_SHARE')}
          >
            {Multilingual(`VIDEO_MODE.SCREEN_SHARE`)}
          </Button>
        </div>
        <div className="modes">
          <h5>{Multilingual(`VIDEO_MODE.VIDEO_WIDTH`)}：</h5>
          <Button
            width="unset"
            color="white"
            mini
            disabled={videoWidth <= 25}
            onClick={() => setVideoWidth((pre) => pre - 5)}
          >
            -
          </Button>
          <span>{videoWidth.toString()}</span>
          <Button width="unset" color="white" mini onClick={() => setVideoWidth((pre) => pre + 5)}>
            +
          </Button>
        </div>
      </div>
      {embedMode === 'YOUTUBE' && (
        <Fragment>
          <div className="mode-control-btns">
            <span>{Multilingual(`VIDEO_MODE.YOUTUBE_HINT`)}</span>
            <Input
              width="5rem"
              value={iframeValue}
              onChange={(e) => setIframeValue(e.target.value)}
              backgroundColor="rgba(256, 256, 256, 0.8)"
            />
            <Button width="unset" color="whiteWithBorder" mini onClick={handleIframeUpdate}>
              {Multilingual(`UPDATE`)}
            </Button>
          </div>
          <VideoEmbedYoutube strikeZoneResizable={strikeZoneResizable} />
        </Fragment>
      )}
      {embedMode === 'SCREEN_SHARE' && (
        <Fragment>
          <div className="mode-control-btns">
            <Button width="unset" color="whiteWithBorder" mini onClick={handleSelectScreen}>
              {Multilingual(`VIDEO_MODE.SCREEN_SHARE_CAPTURE`)}
            </Button>
            {screenInShare && (
              <Button width="unset" color="red" mini onClick={handleStopSharing}>
                {Multilingual(`VIDEO_MODE.SCREEN_SHARE_STOP`)}
              </Button>
            )}
          </div>
          <VideoEmbedScreenShare />
        </Fragment>
      )}
    </StyledDiv>
  );
};

export default VideoEmbedZone;
