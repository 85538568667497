import { Fragment } from 'react';
import styled from 'styled-components';
import Button from '~~elements/Button';
import Multilingual from '../Multilingual';
import { usePerBallState } from '../useResultPanel';
import { ZoneDeviceKey } from '../Const';
import BallEdit from './BallEdit';

const AutoFillHeightDiv = styled.div`
  position: relative;
  height: 100%;
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;

  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0.5rem 0;
  }

  .control-btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    button {
      line-height: 1.1;
    }
  }

  .zone-resizable {
    ${(props) =>
      !props.strikeZoneResizable
        ? ''
        : `
    box-shadow: 1px 1px 5px 1px white;
    &:hover,&:focus {
      box-shadow: 1px 1px 5px 1px white;
    }
    `}
  }

  i {
    font-size: 0.8rem;
  }
`;

const VideoBallEdit = ({
  strikezoneUniqid,
  gameState,
  strikeZoneResizable,
  setStrikeZoneResizable,
  saveDeviceZone,
  loadDeviceZone,
  gameLoading,
  gameError,
}) => {
  const { perBall, editingIndex, showEZone } = usePerBallState();

  const hasDeviceZone = !!window.localStorage.getItem(ZoneDeviceKey);

  const displayNotification = () => {
    if (gameLoading) {
      return <span style={{ color: 'yellow' }}>！！！{Multilingual(`SYNC.LOADING`)}！！！</span>;
    }

    if (gameError) {
      return <span style={{ color: 'yellow' }}>！！！{Multilingual(`SYNC.ERROR`)}！！！</span>;
    }

    if (editingIndex !== perBall.length - 1) {
      return <span style={{ color: 'red' }}>！！！{Multilingual(`EDITING_HISTORY`)}！！！</span>;
    }

    return <Fragment></Fragment>;
  };

  return (
    <AutoFillHeightDiv>
      <StyledDiv strikeZoneResizable={strikeZoneResizable}>
        <div className="control-btns">
          <Button
            className="zone-resizable"
            width="6rem"
            color="blue"
            mini
            onClick={() => setStrikeZoneResizable((pre) => !pre)}
          >
            {Multilingual(`VIDEO_MODE.ADJUST_ZONE`)}
          </Button>
          {hasDeviceZone && (
            <Button width="6rem" color="red" mini onClick={loadDeviceZone}>
              {Multilingual(`VIDEO_MODE.LOAD_ZONE_POSITION`)}
            </Button>
          )}
          <Button width="6rem" color="red" mini onClick={saveDeviceZone}>
            {Multilingual(`VIDEO_MODE.SAVE_ZONE_POSITION`)}
          </Button>
        </div>
        {showEZone && <i style={{ color: 'red', display: 'block' }}>{Multilingual(`VIDEO_MODE.E_ZONE_HINT`)}</i>}
        <i style={{ color: 'orange', display: 'block' }}>{Multilingual(`VIDEO_MODE.EYE_ZONE_HINT`)}</i>
        <p>
          {Multilingual(`BALL_SEQ`)}：{editingIndex + 1}
          {displayNotification()}
        </p>
        <BallEdit
          strikezoneUniqid={strikezoneUniqid}
          gameState={gameState}
          keyboardEffect={true}
          radioMode={true}
          videoMode={true}
        />
        <p>{`${Multilingual(`HISTORY_LIST`)}, ${Multilingual(`PC_EDIT_ENTRY`)}`}</p>
      </StyledDiv>
    </AutoFillHeightDiv>
  );
};

export default VideoBallEdit;
