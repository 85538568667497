import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

export const useQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const value = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

  return {
    get: (param) => (value[param] ? value[param] : null),
    set: (params) =>
      navigate({
        pathname: location.pathname,
        search: qs.stringify({ ...value, ...params }),
      }),
    delete: (param) => {
      delete value[param];
      navigate({
        pathname: location.pathname,
        search: qs.stringify({ ...value }),
      });
    },
  };
};
