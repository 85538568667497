import { languagePreferKey } from '~~elements/Const';

const textMapping = {
  LOGIN: {
    'zh-TW': '登入',
    en: 'Login',
  },
  LOGOUT: {
    'zh-TW': '登出',
    en: 'Logout',
  },
  REQUIRED_LOGIN: {
    'zh-TW': '要求登入帳戶',
    en: 'Required account login',
  },
  CANCEL: {
    'zh-TW': '取消',
    en: 'Cancel',
  },
  CREATE: {
    'zh-TW': '新增',
    en: 'Create',
  },
  UPDATE: {
    'zh-TW': '更新',
    en: 'Update',
  },
  REMOVE: {
    'zh-TW': '移除',
    en: 'Remove',
  },
  DELETE: {
    'zh-TW': '刪除',
    en: 'Delete',
  },
  CONFIRM_DELETE: {
    'zh-TW': '確定刪除',
    en: 'Confirm Delete',
  },
  EDIT: {
    'zh-TW': '編輯',
    en: 'Edit',
  },
  CLOSE: {
    'zh-TW': '關閉',
    en: 'Close',
  },
  VIEW: {
    'zh-TW': '瀏覽',
    en: 'View',
  },
  NAME: {
    'zh-TW': '好球帶',
    en: 'StrikezoneHunter',
  },
  NONE: {
    'zh-TW': '無',
    en: 'None',
  },
  START: {
    'zh-TW': '開始',
    en: 'Start',
  },
  BACK: {
    'zh-TW': '返回',
    en: 'Back',
  },
  MORE_ACTION: {
    'zh-TW': '更多動作',
    en: 'More Action',
  },
  NOT_FOUND: {
    'zh-TW': '查無資料',
    en: 'Not Found',
  },
  NULL: {
    'zh-TW': '無記錄',
    en: 'NULL',
  },
  BACK_TO_LIST: {
    'zh-TW': '返回列表',
    en: 'Back to List',
  },
  FEED: {
    PUBLIC_LIST: {
      'zh-TW': '全站公開紀錄',
      en: 'Public Strikezone',
    },
    FEATURED: {
      'zh-TW': '站方精選',
      en: 'Featured',
    },
    NEWEST: {
      'zh-TW': '最新分析',
      en: 'Newest',
    },
    START_RECORD_REDIRECT: {
      'zh-TW': '開始記錄！一秒後協助導轉！',
      en: 'Start your record! Redirect in one second!',
    },
    SUCCESSFULLY_DELETED: {
      'zh-TW': '刪除成功！',
      en: 'Successfully Deleted!',
    },
    NOT_OWNER: {
      'zh-TW': '非擁有者',
      en: 'Not Owner',
    },
    PREMIUM_DELETE_WARNING: {
      'zh-TW': '進階額度不會返還',
      en: 'Quota will not return',
    },
    FREE_DELETE_WARNING: {
      'zh-TW': '系統將協助保存資料 30 天',
      en: 'Data will archive for 30 days',
    },
    LOGIN_FOR_COMPLETED_SERVICE: {
      'zh-TW': '登入享受完整體驗',
      en: 'Login for completed service',
    },
    EMAIL_NOT_VERIFIED_YET: {
      'zh-TW': '尚未進行信箱驗證',
      en: 'Please verify your email',
    },
    START_A_GAME: {
      'zh-TW': '開始好球帶紀錄',
      en: 'Start a Game',
    },
    PLAN_PERIOD: {
      'zh-TW': '訂閱方案',
      en: 'Plan Period',
    },
    PLAN_QUOTA_LEFT: {
      'zh-TW': '方案剩餘額度',
      en: 'Quota Left',
    },
    PLAN_QUOTA_LEFT_COUNT: {
      'zh-TW': '場',
      en: 'Games',
    },
  },
  PLAN_COMPARE: {
    WORDING: {
      'zh-TW': '方案比較',
      en: 'Plan Compare',
    },
    BUY: {
      'zh-TW': '購買',
      en: 'Buy',
    },
    FREE_TRIAL: {
      'zh-TW': '免費試用',
      en: 'Free Trial',
    },
    FREE_TRIAL_CONTENT: {
      'zh-TW': '每個帳號限定一次，提供七天使用權限與最多新增八場比賽的試用額度！',
      en: 'Once for each account. Provide 7 days access with 8 games quota!',
    },
    FREE_TRIAL_HINT: {
      'zh-TW': '可與已訂閱內容相加',
      en: 'Can add up with current plan',
    },
    USE_MY_FREE_TRIAL: {
      'zh-TW': '我要試用！',
      en: 'Use My Free Trial',
    },
    SUCCESSFULLY_START_MY_FREE_TRIAL: {
      'zh-TW': '成功開啟試用！',
      en: 'Successfully start my free trial',
    },
    FREE_TRIAL_ALREADY_USED: {
      'zh-TW': '抱歉！您已試用過，如有需要歡迎購買',
      en: 'Sorry! You have used the free trial. Buy the plan if needed',
    },
  },
  MY: {
    WORDING: {
      'zh-TW': '我的',
      en: 'My',
    },
    UNLISTED: {
      'zh-TW': '非公開',
      en: 'Unlisted',
    },
    PUBLIC: {
      'zh-TW': '公開',
      en: 'Public',
    },
  },
  GAME: {
    TITLE: {
      'zh-TW': '標題',
      en: 'Title',
    },
    COMBINATION: {
      'zh-TW': '對戰組合',
      en: 'Combination',
    },
    AWAY: {
      'zh-TW': '客場',
      en: 'Away',
    },
    HOME: {
      'zh-TW': '主場',
      en: 'Home',
    },
    DATE: {
      'zh-TW': '比賽日期',
      en: 'Game Date',
    },
    UPDATED_AT: {
      'zh-TW': '更新時間',
      en: 'Updated At',
    },
    REF: {
      'zh-TW': '參考影片連結',
      en: 'Reference Link',
    },
    DESCRIPTION: {
      'zh-TW': '內容說明',
      en: 'Description',
    },
    BALL_COUNT: {
      'zh-TW': '總球數',
      en: 'Ball Count',
    },
    DROPPOINT: {
      'zh-TW': '擊球落點',
      en: 'Droppoint',
    },
    LOCATION_CODE: {
      'zh-TW': '落點分布',
      en: 'Location Code',
    },
    SELECT_ALL: {
      'zh-TW': '全選',
      en: 'ALL',
    },
    EMPTY_ALL: {
      'zh-TW': '清空',
      en: 'Empty',
    },
    NO_RESULT: {
      'zh-TW': '無結果',
      en: 'No Result',
    },
    LIVE_MODE: {
      'zh-TW': 'Live 模式',
      en: 'Live Mode',
    },
    ANALYTICAL_DIRECTION: {
      'zh-TW': '分析面相',
      en: 'Direction',
    },
    ANALYTICAL_PLAYER: {
      'zh-TW': '分析對象',
      en: 'Target',
    },
    PLAYER_FACING: {
      'zh-TW': '目標對手',
      en: 'Facing',
    },
    COLUMN: {
      'zh-TW': '欄位',
      en: 'Column',
    },
    ADD_FILTER_COLUMN: {
      'zh-TW': '增加篩選條件',
      en: 'Add Filter Condition',
    },
    REMOVE_FILTER_COLUMN: {
      'zh-TW': '移除條件',
      en: 'Remove Condition',
    },
    COMMON_HAND: {
      'zh-TW': '投打用手',
      en: 'Hand Use',
    },
    PITCHING_HAND: {
      'zh-TW': '投球用手',
      en: 'P Hand',
    },
    BATTING_HAND: {
      'zh-TW': '打擊用手',
      en: 'B Hand',
    },
    INNING: {
      'zh-TW': '局數',
      en: 'Inning',
    },
    OUT: {
      'zh-TW': '出局數',
      en: 'Out',
    },
    ZONE: {
      'zh-TW': '進壘點',
      en: 'Zone',
    },
    BATTER_ACTION: {
      'zh-TW': '打者行為',
      en: 'Action',
    },
    BATTER_RESULT: {
      'zh-TW': '結果',
      en: 'Result',
    },
    CONTACT: {
      'zh-TW': '擊球',
      en: 'Contact',
    },
    PA_BALL_COUNT: {
      'zh-TW': '對戰球數',
      en: 'Count',
    },
    ATBAT: {
      'zh-TW': '打擊內容',
      en: 'At Bat Content',
    },
    PITCH: {
      'zh-TW': '投球內容',
      en: 'Pitch Content',
    },
    STATS: {
      'zh-TW': '數據統計',
      en: 'Stats',
    },
    PLATE_DISCIPLINE: {
      'zh-TW': '本壘板紀律',
      en: 'Plate Discipline',
    },
    HEAT_MAP: {
      'zh-TW': '熱區圖',
      en: 'Heat Map',
    },
    ZONE_MAP: {
      'zh-TW': '熱區圖%',
      en: 'Zone Map',
    },
    BATTER_ANGLE: {
      'zh-TW': '打者視角',
      en: 'Camera Batter Angle',
    },
    AVG: {
      'zh-TW': '打擊率',
      en: 'AVG',
    },
  },
  COMMENT: {
    WORDING: {
      'zh-TW': '留言',
      en: 'Comment',
    },
    HINT: {
      'zh-TW': '登入表達看法吧！',
      en: 'Login to comment',
    },
    AS_PREFIX: {
      'zh-TW': '以',
      en: 'Comment as ',
    },
    AS_SUFFIX: {
      'zh-TW': '身分表達看法',
      en: '',
    },
  },
  PLAYER_ANALYTICS: {
    WORDING: {
      'zh-TW': '球員分析',
      en: 'Player Analytics',
    },
    DESCRIPTION: {
      'zh-TW': '統整您可編輯的所有場次資料，分析追蹤球員的累積表現',
      en: "Sum up your editable games to trace the player's performance",
    },
    TYPE_BATTER: {
      'zh-TW': '打者',
      en: 'Batter',
    },
    TYPE_PITCHER: {
      'zh-TW': '投手',
      en: 'Pitcher',
    },
    TYPE_GAME: {
      'zh-TW': '比賽',
      en: 'Game',
    },
    PLAYER_NAME: {
      'zh-TW': '選手全名',
      en: 'Player Name',
    },
    GAME_DATE: {
      'zh-TW': '比賽日期',
      en: 'Game Date',
    },
    GAME_DATE_FROM: {
      'zh-TW': '從',
      en: 'From',
    },
    GAME_DATE_TO: {
      'zh-TW': '到',
      en: 'To',
    },
    GAME_AMOUNT: {
      'zh-TW': '比賽場數',
      en: 'Game Amount',
    },
    CREATE_ANALYTICS: {
      'zh-TW': '建立分析報表',
      en: 'Generate Analytics',
    },
    NO_DATA: {
      'zh-TW': '查無資料，請重新確認搜尋條件',
      en: 'No data found, please check your searching params',
    },
    NEW_SEARCH: {
      'zh-TW': '重新搜尋',
      en: 'New Search',
    },
    SCROLL_DOWN_VIEW_ANALYSIS: {
      'zh-TW': '下滑檢視分析',
      en: 'Scroll down for Analysis',
    },
    PRINT_REPORT: {
      'zh-TW': '列印報表',
      en: 'Print Report',
    },
    PROVIDE_REPORT_COMMENT: {
      'zh-TW': '提供報表備註/說明',
      en: 'Provide some comment/description',
    },
    REPORT_COMMENT_HINT: {
      'zh-TW': '建議不超過五行避免報表跑版',
      en: 'Recommend no more than five row',
    },
    REPORT_COMMENT: {
      'zh-TW': '備註 / 說明',
      en: 'Comment / Description',
    },
    PRINT: {
      'zh-TW': '列印',
      en: 'Print',
    },
    REPORT_TITLE_PREFIX: {
      'zh-TW': '好球帶分析報表',
      en: 'StrikezoneHunter Report',
    },
    ANALYST: {
      'zh-TW': '分析員',
      en: 'Analyst',
    },
    GENERATE_FROM_PREFIX: {
      'zh-TW': '由',
      en: 'Generate from',
    },
    GENERATE_FROM_APPEND: {
      'zh-TW': '產製',
      en: '',
    },
    START_PREPARING: {
      'zh-TW': '報表將於完成後提供下載連結',
      en: 'Download link will be provided within a min',
    },
    REPORT_PREPARING: {
      'zh-TW': '報表印製中，請稍後...',
      en: 'Report Preparing, please wait...',
    },
    REPORT_COMPLETED: {
      'zh-TW': '報表印製完成，請點擊連結下載',
      en: 'Report Completed! Click the link to download',
    },
  },
  REQUIRED_SUBSCRIPTION: {
    'zh-TW': '請先訂閱方案',
    en: 'Please subscribe a plan',
  },
  GO_TO_PLUGIN_SHOP: {
    'zh-TW': '前往訂閱頁面',
    en: 'Go to plugin shop',
  },
  ERROR: {
    UNKNOWN: {
      'zh-TW': '發生錯誤，請稍後再試！',
      en: 'Oops, something wrong!',
    },
  },

  POSITION: {
    1: {
      'zh-TW': '投手',
      en: 'Pitcher',
    },
    2: {
      'zh-TW': '捕手',
      en: 'Catcher',
    },
    3: {
      'zh-TW': '一壘',
      en: 'First Base',
    },
    4: {
      'zh-TW': '二壘',
      en: 'Second Base',
    },
    5: {
      'zh-TW': '三壘',
      en: 'Third Base',
    },
    6: {
      'zh-TW': '游擊',
      en: 'Shortstop',
    },
    7: {
      'zh-TW': '左外野',
      en: 'Left Outfield',
    },
    8: {
      'zh-TW': '中外野',
      en: 'Center Outfield',
    },
    9: {
      'zh-TW': '右外野',
      en: 'Right Outfield',
    },
  },

  PITCH_TYPE: {
    WORDING: {
      'zh-TW': '球種',
      en: 'Pitch Type',
    },
    F: {
      'zh-TW': '直球',
      en: 'Fast',
    },
    C: {
      'zh-TW': '變速',
      en: 'Offspeed',
    },
    B: {
      'zh-TW': '變化',
      en: 'Breaking',
    },
    OT: {
      'zh-TW': '其他',
      en: 'Other',
    },
  },

  PITCH_DETAIL_TYPE: {
    WORDING: {
      'zh-TW': '詳細球種',
      en: 'Detail Pitch Type',
    },
    FF: {
      'zh-TW': '四縫',
      en: 'Four-Seam',
    },
    FA: {
      'zh-TW': '四縫',
      en: 'Four-Seam',
    },
    FT: {
      'zh-TW': '二縫',
      en: 'Two-Seam',
    },
    FC: {
      'zh-TW': '卡特',
      en: 'Cutter',
    },

    SL: {
      'zh-TW': '滑球',
      en: 'Slider',
    },
    CU: {
      'zh-TW': '曲球',
      en: 'Curve',
    },
    KC: {
      'zh-TW': '彈指曲',
      en: 'Knuckle-curve',
    },
    SC: {
      'zh-TW': '螺旋',
      en: 'Screwball',
    },
    SI: {
      'zh-TW': '二/伸',
      en: 'Two-Seam',
    },

    CH: {
      'zh-TW': '變速',
      en: 'ChangeUp',
    },
    FS: {
      'zh-TW': '快指',
      en: 'Splitter',
    },
    FO: {
      'zh-TW': '指叉',
      en: 'Forkball',
    },

    KN: {
      'zh-TW': '蝴蝶',
      en: 'Knuckleball',
    },
    EP: {
      'zh-TW': '小便',
      en: 'Eephus',
    },
    OT: {
      'zh-TW': '其他',
      en: 'Other',
    },
  },

  JUDGE_CALL: {
    WORDING: {
      'zh-TW': '好壞球',
      en: 'Judge Call',
    },
    SHORT: {
      'zh-TW': '好壞',
      en: 'Call',
    },
    S: {
      'zh-TW': '好球',
      en: 'Strike',
    },
    B: {
      'zh-TW': '壞球',
      en: 'Ball',
    },
  },

  BASE: {
    WORDING: {
      'zh-TW': '壘包',
      en: 'Base',
    },
    0: {
      'zh-TW': '___',
    },
    1: {
      'zh-TW': '1__',
    },
    2: {
      'zh-TW': '_2_',
    },
    4: {
      'zh-TW': '__3',
    },
    3: {
      'zh-TW': '12_',
    },
    5: {
      'zh-TW': '1_3',
    },
    6: {
      'zh-TW': '_23',
    },
    7: {
      'zh-TW': '123',
    },
  },
};

const Multilingual = (textCode, additionalTextMapping = {}, lang = '') => {
  const userDefaultLangs = [lang, ...navigator.languages];

  const languagePrefer = localStorage.getItem(languagePreferKey);
  if (languagePrefer) {
    userDefaultLangs.unshift(languagePrefer);
  }

  const textCodeIndent = textCode.split('.');

  let target = { ...textMapping, ...additionalTextMapping };
  textCodeIndent.forEach((key) => {
    if (!target[key]) {
      console.log(target);
      console.log(key);
      return textCode;
      // throw new Error();
    }

    target = target[key];
  });

  for (let userLang of userDefaultLangs) {
    if (!target[userLang] && target[userLang] !== '') {
      continue;
    }

    return target[userLang];
  }
  return target['zh-TW'];
};

export default Multilingual;
