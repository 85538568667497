import styled from 'styled-components';
import Modal from '~~elements/Modal';
import Button from '~~elements/Button';
import { FONT_COLOR } from '~~elements/Const';
import Multilingual from '../Multilingual';
import {
  ballTypes,
  ballDetailTypes,
  judgeCalls,
  batterActions,
  ballContacts,
  ballTrajectories,
  batterResults,
  getBatterResultOptions,
} from './ResultPanel/ResultPanel';
import { useEffect, useState } from 'react';

const StyledDiv = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${FONT_COLOR};

  .pre-btn {
    align-self: start;
  }

  .speed {
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .number-pad {
    width: 100%;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    > button {
      padding: 1.8rem 0;
      font-size: 1.2rem;
    }
    &.disabled {
      > button {
        background: #e1e1e1;
      }
    }
  }
  .column-options {
    width: 100%;
    display: grid;
    margin: 1rem 0;
    gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    div {
      padding: 1.5rem 0;
      font-size: 1.2rem;
    }
  }
`;

const columnMultilingualMapping = {
  speed: 'SPEED',
  ballDetailType: 'PITCH_DETAIL_TYPE',
  ballType: 'PITCH_TYPE',
  judgeCall: 'JUDGE_CALL',
  batterAction: 'BATTER_ACTION',

  ballContact: 'CONTACT',
  ballTrajectory: 'TRAJECTORY',
  batterResult: 'BATTER_RESULT',
};

const preColumnMapping = {
  // ballDetailType: 'speed',
  // ballType: 'ballDetailType',
  ballType: 'speed',
  judgeCall: 'ballType',
  batterAction: 'judgeCall',

  ballContact: 'batterAction',
  ballTrajectory: 'ballContact',
  batterResult: 'ballTrajectory',
};

const nextColumnMapping = {
  // speed: 'ballType',
  speed: 'ballDetailType',
  ballDetailType: 'ballType',
  ballType: 'judgeCall',
  judgeCall: 'batterAction',

  batterAction: 'ballContact',
  ballContact: 'ballTrajectory',
  ballTrajectory: 'batterResult',
};

const BallEditModal = ({
  column,
  perBall,
  editingIndex,
  setCurrentBall,
  resetSpeed,
  openDroppointDiagram,
  resetCurrentBallDroppoint,
}) => {
  const [tmpSpeed, setTmpSpeed] = useState('');
  const [resetSpeedWait, setResetSpeedWait] = useState(false);

  useEffect(() => {
    if (!perBall?.[editingIndex]) {
      return;
    }

    setTmpSpeed(perBall[editingIndex].speed);
  }, [perBall, editingIndex]);

  const toClose = () => {
    const selfEl = document.querySelector(`.ball-edit-modal--${column}`);
    if (!selfEl) {
      return;
    }

    selfEl.style.display = 'none';
  };

  const handleTmpSpeedChange = (number) => () => {
    if (resetSpeedWait) {
      return;
    }
    setTmpSpeed((pre) => `${pre}${number}`);
  };

  const handleSpeedUpdate = (e) => {
    setCurrentBall('speed')({
      target: {
        value: tmpSpeed,
      },
    });
    setTimeout(() => {
      nextColumn(e);
    }, 600);
  };

  const handleResetSpeed = () => {
    resetSpeed();
    setResetSpeedWait(true);
    setTimeout(() => {
      setResetSpeedWait(false);
    }, 1000);
  };

  const optionChange = (e) => {
    nextColumn(e);
    setCurrentBall(column)(e);
  };

  const preColumn = () => {
    toClose();
    if (!preColumnMapping[column]) {
      return;
    }

    const preEl = document.querySelector(`.ball-edit-modal--${preColumnMapping[column]}`);
    if (!preEl) {
      return;
    }

    preEl.style.display = 'block';
  };

  const nextColumn = (e) => {
    toClose();
    if (!nextColumnMapping[column] || (column === 'batterAction' && e?.target?.value !== 'INFIELD')) {
      return;
    }

    const nextEl = document.querySelector(`.ball-edit-modal--${nextColumnMapping[column]}`);
    if (!nextEl) {
      return;
    }

    nextEl.style.display = 'block';
  };

  const getBodyOfColumn = () => {
    switch (column) {
      case 'speed':
        return (
          <StyledDiv>
            <div className="speed">
              <span>{tmpSpeed}</span>
              <Button width="unset" color="blue" mini onClick={handleSpeedUpdate}>
                {Multilingual(`UPDATE`)}
              </Button>
              <span>&nbsp;</span>
              <Button width="unset" color="transparentWithBorder" mini onClick={handleResetSpeed}>
                {Multilingual(`SPEED.RESET`)}
              </Button>
            </div>
            <div className={`number-pad ${resetSpeedWait ? 'disabled' : ''}`}>
              {['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', ''].map((number, i) =>
                !number ? (
                  <span key={`${i}-${number}`} />
                ) : (
                  <Button key={`${i}-${number}`} color="blue" onClick={handleTmpSpeedChange(number)}>
                    {number}
                  </Button>
                ),
              )}
            </div>
          </StyledDiv>
        );
      case 'ballDetailType':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            <div className="column-options">
              <div>
                <label>
                  <input
                    type="radio"
                    value=""
                    checked={perBall[editingIndex].ballDetailType === ''}
                    onChange={optionChange}
                  />
                  -
                </label>
              </div>
              {ballDetailTypes.map((ballDetailType) => (
                <div key={`modal-${ballDetailType}`}>
                  <label>
                    <input
                      type="radio"
                      value={ballDetailType}
                      checked={perBall[editingIndex].ballDetailType === ballDetailType}
                      onChange={optionChange}
                    />
                    {ballDetailType}
                    {/* {Multilingual(`PITCH_DETAIL_TYPE.${ballDetailType}`)} */}
                  </label>
                </div>
              ))}
            </div>
          </StyledDiv>
        );
      case 'ballType':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            <div className="column-options">
              <div>
                <label>
                  <input
                    type="radio"
                    value=""
                    checked={perBall[editingIndex].ballType === ''}
                    onChange={optionChange}
                  />
                  -
                </label>
              </div>
              {Object.keys(ballTypes).map((ballType) => (
                <div key={ballType}>
                  <label>
                    <input
                      type="radio"
                      value={ballType}
                      checked={perBall[editingIndex].ballType === ballType}
                      onChange={optionChange}
                    />
                    {Multilingual(`PITCH_TYPE.${ballType.toUpperCase()}`)}
                  </label>
                </div>
              ))}
            </div>
          </StyledDiv>
        );
      case 'judgeCall':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            <div className="column-options">
              <div>
                <label>
                  <input
                    type="radio"
                    value=""
                    checked={perBall[editingIndex].judgeCall === ''}
                    onChange={optionChange}
                  />
                  -
                </label>
              </div>
              {Object.keys(judgeCalls).map((judgeCall) => (
                <div key={judgeCall}>
                  <label>
                    <input
                      type="radio"
                      value={judgeCall}
                      checked={perBall[editingIndex].judgeCall === judgeCall}
                      onChange={optionChange}
                    />
                    {Multilingual(`JUDGE_CALL.${judgeCall.toUpperCase()}`)}
                  </label>
                </div>
              ))}
            </div>
          </StyledDiv>
        );
      case 'batterAction':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            <div className="column-options">
              <div>
                <label>
                  <input
                    type="radio"
                    value=""
                    checked={perBall[editingIndex].batterAction === ''}
                    onChange={optionChange}
                  />
                  -
                </label>
              </div>
              {Object.keys(batterActions).map((action) => (
                <div key={action}>
                  <label>
                    <input
                      type="radio"
                      value={action}
                      checked={perBall[editingIndex].batterAction === action}
                      onChange={optionChange}
                    />
                    {Multilingual(`BATTER_ACTION.${action.toUpperCase()}`)}
                  </label>
                </div>
              ))}
            </div>
          </StyledDiv>
        );
      case 'ballContact':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            {/* <div className="column-options">
              <label>
                <input
                  type="radio"
                  value=""
                  checked={perBall[editingIndex].ballContact === ''}
                  onChange={setCurrentBall('ballContact')}
                />
                -
              </label>
              {Object.keys(ballContacts).map((ballContact) => (
                <label key={ballContact}>
                  <input
                    type="radio"
                    value={ballContact}
                    checked={perBall[editingIndex].ballContact === ballContact}
                    onChange={setCurrentBall('ballContact')}
                  />
                  {ballContact}
                </label>
              ))}
            </div> */}
            <Button onClick={openDroppointDiagram} mini color="blue" width="unset">
              {Multilingual(`DROPPOINT.WORDING`)}：
              {!perBall[editingIndex].ballDroppoint ? Multilingual(`NULL`) : perBall[editingIndex].ballDroppoint}
            </Button>
            <Button width="unset" color="transparentWithBorder" mini onClick={resetCurrentBallDroppoint}>
              {Multilingual(`ENTRY_POINT.RESET`)}
            </Button>
          </StyledDiv>
        );
      case 'ballTrajectory':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            <div className="column-options">
              <div>
                <label>
                  <input
                    type="radio"
                    value=""
                    checked={perBall[editingIndex].ballTrajectory === ''}
                    onChange={optionChange}
                  />
                  -
                </label>
              </div>
              {Object.keys(ballTrajectories).map((trajectory) => (
                <div key={trajectory}>
                  <label>
                    <input
                      type="radio"
                      value={trajectory}
                      checked={perBall[editingIndex].ballTrajectory === trajectory}
                      onChange={optionChange}
                    />
                    {trajectory}
                  </label>
                </div>
              ))}
            </div>
          </StyledDiv>
        );
      case 'batterResult':
        return (
          <StyledDiv>
            <Button className="pre-btn" width="5rem" color="transparentWithBorder" onClick={preColumn}>
              {Multilingual(`BACK`)}
            </Button>
            <div className="column-options">
              <div>
                <label>
                  <input
                    type="radio"
                    value=""
                    checked={perBall[editingIndex].batterResult === ''}
                    onChange={optionChange}
                  />
                  -
                </label>
              </div>
              {getBatterResultOptions(perBall[editingIndex].batterAction, perBall[editingIndex].ballTrajectory).map(
                (result) => (
                  <div key={result}>
                    <label>
                      <input
                        type="radio"
                        value={result}
                        checked={perBall[editingIndex].batterResult === result}
                        onChange={optionChange}
                      />
                      {Multilingual(`BATTER_RESULT.${result}`)}
                    </label>
                  </div>
                ),
              )}
            </div>
          </StyledDiv>
        );
      default:
        break;
    }

    return <StyledDiv></StyledDiv>;
  };

  return (
    <Modal.Modal
      className={`ball-edit-modal--${column}`}
      isOpen={false}
      onClickBack={toClose}
      noPadding={true}
      size="sm"
    >
      <Modal.ModalHeader>{Multilingual(`${columnMultilingualMapping[column]}.WORDING`)}</Modal.ModalHeader>
      <Modal.ModalBody>{getBodyOfColumn()}</Modal.ModalBody>
      <Modal.ModalFooter>
        <Button color="transparentWithBorder" onClick={nextColumn}>
          {!!perBall[editingIndex][column] ? Multilingual(`ON_SITE_MODAL.NEXT`) : Multilingual(`ON_SITE_MODAL.SKIP`)}
        </Button>
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

export default BallEditModal;
