import { Fragment, useEffect } from 'react';
import { format as formatDate } from 'date-fns';
import Multilingual from '~~utils/Multilingual';
import { getNameDisplay } from '~~elements/Const';
import LogoPNG from '~~elements/static/logo.png';
import ApiService from '~~commonAPIs/ApiService';
import { useFilterState } from '../StrikezoneGame/hooks/useFilter';
import { transform4Display } from '../StrikezoneGame/StrikeZone/index';
import JudgeCall from '../StrikezoneGame/StrikeZone/JudgeCall';
import BallType from '../StrikezoneGame/StrikeZone/BallType';
import Heatmap from '../StrikezoneGame/StrikeZone/Heatmap';
import AvgZonemap from '../StrikezoneGame/StrikeZone/AvgZonemap';
import Droppoint from '../StrikezoneGame/Droppoint';
import PitchTable from '../StrikezoneGame/PitchTable';
import AtBatTable from '../StrikezoneGame/AtBatTable';
import StatsTable from '../StrikezoneGame/StatsTable';
import ZoneTable from '../StrikezoneGame/ZoneTable';
import { useRootState } from '../../useRoot';

const Report = ({ perBall, player, playerType, dateStart, dateEnd, gameAmount, reportComment, submitted }) => {
  const {
    side,
    position,
    selectedPlayers,
    selectedOpponents,
    selectedInnings,
    selectedBases,
    selectedOuts,
    selectedZones,
    selectedHands,
    selectedBatterActions,
    selectedJudgeCalls,
    selectedBallTypes,
    selectedBallDetailTypes,
    selectedBatterResults,
    selectedContacts,
    selectedBallCounts,
  } = useFilterState();

  const {
    loginUser: { firstName, lastName },
  } = useRootState();

  const { resolvedZonemap, batterResultOnly, zoneMap } = transform4Display({
    perBall,

    side,
    position,
    selectedPlayers,
    selectedOpponents,
    selectedInnings,
    selectedBases,
    selectedOuts,
    selectedZones,
    selectedHands,
    selectedBatterActions,
    selectedJudgeCalls,
    selectedBallTypes,
    selectedBallDetailTypes,
    selectedBatterResults,
    selectedContacts,
    selectedBallCounts,
  });

  useEffect(() => {
    setTimeout(() => {
      const forPrint = document.getElementById('for-print');

      const imgs = forPrint.querySelectorAll('img');
      for (let img of imgs) {
        if (!img.src.match(/^http/) && !img.src.match(/^\//)) {
          continue;
        }
        const tmpCanvas = document.createElement('canvas');
        const ctx = tmpCanvas.getContext('2d');
        tmpCanvas.height = img.clientHeight;
        tmpCanvas.width = img.clientWidth;
        ctx.drawImage(img, 0, 0, tmpCanvas.width, tmpCanvas.height);
        img.setAttribute('src', tmpCanvas.toDataURL('image/png'));
      }

      const formData = new FormData();
      const title =
        `${Multilingual(`PLAYER_ANALYTICS.REPORT_TITLE_PREFIX`)}-${playerType} ${player}` +
        `-${formatDate(new Date(), 'yyyyMMdd')}`;
      const params = {
        title,
        back_url: window.location.href,
        content: forPrint.innerHTML,
      };
      for (const key in params) {
        formData.append(key, params[key]);
      }

      submitted(
        title,
        ApiService.post(`${process.env.REACT_APP_BACKEND_URL}/print_player_report`, {
          data: formData,
          withCredentials: true,
          responseType: 'blob',
          timeout: 60000,
        }),
      );
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Header = (
    <Fragment>
      <label className="header-right">{`${Multilingual(
        `PLAYER_ANALYTICS.GENERATE_FROM_PREFIX`,
      )} 野革運動股份有限公司 rebas.tw ${Multilingual(`PLAYER_ANALYTICS.GENERATE_FROM_APPEND`)}`}</label>
    </Fragment>
  );

  const Footer = (
    <Fragment>
      <label className="footer-left">{formatDate(new Date(), 'yyyy/MM/dd')}</label>
    </Fragment>
  );

  return (
    <div id="for-print">
      <div className="content-page cover">
        <div className="upper">
          <img style={{ width: '30vw', height: '30vw' }} src={LogoPNG} alt="logo" />
          <h1>{Multilingual(`PLAYER_ANALYTICS.REPORT_TITLE_PREFIX`)}</h1>
          <p />
          <h2>{playerType}</h2>
          <h1>{player}</h1>
        </div>
        <div className="middle">
          {`${Multilingual(`PLAYER_ANALYTICS.GAME_DATE`)}：${dateStart} - ${dateEnd}\n`}
          {`${Multilingual(`PLAYER_ANALYTICS.GAME_AMOUNT`)}：${gameAmount}\n`}
          {`${Multilingual(`PLAYER_ANALYTICS.REPORT_COMMENT`)}：${reportComment ? '' : 'NONE'}\n`}
          {reportComment}
        </div>
        <div className="bottom">
          <p>{`${Multilingual(`PLAYER_ANALYTICS.ANALYST`)}：${getNameDisplay(firstName, lastName)}`}</p>
        </div>
      </div>
      <div className="page-break" />
      <section className="content-page">
        {Header}
        <div className="content-row strikezone">
          <JudgeCall resolvedPerBall={resolvedZonemap} />
          <BallType resolvedPerBall={resolvedZonemap} />
        </div>
        {Footer}
      </section>
      <div className="page-break" />
      <section className="content-page">
        {Header}
        <div className="content-row strikezone">
          <Heatmap resolvedPerBall={resolvedZonemap} />
          <AvgZonemap zoneMap={batterResultOnly} />
        </div>
        {Footer}
      </section>
      <div className="page-break" />
      <section className="content-page">
        {Header}
        <Droppoint resolvedPerBall={resolvedZonemap} />
        {Footer}
      </section>
      <section className="content-page">
        {Header}
        {position === 'pitcher' ? (
          <PitchTable resolvedPerBall={resolvedZonemap} split={true} />
        ) : (
          <AtBatTable resolvedPerBall={resolvedZonemap} split={true} />
        )}
        {Footer}
      </section>
      <div className="page-break" />
      <section className="content-page">
        {Header}
        <StatsTable resolvedPerBall={resolvedZonemap} split={true} />
        {Footer}
      </section>
      <div className="page-break" />
      <section className="content-page">
        {Header}
        <ZoneTable resolvedPerBall={resolvedZonemap} zoneMap={zoneMap} split={true} />
        {Footer}
      </section>
    </div>
  );
};

export default Report;
