import { useEffect, useState, useCallback, Fragment } from 'react';
import { find, findIndex } from 'ramda';
import toast from '~~elements/Toast';
import { StrikezoneGameResource } from '~~apis/resource';
import { usePerBallState, usePerBallDispatch } from '../useResultPanel';
import { FreeBallLimit, PremiumBallLimit } from '../../../Const';
import BallEditSelectMode from './BallEditSelectMode';
import BallEditRadioMode from './BallEditRadioMode';
import DroppointClick from './DroppointClick';
import Multilingual from '../Multilingual';
import BallEditModal from './BallEditModal';

// 防止過度反應，在用戶還在更改的過程中就不需要去即時同步
const debounce = {};

const BallEdit = ({ strikezoneUniqid, gameState, keyboardEffect, radioMode = false, videoMode = false }) => {
  const { perBall, editingIndex } = usePerBallState();
  const perBallDispatch = usePerBallDispatch();

  const [awayPlayers, setAwayPlayers] = useState([]);
  const [homePlayers, setHomePlayers] = useState([]);

  const [droppointDiagramOpen, setDroppointDiagramOpen] = useState(false);

  useEffect(() => {
    perBallDispatch({ type: 'setStrikezoneUniqid', payload: strikezoneUniqid });
  }, [perBallDispatch, strikezoneUniqid]);

  useEffect(() => {
    setAwayPlayers(gameState.awayPlayers);
  }, [gameState.awayPlayers]);

  useEffect(() => {
    setHomePlayers(gameState.homePlayers);
  }, [gameState.homePlayers]);

  useEffect(() => {
    Object.keys(debounce).forEach((debounceKey) => {
      const [editIndex, ballKey] = debounceKey.split('-');
      gameState.perBall[editIndex][ballKey] = debounce[debounceKey].value;
    });
    perBallDispatch({ type: 'remoteCover', payload: gameState.perBall });
  }, [perBallDispatch, gameState.perBall]);

  const addBallable = useCallback(() => {
    if (!gameState.isPremium && perBall.length >= FreeBallLimit + 1) {
      return false;
    }

    if (gameState.isPremium && perBall.length >= PremiumBallLimit + 1) {
      return false;
    }

    return true;
  }, [perBall, gameState.isPremium]);

  // 由 useEffect remoteCover 去自動更新編輯索引
  const addBall = useCallback(() => {
    if (!addBallable()) {
      if (!gameState.isPremium) {
        toast(Multilingual(`INFO.REACH_BALL_LIMIT`), { status: 'error', second: 2 });
        return;
      }
      toast(Multilingual(`INFO.REACH_BALL_LIMIT`), { status: 'error', second: 2 });
      return;
    }

    let nextBatter = perBall[perBall.length - 1].batter;
    let nextBHand = perBall[perBall.length - 1].bHand;
    if (perBall[perBall.length - 1].batterResult !== '') {
      const players =
        perBall[perBall.length - 1].currentSide === 'away' ? gameState.awayPlayers : gameState.homePlayers;
      const currentBatterIndex = findIndex((p) => p.name === nextBatter, players);
      if (currentBatterIndex >= 0) {
        const nextPlayer = currentBatterIndex === players.length - 1 ? players[0] : players[currentBatterIndex + 1];
        nextBatter = nextPlayer.name;

        nextBHand = '';
        if (nextPlayer && nextPlayer.hand) {
          nextBHand = nextPlayer.hand.split('/')[1];
        }
      }
    }

    StrikezoneGameResource.addStrikezoneGameBall({
      strikezoneUniqid,
      data: {
        index: perBall.length,
        inning: perBall[perBall.length - 1].inning,
        currentSide: perBall[perBall.length - 1].currentSide,
        pitcher: perBall[perBall.length - 1].pitcher,
        pHand: perBall[perBall.length - 1].pHand,
        batter: nextBatter,
        bHand: nextBHand,
        bases: perBall[perBall.length - 1].bases,
        outs: perBall[perBall.length - 1].outs,
      },
    }).catch((e) => {
      console.error(e);
      toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
    });
  }, [strikezoneUniqid, perBall, gameState.awayPlayers, gameState.homePlayers, gameState.isPremium, addBallable]);

  // 由 useEffect remoteCover 去自動更新編輯索引
  const removeBall = () => {
    StrikezoneGameResource.removeStrikezoneGameBall({
      strikezoneUniqid,
      data: {
        index: editingIndex,
      },
    }).catch((e) => {
      console.error(e);
      toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
    });
  };

  const perBallUpdate = useCallback(
    (key, value) => {
      perBallDispatch({
        type: 'updateBall',
        payload: { key, value },
      });

      const debounceKey = `${editingIndex}-${key}`;
      if (debounce[debounceKey]) {
        clearTimeout(debounce[debounceKey].timeoutID);
        delete debounce[debounceKey];
      }
      debounce[debounceKey] = {
        timeoutID: setTimeout(() => {
          StrikezoneGameResource.updateStrikezoneGameBall({
            strikezoneUniqid,
            data: {
              index: editingIndex,
              key,
              value,
            },
          })
            .then(() => {
              delete debounce[debounceKey];
            })
            .catch((e) => {
              console.error(e);
              toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
            });
        }, 500),
        value,
      };
    },
    [strikezoneUniqid, editingIndex, perBallDispatch],
  );

  useEffect(() => {
    if (!keyboardEffect) {
      return;
    }

    const keycontrol = (e) => {
      if (['INPUT', 'SELECT', 'OPTION', 'TEXTAREA'].includes(e.target.tagName.toUpperCase())) {
        return true;
      }

      e.preventDefault();
      const keyCode = e.keyCode;
      console.log(keyCode);

      // 13 = enter
      // 45 = -
      // 49 50 51 52 53 54 55 56 57 48 = 1 2 3 4 5 6 7 8 9 0
      // 113 119 101 = q w e
      // 97 115 100 = a s d
      // 122 120 99 118 98 110 = z x c v b n

      if (48 <= keyCode && 57 >= keyCode) {
        const currentSpeed = perBall[editingIndex].speed;
        perBallUpdate('speed', currentSpeed + (keyCode - 48).toString());
        return false;
      }

      switch (keyCode) {
        case 13:
          editingIndex !== perBall.length - 1 ? perBallDispatch({ type: 'backFromNoneCurrentEdit' }) : addBall();
          break;

        case 45:
          perBallUpdate('speed', '');
          break;

        case 113:
          perBallUpdate('ballType', '');
          perBallUpdate('ballDetailType', '');
          break;
        case 119:
          perBallUpdate('ballType', 'F');
          perBallUpdate('ballDetailType', '');
          break;
        case 101:
          perBallUpdate('ballType', 'C');
          perBallUpdate('ballDetailType', '');
          break;
        case 114:
          perBallUpdate('ballType', 'B');
          perBallUpdate('ballDetailType', '');
          break;

        case 97:
          perBallUpdate('judgeCall', '');
          break;
        case 115:
          perBallUpdate('judgeCall', 'S');
          break;
        case 100:
          perBallUpdate('judgeCall', 'B');
          perBallUpdate('batterAction', 'NONE');
          break;

        case 122:
          perBallUpdate('batterAction', '');
          break;
        case 120:
          perBallUpdate('batterAction', 'NONE');
          break;
        case 99:
          perBallUpdate('batterAction', 'SWMISS');
          break;
        case 118:
          perBallUpdate('batterAction', 'SWTIP');
          break;
        case 98:
          perBallUpdate('batterAction', 'INFIELD');
          break;
        case 110:
          perBallUpdate('batterAction', 'OUTFIELD');
          break;
        default:
          break;
      }
    };
    window.addEventListener('keypress', keycontrol);
    return () => {
      window.removeEventListener('keypress', keycontrol);
    };
  }, [perBall, editingIndex, perBallDispatch, perBallUpdate, addBall, keyboardEffect]);

  const resetZoneResult = (key) => () => {
    perBallDispatch({
      type: 'updateZoneResult',
      payload: {
        key,
        value: {
          x: '',
          y: '',
          width: 0,
          height: 0,
        },
      },
    });
  };

  const resetSpeed = () => {
    perBallUpdate('speed', '');
  };

  const setCurrentSide = (e) => {
    perBallDispatch({ type: 'updateCurrentSide', payload: e.target.value });
  };

  const setCurrentPitcher = (e) => {
    const players = perBall[editingIndex].currentSide === 'away' ? homePlayers : awayPlayers;
    const player = find((p) => p.name === e.target.value, players);

    let hand = '';
    if (player && player.hand) {
      hand = player.hand.split('/')[0];
    }

    perBallDispatch({
      type: 'updatePitcher',
      payload: {
        name: e.target.value,
        hand,
      },
    });
  };

  const setCurrentBatter = (e) => {
    const players = perBall[editingIndex].currentSide === 'away' ? awayPlayers : homePlayers;
    const player = find((p) => p.name === e.target.value, players);

    let hand = '';
    if (player && player.hand) {
      hand = player.hand.split('/')[1];
    }

    perBallDispatch({
      type: 'updateBatter',
      payload: {
        name: e.target.value,
        hand,
      },
    });
  };

  const setCurrentBall = (key) => (e) => {
    perBallUpdate(key, e.target.value);

    // 輸入輔助
    switch (key) {
      case 'ballType':
        perBallUpdate('ballDetailType', '');
        break;
      case 'ballDetailType':
        if (['FA', 'FT', 'FC'].includes(e.target.value)) {
          perBallUpdate('ballType', 'F');
          break;
        }
        if (['SL', 'CU', 'KC', 'SC', 'SI'].includes(e.target.value)) {
          perBallUpdate('ballType', 'B');
          break;
        }
        if (['CH', 'FS', 'FO'].includes(e.target.value)) {
          perBallUpdate('ballType', 'C');
          break;
        }
        if (['KN', 'EP', 'OT'].includes(e.target.value)) {
          perBallUpdate('ballType', 'OT');
          break;
        }
        break;
      case 'judgeCall':
        if (e.target.value !== 'B') {
          break;
        }
        perBallUpdate('batterAction', 'NONE');
        break;
      default:
        break;
    }
  };

  const setCurrentBallDroppoint = (locationCode, xy) => {
    perBallDispatch({
      type: 'updateBallDroppoint',
      payload: {
        ballDroppoint: locationCode,
        ballDroppointXY: xy,
      },
    });
    setDroppointDiagramOpen(false);
  };

  const resetCurrentBallDroppoint = () => {
    perBallDispatch({
      type: 'updateBallDroppoint',
      payload: {
        ballDroppoint: '',
        ballDroppointXY: null,
      },
    });
  };

  return (
    <Fragment>
      {radioMode ? (
        <BallEditRadioMode
          perBall={perBall}
          editingIndex={editingIndex}
          setCurrentSide={setCurrentSide}
          setCurrentPitcher={setCurrentPitcher}
          awayPlayers={awayPlayers}
          homePlayers={homePlayers}
          setCurrentBall={setCurrentBall}
          setCurrentBatter={setCurrentBatter}
          resetZoneResult={resetZoneResult}
          resetSpeed={resetSpeed}
          removeBall={removeBall}
          perBallDispatch={perBallDispatch}
          addBall={addBall}
          addBallable={addBallable}
          videoMode={videoMode}
          openDroppointDiagram={() => setDroppointDiagramOpen(true)}
          resetCurrentBallDroppoint={resetCurrentBallDroppoint}
        />
      ) : (
        <BallEditSelectMode
          perBall={perBall}
          editingIndex={editingIndex}
          setCurrentSide={setCurrentSide}
          setCurrentPitcher={setCurrentPitcher}
          awayPlayers={awayPlayers}
          homePlayers={homePlayers}
          setCurrentBall={setCurrentBall}
          setCurrentBatter={setCurrentBatter}
          resetZoneResult={resetZoneResult}
          removeBall={removeBall}
          resetSpeed={resetSpeed}
          perBallDispatch={perBallDispatch}
          addBall={addBall}
          addBallable={addBallable}
          openDroppointDiagram={() => setDroppointDiagramOpen(true)}
          resetCurrentBallDroppoint={resetCurrentBallDroppoint}
        />
      )}
      {!videoMode &&
        // speed ballDetailType ballType judgeCall batterAction ballContact ballTrajectory batterResult 在現場模式可以連續在主螢幕點擊
        [
          'speed',
          'ballDetailType',
          'ballType',
          'judgeCall',
          'batterAction',
          'ballContact',
          'ballTrajectory',
          'batterResult',
        ].map((column) => (
          <BallEditModal
            key={column}
            column={column}
            perBall={perBall}
            editingIndex={editingIndex}
            setCurrentBall={setCurrentBall}
            resetSpeed={resetSpeed}
            openDroppointDiagram={() => setDroppointDiagramOpen(true)}
            resetCurrentBallDroppoint={resetCurrentBallDroppoint}
          />
        ))}
      <DroppointClick
        isOpen={droppointDiagramOpen}
        toClose={() => setDroppointDiagramOpen(false)}
        currentPoint={perBall[editingIndex]?.ballDroppointXY}
        savePoint={setCurrentBallDroppoint}
      />
    </Fragment>
  );
};

export default BallEdit;
