import moment from 'moment';
import { getYoutubeID } from './Utils';

export const FLASH_EVENT = {
  '2022_ONCE_MEMBER': '2022_once_member',
};

export const validLink = (link) => {
  return !!link.match(/^https:\/\/[\w-]+\.[\w-]+[./][^ ]+$/);
};

const videoLinkPrefix = ['youtu.be', 'www.youtube.com', 'vimeo.com', 'player.vimeo.com'];
const videoRegex = new RegExp(`^https://(${videoLinkPrefix.join('|')})/`);

export const isVideoLink = (link) => {
  return link.match(videoRegex);
};

export const parseYTLink = (link, options = {}) => {
  const { shift = '', toIFrame = false, autoplay = false } = options;
  let ytLinkID = '';
  let ytLink = '';
  if (link.match(/^https:\/\/www\.youtube\.com\//)) {
    ytLink = new URL(link);
    if (ytLink.searchParams.has('v') && !!ytLink.searchParams.get('v')) {
      ytLinkID = ytLink.searchParams.get('v');
    }
  }
  if (link.match(/^https:\/\/youtu\.be\//)) {
    ytLink = new URL(link);
    ytLinkID = ytLink.pathname.split('/').slice(-1)[0];
  }

  if (!ytLinkID) {
    return '';
  }

  const start = ytLink.searchParams.get('t') ?? ytLink.searchParams.get('start');

  if (!toIFrame) {
    return ytLinkID;
  }

  let iframeLink =
    `https://www.youtube.com/embed/${ytLinkID}` + `?rel=0&playsinline=1&autoplay=${autoplay ? '1' : '0'}`;
  if (shift) {
    iframeLink += `&start=${shift}`;
  } else if (start) {
    iframeLink += `&start=${start}`;
  }

  return iframeLink;
};

export const getFormalGameLink = (seasonUniqid, date, uniqid, withHost = true) => {
  return (
    `${withHost ? process.env.REACT_APP_MAINPAGE_URL : ''}/season/${seasonUniqid}/` +
    `${moment(date).format('YYYY-MM-DD')}-${uniqid}`
  );
};
