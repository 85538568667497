import moment from 'moment';
import { FLASH_EVENT } from './Const';

export const getYoutubeID = (link) => {
  // 要接受這三種連結
  // https://www.youtube.com/watch?v=Si73cTdFe2c
  // https://youtu.be/Si73cTdFe2c
  // <iframe width="560" height="315" src="https://www.youtube.com/embed/Si73cTdFe2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

  let ytValue = link;
  const iframeMatch = link.match(/^<iframe.*src="(https:\/\/www.youtube.com\/[^"]+)".*><\/iframe>$/);
  if (iframeMatch) {
    ytValue = iframeMatch[1];
  }

  const ytRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const ytMatch = ytValue.match(ytRegExp);
  let ytID = '';
  if (ytMatch && (ytMatch[2].length === 11 || ytMatch[2].length === 12)) {
    ytID = ytMatch[2];
  }

  return ytID;
};

export const CSVToArray = (strData, strDelimiter) => {
  if (strData.length === 0) {
    return [];
  }
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = strDelimiter || ',';

  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    // Delimiters.
    '(\\' +
      strDelimiter +
      '|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      '\\r\\n]*))',
    'gi',
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  const arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    let strMatchedValue = '';
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return arrData;
};

export const inDev = () => {
  return ['localhost', '127.0.0.1'].includes(window.location.hostname);
};

export const startedFlash = (event) => {
  switch (event) {
    case FLASH_EVENT['2022_ONCE_MEMBER']:
      return moment().isSameOrAfter('2022-09-19 18:00:00');
    default:
      return false;
  }
};
