import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import WingmanGame from './WingmanGame';
import WingmanPerBall from './WingmanPerBall';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  z-index: 1002;
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;

  .wingman {
    height: calc(100% - 4.5rem);
    margin-top: 4.5rem;
    width: 100%;
    background-color: ${COLOR.home};
    color: #fafafa;
    display: ${(props) => (props.isOpenWingman ? `flex` : `none`)};
    flex-direction: column;
    padding-bottom: 1rem;
    position: relative;
    pointer-events: auto;

    &--header {
      font-size: 2rem;
      text-align: center;
      margin: 1rem 0;
      padding: 0 0 1rem;
      border-bottom: 1px solid white;
    }
    &--modes {
      position: absolute;
      height: 4rem;
      right: 1rem;
      top: 0;
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }

    &--content {
      display: grid;
      grid-template-columns: 1fr 3fr;
      width: 100%;
      height: 100%;
      overflow-y: hidden;
    }
  }

  input {
    background-color: white;
  }
  select {
    background-color: white;
  }
`;

const Wingman = ({
  isOpenWingman,
  isOpenOnSite,
  openOnSite,
  isOpenVideo,
  openVideo,
  strikezoneUniqid,
  gameState,
  gameLoading,
  gameError,
}) => {
  return (
    <StyledDiv isOpenWingman={isOpenWingman}>
      <div className="wingman">
        <h3 className="wingman--header">{Multilingual(`SETTING_PANEL`)}</h3>
        <div className="wingman--modes">
          <Button color="red" onClick={openOnSite} nowrap>
            {Multilingual(`ONSITE`)}
          </Button>
          <Button color="red" onClick={openVideo} nowrap>
            {Multilingual(`VIDEO`)}
          </Button>
        </div>
        <div className="wingman--content">
          <WingmanGame strikezoneUniqid={strikezoneUniqid} gameState={gameState} />
          <WingmanPerBall
            strikezoneUniqid={strikezoneUniqid}
            gameState={gameState}
            gameLoading={gameLoading}
            gameError={gameError}
            isOpen={isOpenWingman}
            isOpenOnSite={isOpenOnSite}
            isOpenVideo={isOpenVideo}
          />
        </div>
      </div>
    </StyledDiv>
  );
};

export default Wingman;
