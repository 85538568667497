import { Fragment } from 'react';
import { getAtBatContent } from '../utils/base';
import { BallTypeWording, BallTypeENWording, getBallTypeStyle } from '../constants/base';

const renderStatsRow = (stat, ballType, split, isPreHalf) => {
  const preHalf = (
    <Fragment>
      <td>{stat['pitches']}</td>
      <td>{stat['RHP']}</td>
      <td>{stat['LHP']}</td>
      <td>{stat['percentage']}</td>
    </Fragment>
  );

  const postHalf = (
    <Fragment>
      <td>{stat['ground']}</td>
      <td>{stat['lineDrive']}</td>
      <td>{stat['fly']}</td>
      <td>{stat['pop']}</td>
    </Fragment>
  );

  return (
    <tr className={ballType === 'all' ? 'total' : ''}>
      <td style={getBallTypeStyle(ballType)}>
        <span>{BallTypeWording[ballType ? ballType : 'NONE']}</span>
        <span>{BallTypeENWording[ballType ? ballType : 'NONE']}</span>
      </td>
      {!split ? (
        <Fragment>
          {preHalf}
          {postHalf}
        </Fragment>
      ) : isPreHalf ? (
        preHalf
      ) : (
        postHalf
      )}
    </tr>
  );
};

const THPreHalf = (
  <Fragment>
    <th>
      <span>總數</span>
      <span>#</span>
    </th>
    <th>
      <span>右投</span>
      <span># RHP</span>
    </th>
    <th>
      <span>左投</span>
      <span># LHP</span>
    </th>
    <th>
      <span>比例</span>
      <span>%</span>
    </th>
  </Fragment>
);

const THPostHalf = (
  <Fragment>
    <th>
      <span>滾地%</span>
      <span>GB%</span>
    </th>
    <th>
      <span>平飛%</span>
      <span>LD%</span>
    </th>
    <th>
      <span>高飛%</span>
      <span>FB%</span>
    </th>
    <th>
      <span>內飛%</span>
      <span>PU%</span>
    </th>
  </Fragment>
);

export default function BatterTable({ resolvedPerBall, stats, split }) {
  return (
    <Fragment>
      <table>
        <thead>
          <tr>
            <th>
              <span>球種</span>
              <span>Pitch Type</span>
            </th>
            {THPreHalf}
            {!split && THPostHalf}
          </tr>
        </thead>
        <tbody>
          {renderStatsRow(getAtBatContent(resolvedPerBall, stats['F'], 'F'), 'F', split, true)}
          {renderStatsRow(getAtBatContent(resolvedPerBall, stats['B'], 'B'), 'B', split, true)}
          {renderStatsRow(getAtBatContent(resolvedPerBall, stats['C'], 'C'), 'C', split, true)}
          {renderStatsRow(getAtBatContent(resolvedPerBall, stats['others'], ''), '', split, true)}
          {renderStatsRow(getAtBatContent(resolvedPerBall, stats['all'], ''), 'all', split, true)}
        </tbody>
      </table>
      {split && (
        <table>
          <thead>
            <tr>
              <th>
                <span>球種</span>
                <span>Pitch Type</span>
              </th>
              {THPostHalf}
            </tr>
          </thead>
          <tbody>
            {renderStatsRow(getAtBatContent(resolvedPerBall, stats['F'], 'F'), 'F', split, false)}
            {renderStatsRow(getAtBatContent(resolvedPerBall, stats['B'], 'B'), 'B', split, false)}
            {renderStatsRow(getAtBatContent(resolvedPerBall, stats['C'], 'C'), 'C', split, false)}
            {renderStatsRow(getAtBatContent(resolvedPerBall, stats['others'], ''), '', split, false)}
            {renderStatsRow(getAtBatContent(resolvedPerBall, stats['all'], ''), 'all', split, false)}
          </tbody>
        </table>
      )}
    </Fragment>
  );
}
