import ApiService from '~~commonAPIs/ApiService';

const FormalGame = {
  getSeasons: ({ leagueUniqid }) => {
    return ApiService.get(`/api/leagues/${leagueUniqid}?seasons=true`);
  },

  getGames: ({ seasonUniqid }) => {
    return ApiService.get(`/api/seasons/${seasonUniqid}/games`);
  },

  getGameInPlay: ({ seasonUniqid, gameUniqid }) => {
    return ApiService.get(`/api/seasons/${seasonUniqid}/games/${gameUniqid}`);
  },

  updateGameStrikezone: ({ seasonUniqid, gameUniqid, pitches }) => {
    return ApiService.put(`/api/seasons/${seasonUniqid}/formal_games/${gameUniqid}/strikezone`, { data: pitches });
  },

  updateGamePublicStrikezone: ({ seasonUniqid, gameUniqid, managerForm }) => {
    return ApiService.put(`/api/seasons/${seasonUniqid}/formal_games/${gameUniqid}/public_strikezone`, {
      data: managerForm,
    });
  },
};

export default FormalGame;
