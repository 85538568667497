export const FOOTER_BUTTONS = [
  {
    buttonName: '關於我們',
    url: 'https://www.rebas.tw/aboutus',
  },
  {
    buttonName: '業務合作',
    url: 'https://www.rebas.tw/cooperations',
  },
  {
    buttonName: '與我們聯絡',
    url: 'mailto:rebastaiwan@gmail.com',
  },
  {
    buttonName: '資料使用聲明',
    url: 'https://account.rebas.tw/privacy',
  },
];

export const FOOTER_TEXTS = [
  `聯絡信箱：rebastaiwan@gmail.com
  聯絡時間：周一至周五 08:00 - 18:00`,
];
