import { Fragment } from 'react';
import { strikeZoneConfig } from '../constants/base';
import ChaseZone from './Zone/ChaseZone';
import ShadowZone from './Zone/ShadowZone';
import { HomePlate } from '../styled';
import Multilingual from '../../../utils/Multilingual';

const { width, height } = strikeZoneConfig;

export const getPercentage = (amount, total) => {
  if (total <= 0) {
    return -1;
  }
  return amount / total;
};

export const getFillOpacity = (percentage) => {
  if (percentage > 1 || percentage < 0) return { color: 'white', opacity: 1 };

  if (percentage > 0.3) return { color: 'red', opacity: 1 };
  if (percentage > 0.25) return { color: 'red', opacity: 0.8 };
  if (percentage > 0.2) return { color: 'red', opacity: 0.6 };
  if (percentage > 0.15) return { color: 'red', opacity: 0.4 };
  if (percentage > 0.1) return { color: 'blue', opacity: 0.4 };
  if (percentage > 0.06) return { color: 'blue', opacity: 0.6 };
  if (percentage > 0.03) return { color: 'blue', opacity: 0.8 };
  return { color: 'blue', opacity: 1 };
};

const renderZone = (width, height, zoneMap, total) => {
  let count = 1;
  let zone = [];

  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      const x = width / 4 + (j * width) / 6;
      const y = height / 4 + (i * height) / 6;
      const percentage = getPercentage(zoneMap[`${count++}`].length, total);
      const { color, opacity } = getFillOpacity(percentage);

      zone.push(
        <Fragment key={`zone-map-${i}-${j}`}>
          <rect
            x={x}
            y={y}
            width={width / 6}
            height={height / 6}
            stroke="black"
            fill={color}
            fillOpacity={opacity}
          ></rect>
          <text
            dx={x + width / 6 / 2}
            dy={y + height / 6 / 2}
            fontSize={8}
            fill={percentage >= 0 ? 'white' : 'black'}
            textAnchor="middle"
          >
            {percentage >= 0 ? Math.round(percentage * 100) : '-'}
          </text>
        </Fragment>,
      );
    }
  }

  return zone;
};

export default function Zonemap({ zoneMap, total }) {
  const { shadows, chases } = zoneMap;

  return (
    <div style={{ padding: 4 }}>
      <svg width={width / 2} height={height / 2}>
        <g>
          <rect width={width / 2} height={height / 2} stroke="black" fill="none"></rect>
        </g>
        <g>
          <rect x={32} y={8} width={width / 12} height={(height / 2 - 16) / 8} fill="red"></rect>
          <text x={60} y={20}>
            {'30'}&uarr;
          </text>
          <rect
            x={32}
            y={8 + (height / 2 - 16) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="red"
            fillOpacity=".8"
          ></rect>
          <text x={60} y={20 + (height / 2 - 16) / 8}>
            {'25'}
          </text>
          <rect
            x={32}
            y={8 + (2 * (height / 2 - 16)) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="red"
            fillOpacity=".6"
          ></rect>
          <text x={60} y={20 + (2 * (height / 2 - 16)) / 8}>
            {'20'}
          </text>
          <rect
            x={32}
            y={8 + (3 * (height / 2 - 16)) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="red"
            fillOpacity=".4"
          ></rect>
          <text x={60} y={20 + (3 * (height / 2 - 16)) / 8}>
            {'15'}
          </text>
          <rect
            x={32}
            y={8 + (4 * (height / 2 - 16)) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="blue"
            fillOpacity=".4"
          ></rect>
          <text x={60} y={20 + (4 * (height / 2 - 16)) / 8}>
            {'10'}
          </text>
          <rect
            x={32}
            y={8 + (5 * (height / 2 - 16)) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="blue"
            fillOpacity=".6"
          ></rect>
          <text x={60} y={20 + (5 * (height / 2 - 16)) / 8}>
            {'6'}
          </text>
          <rect
            x={32}
            y={8 + (6 * (height / 2 - 16)) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="blue"
            fillOpacity=".8"
          ></rect>
          <text x={60} y={20 + (6 * (height / 2 - 16)) / 8}>
            {'3'}
          </text>
          <rect
            x={32}
            y={8 + (7 * (height / 2 - 16)) / 8}
            width={width / 12}
            height={(height / 2 - 16) / 8}
            fill="blue"
            fillOpacity="1"
          ></rect>
          <text x={60} y={20 + (7 * (height / 2 - 16)) / 8}>
            {'0'}
          </text>
        </g>
      </svg>
      <h3>{Multilingual(`GAME.ZONE_MAP`)}</h3>
      <div style={{ position: 'relative', width, height }}>
        <svg width={width} height={height}>
          <ChaseZone x={width / 3} y={height / 3} chases={chases} total={total} />
          <ShadowZone x={width / 6} y={height / 6} shadows={shadows} total={total} />
          <g>
            <rect x={width / 4} y={height / 4} width={width / 2} height={height / 2} stroke="black" fill="white"></rect>
            <g>{renderZone(width, height, zoneMap, total)}</g>
          </g>
        </svg>
      </div>
      <HomePlate className="home-plate-4-print" width={width / 2} />
    </div>
  );
}
