import styled from 'styled-components';
import HomePlatePNG from '../../static/HomePlate.png';
import { strikeZoneConfig } from './constants/base';

export const StyledTableArea = styled.div`
  margin-top: 1rem;
  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  .table-wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 0.9rem;
    margin-top: 0.6rem;
  }

  table {
    width: 100%;
    margin-bottom: 0.6rem;
    border-spacing: 0;

    tbody > tr {
      :hover {
        background: #b7e9fb;
      }
      border-bottom: 1px solid #eee;
    }

    thead > tr {
      border-bottom: 1px solid #eee;
    }

    th {
      white-space: nowrap;
    }

    th,
    td {
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      border-right: 0px;
      :last-child {
        border-right: 0px;
      }

      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }

      > span {
        display: block;
      }
    }

    .total {
      background: #fafafa;
    }

    .nowrap {
      white-space: nowrap;
    }
  }
`;

export const HomePlate = styled.img`
  display: block;
  margin: ${strikeZoneConfig.height / 8}px auto 0;
  width: ${(props) => props.width + 8}px;
`;

HomePlate.defaultProps = {
  src: HomePlatePNG,
};
