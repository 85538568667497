import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { fullZoneScale, eyeFullZoneDefaultXY } from '../../Const';
import { getResizerCommonStyle } from '../../styled';
import { usePerBallState } from '../../useResultPanel';

const StyledDiv = styled.div`
  z-index: 999;
  .eye-full-zone {
    background: ${(props) => (props.resizable ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.3)')};
    width: ${eyeFullZoneDefaultXY.width}px;
    height: ${eyeFullZoneDefaultXY.height}px;
    position: absolute;
    top: ${eyeFullZoneDefaultXY.y};
    left: ${eyeFullZoneDefaultXY.x};

    ${getResizerCommonStyle('green')}
  }
`;

const ballWidth = 10;
const CurrentBall = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${ballWidth}px;
  height: ${ballWidth}px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}%;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  pointer-events: none;
`;

const zoneClass = '.eye-full-zone';

const EyeStrikeFullZone = ({ isOpen, resizable, setPitchPosition, eyeFullZoneXY }) => {
  const { perBall, editingIndex } = usePerBallState();

  const fullZoneRef = useRef(null);
  const [refWaiting, setRefWaiting] = useState(0);
  const [ballPosition, setBallPosition] = useState(null);

  useEffect(() => {
    const element = document.querySelector(zoneClass);
    element.style.width = eyeFullZoneXY.width + 'px';
    element.style.height = eyeFullZoneXY.height + 'px';
    element.style.left = eyeFullZoneXY.x + 'px';
    element.style.top = eyeFullZoneXY.y + 'px';
  }, [eyeFullZoneXY]);

  useEffect(() => {
    if (!fullZoneRef || !fullZoneRef.current) {
      setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    const el = document.getElementById('eye-full-zone-div');
    const setPitch = setPitchPosition('EYE');
    el.addEventListener('click', setPitch);
    return () => {
      el.removeEventListener('click', setPitch);
    };
  }, [refWaiting, setPitchPosition]);

  useEffect(() => {
    if (!perBall[editingIndex]) {
      setBallPosition(null);
      return;
    }

    const eyeZoneResult = perBall[editingIndex].eyeZoneResult;
    console.log(eyeZoneResult);
    if (!eyeZoneResult || eyeZoneResult.width === 0 || eyeZoneResult.height === 0) {
      setBallPosition(null);
      return;
    }

    const delta = ((fullZoneScale - 1) / 2) * 100;
    // 要把好球帶的比例換成是有效區域的比例
    setBallPosition({
      left: (delta + parseFloat(eyeZoneResult.x)) / fullZoneScale,
      top: (delta + parseFloat(eyeZoneResult.y)) / fullZoneScale,
    });
  }, [perBall, editingIndex]);

  return (
    <StyledDiv
      ref={fullZoneRef}
      id="eye-full-zone-div"
      style={{ display: isOpen ? 'block' : 'none' }}
      resizable={resizable}
    >
      <div className="eye-full-zone">
        <div className="eye-full-zone--resizers">
          <div className="eye-full-zone--resizer eye-full-zone--top-left" style={{ display: 'none' }}></div>
          <div className="eye-full-zone--resizer eye-full-zone--top-right" style={{ display: 'none' }}></div>
          <div className="eye-full-zone--resizer eye-full-zone--bottom-left" style={{ display: 'none' }}></div>
          <div className="eye-full-zone--resizer eye-full-zone--bottom-right" style={{ display: 'none' }}></div>
          {!!ballPosition && <CurrentBall top={ballPosition.top} left={ballPosition.left} />}
        </div>
      </div>
    </StyledDiv>
  );
};

export default EyeStrikeFullZone;
