import { format as formatDate } from 'date-fns';
import { filter } from 'ramda';
import toast from '~~elements/Toast';
import Multilingual from './Multilingual';

export const perBall2JsonFile = (gameState, player = '') => {
  const perBall = !player
    ? gameState.perBall.slice(0, -1)
    : filter((b) => b.pitcher === player || b.batter === player, gameState.perBall.slice(0, -1));
  if (perBall.length === 0) {
    toast(Multilingual(`NULL`), { status: 'error', second: 2 });
    return;
  }

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(new Blob([JSON.stringify(perBall)], { type: 'text/plain' }));
  a.download = `${Multilingual(`NAME`)}-${gameState.title}-${!player ? '' : player}${Multilingual(
    `PER_BALL.WORDING`,
  )}-${formatDate(new Date(), 'yyyyMMdd')}.json`;
  a.click();
};
