import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { eZoneDefaultXY, setZoneResize } from '../../Const';
import { getResizerCommonStyle } from '../../styled';

const StyledDiv = styled.div`
  z-index: 1000;
  .e-strike-zone {
    background: rgba(255, 255, 255, 0.1);
    width: ${eZoneDefaultXY.width}px;
    height: ${eZoneDefaultXY.height}px;
    position: absolute;
    top: ${eZoneDefaultXY.y};
    left: ${eZoneDefaultXY.x};

    ${getResizerCommonStyle('red')}
  }
`;

const zoneClass = '.e-strike-zone';

const debounce = {};

const EStrikeStrikeZone = ({ isOpen, resizable, setPitchPosition, eZoneXY, setEFullZoneXY, setEZoneXY }) => {
  const strikeZoneRef = useRef(null);
  const [refWaiting, setRefWaiting] = useState(0);

  useEffect(() => {
    if (!strikeZoneRef || !strikeZoneRef.current) {
      setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    if (refWaiting === -1) {
      return;
    }
    setRefWaiting(-1);

    setZoneResize(zoneClass, 'e-strike-zone', debounce, setEFullZoneXY, setEZoneXY);
  }, [refWaiting, setEFullZoneXY, setEZoneXY]);

  useEffect(() => {
    const element = document.querySelector(zoneClass);
    element.style.width = eZoneXY.width + 'px';
    element.style.height = eZoneXY.height + 'px';
    element.style.left = eZoneXY.x + 'px';
    element.style.top = eZoneXY.y + 'px';
  }, [eZoneXY]);

  useEffect(() => {
    if (!strikeZoneRef || !strikeZoneRef.current) {
      setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    const el = document.getElementById('e-strike-zone-div');
    const setPitch = setPitchPosition('E');
    el.addEventListener('click', setPitch);
    return () => {
      el.removeEventListener('click', setPitch);
    };
  }, [refWaiting, setPitchPosition]);

  return (
    <StyledDiv
      ref={strikeZoneRef}
      id="e-strike-zone-div"
      style={{ display: isOpen ? 'block' : 'none' }}
      resizable={resizable}
    >
      <div className="e-strike-zone">
        <div className="e-strike-zone--resizers">
          <div
            className="e-strike-zone--resizer e-strike-zone--top-left"
            style={resizable ? {} : { display: 'none' }}
          ></div>
          <div
            className="e-strike-zone--resizer e-strike-zone--top-right"
            style={resizable ? {} : { display: 'none' }}
          ></div>
          <div
            className="e-strike-zone--resizer e-strike-zone--bottom-left"
            style={resizable ? {} : { display: 'none' }}
          ></div>
          <div
            className="e-strike-zone--resizer e-strike-zone--bottom-right"
            style={resizable ? {} : { display: 'none' }}
          ></div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default EStrikeStrikeZone;
