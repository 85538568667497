export const FORMAL_NAV_BAR_INFO = {
  LOGO: {
    text: '',
    url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/`,
  },
  MEMBER: {
    text: <span>訂閱會員</span>,
    url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/member`,
  },
  // ENTERTAINMENT: {
  //   text: <span>遊戲</span>,
  //   links: [
  //     {
  //       text: (
  //         <span>
  //           TPBL
  //           <br />
  //           fantasy
  //         </span>
  //       ),
  //       url: `https://fantasysport.games/home`,
  //       isExternal: true,
  //     },
  //     {
  //       text: (
  //         <span>
  //           棒球
  //           <br />
  //           九宮格
  //         </span>
  //       ),
  //       url: `https://partygame.yyhapp.com/BaseballCPBLJiugongge`,
  //       isExternal: true,
  //     },
  //   ],
  // },
  ESHOP: {
    text: <span>商品小舖</span>,
    url: `https://rebas.cashier.ecpay.com.tw/`,
  },
  ENT: {
    text: <span>每日遊戲🔥</span>,
    url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/entertainment/we-prediction`,
  },
  GAME: {
    text: <span>賽程</span>,
    url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/calendar`,
  },
  LEADERBOARD: {
    text: <span>排行</span>,
    url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/leaderboard`,
  },
  TOOL: {
    text: <span>分析工具</span>,
    links: [
      {
        text: <span>聯盟係數</span>,
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/coefficient`,
      },
      {
        text: <span>投打對決</span>,
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/matchup`,
      },
      {
        text: <span>配球策略</span>,
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/pitch-strategy`,
      },
      {
        text: <span>相關文章</span>,
        url: `https://blog.rebas.tw`,
      },
      // {
      //   text: <span>好球帶</span>,
      //   url: `${process.env.REACT_APP_STRIKEZONE_HUNTER_URL ?? process.env.NEXT_PUBLIC_STRIKEZONE_HUNTER_URL}`,
      // },
    ],
  },
  NEW_STATS: {
    text: <span>新數據</span>,
    links: [
      {
        text: <span>守備指標</span>,
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/newstats/defensive`,
      },
      {
        text: <span>劇場指數</span>,
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/newstats/drama-index`,
      },
      {
        text: <span>投手 WAR</span>,
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/newstats/pitcher-war`,
      },
      {
        text: (
          <span>
            捕手
            <br />
            Pop Time
          </span>
        ),
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/newstats/pop-time`,
      },
      {
        text: (
          <span>
            捕手
            <br />
            Framing
          </span>
        ),
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/newstats/framing`,
      },
      {
        text: (
          <span>
            打者
            <br />
            HardHit%
          </span>
        ),
        url: `${process.env.REACT_APP_MAINPAGE_URL ?? process.env.NEXT_PUBLIC_MAINPAGE_URL}/newstats/hard-hit-rate`,
      },
    ],
  },
  LOG_IN: {
    text: <span>登入</span>,
    url: `${
      process.env.REACT_APP_ACCOUNT_URL ?? process.env.NEXT_PUBLIC_ACCOUNT_URL
    }/login?done_url=${encodeURIComponent(window.location.href)}`,
  },
  LOG_OUT: {
    text: <span>登出</span>,
    url: `${
      process.env.REACT_APP_ACCOUNT_URL ?? process.env.NEXT_PUBLIC_ACCOUNT_URL
    }/logout?done_url=${encodeURIComponent(window.location.href)}`,
  },
  ACCOUNT_MANAGEMENT: {
    text: <span>帳戶管理</span>,
    url: `${process.env.REACT_APP_ACCOUNT_URL ?? process.env.NEXT_PUBLIC_ACCOUNT_URL}/settings/user_profile`,
  },
  MEMBER_MANAGEMENT: {
    text: <span>會員管理</span>,
    url: `${process.env.REACT_APP_MEMBER_URL ?? process.env.NEXT_PUBLIC_MEMBER_URL}/`,
  },
};
