import { useContext, useReducer, createContext } from 'react';
import { uniq, update } from 'ramda';
import { getOpponents } from '../utils/base';

const getPlayers = (playersMap, side, position) => {
  return playersMap[side][position];
};

const defaultInnings = [...new Array(18).fill('').map((_, index) => `${index + 1}`), ''];
const defaultBases = [...new Array(8).fill('').map((_, index) => `${index}`), ''];
const defaultOuts = [...new Array(3).fill('').map((_, index) => `${index}`), ''];
const defaultZones = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'shadow', 'chase', 'waste'];
const defaultHands = ['L', 'R', ''];
const defaultBatterActions = ['NONE', 'SWMISS', 'contact'];
const defaultJudgeCalls = ['S', 'B', ''];
const defaultBallTypes = ['F', 'B', 'C', ''];
const defaultBallDetailTypes = ['FA', 'FT', 'FC', 'SL', 'CU', 'KC', 'SC', 'SI', 'CH', 'FS', 'FO', 'KN', 'EP', 'OT', ''];
const defaultBatterResults = ['', '1B', '2B', '3B', 'HR', 'SO', 'BB', 'outs', 'others'];
const defaultContacts = ['S', 'H', ''];
const defaultBallCounts = ['first', 'twoStrike', 'threeBall', 'full', 'pitcherLead', 'batterLead', 'even'];

const FilterStateCtx = createContext();
const FilterDispatchCtx = createContext();

function filterReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_LIVE_MODE':
      return { ...state, liveMode: action.liveMode };
    case 'UPDATE_PERBALL': {
      const newPlayersMap = {
        home: {
          fielder: uniq(action.perBall.filter(({ currentSide }) => currentSide === 'home').map(({ batter }) => batter)),
          pitcher: uniq(
            action.perBall.filter(({ currentSide }) => currentSide === 'away').map(({ pitcher }) => pitcher),
          ),
        },
        away: {
          fielder: uniq(action.perBall.filter(({ currentSide }) => currentSide === 'away').map(({ batter }) => batter)),
          pitcher: uniq(
            action.perBall.filter(({ currentSide }) => currentSide === 'home').map(({ pitcher }) => pitcher),
          ),
        },
      };
      if (!state.liveMode || action.perBall.length === 0) {
        const newPlayers = [...getPlayers(newPlayersMap, state.side, state.position)];
        const newSelectedPlayers = [...newPlayers];
        newSelectedPlayers.forEach((sPlayer, i) => {
          if (state.selectedPlayers.includes(sPlayer)) {
            return;
          }

          newSelectedPlayers[i] = '0';
        });

        const newOpponents = [...getOpponents(newPlayersMap, state.side, state.position)];
        const newSelectedOpponents = [...newOpponents];
        newSelectedOpponents.forEach((sOpponent, i) => {
          if (state.selectedOpponents.includes(sOpponent)) {
            return;
          }

          newSelectedOpponents[i] = '0';
        });
        return {
          ...state,
          playersMap: newPlayersMap,
          players: newPlayers,
          selectedPlayers: newSelectedPlayers,
          opponents: newOpponents,
          selectedOpponents: newSelectedOpponents,
        };
      }

      // live 用投手角度看球，只顯示當下那位投打的對決紀錄
      const lastBall = action.perBall.slice(-1)[0];
      const side = lastBall.currentSide === 'home' ? 'away' : 'home';
      const players = getPlayers(newPlayersMap, side, 'pitcher');
      return {
        ...state,
        playersMap: newPlayersMap,
        side,
        players: [...players],
        selectedPlayers: [lastBall.pitcher],
        opponents: [...getOpponents(newPlayersMap, side, 'pitcher')],
        selectedOpponents: [lastBall.batter],
        selectedInnings: [...defaultInnings],
        selectedBases: [...defaultBases],
        selectedOuts: [...defaultOuts],
        selectedZones: [...defaultZones],
        selectedHands: [...defaultHands],
        selectedBatterActions: [...defaultBatterActions],
        selectedJudgeCalls: [...defaultJudgeCalls],
        selectedBallTypes: [...defaultBallTypes],
        selectedBallDetailTypes: [...defaultBallDetailTypes],
        selectedContacts: [...defaultContacts],
        selectedBatterResults: [...defaultBatterResults],
        selectedBallCounts: [...defaultBallCounts],
      };
    }
    case 'SELECT_SIDE': {
      const players = getPlayers(state.playersMap, action.side, state.position);
      return {
        ...state,
        side: action.side,
        players: [...players],
        selectedPlayers: [...players],
        opponents: [...getOpponents(state.playersMap, action.side, state.position)],
        selectedOpponents: [...getOpponents(state.playersMap, action.side, state.position)],
      };
    }
    case 'SELECT_POSITION': {
      const players = getPlayers(state.playersMap, state.side, action.position);

      return {
        ...state,
        position: action.position,
        players: [...players],
        selectedPlayers: [...players],
        opponents: [...getOpponents(state.playersMap, state.side, action.position)],
        selectedOpponents: [...getOpponents(state.playersMap, state.side, action.position)],
      };
    }
    case 'CHANGE_SELECTED_PLAYER': {
      return {
        ...state,
        selectedPlayers: [
          ...update(
            action.selectedIndex,
            state.selectedPlayers.includes(action.selectedPlayer) ? '0' : action.selectedPlayer,
            state.selectedPlayers,
          ),
        ],
      };
    }
    case 'SELECT_ALL_PLAYERS': {
      const { playersMap, side, position } = state;

      return {
        ...state,
        selectedPlayers: [...playersMap[side][position]],
      };
    }
    case 'EMPTY_ALL_PLAYERS': {
      return {
        ...state,
        selectedPlayers: [...new Array(state.players.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_OPPONENT': {
      return {
        ...state,
        selectedOpponents: [
          ...update(
            action.selectedIndex,
            state.selectedOpponents.includes(action.selectedOpponent) ? '0' : action.selectedOpponent,
            state.selectedOpponents,
          ),
        ],
      };
    }
    case 'SELECT_ALL_OPPONENTS': {
      const { playersMap, side, position } = state;

      return {
        ...state,
        selectedOpponents: [...getOpponents(playersMap, side, position)],
      };
    }
    case 'EMPTY_ALL_OPPONENTS': {
      return {
        ...state,
        selectedOpponents: [...new Array(state.opponents.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_ZONE': {
      return {
        ...state,
        selectedZones: [
          ...update(
            action.selectedIndex,
            state.selectedZones.includes(action.selectedZone) ? '0' : action.selectedZone,
            state.selectedZones,
          ),
        ],
      };
    }
    case 'SELECT_ALL_ZONES': {
      return {
        ...state,
        selectedZones: [...defaultZones],
      };
    }
    case 'EMPTY_ALL_ZONES': {
      return {
        ...state,
        selectedZones: [...new Array(state.zones.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_INNING': {
      return {
        ...state,
        selectedInnings: [
          ...update(
            action.selectedIndex,
            state.selectedInnings.includes(action.selectedInning) ? '0' : action.selectedInning,
            state.selectedInnings,
          ),
        ],
      };
    }
    case 'SELECT_ALL_INNINGS': {
      return {
        ...state,
        selectedInnings: [...defaultInnings],
      };
    }
    case 'EMPTY_ALL_INNINGS': {
      return {
        ...state,
        selectedInnings: [...new Array(state.innings.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_BASE': {
      return {
        ...state,
        selectedBases: [
          ...update(
            action.selectedIndex,
            state.selectedBases.includes(action.selectedBase) ? '-1' : action.selectedBase,
            state.selectedBases,
          ),
        ],
      };
    }
    case 'SELECT_ALL_BASES': {
      return {
        ...state,
        selectedBases: [...defaultBases],
      };
    }
    case 'EMPTY_ALL_BASES': {
      return {
        ...state,
        selectedBases: [...new Array(state.innings.length).fill('-1')],
      };
    }
    case 'CHANGE_SELECTED_OUT': {
      return {
        ...state,
        selectedOuts: [
          ...update(
            action.selectedIndex,
            state.selectedOuts.includes(action.selectedOut) ? '-1' : action.selectedOut,
            state.selectedOuts,
          ),
        ],
      };
    }
    case 'SELECT_ALL_OUTS': {
      return {
        ...state,
        selectedOuts: [...defaultOuts],
      };
    }
    case 'EMPTY_ALL_OUTS': {
      return {
        ...state,
        selectedOuts: [...new Array(state.outs.length).fill('-1')],
      };
    }
    case 'CHANGE_SELECTED_HAND': {
      return {
        ...state,
        selectedHands: [
          ...update(
            action.selectedIndex,
            state.selectedHands.includes(action.selectedHand) ? '0' : action.selectedHand,
            state.selectedHands,
          ),
        ],
      };
    }
    case 'SELECT_ALL_HANDS': {
      return {
        ...state,
        selectedHands: [...defaultHands],
      };
    }
    case 'EMPTY_ALL_HANDS': {
      return {
        ...state,
        selectedHands: [...new Array(state.hands.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_BATTER_ACTION': {
      return {
        ...state,
        selectedBatterActions: [
          ...update(
            action.selectedIndex,
            state.selectedBatterActions.includes(action.selectedBatterAction) ? '0' : action.selectedBatterAction,
            state.selectedBatterActions,
          ),
        ],
      };
    }
    case 'SELECT_ALL_BATTER_ACTIONS': {
      return {
        ...state,
        selectedBatterActions: [...defaultBatterActions],
      };
    }
    case 'EMPTY_ALL_BATTER_ACTIONS': {
      return {
        ...state,
        selectedBatterActions: [...new Array(state.batterActions.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_JUDGE_CALL': {
      return {
        ...state,
        selectedJudgeCalls: [
          ...update(
            action.selectedIndex,
            state.selectedJudgeCalls.includes(action.selectedJudgeCall) ? '0' : action.selectedJudgeCall,
            state.selectedJudgeCalls,
          ),
        ],
      };
    }
    case 'SELECT_ALL_JUDGE_CALLS': {
      return {
        ...state,
        selectedJudgeCalls: [...defaultJudgeCalls],
      };
    }
    case 'EMPTY_ALL_JUDGE_CALLS': {
      return {
        ...state,
        selectedJudgeCalls: [...new Array(state.judgeCalls.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_BALL_TYPE': {
      return {
        ...state,
        selectedBallTypes: [
          ...update(
            action.selectedIndex,
            state.selectedBallTypes.includes(action.selectedBallType) ? '0' : action.selectedBallType,
            state.selectedBallTypes,
          ),
        ],
      };
    }
    case 'SELECT_ALL_BALL_TYPES': {
      return {
        ...state,
        selectedBallTypes: [...defaultBallTypes],
      };
    }
    case 'EMPTY_ALL_BALL_TYPES': {
      return {
        ...state,
        selectedBallTypes: [...new Array(state.ballTypes.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_BALL_DETAIL_TYPE': {
      return {
        ...state,
        selectedBallDetailTypes: [
          ...update(
            action.selectedIndex,
            state.selectedBallDetailTypes.includes(action.selectedBallDetailType) ? '0' : action.selectedBallDetailType,
            state.selectedBallDetailTypes,
          ),
        ],
      };
    }
    case 'SELECT_ALL_BALL_DETAIL_TYPES': {
      return {
        ...state,
        selectedBallDetailTypes: [...defaultBallDetailTypes],
      };
    }
    case 'EMPTY_ALL_BALL_DETAIL_TYPES': {
      return {
        ...state,
        selectedBallDetailTypes: [...new Array(state.ballDetailTypes.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_BATTER_RESULT': {
      return {
        ...state,
        selectedBatterResults: [
          ...update(
            action.selectedIndex,
            state.selectedBatterResults.includes(action.selectedBatterResult) ? '0' : action.selectedBatterResult,
            state.selectedBatterResults,
          ),
        ],
      };
    }
    case 'SELECT_ALL_BATTER_RESULTS': {
      return {
        ...state,
        selectedBatterResults: [...defaultBatterResults],
      };
    }
    case 'EMPTY_ALL_BATTER_RESULTS': {
      return {
        ...state,
        selectedBatterResults: [...new Array(state.batterResults.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_CONTACT': {
      return {
        ...state,
        selectedContacts: [
          ...update(
            action.selectedIndex,
            state.selectedContacts.includes(action.selectedContact) ? '0' : action.selectedContact,
            state.selectedContacts,
          ),
        ],
      };
    }
    case 'SELECT_ALL_CONTACTS': {
      return {
        ...state,
        selectedContacts: [...defaultContacts],
      };
    }
    case 'EMPTY_ALL_CONTACTS': {
      return {
        ...state,
        selectedContacts: [...new Array(state.contacts.length).fill('0')],
      };
    }
    case 'CHANGE_SELECTED_BALL_COUNT': {
      return {
        ...state,
        selectedBallCounts: [
          ...update(
            action.selectedIndex,
            state.selectedBallCounts.includes(action.selectedBallCount) ? '0' : action.selectedBallCount,
            state.selectedBallCounts,
          ),
        ],
      };
    }
    case 'SELECT_ALL_BALL_COUNTS': {
      return {
        ...state,
        selectedBallCounts: [...defaultBallCounts],
      };
    }
    case 'EMPTY_ALL_BALL_COUNTS': {
      return {
        ...state,
        selectedBallCounts: [...new Array(state.ballCounts.length).fill('0')],
      };
    }
    default:
      throw new Error('Invalid action');
  }
}

function FilterProvider({ gameInfo, targetPlayer = null, children }) {
  const playersMap = {
    home: {
      fielder: uniq(gameInfo.perBall.filter(({ currentSide }) => currentSide === 'home').map(({ batter }) => batter)),
      pitcher: uniq(gameInfo.perBall.filter(({ currentSide }) => currentSide === 'away').map(({ pitcher }) => pitcher)),
    },
    away: {
      fielder: uniq(gameInfo.perBall.filter(({ currentSide }) => currentSide === 'away').map(({ batter }) => batter)),
      pitcher: uniq(gameInfo.perBall.filter(({ currentSide }) => currentSide === 'home').map(({ pitcher }) => pitcher)),
    },
  };

  let initSide = 'away';
  let initPosition = 'pitcher';
  if (targetPlayer) {
    initSide = targetPlayer.side;
    initPosition = targetPlayer.position;
  }

  const flipMapping = {
    side: {
      away: 'home',
      home: 'away',
    },
    position: {
      pitcher: 'fielder',
      fielder: 'pitcher',
    },
  };

  const [state, dispatch] = useReducer(filterReducer, {
    gameInfo,
    playersMap,
    liveMode: gameInfo.isLive ? gameInfo.isLive : false,
    side: initSide,
    position: initPosition,
    players: playersMap[initSide][initPosition],
    selectedPlayers: playersMap[initSide][initPosition],
    opponents: playersMap[flipMapping.side[initSide]][flipMapping.position[initPosition]],
    selectedOpponents: playersMap[flipMapping.side[initSide]][flipMapping.position[initPosition]],
    stickPlayer: !!targetPlayer,
    innings: defaultInnings,
    selectedInnings: defaultInnings,
    bases: defaultBases,
    selectedBases: defaultBases,
    outs: defaultOuts,
    selectedOuts: defaultOuts,
    zones: defaultZones,
    selectedZones: defaultZones,
    hands: defaultHands,
    selectedHands: defaultHands,
    batterActions: defaultBatterActions,
    selectedBatterActions: defaultBatterActions,
    judgeCalls: defaultJudgeCalls,
    selectedJudgeCalls: defaultJudgeCalls,
    ballTypes: defaultBallTypes,
    selectedBallTypes: defaultBallTypes,
    ballDetailTypes: defaultBallDetailTypes,
    selectedBallDetailTypes: defaultBallDetailTypes,
    batterResults: defaultBatterResults,
    selectedBatterResults: defaultBatterResults,
    contacts: defaultContacts,
    selectedContacts: defaultContacts,
    ballCounts: defaultBallCounts,
    selectedBallCounts: defaultBallCounts,
  });

  return (
    <FilterStateCtx.Provider value={state}>
      <FilterDispatchCtx.Provider value={dispatch}>{children}</FilterDispatchCtx.Provider>
    </FilterStateCtx.Provider>
  );
}

function useFilterState() {
  const context = useContext(FilterStateCtx);

  if (context === undefined) throw new Error('Context does not in Provider');

  return context;
}

function useFilterDispatch() {
  const context = useContext(FilterDispatchCtx);

  if (context === undefined) throw new Error('Context does not in Provider');

  return context;
}

export { FilterProvider, useFilterState, useFilterDispatch };
