import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FORMAL_NAV_BAR_INFO } from './FormalNavBarConsts';
import { TABLET_WIDTH } from '../../elements/Const';
import logoSVG from '../../elements/static/logo.svg';
import { inDev, startedFlash } from '../../Utils';
import { FLASH_EVENT } from '../../Const';

const StyledMenu = styled.div`
  display: none;
  letter-spacing: 1px;
  font-size: 0.9rem;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${TABLET_WIDTH}) {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(17, 60, 113, 0.98);
    height: 100vh;
    text-align: left;
    padding: 5rem 0 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.4s ease-in-out;
    z-index: 10;
    box-shadow: 2px 0px 15px 0 rgba(0, 0, 0, 0.5);
    transform: ${(props) => (props.opened === 'opened' ? 'translateX(0)' : 'translateX(-150%)')};

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid rgb(255, 255, 255, 0.3);
      padding: 0 1.5rem;
      margin-bottom: 1.5rem;
      &--pic {
        width: 2.5rem;
        &:hover {
          opacity: 0.8;
        }
      }
      &--text {
        color: rgb(255, 255, 255, 1);
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 3px;
        margin: 0.8rem 0;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .link-btn {
      display: block;
      position: relative;
      color: rgb(255, 255, 255, 1);
      border-bottom: 4px solid white;
      border-color: transparent;
      padding: 0.25rem 0rem;
      text-align: center;
      margin-bottom: 1rem;

      .link-list {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0.5rem 0;
        .link-btn {
          margin: 0;
        }
      }

      &:hover {
        cursor: pointer;
        border-color: #25eeff;
      }

      &:focus,
      &:hover {
        .link-list {
          display: flex;
        }
      }
    }
  }
`;

const HamburgerMenu = ({ isHambugerOpen, isLogin, userInfo = {}, hideItems = false, current = {} }) => {
  const displayItem = (itemKey) => {
    if (FORMAL_NAV_BAR_INFO[itemKey].links && FORMAL_NAV_BAR_INFO[itemKey].links.length > 0) {
      return (
        <div key={itemKey} className="link-btn list-btn">
          {FORMAL_NAV_BAR_INFO[itemKey].text}
          <div className="link-list">
            {FORMAL_NAV_BAR_INFO[itemKey].links.map((link) => (
              <a
                key={link.url}
                className="link-btn"
                href={link.url}
                {...(link.isExternal ? { target: '_blank', rel: 'noreferrer noopenner' } : {})}
              >
                {link.text}
              </a>
            ))}
          </div>
        </div>
      );
    }

    return (
      <a key={itemKey} className="link-btn" href={FORMAL_NAV_BAR_INFO[itemKey].url}>
        {FORMAL_NAV_BAR_INFO[itemKey].text}
      </a>
    );
  };

  return (
    <StyledMenu opened={isHambugerOpen ? 'opened' : 'closed'}>
      <a className="logo" href={FORMAL_NAV_BAR_INFO.LOGO.url}>
        <img className="logo--pic" src={logoSVG?.src ?? logoSVG} alt="" />
        <div className="logo--text"> {FORMAL_NAV_BAR_INFO.LOGO.text} </div>
      </a>
      {current?.url && (
        <a className="link-btn" href={current.url}>
          {current.text}
        </a>
      )}
      {!hideItems && ['ESHOP', 'ENT', 'GAME', 'LEADERBOARD', 'TOOL', 'NEW_STATS', 'MEMBER'].map(displayItem)}
      {isLogin ? (
        <Fragment>
          <a className="link-btn" href={FORMAL_NAV_BAR_INFO.ACCOUNT_MANAGEMENT.url}>
            {FORMAL_NAV_BAR_INFO.ACCOUNT_MANAGEMENT.text}
          </a>
          {userInfo.isAdmin === 'ADMIN' && (
            <a className="link-btn" href={FORMAL_NAV_BAR_INFO.MEMBER_MANAGEMENT.url}>
              {FORMAL_NAV_BAR_INFO.MEMBER_MANAGEMENT.text}
            </a>
          )}
          <a className="link-btn" href={FORMAL_NAV_BAR_INFO.LOG_OUT.url}>
            {FORMAL_NAV_BAR_INFO.LOG_OUT.text}
          </a>
        </Fragment>
      ) : (
        <Fragment>
          <a className="link-btn" href={FORMAL_NAV_BAR_INFO.LOG_IN.url}>
            {FORMAL_NAV_BAR_INFO.LOG_IN.text}
          </a>
        </Fragment>
      )}
    </StyledMenu>
  );
};

export default HamburgerMenu;
