import { useState } from 'react';
import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import Multilingual from '../Multilingual';
import VideoBallEdit from './VideoBallEdit';
import PerBall from './PerBall';
import { usePerBallState, usePerBallDispatch } from '../useResultPanel';
import VideoEmbedZone from './VideoEmbedZone';

const StyledDiv = styled.div`
  z-index: 998;
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0 0.5rem 0.5rem;
  top: 0;
  left: 0;
  background-color: ${COLOR.home};
  color: #fafafa;
  display: ${(props) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;

  .video {
    &--subtitle {
      position: absolute;
      top: 0;
      left: 1rem;
      font-size: 1.5rem;
      text-align: left;
      margin: 0.5rem 0;
    }
    &--header {
      font-size: 1.5rem;
      text-align: center;
      margin: 0.5rem 0;
      border-bottom: 1px solid white;
    }
    &--actions {
      position: absolute;
      height: 2rem;
      right: 1rem;
      top: 0;
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }

    &--content {
      display: grid;
      grid-template-columns: 1fr ${(props) => props.videoWidth}rem;
      gap: 0.5rem;
      width: 100%;
      max-height: 90%;
      overflow-y: hidden;
      flex-shrink: 0;
    }

    &--history {
      width: 100%;
      min-height: 10%;
      overflow-y: hidden;
    }
  }

  input {
    background-color: white;
  }
  select {
    background-color: white;
  }
`;

const Video = ({
  isOpen,
  toClose,
  strikezoneUniqid,
  gameState,
  strikeZoneResizable,
  setStrikeZoneResizable,
  saveDeviceZone,
  loadDeviceZone,
  gameLoading,
  gameError,
}) => {
  const { perBall, editingIndex } = usePerBallState();
  const perBallDispatch = usePerBallDispatch();

  // better look in iPad when init
  const [videoWidth, setVideoWidth] = useState(45);

  return (
    <StyledDiv isOpen={isOpen} videoWidth={videoWidth}>
      <h3 className="video--subtitle">{Multilingual(`CAMERA_ANGLE.${gameState.cameraAngle}`)}</h3>
      <h3 className="video--header">{gameState.title}</h3>
      <div className="video--actions">
        <Button color="red" onClick={toClose}>
          {Multilingual(`OPEN_SETTING_PANEL`)}
        </Button>
      </div>
      <div className="video--content">
        {isOpen && (
          <VideoBallEdit
            strikezoneUniqid={strikezoneUniqid}
            gameState={gameState}
            strikeZoneResizable={strikeZoneResizable}
            setStrikeZoneResizable={setStrikeZoneResizable}
            saveDeviceZone={saveDeviceZone}
            loadDeviceZone={loadDeviceZone}
            gameLoading={gameLoading}
            gameError={gameError}
          />
        )}
        <VideoEmbedZone
          videoWidth={videoWidth}
          setVideoWidth={setVideoWidth}
          strikezoneUniqid={strikezoneUniqid}
          ytIframe={gameState.ytIframe}
          strikeZoneResizable={strikeZoneResizable}
        />
      </div>
      {isOpen && (
        <div className="video--history">
          <PerBall
            perBall={perBall}
            editingIndex={editingIndex}
            setEditingIndex={(targetIndex) => perBallDispatch({ type: 'updateEditingIndex', payload: targetIndex })}
            wingman={false}
            onsite={false}
            video={true}
          />
        </div>
      )}
    </StyledDiv>
  );
};

export default Video;
