import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.div`
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  --select-border: #777;
  --select-arrow: var(--select-border);

  select,
  &::after {
    grid-area: select;
  }

  font-size: 1rem;
  line-height: 1.1;

  min-width: ${(props) => (props.minWidth ? props.minWidth : '6rem')};
  max-width: 12rem;

  &::after {
    content: '';
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    margin: 0 0.5rem 0 0;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    pointer-events: none;
  }

  select::before,
  select::after {
    box-sizing: border-box;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;

    cursor: pointer;

    margin: 0;
    outline: none;

    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: ${(props) => (props.mini ? '0.2rem 1.2rem 0.2rem 0.2rem' : '0.5rem 1.5rem 0.5rem 0.5rem')};

    width: 100%;

    &::-ms-expand {
      display: none;
    }
  }
`;

const Select = ({ placeholder, name, value, onChange, children, mini, minWidth, ...restProps }) => {
  return (
    <StyledSelect mini={mini} minWidth={minWidth}>
      <select name={name} value={value} onChange={onChange} {...restProps}>
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </select>
    </StyledSelect>
  );
};

const StyledDiv = styled.div`
  ${(props) =>
    !props.flex
      ? `text-align: left;`
      : `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  `}

  margin: ${(props) => props.margin ?? `0 0.5rem 0 0`};

  > label {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 0 0.2rem 0 0;
  }
`;

const SelectWrapper = ({
  label = '',
  flex = false,
  value,
  onChange,
  placeholder = '',
  name = '',
  children,
  margin,
  divClassName,
  ...restProps
}) => {
  return (
    <StyledDiv flex={flex} margin={margin} className={divClassName}>
      {label && <label>{label}</label>}
      <Select placeholder={placeholder} value={value} onChange={onChange} name={name} {...restProps}>
        {children}
      </Select>
    </StyledDiv>
  );
};

export default SelectWrapper;
