import { useFilterState } from '../hooks/useFilter';
import { getBallTypeStats } from '../utils/base';
import BatterTable from './BatterTable';
import PitcherTable from './PitcherTable';
import { StyledTableArea } from '../styled';
import Multilingual from '../../../utils/Multilingual';

// const getFirstSwingList = (perBall) => {
//   const firstSwingList = [];
//   let currentBatter = '';
//   let onePA = [];
//   perBall.forEach((ball) => {
//     if (ball.batter !== currentBatter) {
//       currentBatter = ball.batter;
//       firstSwingList.push(onePA);
//       onePA = [];
//     }

//     onePA.push(ball);
//   });

//   return firstSwingList;
// };

export default function ZoneTable({ resolvedPerBall, split = false }) {
  const { position } = useFilterState();
  const stats = getBallTypeStats(resolvedPerBall);
  return (
    <StyledTableArea className="tablearea-4-print">
      <h3 className="title">{Multilingual(`GAME.PLATE_DISCIPLINE`)}</h3>
      <div className="table-wrapper">
        {position === 'pitcher' ? (
          <PitcherTable stats={stats} resolvedPerBall={resolvedPerBall} split={split} />
        ) : (
          <BatterTable stats={stats} resolvedPerBall={resolvedPerBall} split={split} />
        )}
      </div>
    </StyledTableArea>
  );
}
