import styled from 'styled-components';
import Key0 from '../elements/0.svg';
import Key9 from '../elements/9.svg';
import KeyMinus from '../elements/minus.svg';
import KeyQ from '../elements/Q.svg';
import KeyW from '../elements/W.svg';
import KeyE from '../elements/E.svg';
import KeyR from '../elements/R.svg';
import KeyA from '../elements/A.svg';
import KeyS from '../elements/S.svg';
import KeyD from '../elements/D.svg';
import KeyZ from '../elements/Z.svg';
import KeyX from '../elements/X.svg';
import KeyC from '../elements/C.svg';
import KeyV from '../elements/V.svg';
import KeyB from '../elements/B.svg';
import KeyN from '../elements/N.svg';
import KeyEnter from '../elements/enter.svg';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  .group {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    &--name {
      width: 5rem;
      margin: 0.1rem 0;
      border-right: 1px solid gray;
    }
    &--key {
      width: 2rem;
      height: 2rem;
    }
  }
  hr {
    width: 100%;
    height: 1px;
    border: 0px;
    margin: 0;
    background-color: gray;
  }
`;

const Shortcuts = () => {
  return (
    <StyledDiv>
      <div className="group">
        <span className="group--name">{Multilingual(`SPEED.WORDING`)}</span>
        <img className="group--key" src={Key0} alt="0" />
        <span>~</span>
        <img className="group--key" src={Key9} alt="9" />
        <span>,</span>
        <img className="group--key" src={KeyMinus} alt="minus" />
        <span>{Multilingual(`SPEED.RESET`)}</span>
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`PITCH_TYPE.WORDING`)}</span>
        <img className="group--key" src={KeyQ} alt="Q" />
        <span>{Multilingual(`NULL`)}</span>
        <img className="group--key" src={KeyW} alt="W" />
        <span>{Multilingual(`PITCH_TYPE.F`)}</span>
        <img className="group--key" src={KeyE} alt="E" />
        <span>{Multilingual(`PITCH_TYPE.C`)}</span>
        <img className="group--key" src={KeyR} alt="R" />
        <span>{Multilingual(`PITCH_TYPE.B`)}</span>
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`JUDGE_CALL.WORDING`)}</span>
        <img className="group--key" src={KeyA} alt="A" />
        <span>{Multilingual(`NULL`)}</span>
        <img className="group--key" src={KeyS} alt="S" />
        <span>{Multilingual(`JUDGE_CALL.S`)}</span>
        <img className="group--key" src={KeyD} alt="D" />
        <span>{Multilingual(`JUDGE_CALL.B`)}</span>
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`BATTER_ACTION.WORDING`)}</span>
        <img className="group--key" src={KeyZ} alt="Z" />
        <span>{Multilingual(`NULL`)}</span>
        <img className="group--key" src={KeyX} alt="X" />
        <span>{Multilingual(`BATTER_ACTION.NONE`)}</span>
        <img className="group--key" src={KeyC} alt="C" />
        <span>{Multilingual(`BATTER_ACTION.SWMISS`)}</span>
      </div>
      <div className="group">
        <span className="group--name"></span>
        <img className="group--key" src={KeyV} alt="V" />
        <span>{Multilingual(`BATTER_ACTION.SWTIP`)}</span>
        <img className="group--key" src={KeyB} alt="B" />
        <span>{Multilingual(`BATTER_ACTION.INFIELD`)}</span>
        <img className="group--key" src={KeyN} alt="N" />
        <span>{Multilingual(`BATTER_ACTION.OUTFIELD`)}</span>
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`ON_SITE.CREATE_BALL`)}</span>
        <img className="group--key" src={KeyEnter} alt="Enter" />
        <span>（{Multilingual(`SHORTCUT.EDITING_ENTER`)}）</span>
      </div>
    </StyledDiv>
  );
};

export default Shortcuts;
