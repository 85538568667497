import styled from 'styled-components';
import Button from '~~elements/Button';
import { COLOR } from '~~elements/Const';
import toast from '~~elements/Toast';
import { getPitchIndexByTime, timeDisplayReverse, validTimeDisplay } from '../Const';
import { ACTIONS, useLocalDispatch, useLocalState } from '../useLocal';
import KeyHint from './KeyHint';
import { useEffect, useState } from 'react';
import { PITCH_TYPE_NAME } from '~~commonStats/pitch';
import PitchDetail from './PitchDetail';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  .pitches {
    width: 100%;
    height: calc(100% - 280px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pitch {
    display: grid;
    grid-template-columns: 1fr repeat(2, 0.5fr) repeat(5, 1fr) repeat(3, 0.5fr);
    text-align: center;
    overflow-x: hidden;
    text-overflow: clip;
    white-space: nowrap;
    span {
      overflow: hidden;
    }

    &.middle-inning {
      border-top: 1px dashed #9c9c9c;
    }
    &.new-inning {
      border-top: 1px solid #2b2b2b;
    }

    background-color: #f4f4ff;
    &.AWAY {
      background-color: #fff4f4;
    }

    button {
      min-height: unset;
    }

    &.current {
      color: #fafafa;
      background-color: ${COLOR.primary};
    }
  }
`;

const Pitches = ({ handleCSVSelect, handleEntryPointSelect }) => {
  const dispatch = useLocalDispatch();
  const { currentTime, pitches, pitchIndex } = useLocalState();

  const [currentPitch, setCurrentPitch] = useState(null);

  useEffect(() => {
    setCurrentPitch(pitches[pitchIndex]);
  }, [pitches, pitchIndex]);

  const pitchClass = (pitchEvent, peIndex) => {
    if (peIndex === 0) {
      return '';
    }

    if (pitches[peIndex - 1].inning !== pitchEvent.inning) {
      return 'new-inning';
    }

    return pitches[peIndex - 1].side !== pitchEvent.side ? 'middle-inning' : '';
  };

  const handleTimeInsert = (targetIndex) => (e) => {
    e.target.blur();
    if (pitches.length === 0) {
      toast('請先指定場次', { status: 'error', second: 1 });
      return;
    }

    let timePEIndex = getPitchIndexByTime(pitches, Math.floor(currentTime));
    if (Math.ceil(timePEIndex) === timePEIndex) {
      toast('此時間已有投球事件，不得插入', { status: 'error', second: 1 });
      return;
    }

    let preIndex = -1;
    let nextIndex = 10000;
    pitches.forEach((pitchEvent, peIndex) => {
      if (peIndex === targetIndex || !validTimeDisplay(pitchEvent.video_started_at)) {
        return;
      }

      if (peIndex < targetIndex) {
        preIndex = peIndex;
        return;
      }
      nextIndex = peIndex;
      return;
    });

    const preTime = pitches[preIndex] ? timeDisplayReverse(pitches[preIndex].video_started_at) : null;
    const nextTime = pitches[nextIndex] ? timeDisplayReverse(pitches[nextIndex].video_started_at) : null;
    if (preTime && preTime >= currentTime) {
      toast(`前一個有時間的投球更晚 ${preIndex + 1}(${pitches[preIndex].video_started_at})，無法插入`, {
        status: 'error',
        second: 1,
      });
      return;
    }
    if (nextTime && nextTime <= currentTime) {
      toast(`下一個有時間的投球更早 ${nextIndex + 1}(${pitches[nextIndex].video_started_at})，無法插入`, {
        status: 'error',
        second: 1,
      });
      return;
    }

    dispatch({ type: ACTIONS.INSERT_PITCH_TIME, payload: { peIndex: targetIndex, time: currentTime } });
  };

  return (
    <StyledDiv>
      <div>
        {/* <Button mini color="transparent" onClick={handleCSVSelect(0)}>
          載入分析檔案
        </Button> */}
        {/* <Button mini color="transparent" onClick={handleEntryPointSelect}>
          匯入進壘點
        </Button> */}
      </div>
      <PitchDetail currentPitch={currentPitch} dispatch={dispatch} pitchIndex={pitchIndex} />
      <div className="pitch">
        <span>時間</span>
        <span>SignIn</span>
        <span>流</span>
        <span>局/方</span>
        <span>B-S-O</span>
        <span>投</span>
        <span>打</span>
        <span>球速</span>
        <span>球種</span>
        <span>進壘</span>
        <span>結果</span>
      </div>
      <div className="pitches">
        {pitches.map((pitchEvent, peIndex) => (
          <div
            key={peIndex}
            className={`pitch ${pitchEvent.side} ${pitchClass(pitchEvent, peIndex)} ${
              pitchIndex === peIndex ? 'current' : ''
            }`}
          >
            <span>
              {pitchEvent.video_started_at ? (
                <Button
                  mini
                  color="transparent"
                  onClick={(e) => {
                    e.target.blur();
                    dispatch({ type: ACTIONS.FOCUS_PITCH, payload: peIndex });
                  }}
                >
                  {pitchEvent.video_started_at}
                </Button>
              ) : (
                <label>
                  {/* <Button mini width="auto" color="transparent" onClick={handleCSVSelect(peIndex)}>
                  載
                </Button>
                | */}
                  <Button mini width="auto" color="transparent" onClick={handleTimeInsert(peIndex)}>
                    插
                  </Button>
                </label>
              )}
            </span>
            <span>{pitchEvent.video_signin_at ? 'O' : ''}</span>
            <span>{`${peIndex + 1}`}</span>
            <span>{`${pitchEvent.inning}${pitchEvent.side === 'AWAY' ? '上' : '下'}`}</span>
            <span>{`${pitchEvent.b}-${pitchEvent.s}-${
              pitchEvent.startOuts !== pitchEvent.outs ? `${pitchEvent.startOuts}-${pitchEvent.outs}` : pitchEvent.outs
            }`}</span>
            <span>{`${pitchEvent.pitcher.name}`}</span>
            <span>{`${pitchEvent.batter.name}`}</span>
            <span>{`${pitchEvent.pitch.velocity}`}</span>
            <span>{PITCH_TYPE_NAME[pitchEvent.pitch.type] || pitchEvent.pitch.type}</span>
            <span>{pitchEvent.pitch.coord_x && pitchEvent.pitch.coord_y ? 'O' : ''}</span>
            <span>
              {pitchEvent.result &&
                (pitchEvent.pitch.code === 'H' ? <strong>{pitchEvent.result}</strong> : pitchEvent.result)}
            </span>
          </div>
        ))}
      </div>
    </StyledDiv>
  );
};

export default Pitches;
