import { useEffect, useState } from 'react';
import { is } from 'ramda';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { UserResource, StrikezoneGameResource } from '~~apis/resource';
import toast from '~~elements/Toast';
import Loading from '~~elements/Loading';
import { useQuery } from '~~utils/CommonUtils';
import StrikezoneGameEdit from './StrikezoneGameEdit';
import NoPermissionNotification from './components/NoPermissionNotification';
import { useRootState } from '../../useRoot';
import { parseUserInfo } from '../../Const';
import { PerBallProvider } from './useResultPanel';
import Multilingual from './Multilingual';

const Index = () => {
  const query = useQuery();
  const complex = query.get('complex');

  const { strikezoneUniqid } = useParams();
  const {
    loginUser: { isLogin },
  } = useRootState();

  const [strikezoneGameRefPath, setStrikezoneGameRefPath] = useState('');

  const [loginUserWaiting, setLoginUserWaiting] = useState(3);
  const [notEditorError, setNotEditorError] = useState(true);

  const [strikezoneGameTitle, setStrikezoneGameTitle] = useState('');
  const [strikezoneGameNotExist, setStrikezoneGameNotExist] = useState(false);

  const [userInfo, setUserInfo] = useState(parseUserInfo());

  useEffect(() => {
    if (!isLogin || !strikezoneUniqid) {
      setNotEditorError(true);
      return;
    }

    UserResource.getBasicInfo()
      .then(({ data }) => {
        setUserInfo(parseUserInfo(data));
        let gameRef = '';
        if (is(Array, data.publicGameUniqids) && data.publicGameUniqids.includes(strikezoneUniqid)) {
          gameRef = 'publicGames';
        }
        if (is(Array, data.unlistedGameUniqids) && data.unlistedGameUniqids.includes(strikezoneUniqid)) {
          gameRef = 'unlistedGames';
        }

        setNotEditorError(!gameRef);
        if (!gameRef) {
          setStrikezoneGameRefPath('');
          return;
        }

        UserResource.getGameFastpass({ strikezoneUniqid })
          .then(({ data }) => {
            Cookies.set(`RB_${strikezoneUniqid}_FASTPASS`, data, {
              expires: 1,
              path: window.location.pathname,
              domain: process.env.REACT_APP_DOMAIN_PUBLIC_SUFFIX,
              sameSite: 'Lax',
            });
            setStrikezoneGameRefPath(`${gameRef}/${strikezoneUniqid}`);
          })
          .catch((e) => {
            console.error(e);
            toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
          });
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      });

    StrikezoneGameResource.getStrikezoneGame({ strikezoneUniqid })
      .then(({ data }) => {
        setStrikezoneGameTitle(data.title);
        setStrikezoneGameNotExist(false);
      })
      .catch((e) => {
        setStrikezoneGameNotExist(true);
      });
  }, [isLogin, strikezoneUniqid]);

  useEffect(() => {
    if (loginUserWaiting <= 0) {
      return;
    }

    setTimeout(() => {
      setLoginUserWaiting(loginUserWaiting - 1);
    }, 1000);
  }, [loginUserWaiting]);

  if ((!userInfo.inSubscription || notEditorError) && loginUserWaiting > 0) {
    return (
      <div style={{ width: '100vw', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
        <Loading width="20vw" />
      </div>
    );
  }

  if (!userInfo.inSubscription) {
    return (
      <NoPermissionNotification
        strikezoneGameNotExist={strikezoneGameNotExist}
        strikezoneGameTitle={strikezoneGameTitle}
        notInSubscription={true}
      />
    );
  }

  if (notEditorError) {
    return (
      <NoPermissionNotification
        strikezoneGameNotExist={strikezoneGameNotExist}
        strikezoneGameTitle={strikezoneGameTitle}
        notInSubscription={false}
      />
    );
  }

  if (!strikezoneGameRefPath) {
    return (
      <div style={{ width: '100vw', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
        <Loading width="20vw" />
      </div>
    );
  }

  return (
    <PerBallProvider>
      <StrikezoneGameEdit
        strikezoneUniqid={strikezoneUniqid}
        refPath={strikezoneGameRefPath}
        userInfo={userInfo}
        complex={complex}
      />
    </PerBallProvider>
  );
};

export default Index;
