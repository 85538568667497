import styled from 'styled-components';
import LogoSVG from '~~elements/static/logo.svg';
import AnimButton from '~~elements/AnimButton';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  z-index: 996;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: start;
  > * {
    margin-right: 2rem;
  }

  .logo {
    width: calc(2.5rem + 10px);
  }
`;

const CustomNavBar = ({ privacy, strikezoneUniqid }) => {
  return (
    <StyledDiv>
      <a href="/">
        <img className="logo" src={LogoSVG} alt="logo" />
      </a>
      <a href="/">
        <AnimButton text={Multilingual(`NAV.LIST`)} />
      </a>
      <a href={`${privacy === 'PUBLIC' ? '' : '/unlisted'}/${strikezoneUniqid}`}>
        <AnimButton text={Multilingual(`NAV.ANALYTICS`)} />
      </a>
    </StyledDiv>
  );
};

export default CustomNavBar;
