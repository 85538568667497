import React from 'react';
import styled from 'styled-components';
import { COLOR } from '~~elements/Const';

const StyledSpan = styled.span.attrs((props) => ({
  style: {
    minWidth: props.width ?? '18px',
    height: props.height ?? '18px',
  },
}))`
  font-size: 14px;
  line-height: 14px;
  border: 2px solid ${COLOR.secondary};
  border-radius: 5px;
  box-shadow: 1px 1px 2px #000000;
  display: inline-block;
`;

const KeyHint = ({ children }) => {
  return <StyledSpan>{children}</StyledSpan>;
};

export default KeyHint;
