import { Fragment } from 'react';
import styled from 'styled-components';
import Select from '~~elements/Select';
import Button from '~~elements/Button';
import Input from '~~elements/Input';
// import Tooltip from '~~elements/Tooltip';
import {
  ballTypes,
  ballDetailTypes,
  judgeCalls,
  hands,
  batterActions,
  getBatterResultOptions,
  ballTrajectories,
  ballContacts,
} from './ResultPanel/ResultPanel';
import Multilingual from '../Multilingual';
import { usePerBallState } from '../useResultPanel';
import { BasesOptions } from '../Const';

const StyledDiv = styled.div`
  max-width: 100%;
  text-align: start;
  display: block;

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .name {
      font-weight: bold;
      padding-right: 0.5rem;
    }
  }
`;

const BallEditRadioMode = ({
  perBall,
  editingIndex,
  setCurrentSide,
  setCurrentPitcher,
  awayPlayers,
  homePlayers,
  setCurrentBall,
  setCurrentBatter,
  resetZoneResult,
  resetSpeed,
  removeBall,
  perBallDispatch,
  addBall,
  addBallable,
  videoMode,
  openDroppointDiagram,
  resetCurrentBallDroppoint,
}) => {
  const { showEZone } = usePerBallState();

  const handleSpeedModalOpen = (e) => {
    if (videoMode) {
      return;
    }

    const autoFocusSpeedEl = document.querySelector('.ball-edit-modal--speed');
    if (!autoFocusSpeedEl) {
      return;
    }

    autoFocusSpeedEl.style.display = 'block';
    autoFocusSpeedEl.focus();
  };

  return (
    <StyledDiv>
      <div className="row">
        <span className="name">{Multilingual(`CURRENT_SIDE.WORDING`)}</span>
        <Select mini value={perBall[editingIndex].inning} onChange={setCurrentBall('inning')}>
          <option value="">{Multilingual(`NULL`)}</option>
          {new Array(18).fill('').map((_, index) => (
            <option key={index} value={`${index + 1}`}>{`${index + 1}`}</option>
          ))}
        </Select>
        {Multilingual(`CURRENT_SIDE.INNING`)}
        {['away', 'home'].map((value) => (
          <label key={value}>
            <input
              type="radio"
              name="currentSide"
              value={value}
              checked={perBall[editingIndex].currentSide === value}
              onChange={setCurrentSide}
            />
            {Multilingual(`CURRENT_SIDE.${value.toUpperCase()}`)}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`PITCHER.WORDING`)}</span>
        <Select mini value={perBall[editingIndex].pitcher} onChange={setCurrentPitcher}>
          <option value="">{Multilingual(`NULL`)}</option>
          {(perBall[editingIndex].currentSide === 'home' ? awayPlayers : homePlayers).map((player) => (
            <option key={player.name}>{player.name}</option>
          ))}
        </Select>
        <label>
          <input
            type="radio"
            name="pHand"
            value=""
            checked={perBall[editingIndex].pHand === ''}
            onChange={setCurrentBall('pHand')}
          />
          -
        </label>
        {Object.keys(hands).map((hand) => (
          <label key={hand}>
            <input
              type="radio"
              name="pHand"
              value={hand}
              checked={perBall[editingIndex].pHand === hand}
              onChange={setCurrentBall('pHand')}
            />
            {Multilingual(`PITCHER.${hand.toUpperCase()}`)}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`BATTER.WORDING`)}</span>
        <Select mini value={perBall[editingIndex].batter} onChange={setCurrentBatter}>
          <option value="">{Multilingual(`NULL`)}</option>
          {(perBall[editingIndex].currentSide === 'away' ? awayPlayers : homePlayers).map((player) => (
            <option key={player.name}>{player.name}</option>
          ))}
        </Select>
        <label>
          <input
            type="radio"
            name="bHand"
            value=""
            checked={perBall[editingIndex].bHand === ''}
            onChange={setCurrentBall('bHand')}
          />
          -
        </label>
        {Object.keys(hands).map((hand) => (
          <label key={hand}>
            <input
              type="radio"
              name="bHand"
              value={hand}
              checked={perBall[editingIndex].bHand === hand}
              onChange={setCurrentBall('bHand')}
            />
            {Multilingual(`BATTER.${hand.toUpperCase()}`)}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`BASES.WORDING`)}</span>
        <Select mini value={perBall[editingIndex].bases} onChange={setCurrentBall('bases')}>
          <option value="">{Multilingual(`NULL`)}</option>
          {Object.keys(BasesOptions).map((key) => (
            <option key={key} value={BasesOptions[key].value}>
              {BasesOptions[key].label}
            </option>
          ))}
        </Select>
        <span className="name">{Multilingual(`OUTS.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="outs"
            value=""
            checked={perBall[editingIndex].outs === ''}
            onChange={setCurrentBall('outs')}
          />
          -
        </label>
        {new Array(3).fill('').map((_, index) => (
          <label key={index}>
            <input
              type="radio"
              name="outs"
              value={`${index}`}
              checked={perBall[editingIndex].outs === `${index}`}
              onChange={setCurrentBall('outs')}
            />
            {`${index}`}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`ENTRY_POINT.WORDING`)}</span>
        <span>{`${perBall[editingIndex].eyeZoneResult.x},${perBall[editingIndex].eyeZoneResult.y}`}</span>
        <Button width="unset" color="whiteWithBorder" mini onClick={resetZoneResult('eyeZoneResult')}>
          {Multilingual(`ENTRY_POINT.RESET`)}
        </Button>
        {showEZone && videoMode && (
          <Fragment>
            <span className="name">{Multilingual(`ENTRY_POINT.ELECTRONIC`)}</span>
            <span>{`${perBall[editingIndex].eZoneResult.x},${perBall[editingIndex].eZoneResult.y}`}</span>
            <Button width="unset" color="whiteWithBorder" mini onClick={resetZoneResult('eZoneResult')}>
              {Multilingual(`ENTRY_POINT.RESET`)}
            </Button>
          </Fragment>
        )}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`SPEED.WORDING`)}</span>
        <Input
          type="text"
          inputmode="numeric"
          name="speed"
          min="30"
          max="170"
          pattern="[0-9]*"
          width="5rem"
          value={perBall[editingIndex].speed}
          onChange={setCurrentBall('speed')}
          onClick={handleSpeedModalOpen}
          readOnly={!videoMode}
        />
        <Button width="unset" color="whiteWithBorder" mini onClick={resetSpeed}>
          {Multilingual(`ENTRY_POINT.RESET`)}
        </Button>
      </div>
      <div className="row">
        <span className="name">{Multilingual(`PITCH_DETAIL_TYPE.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="ballDetailType"
            value=""
            checked={perBall[editingIndex].ballDetailType === ''}
            onChange={setCurrentBall('ballDetailType')}
          />
          -
        </label>
        {ballDetailTypes.map((ballDetailType) => (
          <label key={ballDetailType}>
            <input
              type="radio"
              name="ballDetailType"
              value={ballDetailType}
              checked={perBall[editingIndex].ballDetailType === ballDetailType}
              onChange={setCurrentBall('ballDetailType')}
            />
            {ballDetailType}
            {/* {Multilingual(`PITCH_DETAIL_TYPE.${ballDetailType}`)} */}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`PITCH_TYPE.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="ballType"
            value=""
            checked={perBall[editingIndex].ballType === ''}
            onChange={setCurrentBall('ballType')}
          />
          -
        </label>
        {Object.keys(ballTypes).map((ballType) => (
          <label key={ballType}>
            <input
              type="radio"
              name="ballType"
              value={ballType}
              checked={perBall[editingIndex].ballType === ballType}
              onChange={setCurrentBall('ballType')}
            />
            {Multilingual(`PITCH_TYPE.${ballType.toUpperCase()}`)}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`JUDGE_CALL.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="judgeCall"
            value=""
            checked={perBall[editingIndex].judgeCall === ''}
            onChange={setCurrentBall('judgeCall')}
          />
          -
        </label>
        {Object.keys(judgeCalls).map((judgeCall) => (
          <label key={judgeCall}>
            <input
              type="radio"
              name="judgeCall"
              value={judgeCall}
              checked={perBall[editingIndex].judgeCall === judgeCall}
              onChange={setCurrentBall('judgeCall')}
            />
            {Multilingual(`JUDGE_CALL.${judgeCall.toUpperCase()}`)}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`BATTER_ACTION.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="batterAction"
            value=""
            checked={perBall[editingIndex].batterAction === ''}
            onChange={setCurrentBall('batterAction')}
          />
          -
        </label>
        {Object.keys(batterActions).map((action) => (
          <label key={action}>
            <input
              type="radio"
              name="batterAction"
              value={action}
              checked={perBall[editingIndex].batterAction === action}
              onChange={setCurrentBall('batterAction')}
            />
            {Multilingual(`BATTER_ACTION.${action.toUpperCase()}`)}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">- {Multilingual(`BATTER_RESULT.WORDING`)} -</span>
      </div>
      <div className="row">
        <span className="name">{Multilingual(`CONTACT.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="ballContact"
            value=""
            checked={perBall[editingIndex].ballContact === ''}
            onChange={setCurrentBall('ballContact')}
          />
          -
        </label>
        {Object.keys(ballContacts).map((ballContact) => (
          <label key={ballContact}>
            <input
              type="radio"
              name="ballContact"
              value={ballContact}
              checked={perBall[editingIndex].ballContact === ballContact}
              onChange={setCurrentBall('ballContact')}
            />
            {ballContact}
          </label>
        ))}
        <Button onClick={openDroppointDiagram} mini color="blue" width="unset">
          {Multilingual(`DROPPOINT.WORDING`)}：
          {!perBall[editingIndex].ballDroppoint ? Multilingual(`NULL`) : perBall[editingIndex].ballDroppoint}
        </Button>
        <Button width="unset" color="whiteWithBorder" mini onClick={resetCurrentBallDroppoint}>
          {Multilingual(`ENTRY_POINT.RESET`)}
        </Button>
        {/* <Tooltip content="ref: Retrosheet Hit Location Diagram" /> */}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`TRAJECTORY.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="ballTrajectory"
            value=""
            checked={perBall[editingIndex].ballTrajectory === ''}
            onChange={setCurrentBall('ballTrajectory')}
          />
          -
        </label>
        {Object.keys(ballTrajectories).map((trajectory) => (
          <label key={trajectory}>
            <input
              type="radio"
              name="ballTrajectory"
              value={trajectory}
              checked={perBall[editingIndex].ballTrajectory === trajectory}
              onChange={setCurrentBall('ballTrajectory')}
            />
            {trajectory}
          </label>
        ))}
      </div>
      <div className="row">
        <span className="name">{Multilingual(`BATTER_RESULT.WORDING`)}</span>
        <label>
          <input
            type="radio"
            name="batterResult"
            value=""
            checked={perBall[editingIndex].batterResult === ''}
            onChange={setCurrentBall('batterResult')}
          />
          -
        </label>
        {getBatterResultOptions(perBall[editingIndex].batterAction, perBall[editingIndex].ballTrajectory).map(
          (result) => (
            <label key={result}>
              <input
                type="radio"
                name="batterResult"
                value={result}
                checked={perBall[editingIndex].batterResult === result}
                onChange={setCurrentBall('batterResult')}
              />
              {Multilingual(`BATTER_RESULT.${result}`)}
            </label>
          ),
        )}
      </div>
      <div className="row">
        {editingIndex !== perBall.length - 1 ? (
          <span>
            <Button width="unset" mini color="blue" onClick={removeBall}>
              {Multilingual(`ON_SITE.DELETE_BALL`)}
            </Button>
            <Button width="unset" mini color="red" onClick={() => perBallDispatch({ type: 'backFromNoneCurrentEdit' })}>
              {Multilingual(`ON_SITE.BACK_BALL`)}
            </Button>
          </span>
        ) : (
          <Button width="unset" color="red" onClick={addBall} disabled={!addBallable()}>
            {Multilingual(`ON_SITE.CREATE_BALL`)}
          </Button>
        )}
      </div>
    </StyledDiv>
  );
};

export default BallEditRadioMode;
