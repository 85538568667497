import { useEffect } from 'react';
import styled from 'styled-components';
import heat from 'simpleheat';
import { filter } from 'ramda';
import DroppointDisplayDiagram from '~~elements/static/DroppointDisplayDiagram.png';
import { getLocationCenterPosition } from '~~elements/LocationCodes';
import { strikeZoneConfig } from '../constants/base';
import Multilingual from '../../../utils/Multilingual';
const { height } = strikeZoneConfig;

const DropmapStyledDiv = styled.div`
  // 放這邊才不會跟列印時衝突
  #dropmap-img-4-print {
    display: none;
  }
`;

const Index = ({ resolvedPerBall }) => {
  const dropData = filter(
    (b) =>
      b.batterResult &&
      b.batterResult !== 'NONE' &&
      ((!!b.ballDroppoint && b.ballDroppoint !== 'NONE') || !!b.ballDroppointXY),
    resolvedPerBall,
  ).map(({ ballDroppoint, ballDroppointXY }) => {
    const scale = height / 100;
    if (ballDroppointXY) {
      return [ballDroppointXY[0] * scale, ballDroppointXY[1] * scale, 1];
    }

    if (ballDroppoint) {
      const centerPoint = getLocationCenterPosition(ballDroppoint);
      return [centerPoint[0] * scale, centerPoint[1] * scale, 1];
    }

    throw new Error('Something went wrong');
  });

  useEffect(() => {
    const shrink = dropData.length <= 50 ? 1 : 50 / dropData.length;
    const dropmap = heat('dropmap-canvas')
      .data(
        dropData.map((data) => {
          data[2] *= shrink;
          return data;
        }),
      )
      .max(5);
    dropmap.draw();

    // 轉換成顯示 img 方便出報告時顯示
    const dropmapCanvas = document.getElementById('dropmap-canvas');
    const dropmapImg = document.getElementById('dropmap-img-4-print');
    dropmapImg.src = dropmapCanvas.toDataURL('image/png');
  }, [dropData]);

  return (
    <div>
      <h3>{Multilingual(`GAME.DROPPOINT`)}</h3>
      <DropmapStyledDiv style={{ position: 'relative', width: height, height, margin: '0 auto' }}>
        <svg width={height} height={height}>
          <g>
            <rect width={height} height={height} stroke="black" fill="none"></rect>
          </g>
        </svg>
        <img
          src={DroppointDisplayDiagram}
          alt="dropmap-map"
          style={{ position: 'absolute', zIndex: 98, top: 0, left: 0 }}
          width={height}
          height={height}
        />
        <img
          id="dropmap-img-4-print"
          src=""
          alt="dropmap-img"
          style={{ position: 'absolute', zIndex: 99, top: 0, left: 0 }}
          width={height}
          height={height}
        />
        <canvas
          className="noprint"
          style={{ position: 'absolute', zIndex: 100, top: 0, left: 0 }}
          id="dropmap-canvas"
          width={height}
          height={height}
        />
      </DropmapStyledDiv>
    </div>
  );
};

export default Index;
