import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '~~elements/Button';
import Input from '~~elements/Input';
import Select from '~~elements/Select';
import styled from 'styled-components';
import { hands } from './ResultPanel/ResultPanel';
import Multilingual from '../Multilingual';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: `${grid}px ${grid * 2}px ${grid}px ${grid}px`,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '5px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  borderRadius: '5px',
  // width: 250,
});

const PlayerDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  input {
    margin-right: 2px;
  }
`;

const PlayerList = ({ debounce, players, setPlayers, remotePlayersUpdate }) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newPlayers = reorder(players, result.source.index, result.destination.index);
    setPlayers(newPlayers);
    remotePlayersUpdate(newPlayers);
  };

  const removePlayer = (index) => () => {
    players.splice(index, 1);
    const newPlayers = [...players];
    setPlayers(newPlayers);
    remotePlayersUpdate(newPlayers);
  };

  const updatePlayer = (index) => (e) => {
    debounce[index] = e.target.value;
    setPlayers((pre) => {
      pre[index][e.target.name] = e.target.value;
      return [...pre];
    });
  };

  const changePlayerHand = (index) => (e) => {
    players[index][e.target.name] = e.target.value;
    setPlayers([...players]);
    remotePlayersUpdate([...players]);
  };

  const appendPlayer = () => {
    const newPlayers = [...players, { name: '', hand: '' }];
    setPlayers(newPlayers);
    remotePlayersUpdate(newPlayers);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {players.map((player, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <PlayerDiv>
                      <span style={{ cursor: 'pointer', color: 'darkred' }} onClick={removePlayer(index)}>
                        X
                      </span>
                      <Input
                        name="name"
                        textAlign="left"
                        value={player.name}
                        onChange={updatePlayer(index)}
                        disabled={snapshot.isDragging}
                        onBlur={() => remotePlayersUpdate()}
                      />
                      <Select
                        name="hand"
                        value={player.hand}
                        onChange={changePlayerHand(index)}
                        disabled={snapshot.isDragging}
                        mini
                      >
                        <option value="">{Multilingual(`NULL`)}</option>
                        <option value="R/R">{`${hands.R}/${hands.R}`}</option>
                        <option value="R/L">{`${hands.R}/${hands.L}`}</option>
                        <option value="L/L">{`${hands.L}/${hands.L}`}</option>
                        <option value="L/R">{`${hands.L}/${hands.R}`}</option>
                      </Select>
                    </PlayerDiv>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button width="unset" color="red" mini onClick={appendPlayer}>
        {Multilingual(`CREATE`)}
      </Button>
    </DragDropContext>
  );
};

export default PlayerList;
