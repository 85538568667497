import styled from 'styled-components';
import { reverse } from 'ramda';
import { useLongPress } from 'use-long-press';
import { isMobile } from 'react-device-detect';
import { PHONE_WIDTH } from '~~elements/Const';
import Multilingual from '../Multilingual';
import { usePerBallState } from '../useResultPanel';
import { BasesOptions } from '../Const';

const StyledDiv = styled.div`
  ${(props) => (!props.onsite && !props.video ? '' : 'height: 100%; overflow-y: auto;')}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem ${(props) => (props.onsite || props.video ? '3rem' : '0')};

  .ball-record {
    width: calc(100% - 1rem);
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) repeat(3, 1fr) ${(props) =>
        props.showEZone && !props.onsite ? '1fr' : ''} 1fr 1fr 2fr ${(props) =>
        !props.wingman || isMobile ? '' : '1fr'};
    margin: 0.2rem 1rem 0 0;
    border-bottom: 1px solid #fafafa;
    &:last-child {
      border-bottom: 0px;
    }
  }

  @media (max-width: ${PHONE_WIDTH}) {
    padding: 0.5rem ${(props) => (props.onsite || props.video ? '0.1rem' : '0')};
  }
`;

const noHitBall = ['NONE', 'uBB', 'IBB', 'HBP', 'SO', 'IH'];

const Ball = ({ perBall, editingIndex, wingman, onsite, dbClick, ball, i }) => {
  const { showEZone } = usePerBallState();
  const longPress = useLongPress(() => {
    dbClick(i);
  });

  return (
    <label
      className="ball-record"
      onDoubleClick={dbClick(i)}
      {...longPress}
      style={editingIndex + 1 !== perBall.length - i - 1 ? {} : { color: 'yellow' }}
    >
      <span>{perBall.length - i - 1}</span>
      <span>
        {ball.inning && ball.currentSide ? (
          <>
            {ball.inning}
            {ball.currentSide === 'away' ? <>&Delta;</> : <>&nabla;</>}
          </>
        ) : (
          ''
        )}
        {ball.bases !== '' && ball.outs !== '' ? (
          <>
            ,{BasesOptions[ball.bases].label},{ball.outs}o
          </>
        ) : (
          ''
        )}
      </span>
      <span>
        /{!ball.pitcher ? Multilingual('NULL') : ball.pitcher}
        {!ball.pHand ? '' : `(${ball.pHand})`}
      </span>
      <span>
        /{!ball.batter ? Multilingual('NULL') : ball.batter}
        {!ball.bHand ? '' : `(${ball.bHand})`}
      </span>
      <span>/{!ball.speed ? Multilingual('NULL') : ball.speed}</span>
      <span>/{!ball.ballType ? Multilingual('NULL') : Multilingual(`PITCH_TYPE.${ball.ballType}`)}</span>
      <span>/{!ball.judgeCall ? Multilingual('NULL') : Multilingual(`JUDGE_CALL.${ball.judgeCall}`)}</span>
      {showEZone && !onsite && (
        <span>
          /
          {ball.eZoneResult.width === 0 && ball.eZoneResult.height === 0
            ? Multilingual('NULL_SHORT')
            : Multilingual('RECORDED_SHORT')}
        </span>
      )}
      <span>
        /
        {ball.eyeZoneResult.width === 0 && ball.eyeZoneResult.height === 0
          ? Multilingual('NULL_SHORT')
          : Multilingual('RECORDED_SHORT')}
      </span>
      <span>/{!ball.batterAction ? Multilingual('NULL') : Multilingual(`BATTER_ACTION.${ball.batterAction}`)}</span>
      <span>/{!ball.batterResult ? Multilingual('NULL') : Multilingual(`BATTER_RESULT.${ball.batterResult}`)}</span>
      {!isMobile && wingman && (
        <span>
          /
          {!ball.batterResult || noHitBall.includes(ball.batterResult)
            ? '-'
            : !ball.ballTrajectory || !ball.ballDroppoint
            ? Multilingual(`BALL_HINT.PENDING`)
            : Multilingual(`BALL_HINT.COMPLETE`)}
        </span>
      )}
    </label>
  );
};

const PerBall = ({ perBall, editingIndex, setEditingIndex, wingman, onsite, video }) => {
  const { showEZone } = usePerBallState();
  const dbClick = (index) => () => {
    return setEditingIndex(perBall.length - index - 2);
  };

  return (
    <StyledDiv wingman={wingman} showEZone={showEZone} onsite={onsite} video={video}>
      <div className="ball-record">
        <span>{Multilingual(`BALL_SEQ`)}</span>
        <span>{Multilingual(`GAME_STATUS`)}</span>
        <span>/{Multilingual(`PITCHER.WORDING`)}</span>
        <span>/{Multilingual(`BATTER.WORDING`)}</span>
        <span>/{Multilingual(`SPEED.WORDING`)}</span>
        <span>/{Multilingual(`PITCH_TYPE.WORDING`)}</span>
        <span>/{Multilingual(`JUDGE_CALL.SHORT`)}</span>
        {showEZone && !onsite && <span>/E {Multilingual(`ENTRY_POINT.WORDING`)}</span>}
        <span>/{Multilingual(`ENTRY_POINT.WORDING`)}</span>
        <span>/{Multilingual(`BATTER_ACTION.SHORT`)}</span>
        <span>/{Multilingual(`BATTER_RESULT.SHORT`)}</span>
        {!isMobile && wingman && <span>/{Multilingual(`BALL_HINT.WORDING`)}</span>}
      </div>
      {reverse(perBall)
        .slice(1)
        .map((ball, i) => (
          <Ball
            key={`${ball.updatedAt}-${i}-${ball.pitcher}-${ball.pHand}-${ball.batter}-${ball.bHand}-${ball.speed}-${ball.ballType}-${ball.judgeCall}-${ball.eZoneResult.x}-${ball.eZoneResult.y}-${ball.eyeZoneResult.x}-${ball.eyeZoneResult.y}-${ball.batterAction}-${ball.batterResult}`}
            perBall={perBall}
            editingIndex={editingIndex}
            wingman={wingman}
            onsite={onsite}
            dbClick={dbClick}
            ball={ball}
            i={i}
          />
        ))}
    </StyledDiv>
  );
};

export default PerBall;
