import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookie } from 'react-use';
import { is } from 'ramda';
import jwtDecode from 'jwt-decode';
import ApiService from '~~commonAPIs/ApiService';
import FormalNavBar from '~~components/FormalNavBar';
import Footer from '~~components/Footer';
import Feed from '~~features/Feed';
import StrikezoneGameEdit from '~~features/StrikezoneGameEdit';
import StrikezoneGame from '~~features/StrikezoneGame';
import PlayerAnalytics from '~~features/PlayerAnalytics';
import HeatmapAdaptor from '~~features/HeatmapAdaptor';
import Local from '~~features/Local';
import { UserResource } from '~~apis/resource';
import { RootProvider, useRootDispatch, useRootState } from './useRoot';

import './common/assets/App.scss';

const App = () => {
  return (
    <RootProvider>
      <div className="App">
        <RootContainer />
      </div>
    </RootProvider>
  );
};

// eslint-disable-next-line no-unused-vars
const TopOnlyContainer = ({ children }) => {
  const { loginUser } = useRootState();
  return (
    <Fragment>
      <FormalNavBar userInfo={loginUser} />
      <div id="app-content">{children}</div>
    </Fragment>
  );
};

const RegularContainer = ({ children }) => {
  const { loginUser, isAdmin, isAdminManager } = useRootState();
  return (
    <Fragment>
      <FormalNavBar userInfo={{ ...loginUser, isAdmin: isAdminManager ? 'ADMIN' : isAdmin ? 'EDITOR' : false }} />
      <div id="app-content">{children}</div>
      <Footer />
    </Fragment>
  );
};

const RootContainer = () => {
  const rootDispatch = useRootDispatch();
  const [accessToken] = useCookie('RB_UID');
  const [refreshToken] = useCookie('RB_RID');

  useEffect(() => {
    const parseAccessToken = (token) => {
      const resolvedUserInfo = jwtDecode(token);
      rootDispatch({
        type: 'setLoginUser',
        payload: {
          isLogin: true,
          ...resolvedUserInfo,
        },
      });

      UserResource.permissionList()
        .then(({ data }) => {
          rootDispatch({
            type: 'setPermission',
            payload: {
              isAdmin: data?.platform === 'ADMIN' || data?.platform === 'EDITOR',
              isAdminManager: data?.platform === 'ADMIN',
            },
          });
        })
        .catch(() => {});
    };

    if (!is(String, accessToken)) {
      rootDispatch({
        type: 'setLoginUser',
        payload: {
          isLogin: false,
        },
      });
      if (is(String, refreshToken) && refreshToken !== '') {
        ApiService.refreshToken()
          .then((newToken) => {
            parseAccessToken(newToken);
          })
          .catch(() => {});
      }
    } else {
      parseAccessToken(accessToken);
    }
  }, [accessToken, refreshToken, rootDispatch]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RegularContainer>
              <Feed />
            </RegularContainer>
          }
        />
        <Route
          path="/analytics"
          element={
            <RegularContainer>
              <PlayerAnalytics />
            </RegularContainer>
          }
        />
        <Route
          path="/:strikezoneUniqid"
          element={
            <RegularContainer>
              <StrikezoneGame />
            </RegularContainer>
          }
        />
        <Route
          path="/unlisted/:strikezoneUniqid"
          element={
            <RegularContainer>
              <StrikezoneGame unlisted />
            </RegularContainer>
          }
        />
        <Route path="/:strikezoneUniqid/edit" element={<StrikezoneGameEdit />} />
        <Route
          path="/local"
          element={
            <RegularContainer>
              <Local />
            </RegularContainer>
          }
        />
        <Route
          path="/heatmap-adaptor"
          element={
            <RegularContainer>
              <HeatmapAdaptor />
            </RegularContainer>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
