import React from 'react';
import styled from 'styled-components';
import Select from '../../elements/Select';
import { FOOTER_BUTTONS, FOOTER_TEXTS } from './FooterConsts';
import IconInstagram from './IconInstagram.svg';
import IconFacebook from './IconFacebook.svg';
import { PHONE_WIDTH, languagePreferKey } from '../../elements/Const';
import Multilingual from '../../Multilingual';

const StyledFooter = styled.footer`
  letter-spacing: 2px;
  background: linear-gradient(135deg, rgb(70 9 9), rgb(10, 74, 154, 1));
  font-size: 0.8rem;
  z-index: 2;
  position: relative;

  .upper-box {
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1.5rem 0rem;
  }
  .upper-box--start {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.65);
    }
    select {
      color: rgba(255, 255, 255, 0.65);
      font-size: 0.8rem;
    }
    option {
      color: black;
      font-size: 0.8rem;
    }
    .icon-group {
      margin: 0 0.5rem;
      width: 8rem;
      img {
        width: 2.5rem;
        margin: 0 0.4rem;
        opacity: 0.8;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
  .upper-box--center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .button-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      margin: 0 0.5rem;
      .link-btn {
        margin: 0 0.8rem;
        color: rgba(255, 255, 255, 0.8);
        opacity: 0.8;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
  .upper-box--end {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text-group {
      margin: 0 0.5rem;
      display: flex;
      .description {
        text-align: start;
        margin: 0 0.4rem;
        white-space: pre-line;
        font-size: 12px;
        font-weight: lighter;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
  .bottom-box {
    width: 100%;
    height: 25%;
    background: rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0;
    .copyright-btn {
      display: block;
      margin: 0 0.5rem;
      color: #ffffff;
    }
  }

  @media (max-width: ${PHONE_WIDTH}) {
    .upper-box {
      flex-direction: column;
    }

    .upper-box--start {
      flex-direction: column;
      .icon-group {
        img {
          width: 2rem;
          margin: 0.5rem 0;
        }
      }
    }

    .upper-box--center {
      flex-direction: column;
      .button-group {
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
        margin: 1.2rem 0;
        .link-btn {
          display: block;
          margin: 0;
        }
      }
    }

    .upper-box--end {
      flex-direction: column;
      .text-group {
        flex-direction: column;
        .description {
          margin: 0.4rem 0;
        }
      }
    }
  }
`;

const Footer = () => {
  const languagePrefer = localStorage.getItem(languagePreferKey);
  const handleLanguagePrefer = (e) => {
    if (!e.target.value) {
      return;
    }

    localStorage.setItem(languagePreferKey, e.target.value);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <StyledFooter id="footer-wrapper">
      <div className="upper-box">
        <div className="upper-box--start">
          {/* <Select
            label={Multilingual(`LANGUAGE_PREFERENCE`)}
            defaultValue={languagePrefer}
            mini
            minWidth="1rem"
            onChange={handleLanguagePrefer}
            margin="0"
          >
            <option value="zh-TW">繁中</option>
            <option value="en">English</option>
          </Select> */}
          {/* 這邊是社群Icon們*/}
          <div className="icon-group">
            <a
              href={process.env.REACT_APP_FACEBOOK_URL ?? process.env.NEXT_PUBLIC_FACEBOOK_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconFacebook} alt="" />
            </a>
            <a
              href={process.env.REACT_APP_INSTAGRAM_URL ?? process.env.NEXT_PUBLIC_INSTAGRAM_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconInstagram} alt="" />
            </a>
          </div>
        </div>
        <div className="upper-box--center">
          {/* 這邊是關於我們的那幾頁 */}
          <div className="button-group">
            {FOOTER_BUTTONS.map((btn) => (
              <a className="link-btn" href={btn.url} key={btn.url}>
                {btn.buttonName}
              </a>
            ))}
          </div>
        </div>
        <div className="upper-box--end">
          {/* 這邊是文字說明一些宣告內容 */}
          <div className="text-group">
            {FOOTER_TEXTS.map((text, i) => (
              <div className="description" key={i}>
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bottom-box">
        {`© ${new Date().getFullYear()} Copyright:`}
        <a className="copyright-btn" href="https://www.rebas.tw/">
          野革運動股份有限公司
        </a>
      </div>
    </StyledFooter>
  );
};

Footer.defaultProps = {};

export default Footer;
