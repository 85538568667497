import React from 'react';
import styled from 'styled-components';
import LogoSvg from '../static/logo.svg';

const StyledDiv = styled.div`
  height: ${({ width }) => width};
  width: ${({ width }) => width};
  background-image: url(${LogoSvg.src ?? LogoSvg});
  background-repeat: no-repeat;
  background-position: center;
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  -webkit-animation: inside-logo 1s infinite linear;
  -moz-animation: inside-logo 1s infinite linear;
  -o-animation: inside-logo 1s infinite linear;
  animation: inside-logo 1s infinite linear;

  @-webkit-keyframes inside-logo {
    from {
      opacity: 0.5;
    }
    50% {
      opacity: 0.9;
    }
    to {
      opacity: 0.5;
    }
  }
  @-moz-keyframes inside-logo {
    from {
      opacity: 0.5;
    }
    50% {
      opacity: 0.9;
    }
    to {
      opacity: 0.5;
    }
  }
  @-o-keyframes inside-logo {
    from {
      opacity: 0.5;
    }
    50% {
      opacity: 0.9;
    }
    to {
      opacity: 0.5;
    }
  }
  @keyframes inside-logo {
    from {
      opacity: 0.5;
    }
    50% {
      opacity: 0.9;
    }
    to {
      opacity: 0.5;
    }
  }
`;

const Loading = (props) => {
  return <StyledDiv {...props}></StyledDiv>;
};

export default Loading;
