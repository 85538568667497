import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { filter } from 'ramda';
import { format as formatDate, isValid as isValidDate, subMonths } from 'date-fns';
import { ref, child, get as getRealtimeDBValue } from 'firebase/database';
import { useQuery } from '~~utils/CommonUtils';
import Multilingual from '~~utils/Multilingual';
import toast from '~~elements/Toast';
import Input from '~~elements/Input';
import Loading from '~~elements/Loading';
import Button from '~~elements/Button';
import IconDownloadSVG from '~~elements/static/IconDownload.svg';
import IconChevronLeft from '~~elements/static/IconChevronLeft.svg';
import { UserResource } from '~~apis/resource';
import { getRealtimeDB } from '../../Firebase';
import { parseAPIGame } from '../StrikezoneGame/constants/base';
import { FilterProvider } from '../StrikezoneGame/hooks/useFilter';
import Filter from '../StrikezoneGame/Filter';
import StrikeZone from '../StrikezoneGame/StrikeZone';
import Report from '../Report';

const StyledDiv = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 0 0 0.5rem;
  }
  .description {
    margin: 0 0 0.8rem;
    font-size: 0.9rem;
  }
  .search-panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 0.8rem;
  }

  .new-search {
    align-self: flex-start;
    margin: 0 0 0 1rem;
    display: flex;
    align-items: center;
    > img {
      height: 1rem;
    }
  }

  .report-comment {
    margin: 0 auto;
    display: block;
    width: 20rem;
    max-width: 95%;
    height: 5rem;
    resize: none;
    border-radius: 5px;
  }

  .report-download-link {
    display: block;
    margin: 1rem 0;
    font-weight: bold;
    > img {
      height: 1.2rem;
    }
  }
`;

const forceSide = 'home';
const pitcherForceSide = 'away';

const parsePlayerInGame = (games, playerName, playerType, dateStart, dateEnd) => {
  const filteredGames = [];
  const perBall = [];
  games.forEach((game) => {
    if (!game.gameDate) {
      return;
    }

    const gameDate = new Date(game.gameDate);
    if (!isValidDate(gameDate) || gameDate.getTime() < dateStart.getTime() || gameDate.getTime() > dateEnd.getTime()) {
      return;
    }

    const playerJoinedBalls = filter((ball) => ball?.[playerType] === playerName, game.perBall.slice(0, -1)).map(
      (ball) => ({
        ...ball,
        currentSide: forceSide,
      }),
    );
    if (playerJoinedBalls.length === 0) {
      return;
    }

    const parsedGame = parseAPIGame({
      ...game,
      perBall: [...playerJoinedBalls, {}], // append empty object for slice
    });
    perBall.push(...parsedGame.perBall);
    filteredGames.push(parsedGame);
  });

  if (perBall.length === 0) {
    const url = new URL(window.location);
    url.searchParams.delete('player');
    url.searchParams.delete('player_type');
    url.searchParams.delete('date_start');
    url.searchParams.delete('date_end');
    window.history.pushState({}, '', url);
  }

  return {
    games: filteredGames,
    perBall,
  };
};

const PlayerAnalytics = () => {
  const query = useQuery();
  const playerQuery = query.get('player');
  const playerTypeQuery = query.get('player_type');
  const dateStartQuery = query.get('date_start');
  const dateEndQuery = query.get('date_end');

  const [dateFrom, setDateFrom] = useState(formatDate(subMonths(new Date(), 3), 'yyyy-MM-dd'));
  const [dateTo, setDateTo] = useState(formatDate(new Date(), 'yyyy-MM-dd'));
  const [playerInput, setPlayerInput] = useState('');
  const [playerTypeInput, setPlayerTypeInput] = useState('pitcher');

  const [dataLoading, setDataLoading] = useState(false);
  const [playerData, setPlayerData] = useState({
    games: [],
    perBall: [],
  });

  const [writingComment, setWritingComment] = useState(false);
  const [reportComment, setReportComment] = useState('');
  const [printMode, setPrintMode] = useState(false);
  const [pdfPreparing, setPDFPreparing] = useState('');

  useEffect(() => {
    const dateStart = new Date(dateStartQuery);
    const dateEnd = new Date(dateEndQuery);
    if (
      !playerQuery ||
      !playerQuery.trim() ||
      !['pitcher', 'batter'].includes(playerTypeQuery) ||
      !dateStartQuery ||
      !isValidDate(dateStart) ||
      !dateEndQuery ||
      !isValidDate(dateEnd)
    ) {
      return;
    }

    setDataLoading(true);
    UserResource.getGamesInfo()
      .then(({ data }) => {
        const filterDate = (g) => {
          const gameDate = new Date(g.gameDate);
          if (
            !isValidDate(gameDate) ||
            gameDate.getTime() < dateStart.getTime() ||
            gameDate.getTime() > dateEnd.getTime()
          ) {
            return false;
          }
          return true;
        };

        const publicGames = (!data.publicGames ? [] : data.publicGames).filter(filterDate);
        const unlistedGames = (!data.unlistedGames ? [] : data.unlistedGames).filter(filterDate);

        const fullDataGames = [];
        let apiCountDown = publicGames.length + unlistedGames.length + 1;
        const apiDone = () => {
          apiCountDown--;
          if (apiCountDown > 0) {
            return;
          }

          setPlayerData(parsePlayerInGame(fullDataGames, playerQuery.trim(), playerTypeQuery, dateStart, dateEnd));
          setDataLoading(false);
        };

        const getGameValue = (path) => {
          getRealtimeDBValue(child(ref(getRealtimeDB()), path))
            .then((snapshot) => {
              if (!snapshot.exists()) {
                return;
              }
              fullDataGames.push(snapshot.val());
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              apiDone();
            });
        };
        publicGames.forEach((publicGame) => getGameValue(`publicGames/${publicGame.uniqid}`));
        unlistedGames.forEach((unlistedGame) => getGameValue(`unlistedGames/${unlistedGame.uniqid}`));
        apiDone();
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
        setDataLoading(false);
      });
  }, [playerQuery, playerTypeQuery, dateStartQuery, dateEndQuery]);

  const handleCreateAnalytics = () => {
    query.delete('player');
    setTimeout(() => {
      query.set({
        player: playerInput.trim(),
        player_type: playerTypeInput,
        date_start: dateFrom,
        date_end: dateTo,
      });
    }, 100);
  };

  const handleNewSearch = () => {
    if (playerData.perBall.length === 0) {
      window.location.replace('/');
      return;
    }

    setWritingComment(false);

    query.delete('player_type');
    query.delete('player');
    setPlayerData({
      games: [],
      perBall: [],
    });
  };

  const startWritingComment = () => {
    setWritingComment(true);
    setReportComment('');
  };

  const handlePrintReport = () => {
    setPrintMode(true);
    setPDFPreparing('');
  };

  const handleReportSubmitted = (title, callback) => {
    setPrintMode(false);
    setWritingComment(false);
    toast(Multilingual(`PLAYER_ANALYTICS.START_PREPARING`), { status: 'info', second: 3 });

    setPDFPreparing('PREPARING');
    callback
      .then((blobData) => {
        setPDFPreparing({
          title: `${title}.pdf`,
          href: window.URL.createObjectURL(blobData),
        });
        toast(Multilingual(`PLAYER_ANALYTICS.REPORT_COMPLETED`), { status: 'success', second: 5 });
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
        setPDFPreparing('');
      });
  };

  return (
    <StyledDiv>
      <Button className="new-search" width="unset" color="transparentWithBorder" onClick={handleNewSearch}>
        <img src={IconChevronLeft} alt="new search" />
        <span>
          {playerData.perBall.length > 0 ? Multilingual(`PLAYER_ANALYTICS.NEW_SEARCH`) : Multilingual(`BACK_TO_LIST`)}
        </span>
      </Button>
      <h1 className="title">
        {playerData.perBall.length === 0
          ? Multilingual(`PLAYER_ANALYTICS.WORDING`)
          : `${Multilingual(`PLAYER_ANALYTICS.TYPE_${playerTypeQuery.toUpperCase()}`)} ${playerQuery}`}
      </h1>
      <i className="description">{Multilingual(`PLAYER_ANALYTICS.DESCRIPTION`)}</i>
      {playerData.perBall.length === 0 && (
        <Fragment>
          <div className="search-panel">
            <span>{Multilingual(`PLAYER_ANALYTICS.GAME_DATE`)}</span>
          </div>
          <div className="search-panel">
            <span>{Multilingual(`PLAYER_ANALYTICS.GAME_DATE_FROM`)}</span>
            <Input
              textAlign="start"
              type="date"
              width="10rem"
              name="dateFrom"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </div>
          <div className="search-panel">
            <span>{Multilingual(`PLAYER_ANALYTICS.GAME_DATE_TO`)}</span>
            <Input
              textAlign="start"
              type="date"
              width="10rem"
              name="dateTo"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </div>
          <div className="search-panel">
            <label>
              <input
                type="radio"
                name="player_type"
                value="batter"
                checked={playerTypeInput === 'batter'}
                onChange={(e) => setPlayerTypeInput(e.target.value)}
              />
              {Multilingual(`PLAYER_ANALYTICS.TYPE_BATTER`)}
            </label>
            <label>
              <input
                type="radio"
                name="player_type"
                value="pitcher"
                checked={playerTypeInput === 'pitcher'}
                onChange={(e) => setPlayerTypeInput(e.target.value)}
              />
              {Multilingual(`PLAYER_ANALYTICS.TYPE_PITCHER`)}
            </label>
          </div>
          <div className="search-panel">
            <span>{Multilingual(`PLAYER_ANALYTICS.PLAYER_NAME`)}</span>
            <Input
              textAlign="start"
              width="10rem"
              name="player"
              value={playerInput}
              onChange={(e) => setPlayerInput(e.target.value)}
            />
          </div>
          <Button
            width="unset"
            disabled={
              !dateFrom ||
              !isValidDate(new Date(dateFrom)) ||
              !dateTo ||
              !isValidDate(new Date(dateTo)) ||
              playerInput.trim() === '' ||
              dataLoading
            }
            onClick={handleCreateAnalytics}
          >
            {Multilingual(`PLAYER_ANALYTICS.CREATE_ANALYTICS`)}
          </Button>
        </Fragment>
      )}
      {dataLoading && <Loading width="20vw" margin="2rem auto" />}
      {!dataLoading && playerQuery && playerData.perBall.length === 0 && (
        <h2>{Multilingual(`PLAYER_ANALYTICS.NO_DATA`)}</h2>
      )}
      {playerData.perBall.length > 0 && (
        <div style={{ width: '100%' }}>
          <p>
            {`${Multilingual(`PLAYER_ANALYTICS.GAME_DATE`)}：` +
              `${
                !dateStartQuery || !isValidDate(new Date(dateStartQuery))
                  ? ''
                  : formatDate(new Date(dateStartQuery), 'yyyy/MM/dd')
              }` +
              ` - ` +
              `${
                !dateEndQuery || !isValidDate(new Date(dateEndQuery))
                  ? ''
                  : formatDate(new Date(dateEndQuery), 'yyyy/MM/dd')
              }`}
          </p>
          <p>{`${Multilingual(`PLAYER_ANALYTICS.GAME_AMOUNT`)}：${playerData.games.length}`}</p>
          <FilterProvider
            gameInfo={{
              perBall: playerData.perBall,
              isLive: false,
            }}
            targetPlayer={{
              side: playerTypeQuery === 'pitcher' ? pitcherForceSide : forceSide,
              position: playerTypeQuery === 'pitcher' ? 'pitcher' : 'fielder',
            }}
          >
            {printMode && (
              <Report
                perBall={playerData.perBall}
                player={playerQuery}
                playerType={Multilingual(`PLAYER_ANALYTICS.TYPE_${playerTypeQuery.toUpperCase()}`)}
                dateStart={dateStartQuery}
                dateEnd={dateEndQuery}
                gameAmount={playerData.games.length}
                reportComment={reportComment}
                submitted={handleReportSubmitted}
              />
            )}
            <Filter
              gameInfo={{
                perBall: playerData.perBall,
                isLive: false,
                away: '',
                home: '',
              }}
            />
            {!writingComment && (
              <Fragment>
                <span>{Multilingual(`PLAYER_ANALYTICS.SCROLL_DOWN_VIEW_ANALYSIS`)}</span>
                <span>&nbsp;-or-&nbsp;</span>
                <Button
                  width="unset"
                  color="transparentWithBorder"
                  onClick={startWritingComment}
                  disabled={pdfPreparing === 'PREPARING'}
                >
                  {pdfPreparing === 'PREPARING'
                    ? Multilingual(`PLAYER_ANALYTICS.REPORT_PREPARING`)
                    : Multilingual(`PLAYER_ANALYTICS.PRINT_REPORT`)}
                </Button>
              </Fragment>
            )}
            {writingComment && (
              <Fragment>
                <p>{Multilingual(`PLAYER_ANALYTICS.PRINT_REPORT`)}</p>
                <label>{Multilingual(`PLAYER_ANALYTICS.PROVIDE_REPORT_COMMENT`)}</label>
                <textarea
                  className="report-comment"
                  placeholder={Multilingual(`PLAYER_ANALYTICS.REPORT_COMMENT_HINT`)}
                  value={reportComment}
                  textalign="left"
                  onChange={(e) => setReportComment(e.target.value)}
                />
                <Button width="unset" color="blue" onClick={() => setWritingComment(false)}>
                  {Multilingual(`CANCEL`)}
                </Button>
                <Button width="unset" color="red" onClick={handlePrintReport}>
                  {Multilingual(`PLAYER_ANALYTICS.PRINT`)}
                </Button>
              </Fragment>
            )}
            {pdfPreparing && pdfPreparing !== 'PREPARING' && (
              <a
                className="report-download-link"
                href={pdfPreparing.href}
                download={pdfPreparing.title}
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconDownloadSVG} alt="download" />
                {pdfPreparing.title}
              </a>
            )}
            <StrikeZone perBall={playerData.perBall} />
          </FilterProvider>
        </div>
      )}
    </StyledDiv>
  );
};

export default PlayerAnalytics;
