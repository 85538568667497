import React, { createContext, useReducer, useContext } from 'react';
import { StrikezoneGameResource } from '~~apis/resource';
import toast from '~~elements/Toast';
import { getBallInitState } from '../StrikezoneGame/constants/base';
import { parseUserInfo } from '../../Const';
import Multilingual from './Multilingual';

const PerBallStateContext = createContext();
const PerBallDispatchContext = createContext();

const initPerBallStates = {
  strikezoneUniqid: '',
  perBall: [getBallInitState()],
  editingIndex: 0,
  showEZone: false,
  userInfo: parseUserInfo(),
};

const perBallReducer = (state, action) => {
  switch (action.type) {
    case 'setStrikezoneUniqid':
      return { ...state, strikezoneUniqid: action.payload };
    case 'updateCurrentSide':
      state.perBall[state.editingIndex].currentSide = action.payload;
      state.perBall[state.editingIndex].pitcher = '';
      state.perBall[state.editingIndex].pHand = '';
      state.perBall[state.editingIndex].batter = '';
      state.perBall[state.editingIndex].bHand = '';
      StrikezoneGameResource.updateStrikezoneGameBall({
        strikezoneUniqid: state.strikezoneUniqid,
        data: {
          index: state.editingIndex,
          multi: {
            currentSide: action.payload,
            pitcher: '',
            pHand: '',
            batter: '',
            bHand: '',
          },
        },
      }).catch((e) => {
        console.error(e);
        toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
      });

      return { ...state, perBall: [...state.perBall] };
    case 'updatePitcher':
      state.perBall[state.editingIndex].pitcher = action.payload.name;
      state.perBall[state.editingIndex].pHand = action.payload.hand;
      StrikezoneGameResource.updateStrikezoneGameBall({
        strikezoneUniqid: state.strikezoneUniqid,
        data: {
          index: state.editingIndex,
          multi: {
            pitcher: action.payload.name,
            pHand: action.payload.hand,
          },
        },
      }).catch((e) => {
        console.error(e);
        toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
      });

      return { ...state, perBall: [...state.perBall] };
    case 'updateBatter':
      state.perBall[state.editingIndex].batter = action.payload.name;
      state.perBall[state.editingIndex].bHand = action.payload.hand;
      StrikezoneGameResource.updateStrikezoneGameBall({
        strikezoneUniqid: state.strikezoneUniqid,
        data: {
          index: state.editingIndex,
          multi: {
            batter: action.payload.name,
            bHand: action.payload.hand,
          },
        },
      }).catch((e) => {
        console.error(e);
        toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
      });

      return { ...state, perBall: [...state.perBall] };
    case 'updateBall':
      // Ball 容易快速變動的值由 ResultPanel 的 debounce 機制來做線上資料更新
      state.perBall[state.editingIndex][action.payload.key] = action.payload.value;
      return { ...state, perBall: [...state.perBall] };
    case 'updateZoneResult':
      state.perBall[state.editingIndex][action.payload.key] = action.payload.value;

      StrikezoneGameResource.updateStrikezoneGameBall({
        strikezoneUniqid: state.strikezoneUniqid,
        data: {
          index: state.editingIndex,
          key: action.payload.key,
          value: action.payload.value,
        },
      }).catch((e) => {
        console.error(e);
        toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
      });

      return { ...state, perBall: [...state.perBall] };
    case 'updateBallDroppoint':
      state.perBall[state.editingIndex].ballDroppoint = action.payload.ballDroppoint;
      state.perBall[state.editingIndex].ballDroppointXY = action.payload.ballDroppointXY;
      StrikezoneGameResource.updateStrikezoneGameBall({
        strikezoneUniqid: state.strikezoneUniqid,
        data: {
          index: state.editingIndex,
          multi: {
            ballDroppoint: action.payload.ballDroppoint,
            ballDroppointXY: action.payload.ballDroppointXY,
          },
        },
      }).catch((e) => {
        console.error(e);
        toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
      });

      return { ...state, perBall: [...state.perBall] };
    case 'updateEditingIndex':
      return { ...state, editingIndex: action.payload };
    case 'backFromNoneCurrentEdit':
      return { ...state, editingIndex: state.perBall.length - 1 };
    case 'remoteCover': {
      let newEditingIndex = state.editingIndex;
      if (state.perBall.length !== action.payload.length) {
        // 原本編輯當下球，繼續編輯當下球；球變少強制回當下球；球變多超過一顆強制回當下球
        if (
          state.editingIndex === state.perBall.length - 1 ||
          state.perBall.length > action.payload.length ||
          state.perBall.length + 1 < action.payload.length
        ) {
          newEditingIndex = action.payload.length - 1;
        }
      }
      return {
        ...state,
        perBall: action.payload,
        editingIndex: newEditingIndex,
      };
    }

    case 'complexMode':
      return {
        ...state,
        showEZone: true,
      };

    case 'setUserInfo':
      return {
        ...state,
        userInfo: parseUserInfo(action.payload),
      };
    default:
      throw new Error('Invalid action type');
  }
};

function PerBallProvider({ children }) {
  const [state, dispatch] = useReducer(perBallReducer, { ...initPerBallStates });

  return (
    <PerBallStateContext.Provider value={state}>
      <PerBallDispatchContext.Provider value={dispatch}>{children}</PerBallDispatchContext.Provider>
    </PerBallStateContext.Provider>
  );
}

function usePerBallState() {
  const context = useContext(PerBallStateContext);

  if (context === undefined) {
    throw new Error('usePerBallState must be used within a PerBallProvider');
  }
  return context;
}

function usePerBallDispatch() {
  const context = useContext(PerBallDispatchContext);

  if (context === undefined) {
    throw new Error('usePerBallDispatch must be used within a PerBallProvider');
  }
  return context;
}

export { PerBallProvider, usePerBallState, usePerBallDispatch };
