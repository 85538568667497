import Cookies from 'js-cookie';
import ApiService from '~~commonAPIs/ApiService';

const StrikezoneGame = {
  createStrikezoneGame: ({ data }) => {
    return ApiService.post(`/api/games`, { data });
  },

  getStrikezoneGame: ({ strikezoneUniqid }) => {
    return ApiService.get(`/api/games/${strikezoneUniqid}`);
  },

  updateStrikezoneGameBasic: ({ strikezoneUniqid, data }) => {
    return ApiService.put(`/api/games/${strikezoneUniqid}/basic`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  updateStrikezoneGameBall: ({ strikezoneUniqid, data }) => {
    return ApiService.put(`/api/games/${strikezoneUniqid}/ball`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  addStrikezoneGameBall: ({ strikezoneUniqid, data }) => {
    return ApiService.post(`/api/games/${strikezoneUniqid}/ball`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  removeStrikezoneGameBall: ({ strikezoneUniqid, data }) => {
    return ApiService.delete(`/api/games/${strikezoneUniqid}/ball`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  insertStrikezoneGameBalls: ({ strikezoneUniqid, data }) => {
    return ApiService.post(`/api/games/${strikezoneUniqid}/insert_ball`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  changeStrikezoneGamePrivacy: ({ strikezoneUniqid, data }) => {
    return ApiService.put(`/api/games/${strikezoneUniqid}/privacy`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  deleteStrikezoneGame: ({ strikezoneUniqid }) => {
    return ApiService.delete(`/api/games/${strikezoneUniqid}`);
  },

  upgradeStrikezoneGameToPremium: ({ strikezoneUniqid }) => {
    return ApiService.put(`/api/games/${strikezoneUniqid}/premium`);
  },
};

export default StrikezoneGame;
