import React, { createContext, useReducer, useContext } from 'react';
import * as R from 'ramda';
import { videoID, timeToDisplay, validTimeDisplay, timeDisplayReverse, getPitchIndexByTime, insertable } from './Const';

const LocalStateContext = createContext();
const LocalDispatchContext = createContext();

export const ACTIONS = {
  UPDATE_VIDEO: 'UPDATE_VIDEO',
  SET_CURRENT_TIME: 'SET_CURRENT_TIME',
  RESET_GAME: 'RESET_GAME',
  SET_PITCHES: 'SET_PITCHES',
  TIMELINE_OVERWRITE: 'TIMELINE_OVERWRITE',
  SCOUT: 'SCOUT',
  UPDATE_PITCH_TIME: 'UPDATE_PITCH_TIME',
  INSERT_PITCH_TIME: 'INSERT_PITCH_TIME',
  QUICK_INSERT_PITCH_TIME: 'QUICK_INSERT_PITCH_TIME',
  FOCUS_PITCH: 'FOCUS_PITCH',
  FOCUS_PITCH_TIMING: 'FOCUS_PITCH_TIMING',
  DELETE_PITCH: 'DELETE_PITCH',
  BACK_SPACE: 'BACK_SPACE',
  SHIFT: 'SHIFT',
  UPDATE_PITCH_DURATION: 'UPDATE_PITCH_DURATION',
  SET_COORD: 'SET_COORD',
  DELETE_COORD: 'DELETE_COORD',
  UPDATE_PITCH_COLUMN: 'UPDATE_PITCH_COLUMN',
  UPDATE_PITCH_LOCATION_CODE: 'UPDATE_PITCH_LOCATION_CODE',
};

const initStates = {
  duration: 0,
  currentTime: 0,
  pitches: [],
  pitchIndex: -1,
  scoutingMode: false,
  durationPerPitch: '3',
  muted: true,
};

const localReducer = (state, action) => {
  const insertTime = (firstTimeInfo, targetIndex, newPitchIndex) => {
    let swaping = true;
    let preInfo = [{}, firstTimeInfo];

    return {
      ...state,
      pitchIndex: newPitchIndex,
      pitches: R.clone(
        state.pitches.map((pitchEvent, peIndex) => {
          if (peIndex < targetIndex || !swaping) {
            return { ...pitchEvent };
          }
          if (!validTimeDisplay(pitchEvent.video_started_at)) {
            swaping = false;
          }
          preInfo = preInfo.slice(1);
          preInfo.push({
            video_started_at: pitchEvent.video_started_at,
            velocity: pitchEvent.pitch.velocity,
            // coord_x: pitchEvent.pitch.coord_x,
            // coord_y: pitchEvent.pitch.coord_y,
          });
          return {
            ...pitchEvent,
            video_started_at: preInfo[0].video_started_at,
            pitch: {
              ...pitchEvent.pitch,
              velocity: preInfo[0].velocity,
              // coord_x: preInfo[0].coord_x,
              // coord_y: preInfo[0].coord_y,
            },
          };
        }),
      ),
    };
  };
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.UPDATE_VIDEO:
      return {
        ...state,
        duration: payload,
      };
    case ACTIONS.SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: payload.currentTime,
        muted: payload.muted,
      };
    case ACTIONS.RESET_GAME: {
      return {
        ...state,
        scoutingMode: false,
        pitches: [],
        pitchIndex: -1,
      };
    }
    case ACTIONS.SET_PITCHES:
      console.log(payload);
      return {
        ...state,
        pitches: payload,
      };
    case ACTIONS.TIMELINE_OVERWRITE: {
      const { startIndex, timeline } = payload;
      if (state.pitches.length === 0 || timeline.length === 0) {
        return state;
      }

      const pMatched = (event, row) => {
        return (
          row.awayScore >= event.startAwayScore &&
          row.awayScore <= event.awayScore &&
          row.homeScore >= event.startHomeScore &&
          row.homeScore <= event.homeScore &&
          row.inning === event.inning &&
          row.side === event.side &&
          row.S === event.s &&
          row.B === event.b &&
          row.O >= event.startOuts &&
          row.O <= event.outs &&
          !!row.first === !!event.first &&
          !!row.second === !!event.second &&
          !!row.third === !!event.third
        );
      };

      let firstMatchRowIndex = -1;
      let firstMatchEventIndex = -1;
      while (firstMatchEventIndex < 0 && firstMatchRowIndex < timeline.length - 1) {
        firstMatchRowIndex += 1;
        firstMatchEventIndex = R.findIndex((p) => pMatched(p, timeline[firstMatchRowIndex]), state.pitches);
      }

      if (firstMatchEventIndex < 0) {
        return state;
      }

      const validTimeline = timeline.slice(firstMatchRowIndex);
      let currentIndex = -1;
      let toSkip = 0;
      return {
        ...state,
        pitches: R.clone(
          state.pitches.map((pitchEvent, peIndex) => {
            if (toSkip > 0) {
              toSkip--;
              return { ...pitchEvent };
            }
            if (peIndex < firstMatchEventIndex) {
              return { ...pitchEvent };
            }
            currentIndex++;
            let row = validTimeline[currentIndex];
            if (!row) {
              return { ...pitchEvent };
            }

            if (!pMatched(pitchEvent, row)) {
              // 最後一個了，就放棄吧
              if (currentIndex === validTimeline.length - 1 || peIndex === state.pitches.length - 1) {
                return { ...pitchEvent };
              }

              // 偵測往後推有找到相符的
              let toAdd = R.findIndex((r) => pMatched(pitchEvent, r), validTimeline.slice(currentIndex + 1));
              console.log('toAdd', toAdd, pitchEvent, row);
              if (toAdd < 0) {
                // 事件往後推有找到相符的
                toSkip = R.findIndex((e) => pMatched(e, row), state.pitches.slice(peIndex + 1));
                console.log('toSkip', toSkip);
                if (toSkip < 0) {
                  toSkip = 0;
                } else {
                  currentIndex--;
                }
                return { ...pitchEvent };
              }

              currentIndex += toAdd + 1;
              row = validTimeline[currentIndex];
            }

            return {
              ...pitchEvent,
              video_started_at: row.startTime,
              pitch: {
                ...pitchEvent.pitch,
                velocity: row.velocity,
                RPM: row.RPM,
                EV: row.EV,
                // coord_x: '',
                // coord_y: '',
              },
            };
          }),
        ),
      };
    }
    case ACTIONS.UPDATE_PITCH_TIME: {
      if (!state.pitches[state.pitchIndex]) {
        return state;
      }

      const { time, timeType } = payload;
      if (timeType) {
        state.pitches[state.pitchIndex][timeType] = timeToDisplay(state.currentTime);
      } else {
        state.pitches[state.pitchIndex].video_started_at = timeToDisplay(time);
      }
      return {
        ...state,
        pitches: R.clone(state.pitches),
      };
    }
    case ACTIONS.INSERT_PITCH_TIME: {
      const { peIndex: targetIndex, time } = payload;
      return insertTime(
        {
          video_started_at: timeToDisplay(time),
          velocity: '',
          coord_x: '',
          coord_y: '',
        },
        targetIndex,
        state.pitchIndex,
      );
    }
    case ACTIONS.QUICK_INSERT_PITCH_TIME: {
      const peIndex = getPitchIndexByTime(state.pitches, Math.floor(state.currentTime));
      const targetPeIndex = Math.ceil(peIndex);
      if (targetPeIndex === peIndex || targetPeIndex === state.pitches.length) {
        return state;
      }
      if (targetPeIndex < state.pitches.length - 1 && validTimeDisplay(state.pitches[targetPeIndex].video_started_at)) {
        return state;
      }
      return insertTime(
        {
          video_started_at: timeToDisplay(state.currentTime),
          velocity: '',
          coord_x: '',
          coord_y: '',
        },
        targetPeIndex,
        state.pitchIndex,
      );
    }
    case ACTIONS.FOCUS_PITCH: {
      const pitches = state.pitches;
      const newPitchIndex = payload;
      const video = document.querySelector(`#${videoID}`);
      const pitch = pitches[newPitchIndex];
      if (pitch && video && (validTimeDisplay(pitch.video_started_at) || validTimeDisplay(pitch.video_signin_at))) {
        const videoTime =
          state.scoutingMode && validTimeDisplay(pitch.video_signin_at)
            ? timeDisplayReverse(pitch.video_signin_at)
            : timeDisplayReverse(pitch.video_started_at);
        video.currentTime = videoTime;
        video.classList.add('pitch-protected');
        setTimeout(() => {
          video.classList.remove('pitch-protected');
        }, 1000);
      }

      return {
        ...state,
        pitchIndex: newPitchIndex,
      };
    }
    case ACTIONS.FOCUS_PITCH_TIMING: {
      if (!validTimeDisplay(payload)) {
        return state;
      }
      const video = document.querySelector(`#${videoID}`);
      const videoTime = timeDisplayReverse(payload);
      video.currentTime = videoTime;
      video.classList.add('pitch-protected');
      setTimeout(() => {
        video.classList.remove('pitch-protected');
      }, 1000);

      return state;
    }
    case ACTIONS.DELETE_PITCH: {
      if (!state.pitches[state.pitchIndex]) {
        return state;
      }

      const timeType = payload?.timeType;
      if (!timeType || timeType === 'video_started_at') {
        state.pitches[state.pitchIndex] = {
          ...state.pitches[state.pitchIndex],
          video_started_at: '',
          video_signin_at: '',
          video_contact_at: '',
          video_fly_catch_at: '',
          video_ground_catch_at: '',
          video_sb_throw_at: '',
          video_sb_catch_at: '',
          pitch: {
            ...state.pitches[state.pitchIndex].pitch,
            velocity: '',
            type: '',
            RPM: '',
            coord_x: '',
            coord_y: '',
            EV: '',
            trajectory: '',
            hardness: '',
            location_code: '',
            location_coord_x: '',
            location_coord_y: '',
            fielder_position: '',
          },
        };
        return {
          ...state,
          pitches: R.clone(state.pitches),
          pitchIndex: -1,
        };
      }

      state.pitches[state.pitchIndex] = {
        ...state.pitches[state.pitchIndex],
        [timeType]: '',
      };
      return {
        ...state,
        pitches: R.clone(state.pitches),
      };
    }
    case ACTIONS.BACK_SPACE: {
      if (
        state.pitchIndex <= 0 ||
        !state.pitches[state.pitchIndex] ||
        validTimeDisplay(state.pitches[state.pitchIndex - 1]?.video_started_at)
      ) {
        return state;
      }

      const targetIndex = state.pitchIndex;
      let swaping = true;
      return {
        ...state,
        pitchIndex: state.pitchIndex - 1,
        pitches: R.clone(
          state.pitches.map((pitchEvent, peIndex) => {
            if (peIndex + 1 < targetIndex || !swaping) {
              return { ...pitchEvent };
            }
            let nextPitchEvent = state.pitches[peIndex + 1];
            if (!nextPitchEvent) {
              nextPitchEvent = {
                video_started_at: '',
                pitch: {
                  velocity: '',
                  coord_x: '',
                  coord_y: '',
                },
              };
            }
            if (!validTimeDisplay(nextPitchEvent.video_started_at)) {
              swaping = false;
            }

            return {
              ...pitchEvent,
              video_started_at: nextPitchEvent.video_started_at,
              pitch: {
                ...pitchEvent.pitch,
                velocity: nextPitchEvent.pitch.velocity,
                // coord_x: nextPitchEvent.pitch.coord_x,
                // coord_y: nextPitchEvent.pitch.coord_y,
              },
            };
          }),
        ),
      };
    }
    case ACTIONS.SHIFT: {
      if (
        state.pitchIndex <= 0 ||
        !state.pitches[state.pitchIndex] ||
        validTimeDisplay(state.pitches[state.pitchIndex - 1]?.video_started_at)
      ) {
        return state;
      }

      const targetIndex = state.pitchIndex;
      return insertTime(
        {
          video_started_at: '',
          velocity: '',
          coord_x: '',
          coord_y: '',
        },
        targetIndex,
        targetIndex + 1,
      );
    }
    case ACTIONS.SCOUT:
      return {
        ...state,
        scoutingMode: !state.scoutingMode,
      };
    case ACTIONS.UPDATE_PITCH_DURATION:
      return {
        ...state,
        durationPerPitch: payload,
      };
    case ACTIONS.SET_COORD: {
      if (!state.pitches[state.pitchIndex]) {
        return state;
      }

      state.pitches[state.pitchIndex].pitch.coord_x = payload.x.toFixed(2);
      state.pitches[state.pitchIndex].pitch.coord_y = payload.y.toFixed(2);
      return {
        ...state,
        pitches: R.clone(state.pitches),
      };
    }
    case ACTIONS.DELETE_COORD: {
      if (!state.pitches[state.pitchIndex]) {
        return state;
      }
      state.pitches[state.pitchIndex].pitch.coord_x = '';
      state.pitches[state.pitchIndex].pitch.coord_y = '';
      return {
        ...state,
        pitches: R.clone(state.pitches),
      };
    }
    case ACTIONS.UPDATE_PITCH_COLUMN: {
      if (!state.pitches[state.pitchIndex]) {
        return state;
      }

      state.pitches[state.pitchIndex].pitch[payload.column] = payload.value;
      return {
        ...state,
        pitches: R.clone(state.pitches),
      };
    }
    case ACTIONS.UPDATE_PITCH_LOCATION_CODE: {
      if (!state.pitches[state.pitchIndex]) {
        return state;
      }

      state.pitches[state.pitchIndex].pitch.location_code = payload.locationCode;
      state.pitches[state.pitchIndex].pitch.location_coord_x = `${payload.xy[0]}`;
      state.pitches[state.pitchIndex].pitch.location_coord_y = `${payload.xy[1]}`;
      return {
        ...state,
        pitches: R.clone(state.pitches),
      };
    }
    default:
      throw new Error('Invalid action type');
  }
};

function LocalProvider({ children }) {
  const [state, dispatch] = useReducer(localReducer, initStates);

  return (
    <LocalStateContext.Provider value={state}>
      <LocalDispatchContext.Provider value={dispatch}>{children}</LocalDispatchContext.Provider>
    </LocalStateContext.Provider>
  );
}

function useLocalState() {
  const context = useContext(LocalStateContext);

  if (context === undefined) {
    throw new Error('useLocal must be used within a LocalProvider');
  }
  return context;
}

function useLocalDispatch() {
  const context = useContext(LocalDispatchContext);

  if (context === undefined) {
    throw new Error('useLocalDispatch must be used within a LocalProvider');
  }
  return context;
}

export { LocalProvider, useLocalState, useLocalDispatch };
