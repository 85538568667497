import { useState } from 'react';
import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import { isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import Multilingual from '../Multilingual';

const MinWidth = 1024;

const StyledDiv = styled.div`
  z-index: 1004;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  .wording {
    z-index: 1002;
    background-color: ${COLOR.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fafafa;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  ${(props) => props.ignore && 'display: none;'}

  @media (min-width: ${MinWidth}px) {
    ${(props) => (props.isPortraitTable && !props.ignore ? '' : 'display: none;')}
  }
`;

const DeviceNotification = () => {
  const [ignore, setIgnore] = useState(false);
  const { isLandscape } = useMobileOrientation();

  const ignoreDevice = () => {
    setIgnore(true);
  };

  return (
    <StyledDiv ignore={ignore} isPortraitTable={isTablet && !isLandscape}>
      <div className="wording">
        {isMobileOnly
          ? Multilingual(`DEVICE_NOTIFICATION.NOT_SUPPORT_MOBILE`)
          : isTablet
          ? !isLandscape
            ? Multilingual(`DEVICE_NOTIFICATION.ROTATE_TABLET`)
            : Multilingual(`DEVICE_NOTIFICATION.NOT_SUPPORT_SIZE`)
          : Multilingual(`DEVICE_NOTIFICATION.NOT_SUPPORT_SIZE`)}
        <Button color="red" onClick={ignoreDevice}>
          {Multilingual(`DEVICE_NOTIFICATION.IGNORE`)}
        </Button>
      </div>
    </StyledDiv>
  );
};

export default DeviceNotification;
