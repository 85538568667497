import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatDistanceToNow, isValid as isValidDate, format as formatDate } from 'date-fns';
import twLocale from 'date-fns/locale/zh-TW';
import Modal from '~~elements/Modal';
import Loading from '~~elements/Loading';
import Form from '~~elements/Form';
import Button from '~~elements/Button';
import Input from '~~elements/Input';
import toast from '~~elements/Toast';
import { COLOR, FONT_COLOR } from '~~elements/Const';
import IconLinkSVG from '~~elements/static/IconLink.svg';
import { UserResource, StrikezoneGameResource } from '~~apis/resource';
import { useRootState } from '../../../useRoot';
import { parseUserInfo } from '../../../Const';
import Multilingual from '../../../utils/Multilingual';

const defaultWidth = 1080;
const actionWidth = 240;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 2rem;
  border-bottom: 1px solid gray;
  width: ${defaultWidth}px;
  max-width: 98%;
  min-height: 360px;

  .actions {
    width: ${actionWidth}px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    color: white;
    > span {
      text-align: start;
    }
    > span > * {
      margin: 0 0 0 0.2rem;
    }
  }
`;

const MyGamesDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(${defaultWidth - actionWidth}px - 1rem);
  max-width: 98%;
  height: 360px;
  background-color: #fafafa;
  border-radius: 2px;

  > label {
    font-size: 1rem;
    padding: 5px 16px 0;
    cursor: pointer;
    height: calc(2rem + 5px);
    &.non-click {
      cursor: default;
      color: gray;
    }

    span {
      padding: 0.1rem 0.2rem;
      border: 1px solid ${FONT_COLOR};
      border-radius: 4px;
      margin-left: 2px;
    }
  }

  > input {
    display: none;
  }

  .tabs {
    &--content {
      order: 1;
      width: 100%;
      height: calc(100% - 2rem - 5px - 2rem);
      border-top: 1px solid #dddddd;
      display: none;
      overflow-y: auto;
    }
    &--footer {
      width: 100%;
      height: 2rem;
      order: 2;
      text-align: end;
      padding: 0 0.5rem 0 0;
    }
  }

  > input:checked + label {
    font-weight: bold;
    color: ${COLOR.secondary};
    border-bottom: 2px solid ${COLOR.secondary};
  }

  > input:checked + label + .tabs--content {
    display: initial;
  }

  .game-info {
    width: 100%;
    padding: 0 0.5rem;
    margin: 0.3rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fafafa;
    text-align: start;
    .updated-at {
      color: gray;
      font-size: 0.8rem;
      margin-right: 0.5rem;
    }
    &--title {
      max-width: 60%;
      > img {
        width: 1.1rem;
        height: 1.1rem;
      }
    }
    &--comment {
      text-align: end;
    }
  }
`;

const SubmitSection = styled.section`
  width: 100%;
  text-align: end;
`;

const getInitFormData = () => ({
  title: '',
  isPremium: true,
  privacy: 'PUBLIC',
  reference: '',
  description: '',
  away: '',
  home: '',
  gameDate: '',
});

const MyStrikezoneGames = ({ setGameCompareOpen, setFreeTrialOpen }) => {
  const {
    loginUser: { isLogin },
  } = useRootState();
  const [accountEmailVerified, setAccountEmailVerified] = useState(-1);
  const [userInfo, setUserInfo] = useState(null);

  const [gameReloadCount, setGameReloadCount] = useState(1);
  const [userGames, setUserGames] = useState(null);

  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [deleteTargetUniqid, setDeleteTargetUniqid] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const [isCreateOpen, setCreateOpen] = useState(false);
  const [createFormData, setCreateFormData] = useState(getInitFormData());
  const [apiInProgress, setApiInProgress] = useState(false);

  useEffect(() => {
    if (!isLogin) {
      return;
    }

    const getBasicInfo = () => {
      UserResource.getBasicInfo()
        .then(({ data }) => {
          setUserInfo(parseUserInfo(data));
        })
        .catch((e) => {
          console.error(e);
          toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
        });
    };

    UserResource.getAccountInfo()
      .then(({ data }) => {
        const isEmailVerified = !!data.email_verified;
        setAccountEmailVerified(isEmailVerified);

        if (!isEmailVerified) {
          return;
        }
        getBasicInfo();
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      });
  }, [isLogin]);

  useEffect(() => {
    setCreateFormData(getInitFormData());
  }, [isCreateOpen]);

  useEffect(() => {
    setIsDeleteMode(false);
    setDeleteTargetUniqid('');
    setIsDeleting(false);
    setUserGames(null);

    UserResource.getGamesInfo()
      .then(({ data }) => {
        const publicGames = !data.publicGames ? [] : data.publicGames;
        const unlistedGames = !data.unlistedGames ? [] : data.unlistedGames;
        const ownedGameUniqids = !data.ownedGameUniqids ? [] : data.ownedGameUniqids;

        const orderByUpdatedAt = (a, b) => {
          if (!a.updatedAt || !isValidDate(new Date(a.updatedAt))) {
            return 1;
          }
          if (!b.updatedAt || !isValidDate(new Date(b.updatedAt))) {
            return -1;
          }

          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        };

        publicGames.forEach((g) => {
          g.isOwner = ownedGameUniqids.includes(g.uniqid);
        });
        publicGames.sort(orderByUpdatedAt);
        unlistedGames.forEach((g) => {
          g.isOwner = ownedGameUniqids.includes(g.uniqid);
        });
        unlistedGames.sort(orderByUpdatedAt);

        setUserGames({
          publicGames,
          unlistedGames,
        });
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      });
  }, [gameReloadCount]);

  const handleCreateModalOpen = () => {
    setCreateOpen(!isCreateOpen);
  };

  const handleInputOnChange = (e) => {
    if (e.target.type === 'checkbox') {
      setCreateFormData({
        ...createFormData,
        [e.target.name]: e.target.checked,
      });
      return;
    }

    setCreateFormData({
      ...createFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setApiInProgress(true);
    StrikezoneGameResource.createStrikezoneGame({ data: createFormData })
      .then(({ data }) => {
        console.log(data);
        toast(Multilingual(`FEED.START_RECORD_REDIRECT`), { status: 'success', second: 1 });
        setTimeout(() => {
          window.location.replace(`/${data.uniqid}/edit`);
        }, 500);
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      })
      .finally(() => {
        setApiInProgress(false);
      });
  };

  const handleDeleteGame = () => {
    if (!deleteTargetUniqid) {
      return;
    }

    setIsDeleting(true);
    StrikezoneGameResource.deleteStrikezoneGame({ strikezoneUniqid: deleteTargetUniqid })
      .then(() => {
        toast(Multilingual(`FEED.SUCCESSFULLY_DELETED`), { status: 'success', second: 2 });
        setGameReloadCount((pre) => pre + 1);
      })
      .catch(() => {
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 2 });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const listStrikezoneGames = (games, prepand = '') => {
    const actionButton = (g) => {
      if (isDeleteMode) {
        if (deleteTargetUniqid !== '') {
          return <span>-</span>;
        }

        if (!g.isOwner) {
          return <span>{Multilingual(`FEED.NOT_OWNER`)}</span>;
        }

        return (
          <Button
            nowrap
            width="unset"
            color="red"
            mini
            onClick={() => setDeleteTargetUniqid(g.uniqid)}
            disabled={isDeleting}
          >
            {Multilingual(`DELETE`)}
          </Button>
        );
      }

      return (
        <a href={`/${g.uniqid}/edit`} target="_blank" rel="noreferrer">
          <Button nowrap width="unset" color="red" mini disabled={!userInfo.inSubscription}>
            {Multilingual(`EDIT`)}
          </Button>
        </a>
      );
    };

    return games.map((g, i) => (
      <Fragment key={i}>
        <div className="game-info">
          <a href={`${prepand}/${g.uniqid}`}>
            <span className="game-info--title">
              <img src={IconLinkSVG} alt="link" />
              {g.title}
            </span>
          </a>
          <span className="game-info--comment">
            {g.updatedAt && isValidDate(new Date(g.updatedAt)) && (
              <i className="updated-at">
                {formatDistanceToNow(new Date(g.updatedAt), { addSuffix: true, locale: twLocale })}
              </i>
            )}
            {actionButton(g)}
          </span>
        </div>
        {g.uniqid === deleteTargetUniqid && (
          <div className="game-info">
            <span className="game-info--title">{`${Multilingual(`CONFIRM_DELETE`)} ${g.title} ？`}</span>
            <span>
              <i className="updated-at">
                {g.isPremium ? Multilingual(`FEED.PREMIUM_DELETE_WARNING`) : Multilingual(`FEED.FREE_DELETE_WARNING`)}
              </i>
              <Button mini color="red" width="unset" onClick={handleDeleteGame}>
                {Multilingual(`CONFIRM_DELETE`)}
              </Button>
              <Button mini color="blue" width="unset" onClick={() => setDeleteTargetUniqid('')}>
                {Multilingual(`CANCEL`)}
              </Button>
            </span>
          </div>
        )}
      </Fragment>
    ));
  };

  const switchDeleteMode = () => {
    setIsDeleting(false);
    setDeleteTargetUniqid('');
    setIsDeleteMode(!isDeleteMode);
  };

  return (
    <StyledDiv>
      {!isLogin && (
        <div className="actions">
          <a href={`${process.env.REACT_APP_ACCOUNT_URL}/login?done_url=${encodeURIComponent(window.location.href)}`}>
            <Button color="blue">{Multilingual(`FEED.LOGIN_FOR_COMPLETED_SERVICE`)}</Button>
          </a>
        </div>
      )}
      {accountEmailVerified === false && (
        <div className="actions">
          <a href={`${process.env.REACT_APP_ACCOUNT_URL}/settings/user_profile`}>
            <Button color="blue">{Multilingual(`FEED.EMAIL_NOT_VERIFIED_YET`)}</Button>
          </a>
        </div>
      )}
      {userInfo && accountEmailVerified === true && (
        <Fragment>
          <div className="actions">
            <Button
              width="unset"
              onClick={handleCreateModalOpen}
              disabled={!userInfo.inSubscription || (!userInfo.inUnlimited && userInfo.premiumQuota <= 0)}
            >
              {Multilingual(`FEED.START_A_GAME`)}
            </Button>
            <a href="/analytics">
              <Button width="unset" nowrap disabled={!userInfo.inSubscription}>
                {Multilingual(`PLAYER_ANALYTICS.WORDING`)}
              </Button>
            </a>
            <span>
              {Multilingual(`FEED.PLAN_PERIOD`)}：
              {!userInfo.inSubscription ? '-' : `~${formatDate(userInfo.subscriptionPeriod, 'yyyy/MM/dd')}`}
            </span>
            <span>
              {Multilingual(`FEED.PLAN_QUOTA_LEFT`)}：{userInfo.inUnlimited ? <>&infin;</> : userInfo.premiumQuota}{' '}
              {Multilingual(`FEED.PLAN_QUOTA_LEFT_COUNT`)}
            </span>
            <span>
              {/* <a href={`${process.env.REACT_APP_ACCOUNT_URL}/shop/plugin`}>
                <Button width="unset" nowrap mini>
                  {Multilingual(`PLAN_COMPARE.BUY`)}
                </Button>
              </a>
              <Button width="unset" color="whiteWithBorder" nowrap mini onClick={() => setGameCompareOpen(true)}>
                {Multilingual(`PLAN_COMPARE.WORDING`)}
              </Button> */}
              <Button width="unset" color="whiteWithBorder" nowrap mini onClick={() => setFreeTrialOpen(true)}>
                {Multilingual(`PLAN_COMPARE.FREE_TRIAL`)}
              </Button>
            </span>
          </div>
          {/* <Button width="unset" color="whiteWithBorder" onClick={handleListModalOpen}>
            我紀錄的好球帶<span>{userInfo.editableAmount}</span>
          </Button> */}
          <MyGamesDiv>
            <label className="non-click">{Multilingual(`MY.WORDING`)}</label>
            <input type="radio" name="my-games-tabs" id="my-games-tab-unlisted" defaultChecked />
            <label htmlFor="my-games-tab-unlisted">
              {Multilingual(`MY.UNLISTED`)}
              {userGames && <span>{userGames.unlistedGames.length}</span>}
            </label>
            <div className="tabs--content">
              {!userGames ? (
                <Loading width="5rem" margin=" 0.5rem auto" />
              ) : userGames.unlistedGames.length === 0 ? (
                <div className="game-info">
                  <span>{Multilingual(`NONE`)}</span>
                  <span />
                </div>
              ) : (
                listStrikezoneGames(userGames.unlistedGames, '/unlisted')
              )}
            </div>
            <input type="radio" name="my-games-tabs" id="my-games-tab-public" />
            <label htmlFor="my-games-tab-public">
              {Multilingual(`MY.PUBLIC`)}
              {userGames && <span>{userGames.publicGames.length}</span>}
            </label>
            <div className="tabs--content">
              {!userGames ? (
                <Loading width="5rem" margin=" 0.5rem auto" />
              ) : userGames.publicGames.length === 0 ? (
                <div className="game-info">
                  <span>{Multilingual(`NONE`)}</span>
                  <span />
                </div>
              ) : (
                listStrikezoneGames(userGames.publicGames)
              )}
            </div>
            <div className="tabs--footer">
              <Button width="unset" color="blue" mini onClick={switchDeleteMode}>
                {isDeleteMode ? Multilingual(`BACK`) : Multilingual(`MORE_ACTION`)}
              </Button>
            </div>
          </MyGamesDiv>
        </Fragment>
      )}
      <Modal.Modal size="sm" isOpen={isCreateOpen} outterClickBack={true} onClickBack={handleCreateModalOpen}>
        <Modal.ModalHeader hasBack onClickBack={handleCreateModalOpen}>
          {Multilingual(`FEED.START_A_GAME`)}
        </Modal.ModalHeader>
        <Modal.ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <div className="box">
              <label>
                {Multilingual(`GAME.TITLE`)}
                <i>*</i>
              </label>
              <div className="input-field">
                <div className="input-box">
                  <Input
                    width="100%"
                    placeholder={Multilingual(`GAME.TITLE`)}
                    name="title"
                    value={createFormData.title}
                    onChange={handleInputOnChange}
                    textAlign="left"
                    disabled={apiInProgress}
                  />
                </div>
              </div>
              <label>
                {Multilingual(`GAME.COMBINATION`)}
                <i>*</i>
              </label>
              <div className="input-field --multi-input">
                <div className="input-box">
                  <Input
                    width="100%"
                    placeholder={Multilingual(`GAME.AWAY`)}
                    name="away"
                    onChange={handleInputOnChange}
                    value={createFormData.away}
                    disabled={apiInProgress}
                  />
                  <Input
                    width="100%"
                    placeholder={Multilingual(`GAME.HOME`)}
                    name="home"
                    onChange={handleInputOnChange}
                    value={createFormData.home}
                    disabled={apiInProgress}
                  />
                </div>
              </div>
              <label>{Multilingual(`GAME.DATE`)}</label>
              <div className="input-field">
                <div className="input-box">
                  <Input
                    width="100%"
                    type="date"
                    name="gameDate"
                    onChange={handleInputOnChange}
                    value={createFormData.gameDate}
                    disabled={apiInProgress}
                  />
                </div>
              </div>
              <label>{Multilingual(`GAME.REF`)}</label>
              <div className="input-field">
                <div className="input-box">
                  <Input
                    width="100%"
                    placeholder={`${Multilingual(`GAME.REF`)} ex: youtube...`}
                    name="reference"
                    value={createFormData.reference}
                    onChange={handleInputOnChange}
                    textAlign="left"
                    disabled={apiInProgress}
                  />
                </div>
              </div>
              {/* <label>
                <input
                  type="checkbox"
                  name="isPremium"
                  checked={createFormData.isPremium}
                  disabled={apiInProgress || !userInfo || userInfo.premiumQuota <= 0}
                  onChange={handleInputOnChange}
                />
                使用進階方案（預設非公開）
              </label>
              <div className="input-field" /> */}
            </div>
          </Form>
          <SubmitSection>
            <Button
              width="unset"
              disabled={
                apiInProgress ||
                !createFormData.title.trim() ||
                !createFormData.away.trim() ||
                !createFormData.home.trim()
              }
              onClick={handleSubmit}
            >
              {Multilingual(`START`)}
            </Button>
          </SubmitSection>
        </Modal.ModalBody>
      </Modal.Modal>
    </StyledDiv>
  );
};

export default MyStrikezoneGames;
