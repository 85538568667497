import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PHONE_WIDTH } from '../Const';

const mappingWidth = (widthType) => {
  let width;
  switch (widthType) {
    case 'full':
      width = '100%';
      break;
    case 'lg':
      width = '67.5rem';
      break;
    case 'md':
      width = '56.25rem';
      break;
    case 'sm':
      width = '38.125rem';
      break;
    default:
      width = '38.125rem';
      break;
  }
  return width;
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: ${(props) => (!props.focusEnhance ? 'transparent' : '#00000095')};

  .modal-dialog {
    max-width: ${({ size }) => mappingWidth(size)};
    min-width: ${(props) => props.width};
    margin: 5rem auto 2rem;
    height: calc(100vh - 7rem);
    transition: margin 300ms;
  }
  .modal-content {
    max-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: ${({ bgColor }) => (!bgColor ? '#fff' : bgColor)};
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }

  /* Header Style */
  .modal-header {
    border-bottom: 1px solid #f4f6fa;
    padding: 1rem 3rem;
    letter-spacing: 3px;
    font-size: 0.8rem;
    ${({ headerColor }) =>
      !headerColor
        ? ''
        : `
      color: ${headerColor};
      .modal-title {
        color: ${headerColor};
      }
    `}
  }

  /* Body Style */
  .modal-body {
    overflow: auto;
    ${(props) => (props.noPadding ? '' : `padding: ${props.moreSpace ? '5rem 2.5rem' : '1.25rem 2.5rem 2.5rem'};`)}
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease all;
  }

  /* Footer Style */
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f4f6fa;
    padding: 1.25rem 2rem;
  }
`;

ModalWrapper.propTypes = {
  // widthType: PropTypes.oneOf(['large', 'middle', 'small']),
  isOpen: PropTypes.bool,
  moreSpace: PropTypes.bool,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
};

ModalWrapper.defaultProps = {
  /** 寬度 */
  // width: '40rem',
  // widthType: 'small',
  /** 是否需要大的padding */
  isOpen: false,
  moreSpace: false,
  noPadding: false,
  className: '',
};

const Modal = ({ className = '', outterClickBack = true, onClickBack = () => {}, ...restProps }) => {
  const dialogRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      const navWrapper = document.querySelector('#navbar-wrapper');
      const nav = document.querySelector('#navbar');
      if ((!navWrapper && !nav) || !dialogRef?.current) {
        return;
      }

      const navHeight = (navWrapper || nav).getBoundingClientRect().height;
      const marginHeight = Math.min(navHeight + 5, 100);
      dialogRef.current.style.margin = `${marginHeight}px auto 2rem`;
      dialogRef.current.style.height = `calc(100% - ${marginHeight}px - 2rem)`;
    }, 100);
  }, []);

  const handlerWrapperClick = (e) => {
    if (!outterClickBack) {
      return;
    }

    if (!e.target.classList.contains('modal-wrapper') && !e.target.classList.contains('modal-dialog')) {
      return;
    }

    onClickBack();
  };
  return (
    <ModalWrapper className={`modal-wrapper ${className}`} onClick={handlerWrapperClick} {...restProps}>
      <div ref={dialogRef} className="modal-dialog">
        <div className="modal-content">{restProps.children}</div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
