import ApiService from '~~commonAPIs/ApiService';

const User = {
  getAccountInfo: () => {
    return ApiService.get(`${process.env.REACT_APP_ACCOUNT_BACKEND_URL}/api/user/info`);
  },

  getEditorInfo: (uniqid) => {
    return ApiService.get(`${process.env.REACT_APP_ACCOUNT_BACKEND_URL}/api/users/${uniqid}`);
  },

  checkUserExist: (value) => {
    return ApiService.get(
      `${process.env.REACT_APP_ACCOUNT_BACKEND_URL}/api/user/exists` +
        `?email=${encodeURIComponent(value)}&user_name=${encodeURIComponent(value)}`,
    );
  },

  getBasicInfo: () => {
    return ApiService.get(`/api/userInfo/basic`);
  },

  getGamesInfo: () => {
    return ApiService.get(`/api/userInfo/games`);
  },

  getGameFastpass: ({ strikezoneUniqid }) => {
    return ApiService.get(`/api/userInfo/games/${strikezoneUniqid}/fastpass`);
  },

  addGameComment: ({ strikezoneUniqid, data }) => {
    return ApiService.post(`/api/games/${strikezoneUniqid}/comments`, { data });
  },

  startFreeTrial: () => {
    return ApiService.post(`/api/free_trial`);
  },

  permissionList: () => {
    return ApiService.get(`/api/user/permission`);
  },
};

export default User;
