import React from 'react';
import styled from 'styled-components';
import GGFace from './ggface.svg';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UnknownError = (props) => {
  return (
    <StyledDiv className="unknown-error" {...props}>
      <img src={GGFace} alt="oops" style={{ width: props.imgWidth, height: props.imgWidth }} />
      <span>Oops ='(</span>
    </StyledDiv>
  );
};

export default UnknownError;
