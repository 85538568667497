import { omit, flatten } from 'ramda';
import { strikeZoneConfig } from '../constants/base';

const { width, height } = strikeZoneConfig;

const strikeZones = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

const defaultHeartZoneMap = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  outside: [],
};

const defaultShadowZoneMap = {
  inside: [],
  outside: [],
};

const defaultShadowsZoneMap = {
  1: [],
  2: [],
  3: [],
  4: [],
};

const defaultChaseZoneMap = {
  inside: [],
  outside: [],
};

const defaultChasesZoneMap = {
  1: [],
  2: [],
  3: [],
  4: [],
};

const getHeartZone = (x, y) => {
  if (x >= width / 4 && x <= width / 4 + width / 6 && y >= height / 4 && y <= height / 4 + height / 6) return 1;
  if (x >= width / 4 + width / 6 && x <= width / 4 + (2 * width) / 6 && y >= height / 4 && y <= height / 4 + height / 6)
    return 2;
  if (
    x >= width / 4 + (2 * width) / 6 &&
    x <= width / 4 + (3 * width) / 6 &&
    y >= height / 4 &&
    y <= height / 4 + height / 6
  )
    return 3;
  if (
    x >= width / 4 &&
    x <= width / 4 + width / 6 &&
    y >= height / 4 + height / 6 &&
    y <= height / 4 + (2 * height) / 6
  )
    return 4;
  if (
    x >= width / 4 + width / 6 &&
    x <= width / 4 + (2 * width) / 6 &&
    y >= height / 4 + height / 6 &&
    y <= height / 4 + (2 * height) / 6
  )
    return 5;
  if (
    x >= width / 4 + (2 * width) / 6 &&
    x <= width / 4 + (3 * width) / 6 &&
    y >= height / 4 + height / 6 &&
    y <= height / 4 + (2 * height) / 6
  )
    return 6;
  if (
    x >= width / 4 &&
    x <= width / 4 + width / 6 &&
    y >= height / 4 + (2 * height) / 6 &&
    y <= height / 4 + (3 * height) / 6
  )
    return 7;
  if (
    x >= width / 4 + width / 6 &&
    x <= width / 4 + (2 * width) / 6 &&
    y >= height / 4 + (2 * height) / 6 &&
    y <= height / 4 + (3 * height) / 6
  )
    return 8;
  if (
    x >= width / 4 + (2 * width) / 6 &&
    x <= width / 4 + (3 * width) / 6 &&
    y >= height / 4 + (2 * height) / 6 &&
    y <= height / 4 + (3 * height) / 6
  )
    return 9;
  return 'outside';
};

const getShadowZone = (x, y) => {
  if (x >= width / 6 && x <= (5 * width) / 6 && y >= height / 6 && y <= (5 * height) / 6) return 'inside';

  return 'outside';
};

// 用 shadow inside
const getShadowsZone = (x, y) => {
  if (x <= width / 2) {
    if (y <= height / 2) {
      return 1;
    }
    return 3;
  }
  if (y <= height / 2) {
    return 2;
  }
  return 4;
};

const getChaseZone = (x, y) => {
  if (x >= 0 && x <= width && y >= 0 && y <= height) return 'inside';

  return 'outside';
};

// 用 chase inside
const getChasesZone = (x, y) => {
  if (x <= width / 2) {
    if (y <= height / 2) {
      return 1;
    }
    return 3;
  }
  if (y <= height / 2) {
    return 2;
  }
  return 4;
};

const getEdge = (x, y) => {
  if (x >= width / 6 && x <= (5 * width) / 6 && y >= height / 6 && y <= (2 * height) / 6) return 'inside';
  if (x >= width / 6 && x <= (2 * width) / 6 && y >= (2 * height) / 6 && y <= (4 * height) / 6) return 'inside';
  if (x >= (4 * width) / 6 && x <= (5 * width) / 6 && y >= (2 * height) / 6 && y <= (4 * height) / 6) return 'inside';
  if (x >= width / 6 && x <= (5 * width) / 6 && y >= (4 * height) / 6 && y <= (5 * height) / 6) return 'inside';
  return 'outside';
};

const groupByZone = (perBall, defaultZoneMap, getZone) => {
  return perBall.reduce((acc, cur) => {
    if (!cur.eZoneResult && !cur.eyeZoneResult) {
      return { ...acc };
    }
    const x = cur.eZoneResult ? cur.eZoneResult.x : cur.eyeZoneResult.x;
    const y = cur.eZoneResult ? cur.eZoneResult.y : cur.eyeZoneResult.y;

    const zone = getZone(x, y);

    return {
      ...acc,
      [zone]: [...acc[zone], { ...cur, x: x, y: y }],
    };
  }, defaultZoneMap);
};

export const getZonemap = (resolvedPerBall) => {
  const heart = groupByZone(resolvedPerBall, defaultHeartZoneMap, getHeartZone);
  const shadow = groupByZone(heart.outside, defaultShadowZoneMap, getShadowZone);
  const shadows = groupByZone(shadow.inside, defaultShadowsZoneMap, getShadowsZone);
  const chase = groupByZone(shadow.outside, defaultChaseZoneMap, getChaseZone);
  const chases = groupByZone(chase.inside, defaultChasesZoneMap, getChasesZone);

  return {
    heart: omit(['outside'], heart),
    shadow: omit(['outside'], shadow),
    shadows: omit(['outside'], shadows),
    chase: omit(['outside'], chase),
    chases: omit(['outside'], chases),
    waste: chase.outside,
  };
};

export const getZoneStat = (resolvedPerBall, zoneMap) => {
  const total = resolvedPerBall.length;
  const heart = flatten(strikeZones.filter((zone) => zone !== '0').map((zone) => [...zoneMap[zone]]));
  const chase = flatten(['chase'].filter((zone) => zone !== '0').map((zone) => [...zoneMap[zone]]));
  const zoneSwing = heart.filter(({ batterAction }) => !!batterAction && batterAction !== 'NONE');
  const zoneSwmiss = heart.filter(({ batterAction }) => batterAction === 'SWMISS');
  const zoneWhiff = zoneSwing.length > 0 ? ((zoneSwmiss.length / zoneSwing.length) * 100).toFixed(1) : 0;
  const zoneContact = 100 - parseFloat(zoneWhiff, 10);
  const chaseSwing = chase.filter(({ batterAction }) => !!batterAction && batterAction !== 'NONE');
  const chaseSwmiss = chase.filter(({ batterAction }) => batterAction === 'SWMISS');
  const chaseWhiff = chaseSwing.length > 0 ? ((chaseSwmiss.length / chaseSwing.length) * 100).toFixed(1) : 0;
  const chaseContact = 100 - parseFloat(chaseWhiff, 10);
  const swing = resolvedPerBall.filter(({ batterAction }) => !!batterAction && batterAction !== 'NONE');
  const swmiss = resolvedPerBall.filter(({ batterAction }) => batterAction === 'SWMISS');
  const whiff = swing.length > 0 ? ((swmiss.length / swing.length) * 100).toFixed(1) : 0;
  const meatball = flatten(['5'].filter((zone) => zone !== '0').map((zone) => [...zoneMap[zone]]));
  const meatballSwing = meatball.filter(({ batterAction }) => !!batterAction && batterAction !== 'NONE');
  const edge = groupByZone(resolvedPerBall, { inside: [], outside: [] }, getEdge).inside;
  const firstBall = resolvedPerBall.filter(({ ballCountB4Pitch }) => ballCountB4Pitch === 0);
  const firstSwing = firstBall.filter(({ batterAction }) => !!batterAction && batterAction !== 'NONE');
  const firstStrike = firstBall.filter(({ judgeCall }) => judgeCall === 'S');

  return {
    zone: total > 0 ? ((heart.length / total) * 100).toFixed(1) : 0,
    zoneSwing: heart.length > 0 ? ((zoneSwing.length / heart.length) * 100).toFixed(1) : 0,
    zoneContact: zoneSwing.length > 0 ? zoneContact : 0,
    chase: total > 0 ? ((chase.length / total) * 100).toFixed(1) : 0,
    chaseContact: chaseSwing.length > 0 ? chaseContact.toFixed(1) : 0,
    edge: total > 0 ? ((edge.length / total) * 100).toFixed(1) : 0,
    firstSwing: firstBall.length > 0 ? ((firstSwing.length / firstBall.length) * 100).toFixed(1) : 0,
    firstStrike: firstBall.length > 0 ? ((firstStrike.length / firstBall.length) * 100).toFixed(1) : 0,
    swing: total > 0 ? ((swing.length / total) * 100).toFixed(1) : 0,
    whiff,
    meatball: total > 0 ? ((meatball.length / total) * 100).toFixed(1) : 0,
    meatballSwing: meatball.length > 0 ? ((meatballSwing.length / meatball.length) * 100).toFixed(1) : 0,
  };
};
