import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  .content {
    margin: 1rem 0;
    padding: 1rem;
    width: 780px;
    max-width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${COLOR.secondary};
    color: white;
    border-radius: 0 10px 0 10px;
  }
  .hello {
    font-size: 1rem;
    display: block;
  }
  .wording {
    font-size: 0.9rem;
    white-space: pre-line;
    padding: 0 0.3rem;
  }
  .donate-btn {
    button {
      height: 5rem;
    }
  }
`;

const Index = () => {
  return (
    <StyledDiv>
      <div className="content">
        <div className="wording">
          <span className="hello">Hi, 喜愛進階數據的你/妳</span>
          感謝您的到來，每一位用戶進來逛逛， 大家一起討論正是 Rebas 野球革命想做到的！
          我們致力於為台灣棒球迷/從業者們提供多樣化的棒球數據與資訊。
          <br />
          野球革命顧名思義，想要不斷地帶給大家全新的棒球體驗。 如果您喜歡也認同這個想法，歡迎給我們一些鼓勵，
          我們將有更大的能量帶來更酷更有趣的服務！
        </div>
        <a className="donate-btn" href={process.env.REACT_APP_ECPAY_DONATE_URL} target="_blank" rel="noreferrer">
          <Button width="unset" nowrap>
            支持野革
          </Button>
        </a>
      </div>
    </StyledDiv>
  );
};

export default Index;
