import React from 'react';
import styled from 'styled-components';
import Icon from '../static/IconTooltip.svg';
import IconImportant from '../static/IconTooltipImportant.svg';

const StyledDiv = styled.div`
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  background-image: url(${(props) => (props.important ? IconImportant : Icon)});
  background-position: center;
  background-size: cover;
  width: ${({ width }) => (width ? width : '1.2rem')};
  height: ${({ height }) => (height ? height : '1.2rem')};

  &.custom-display {
    background-image: unset;
  }

  .tooltiptext {
    min-width: 10rem;
    max-width: 15rem;
    padding: 0.2rem;
    opacity: 0;
    font-size: 0.6rem;
    border-radius: 3px;
    text-align: center;
    transition: ease-in-out 0.2s;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    ${(props) => (props.pushLeft ? 'right' : 'left')}: -20px;
    bottom: 2rem;
    background-color: #000;
    color: #fff;
    white-space: normal;

    &::before {
      content: ''; /*讓before顯示出來*/
      position: absolute;
      bottom: -4px; /*調整位置*/
      ${(props) => (props.pushLeft ? 'right' : 'left')}: 15px; /*調整位置*/
      padding: 8px; /*做出對話框的角*/
      background-color: inherit;
      border: inherit; /*讓before繼承talkingFrame的CSS屬性*/
      border-right: 0; /*隱藏內邊的border*/
      border-bottom: 0; /*隱藏內邊的border*/
      transform: rotate(45deg); /*旋軟角度*/
      z-index: -1;
    }
  }

  &:hover .tooltiptext {
    opacity: 1;
  }
`;

const Tooltip = ({ className = '', display, pushLeft, content, ...restProps }) => {
  return (
    <StyledDiv {...restProps} className={`${className} ${display ? 'custom-display' : ''}`} pushLeft={!!pushLeft}>
      {display}
      <span className="tooltiptext">{content}</span>
    </StyledDiv>
  );
};

export default Tooltip;
