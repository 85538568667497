import Cookies from 'js-cookie';
import ApiService from '~~commonAPIs/ApiService';

const StrikezoneGameEditor = {
  addEditor: ({ strikezoneUniqid, data }) => {
    return ApiService.post(`/api/games/${strikezoneUniqid}/editors`, {
      data,
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },

  removeEditor: ({ strikezoneUniqid, editorUniqid }) => {
    return ApiService.delete(`/api/games/${strikezoneUniqid}/editors/${editorUniqid}`, {
      withToken: false,
      headers: {
        Authorization: `Bearer ${Cookies.get(`RB_${strikezoneUniqid}_FASTPASS`)}`,
      },
    });
  },
};

export default StrikezoneGameEditor;
