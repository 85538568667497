import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR, FONT_COLOR } from '../Const';

const GRADIENT = 'linear-gradient(135deg, rgb(195, 12, 12, 1), rgb(10, 74, 154, 1))';
const TRANSPARENT = 'transparent';
const BLACK = '#0f0f0f';
const NEARLY_WHITE = '#EEEEEE';
const WHITE = '#FFFFFF';
const BLUE = 'rgb(10, 74, 154, 1)';
const RED = 'rgb(142, 24, 24, 1)';

const colorMapper = {
  gradient: {
    font: WHITE,
    background: GRADIENT,
  },
  blue: {
    font: WHITE,
    background: BLUE,
    border: BLUE,
    hoverBorder: BLUE,
  },
  red: {
    font: WHITE,
    border: RED,
    hoverBorder: RED,
    background: RED,
  },
  transparent: {
    font: BLACK,
    background: TRANSPARENT,
    // hoverBackground: '#D9D9D9',
  },
  transparentWithBorder: {
    font: BLACK,
    border: FONT_COLOR,
    hoverBorder: FONT_COLOR,
    background: TRANSPARENT,
  },
  white: {
    font: WHITE,
    background: TRANSPARENT,
    hoverBorder: WHITE,
  },
  whiteWithBorder: {
    font: WHITE,
    border: NEARLY_WHITE,
    background: TRANSPARENT,
    hoverBorder: WHITE,
  },
  primary: {
    font: WHITE,
    border: COLOR.primary,
    hoverBorder: COLOR.primary,
    background: COLOR.primary,
  },
  secondary: {
    font: WHITE,
    border: COLOR.secondary,
    hoverBorder: COLOR.secondary,
    background: COLOR.secondary,
  },
};

function getColoring(color) {
  return colorMapper[color] || colorMapper.gradient;
}

const StyledBtn = styled.button`
  &.button {
    font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: 2px;
    transition: 0.3s ease all;
    padding: 0.6rem 0.6rem;
    /* min-height: 2rem; */
    border-radius: 4px;
    width: ${(props) => props.width || '100%'};
    color: ${(props) => props.coloring.font};
    background: ${(props) => props.coloring.background};
    border: ${(props) => (props.border || props.coloring.border ? `1px solid ${props.coloring.border}` : 'none')};
    box-shadow: ${(props) => (props.boxShadow ? '0 6px 8px 0 rgba(0, 0, 0, 0.3)' : 'none')};
    white-space: pre-line;
    line-height: 1.5;
    text-align: center;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.coloring.hoverFont || props.coloring.font};
      ${({ coloring }) => coloring.hoverBackground && `background: ${coloring.hoverBackground};`}
      border: ${(props) => (props.coloring.hoverBorder ? `1px solid ${props.coloring.hoverBorder}` : 'none')};
      box-shadow: ${(props) => (props.boxShadow ? '0 4px 4px 0 rgba(0, 0, 0, 0.3)' : 'none')};
      transition: 0.4s ease all;
    }

    &:focus,
    &.focus {
      box-shadow: none;
      outline: unset;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        color: ${(props) => props.coloring.font};
        background: ${(props) => props.coloring.background};
      }
    }

    .icon,
    img {
      margin: 0 0.25rem;
      /* margin-right: 0.25rem; */
    }
  }

  &.button-round {
    font-size: 0.8rem;
    min-height: 2rem;
    line-height: 1.5;
    border-radius: 180px;
    /* width: auto; */
  }

  &.button-mini {
    font-size: 0.7rem;
    line-height: 0.5rem;
    padding: 0 0.4rem;
    min-height: 1.5rem;
  }

  &.button-inverse {
    color: ${(props) => props.coloring.background};
    background: ${(props) => props.coloring.font};

    &:hover {
      cursor: pointer;
      color: ${(props) => props.coloring.hoverFont || props.coloring.font};
      background: ${(props) => props.coloring.background};
      transition: 0.3s ease all;
    }

    &:disabled:hover {
      color: ${(props) => props.coloring.background};
      background: ${(props) => props.coloring.font};
    }
  }

  &.nowrap {
    white-space: nowrap;
  }
  &.pre {
    white-space: pre;
    line-height: 0.9rem;
  }
`;

const Button = (props) => {
  const { color, type, ...restProps } = props;
  return (
    <StyledBtn {...restProps} coloring={getColoring(color)} className={getClassName()} type={!type ? 'button' : type}>
      {props.children}
    </StyledBtn>
  );

  function getClassName() {
    const { round, mini, className, inverse, nowrap, pre } = props;
    let result = 'button';

    if (round) {
      result += ' button-round';
    }
    if (mini) {
      result += ' button-mini';
    }

    if (inverse) {
      result += ' button-inverse';
    }

    if (nowrap) {
      result += ' nowrap';
    }
    if (pre) {
      result += ' pre';
    }

    if (className) {
      result += ` ${className}`;
    }
    return result;
  }
};

Button.propTypes = {
  /* 設定顏色的集合 */
  // color: PropTypes.oneOf(['white', 'black', 'transparent', 'transparentInModal', 'green', 'auto']),
  /* 是否顯示邊框 */
  border: PropTypes.bool,
  /* 寬度設定 */
  width: PropTypes.string,
  /* 是否取消上下邊界高度設定以及縮小字體 */
  mini: PropTypes.bool,
  /* 是否使按鈕失效 */
  disabled: PropTypes.bool,
  /* 反轉文字與背景顏色 */
  inverse: PropTypes.bool,
  /* 是否變成圓角的按鈕 */
  round: PropTypes.bool,
  /* 是否需要陰影 */
  boxShadow: PropTypes.bool,
};

Button.defaultProps = {
  color: 'gradient',
  border: false,
  mini: false,
  disabled: false,
  inverse: false,
  round: false,
  width: '100%',
  boxShadow: false,
};

export default Button;
