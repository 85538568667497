import { PITCH_TYPE } from '~~commonStats/pitch';
import { ACTIONS } from '../useLocal';

export const KEY_PITCH_MAPPING = {
  [PITCH_TYPE.FF]: {
    display: 'Q',
    value: 81,
  },
  [PITCH_TYPE.SI]: {
    display: 'W',
    value: 87,
  },
  [PITCH_TYPE.FC]: {
    display: 'E',
    value: 69,
  },
  [PITCH_TYPE.SL]: {
    display: 'A',
    value: 65,
  },
  [PITCH_TYPE.CU]: {
    display: 'S',
    value: 83,
  },
  [PITCH_TYPE.CH]: {
    display: 'D',
    value: 68,
  },
  [PITCH_TYPE.FO]: {
    display: 'F',
    value: 70,
  },
};

export const handleKeydown = (dispatch, keyCode) => {
  let pitchType = '';
  for (let pType of Object.keys(KEY_PITCH_MAPPING)) {
    if (keyCode !== KEY_PITCH_MAPPING[pType].value) {
      continue;
    }
    pitchType = pType;
    break;
  }

  if (!pitchType) {
    return;
  }
  dispatch({ type: ACTIONS.UPDATE_PITCH_COLUMN, payload: { column: 'type', value: pitchType } });
};
