import { sum } from 'ramda';
import * as R from 'ramda';
import { ballDetailTypes } from '~~features/StrikezoneGameEdit/components/ResultPanel/ResultPanel';

export const strikeZoneConfig = {
  width: 225,
  height: 270,
};

// 本壘板寬大約是六顆球的寬
export const ballRadius = strikeZoneConfig.width / 2 / 6 / 2 - 1.5;

export const BallTypeWording = {
  F: '直球',
  C: '變速',
  B: '變化',
  NONE: '未分類',
  all: '總計',
};
export const BallTypeENWording = {
  F: 'Fast ball',
  C: 'Offspeed ball',
  B: 'Breaking ball',
  NONE: 'NONE',
  all: 'Total',
};

export const getBallTypeStyle = (ballType) => {
  const style = {};
  switch (ballType) {
    case 'F':
      style.color = 'red';
      return style;
    case 'C':
      style.color = 'green';
      return style;
    case 'B':
      style.color = 'orange';
      return style;
    case 'all':
      return style;
    default:
      style.color = 'blue';
      return style;
  }
};

export const getBallInitState = () => ({
  inning: '',
  currentSide: 'away',
  pitcher: '',
  pHand: '',
  batter: '',
  bHand: '',
  bases: '',
  outs: '',
  speed: '',
  ballType: '',
  ballDetailType: '',
  judgeCall: '',
  eZoneResult: { x: '', y: '', width: 0, height: 0 },
  eyeZoneResult: { x: '', y: '', width: 0, height: 0 },
  batterAction: '',
  batterResult: '',
  resultFielder: '',
  ballTrajectory: '',
  ballContact: '',
  ballDroppoint: '',
  ballDroppointXY: null, // [x, y]
  battedBallLocation: '',

  // 後天補上
  // ballCountB4Pitch: 0,
  // strikeB4Pitch: 0,
  // ballB4Pitch: 0,
});

export const parseAPIGame = (game) => {
  const defaultState = {
    title: '',
    isPremium: false,
    privacy: 'PUBLIC',
    reference: '',
    description: '',
    away: '',
    home: '',
    cameraAngle: 'PITCHER',
    speedUnit: 'KM',
    isLive: false,
    perBall: [getBallInitState()],
    awayPlayers: [],
    homePlayers: [],
    comments: [],
    ownerUniqid: '',
    ownerUserName: '',
    editorUniqids: [],
    gameDate: '',
    createdAt: null,
    updatedAt: null,
  };

  // console.log(game);
  const gameState = {
    ...defaultState,
    ...game,
  };
  // 避免空字串
  ['cameraAngle', 'speedUnit'].forEach((column) => {
    if (!!gameState[column]) {
      return;
    }

    gameState[column] = defaultState[column];
  });
  // console.log(gameState);

  // 好球帶的長寬各兩倍就是有效範圍
  const perPercentageWidth = strikeZoneConfig.width / 2 / 100;
  const perPercentageHeight = strikeZoneConfig.height / 2 / 100;

  // API 的 X 跟 Y 是好球帶 0 - 100 的百分比
  const getPixel = (zoneResult) => {
    let x = parseFloat(zoneResult.x);
    let y = parseFloat(zoneResult.y);

    // 如果原本記錄視角是投手就要先以好球帶正中間為基準反轉一次
    if (gameState.cameraAngle === 'PITCHER') {
      x = (x - 50) * -1 + 50;
    }

    // 把座標 0, 0 從好球帶左上移動到有效範圍左上
    x += 50;
    y += 50;

    // 把 % 換成 pixel
    return {
      x: x * perPercentageWidth,
      y: y * perPercentageHeight,
    };
  };

  const perBall = R.filter(
    (ball) => ball !== null && ball !== undefined && ball !== false,
    !Array.isArray(game.perBall) ? [getBallInitState()] : game.perBall,
  );
  const resolvedPerBall = [];
  const eyeDelta = [];
  let onlyEyeCount = 0;
  let ballCountB4Pitch = 0;
  let strikeB4Pitch = 0;
  let ballB4Pitch = 0;
  let preBallBatterResult = '';

  perBall.slice(0, -1).forEach((ball, index) => {
    if (index === 0) {
      preBallBatterResult = 'INIT';
    }

    const ballState = {
      ...getBallInitState(),
      ...ball,
    };

    if (!!preBallBatterResult && preBallBatterResult !== 'NONE') {
      ballCountB4Pitch = 0;
      strikeB4Pitch = 0;
      ballB4Pitch = 0;
    }
    ballState.ballCountB4Pitch = ballCountB4Pitch;
    ballState.strikeB4Pitch = strikeB4Pitch;
    ballState.ballB4Pitch = ballB4Pitch;

    ballCountB4Pitch++;
    if (ballState.judgeCall === 'S' && strikeB4Pitch < 2) {
      strikeB4Pitch++;
    }
    if (ballState.judgeCall === 'B' && ballB4Pitch < 3) {
      ballB4Pitch++;
    }

    ['eZoneResult', 'eyeZoneResult'].forEach((key) => {
      if (ballState[key].width === 0) {
        delete ballState[key];
        return;
      }

      ballState[key] = {
        ...ballState[key],
        ...getPixel(ballState[key]),
      };
    });

    // 抓平均偏移值，校正人眼進壘
    if (ballState.eZoneResult && ballState.eyeZoneResult) {
      eyeDelta.push({
        x: ballState.eZoneResult.x - ballState.eyeZoneResult.x,
        y: ballState.eZoneResult.y - ballState.eyeZoneResult.y,
      });
    } else if (!ballState.eZoneResult && ballState.eyeZoneResult) {
      onlyEyeCount++;
    }

    // 有球速的全部轉換為 KM
    if (gameState.speedUnit === 'MI') {
      const speed = parseInt(ballState.speed);
      if (ballState.speed !== '' && !Number.isNaN(speed)) {
        ballState.speed = (speed * 1.609344).toFixed(0);
      }
    }

    if (!ballDetailTypes.includes(ballState.ballDetailType)) {
      ballState.ballDetailType = '';
    }

    resolvedPerBall.push(ballState);
    preBallBatterResult = ball.batterResult;
  });

  // 同時記電子跟人眼的數量至少要跟只有人眼的數量一樣多，才算有夠多樣本作偏移校正
  // console.log(eyeDelta);
  // console.log(onlyEyeCount);
  if (eyeDelta.length >= onlyEyeCount) {
    // 取兩個標準差內的數值來做有效參數
    const getAvaliableValues = (data) => {
      let sum = 0; // stores sum of elements
      let sumsq = 0; // stores sum of squares
      for (let i = 0; i < data.length; i++) {
        sum += data[i];
        sumsq += data[i] * data[i];
      }
      const mean = sum / data.length;
      const varience = sumsq / data.length - mean * mean;
      const sd = Math.sqrt(varience);
      const data2 = []; // uses for data which is 2 standard deviations from the mean
      for (let i = 0; i < data.length; i++) {
        if (data[i] > mean - 2 * sd && data[i] < mean + 2 * sd) {
          data2.push(data[i]);
        }
      }

      return data2;
    };

    const avaliableXValues = getAvaliableValues(eyeDelta.map((eye) => eye.x));
    const avaliableYValues = getAvaliableValues(eyeDelta.map((eye) => eye.y));
    // console.log(avaliableXValues);
    // console.log(avaliableYValues);

    const deltaX = sum(avaliableXValues) / avaliableXValues.length;
    const deltaY = sum(avaliableYValues) / avaliableYValues.length;
    // console.log('校正偏移', deltaX, deltaY);

    resolvedPerBall.forEach((ball, index) => {
      if (!ball.eyeZoneResult) {
        return;
      }

      resolvedPerBall[index].eyeZoneResult.x += deltaX;
      resolvedPerBall[index].eyeZoneResult.y += deltaY;
    });
  }

  return {
    ...defaultState,
    ...game,
    perBall: resolvedPerBall,
  };
};
