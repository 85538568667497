import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { strikeZoneConfig } from '../../StrikezoneGame/constants/base';
import HomePlatePNG from '../../../static/HomePlate.png';
import LeftHandPitcherPNG from '../../../static/LeftHandPitcher.png';
import RightHandPitcherPNG from '../../../static/RightHandPitcher.png';
import LeftHandBatterPNG from '../../../static/LeftHandBatter.png';
import RightHandBatterPNG from '../../../static/RightHandBatter.png';
import PitcherAngleLeftHandPitcherPNG from '../../../static/PitcherAngle-LeftHandPitcher.png';
import PitcherAngleRightHandPitcherPNG from '../../../static/PitcherAngle-RightHandPitcher.png';
import PitcherAngleLeftHandBatterPNG from '../../../static/PitcherAngle-LeftHandBatter.png';
import PitcherAngleRightHandBatterPNG from '../../../static/PitcherAngle-RightHandBatter.png';
import PitcherAngleHomePlatePNG from '../../../static/PitcherAngle-HomePlate.png';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: lightblue;

  * {
    pointer-events: none;
  }

  #on-site-strike-zone {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 33%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .home-plate {
    width: 33.5%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: calc(50% + ${(props) => (props.batterHeight / 5) * 2}px);
  }

  .batter-name {
    color: black;
    position: absolute;
    white-space: nowrap;
    top: 0;
    &.L {
      transform: translate(-100%, 100%);
      left: ${(props) => (props.cameraAngle === 'PITCHER' ? '0' : '100%')};
    }
    &.R {
      left: ${(props) => (props.cameraAngle === 'PITCHER' ? '100%' : '0')};
      transform: translate(0, 100%);
    }
  }

  .batter-image {
    height: ${(props) => props.batterHeight}px;
    position: absolute;
    top: calc(50% - ${(props) => props.batterHeight / 15}px);
    transform: translate(-50%, -50%);
    &.L {
      left: ${(props) => (props.cameraAngle === 'PITCHER' ? '0' : '100%')};
    }
    &.R {
      left: ${(props) => (props.cameraAngle === 'PITCHER' ? '100%' : '0')};
    }
  }

  .pitcher-name {
    color: black;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    white-space: nowrap;
  }

  .pitcher-image {
    width: 10%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 33%;
    ${(props) => (props.cameraAngle === 'PITCHER' ? 'display: none;' : '')}
  }
`;

const CurrentBall = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
`;

const PitcherImages = {
  PITCHER: {
    L: PitcherAngleLeftHandPitcherPNG,
    R: PitcherAngleRightHandPitcherPNG,
  },
  CATCHER: {
    L: LeftHandPitcherPNG,
    R: RightHandPitcherPNG,
  },
};

const BatterImages = {
  PITCHER: {
    L: PitcherAngleLeftHandBatterPNG,
    R: PitcherAngleRightHandBatterPNG,
  },
  CATCHER: {
    L: LeftHandBatterPNG,
    R: RightHandBatterPNG,
  },
};

const OnSiteStrikeZone = ({
  isOpen,
  cameraAngle,
  pitcher,
  pHand,
  batter,
  bHand,
  eyeZoneResult,
  setPitchPosition,
  setOnSiteZoneXY,
}) => {
  const fullZoneRef = useRef(null);
  const strikezoneRef = useRef(null);
  const [strikezoneWidth, setStrikezoneWidth] = useState(strikeZoneConfig.width);
  const [strikezoneHeight, setStrikezoneHeight] = useState(strikeZoneConfig.height);

  const [ballPosition, setBallPosition] = useState(null);

  useEffect(() => {
    if (!fullZoneRef || !fullZoneRef.current) {
      return;
    }

    const el = fullZoneRef.current;
    const setPitch = setPitchPosition('ON_SITE');
    el.addEventListener('click', setPitch);
    return () => {
      el.removeEventListener('click', setPitch);
    };
  }, [fullZoneRef, setPitchPosition]);

  useEffect(() => {
    if (!strikezoneRef || !strikezoneRef.current) {
      return;
    }

    const resetHeight = () => {
      if (!document.querySelector('#on-site-strike-zone')) {
        return;
      }
      const rect = document.querySelector('#on-site-strike-zone').getBoundingClientRect();
      const currentWidth = rect.width;
      const expectedHeight = (currentWidth / strikeZoneConfig.width) * strikeZoneConfig.height;
      document.querySelector('#on-site-strike-zone').style.height = `${expectedHeight}px`;

      setStrikezoneWidth(currentWidth);
      setStrikezoneHeight(expectedHeight);
      setOnSiteZoneXY({
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height,
      });
      console.log(rect);
    };
    resetHeight();
    // iPad element render in wrong position at first
    setTimeout(resetHeight, 500);
    setTimeout(resetHeight, 1000);
    setTimeout(resetHeight, 1500);
    setTimeout(resetHeight, 2000);

    const resizeOb = new ResizeObserver((entries) => {
      resetHeight();
    });
    resizeOb.observe(document.querySelector('#on-site-strike-zone'));
    document.querySelector('#on-site-strike-zone').addEventListener('move', resetHeight);

    return () => {
      const onSiteStrikeZoneEl = document.querySelector('#on-site-strike-zone');
      if (!onSiteStrikeZoneEl) {
        return;
      }
      resizeOb.unobserve(onSiteStrikeZoneEl);
      onSiteStrikeZoneEl.removeEventListener('move', resetHeight);
    };
  }, [strikezoneRef, setOnSiteZoneXY, isOpen]);

  useEffect(() => {
    console.log(eyeZoneResult);
    if (!eyeZoneResult || eyeZoneResult.width === 0 || eyeZoneResult.height === 0) {
      setBallPosition(null);
      return;
    }

    const fullDiv = document.getElementById('on-site-strike-zone-full');
    if (!fullDiv) {
      return;
    }
    const height = fullDiv.getBoundingClientRect().height;
    const heightPercent = (strikezoneHeight / height) * 100;
    const heightDeltaPercent = (100 - heightPercent) / 2;

    // width: 33%;
    // height: ?%;
    // left: 50%;
    // top: 50%;
    const displayX = parseFloat(eyeZoneResult.x) + (100 * 33.5) / 33;
    const displayY = parseFloat(eyeZoneResult.y) + (100 * heightDeltaPercent) / heightPercent;
    setBallPosition({ left: (displayX * strikezoneWidth) / 100, top: (displayY * strikezoneHeight) / 100 });
  }, [eyeZoneResult, strikezoneWidth, strikezoneHeight]);

  return (
    <StyledDiv
      id="on-site-strike-zone-full"
      batterHeight={strikezoneHeight * 3}
      cameraAngle={cameraAngle}
      ref={fullZoneRef}
    >
      <img
        className="home-plate"
        src={cameraAngle === 'PITCHER' ? PitcherAngleHomePlatePNG : HomePlatePNG}
        alt="home-plate"
      />
      {pHand && PitcherImages[cameraAngle]?.[pHand] && (
        <img className="pitcher-image" src={PitcherImages[cameraAngle][pHand]} alt="hand-pitcher" />
      )}
      {pitcher && <span className="pitcher-name">{pitcher}</span>}
      {bHand && BatterImages[cameraAngle]?.[bHand] && (
        <img className={`batter-image ${bHand}`} src={BatterImages[cameraAngle][bHand]} alt="hand-batter" />
      )}
      {batter && <span className={`batter-name ${!bHand ? 'R' : bHand}`}>{batter}</span>}
      <div id="on-site-strike-zone" ref={strikezoneRef}></div>
      {!!ballPosition && <CurrentBall size={strikezoneWidth / 6} top={ballPosition.top} left={ballPosition.left} />}
    </StyledDiv>
  );
};

export default OnSiteStrikeZone;
