import { useEffect, useState } from 'react';
import { UserResource } from '~~apis/resource';
import Loading from '~~elements/Loading';
import toast from '~~elements/Toast';
import NoPermissionNotification from './components/NoPermissionNotification';
import PlayerAnalytics from './PlayerAnalytics';
import { useRootState } from '../../useRoot';
import { parseUserInfo } from '../../Const';

const Index = () => {
  const {
    loginUser: { isLogin },
  } = useRootState();

  const [userInfo, setUserInfo] = useState(parseUserInfo());
  const [loginUserWaiting, setLoginUserWaiting] = useState(4);

  useEffect(() => {
    if (loginUserWaiting <= 0) {
      return;
    }

    setTimeout(() => {
      setLoginUserWaiting(loginUserWaiting - 1);
    }, 1000);
  }, [loginUserWaiting]);

  useEffect(() => {
    if (!isLogin) {
      return;
    }

    UserResource.getBasicInfo()
      .then(({ data }) => {
        setUserInfo(parseUserInfo(data));
      })
      .catch((e) => {
        console.error(e);
        toast('發生錯誤，請稍後再試', { status: 'error', second: 1 });
      });
  }, [isLogin]);

  if ((!isLogin || !userInfo.inSubscription) && loginUserWaiting > 0) {
    return <Loading width="20vw" margin="3rem auto" />;
  }

  if (!isLogin || !userInfo.inSubscription) {
    return <NoPermissionNotification notInSubscription={!userInfo.inSubscription} />;
  }

  return <PlayerAnalytics />;
};

export default Index;
