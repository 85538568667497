export const getResizerCommonStyle = (color) => `
  &--resizers {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid ${color};
    box-sizing: border-box;
  }

  &--resizer {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 2px solid ${color};
    position: absolute;
  }

  &--top-left {
    left: -6px;
    top: -6px;
    cursor: nwse-resize;
  }
  &--top-right {
    right: -6px;
    top: -6px;
    cursor: nesw-resize;
  }
  &--bottom-left {
    left: -6px;
    bottom: -6px;
    cursor: nesw-resize;
  }
  &--bottom-right {
    right: -6px;
    bottom: -6px;
    cursor: nwse-resize;
  }
`;
