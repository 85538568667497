import { COLOR } from '../Const';

const toastStyled = `
  width: 15rem;
  margin-left: -7.5rem;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 0.5rem;
  position: fixed;
  z-index: 10000;
  left: 50%;
  font-size: 1rem;
  bottom: 6vh;

  -webkit-animation: fade-in 0.3s normal linear;
  -moz-animation: fade-in 0.3s normal linear;
  -o-animation: fade-in 0.3s normal linear;
  animation: fade-in 0.3s normal linear;

  @-webkit-keyframes fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 10vh;
      opacity: 1;
    }
  }
  @-moz-keyframes fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 10vh;
      opacity: 1;
    }
  }
  @-o-keyframes fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 10vh;
      opacity: 1;
    }
  }
  @keyframes fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 10vh;
      opacity: 1;
    }
  }
`;

const bgColor = {
  info: COLOR.secondary,
  error: COLOR.primary,
  success: '#00965e',
};

const toast = (message, options = {}) => {
  const { status = 'info', second = 1 } = options;
  const element = document.createElement('div');
  element.style.cssText = toastStyled;
  element.style.backgroundColor = bgColor[status];
  document.body.prepend(element);

  element.classList.add('show');
  switch (status) {
    case 'error':
      element.classList.add('error');
      break;
    case 'success':
      element.classList.add('success');
      break;
    default:
      break;
  }
  element.innerHTML = message;

  setTimeout(() => {
    element.parentElement.removeChild(element);
  }, second * 1000 + 300);
};

const useToast = () => {
  return toast;
};

export default toast;
export { useToast };
