import * as R from 'ramda';

export const videoID = `video-in-control`;

export const timeToDisplay = (time) => {
  const ms = `${time}`.includes('.') ? parseInt(`${time}`.split('.')[1]) : 0;
  const sec = Math.floor(time % 60);
  let mins = Math.floor(time / 60);
  const min = mins % 60;
  const hour = Math.floor(mins / 60);

  const msDisplay = `${ms}`.length > 3 ? `${ms}`.slice(0, 3) : `${ms}`;

  return [hour, min, `${sec}.${msDisplay}`].join(':');
};

export const timeDisplayReverse = (display) => {
  const [hour, min, sec] = display.split(':');
  return parseInt(hour) * 60 * 60 + parseInt(min) * 60 + parseFloat(sec);
};

export const validTimeDisplay = (display) => {
  return display.match(/^\d+:\d+:\d+.?\d*$/);
};

export const getPitchIndexByTime = (pitches, targeTime) => {
  let currentIndex = -0.1;
  pitches.forEach((pitchEvent, peIndex) => {
    if (!validTimeDisplay(pitchEvent.video_started_at)) {
      return;
    }

    const time = timeDisplayReverse(pitchEvent.video_started_at);
    if (time > targeTime) {
      return;
    }
    if (time < targeTime) {
      currentIndex = peIndex + 0.5;
      return;
    }
    currentIndex = peIndex;
    return;
  });

  return currentIndex;
};

export const updatable = (pitches, pitchIndex, targetIndex) => {
  let preIndex = -1;
  let nextIndex = 100000;
  pitches.forEach((pitchEvent, peIndex) => {
    if (peIndex < pitchIndex) {
      if (validTimeDisplay(pitchEvent.video_started_at)) {
        preIndex = peIndex;
      }
      return;
    }

    if (peIndex === pitchIndex) {
      return;
    }

    if (peIndex < nextIndex && validTimeDisplay(pitchEvent.video_started_at)) {
      nextIndex = peIndex;
    }
    return;
  });

  return preIndex < targetIndex && nextIndex > targetIndex;
};

export const insertable = (pitches, targetIndex) => {
  let hasEmpty = false;
  pitches.forEach((pitchEvent, peIndex) => {
    if (peIndex < targetIndex) {
      return;
    }

    if (validTimeDisplay(pitchEvent.video_started_at)) {
      return;
    }
    hasEmpty = true;
  });

  return hasEmpty;
};

export const getPitchesInTimeRange = (pitches, startEnd) => {
  return R.filter(
    (p) =>
      validTimeDisplay(p.video_started_at) &&
      startEnd[0] <= timeDisplayReverse(p.video_started_at) &&
      startEnd[1] >= timeDisplayReverse(p.video_started_at),
    pitches,
  );
};
