import { filter, sum } from 'ramda';

const defaultPitchTypeStats = {
  F: [],
  C: [],
  B: [],
  others: [],
  all: [],
};

const getPutAway = (perBall, ballType) => {
  const twoStrikePAList = [];
  let strikeCount = 0;
  let lastBatter = '';
  perBall.forEach((ball) => {
    if (ball.batter !== lastBatter) {
      lastBatter = ball.batter;
      strikeCount = 0;
    }

    if (!!ball.batterResult && ball.batterResult !== 'NONE') {
      if (strikeCount === 2) {
        twoStrikePAList.push(ball);
      }
      return;
    }

    if (ball.judgeCall !== 'S') {
      return;
    }
    strikeCount++;
  });

  const twoStrikeBallTypePAList = !ballType
    ? twoStrikePAList
    : filter((ball) => ball.ballType === ballType, twoStrikePAList);

  return twoStrikeBallTypePAList.length > 0
    ? (
        (filter((ball) => ball.batterResult === 'SO', twoStrikeBallTypePAList).length /
          twoStrikeBallTypePAList.length) *
        100
      ).toFixed(1)
    : 0;
};

export const getAtBatContent = (perBall, stats) => {
  const pitches = stats.length;
  const percentage = perBall.length ? ((stats.length / perBall.length) * 100).toFixed(1) : 0;
  const RHP = filter(({ pHand }) => pHand === 'R', stats).length;
  const LHP = filter(({ pHand }) => pHand === 'L', stats).length;

  const haveTrajectoryStats = filter((ball) => ['G', 'L', 'F', 'P'].includes(ball.ballTrajectory), stats);

  const getPercentage = (trajectory) => {
    return haveTrajectoryStats.length === 0
      ? '-'
      : (
          (filter((ball) => ball.ballTrajectory === trajectory, haveTrajectoryStats).length * 100) /
          haveTrajectoryStats.length
        ).toFixed(1);
  };
  const ground = getPercentage('G');
  const lineDrive = getPercentage('L');
  const fly = getPercentage('F');
  const pop = getPercentage('P');

  return {
    pitches,
    percentage,
    RHP,
    LHP,
    ground,
    lineDrive,
    fly,
    pop,
  };
};

export const getPitchContent = (perBall, stats) => {
  const pitches = stats.length;
  const percentage = perBall.length ? ((stats.length / perBall.length) * 100).toFixed(1) : 0;
  const RHB = filter(({ bHand }) => bHand === 'R', stats).length;
  const LHB = filter(({ bHand }) => bHand === 'L', stats).length;

  const haveSpeedStats = filter((ball) => !Number.isNaN(parseInt(ball.speed)), stats);
  const max = haveSpeedStats.length === 0 ? '-' : Math.max(...haveSpeedStats.map((ball) => parseInt(ball.speed)));
  const min = haveSpeedStats.length === 0 ? '-' : Math.min(...haveSpeedStats.map((ball) => parseInt(ball.speed)));
  const avg =
    haveSpeedStats.length === 0
      ? '-'
      : (sum(haveSpeedStats.map((ball) => parseInt(ball.speed))) / haveSpeedStats.length).toFixed(0);

  const haveTrajectoryStats = filter((ball) => ['G', 'L', 'F', 'P'].includes(ball.ballTrajectory), stats);

  const getPercentage = (trajectory) => {
    return haveTrajectoryStats.length === 0
      ? '-'
      : (
          (filter((ball) => ball.ballTrajectory === trajectory, haveTrajectoryStats).length * 100) /
          haveTrajectoryStats.length
        ).toFixed(1);
  };
  const ground = getPercentage('G');
  const lineDrive = getPercentage('L');
  const fly = getPercentage('F');
  const pop = getPercentage('P');

  return {
    pitches,
    percentage,
    RHB,
    LHB,
    max,
    min,
    avg,
    ground,
    lineDrive,
    fly,
    pop,
  };
};

export const getBatterTable = (perBall, stats, ballType) => {
  const PAList = filter((ball) => !!ball.batterResult && ball.batterResult !== 'NONE', stats);
  const ABList = filter(
    (ball) => !['uBB', 'IBB', 'HBP', 'SH', 'SF', 'IH', 'IR', 'other'].includes(ball.batterResult),
    PAList,
  );

  const resultCount = (results) => {
    return filter((ball) => results.includes(ball.batterResult), ABList).length;
  };

  const normalStats = {
    PA: PAList.length,
    AB: ABList.length,
    H: resultCount(['1B', '2B', '3B', 'HR']),
    '1B': resultCount(['1B']),
    '2B': resultCount(['2B']),
    '3B': resultCount(['3B']),
    HR: resultCount(['HR']),
    SO: resultCount(['SO']),
    BA: ABList.length > 0 ? (resultCount(['1B', '2B', '3B', 'HR']) / ABList.length).toFixed(3) : 0,
    SLG:
      ABList.length > 0
        ? (
            (resultCount(['1B']) + resultCount(['2B']) * 2 + resultCount(['3B']) * 3 + resultCount(['HR']) * 4) /
            ABList.length
          ).toFixed(3)
        : 0,
    putAway: getPutAway(perBall, ballType),
  };

  return {
    ...normalStats,
  };
};

export const getPitcherTable = (perBall, stats, ballType) => {
  const PAList = filter((ball) => !!ball.batterResult && ball.batterResult !== 'NONE', stats);
  const ABList = filter((ball) => !['uBB', 'IBB', 'HBP', 'SH', 'SF', 'IH', 'IR'].includes(ball.batterResult), PAList);

  const resultCount = (results) => {
    return filter((ball) => results.includes(ball.batterResult), ABList).length;
  };

  const normalStats = {
    PA: PAList.length,
    AB: ABList.length,
    H: resultCount(['1B', '2B', '3B', 'HR']),
    '1B': resultCount(['1B']),
    '2B': resultCount(['2B']),
    '3B': resultCount(['3B']),
    HR: resultCount(['HR']),
    SO: resultCount(['SO']),
    BA: ABList.length > 0 ? (resultCount(['1B', '2B', '3B', 'HR']) / ABList.length).toFixed(3) : 0,
    SLG:
      ABList.length > 0
        ? (
            (resultCount(['1B']) + resultCount(['2B']) * 2 + resultCount(['3B']) * 3 + resultCount(['HR']) * 4) /
            ABList.length
          ).toFixed(3)
        : 0,
    putAway: getPutAway(perBall, ballType),
  };

  return {
    ...normalStats,
  };
};

export const getBallTypeStats = (perBall) => {
  return perBall.reduce((acc, cur) => {
    if (!cur.ballType)
      return {
        ...acc,
        others: [...acc['others'], cur],
        all: [...acc['all'], cur],
      };
    return {
      ...acc,
      [cur.ballType]: [...acc[cur.ballType], cur],
      all: [...acc['all'], cur],
    };
  }, defaultPitchTypeStats);
};

export const pitchTracking = (perBall, column, ballType = '') => {
  const filteredPerBall = !ballType ? perBall : filter((ball) => ball.ballType === ballType, perBall);
  switch (column) {
    case 'pitch':
      return filteredPerBall.length;
    case 'percentage':
      return ((filteredPerBall.length / perBall.length) * 100).toFixed(1);
    case 'Whiff': {
      const trySwings = filter((ball) => !!ball.batterAction && ball.batterAction !== 'NONE', filteredPerBall);
      const swmiss = filter((ball) => ball.batterAction === 'SWMISS', filteredPerBall);
      return ((swmiss.length / trySwings.length) * 100).toFixed(1);
    }
    default:
      break;
  }

  const PAList = filter((ball) => !!ball.batterResult && ball.batterResult !== 'NONE', filteredPerBall);
  const ABList = filter((ball) => !['uBB', 'IBB', 'HBP', 'SH', 'SF', 'IH', 'IR'].includes(ball.batterResult), PAList);

  const resultCount = (results) => {
    return filter((ball) => results.includes(ball.batterResult), ABList).length;
  };

  switch (column) {
    case 'PA':
      return PAList.length;
    case 'AB':
      return ABList.length;
    case 'H':
      return resultCount(['1B', '2B', '3B', 'HR']);
    case '1B':
      return resultCount(['1B']);
    case '2B':
      return resultCount(['2B']);
    case '3B':
      return resultCount(['3B']);
    case 'HR':
      return resultCount(['HR']);
    case 'SO':
      return resultCount(['SO']);
    case 'BA':
      return (resultCount(['1B', '2B', '3B', 'HR']) / ABList.length).toFixed(3);
    case 'SLG':
      return (
        (resultCount(['1B']) + resultCount(['2B']) * 2 + resultCount(['3B']) * 3 + resultCount(['HR']) * 4) /
        ABList.length
      ).toFixed(3);
    case 'PutAway':
      break;
    default:
      throw new Error();
  }

  // 要挑出兩好球的打席
  const twoStrikePAList = [];
  let strikeCount = 0;
  let lastBatter = '';
  perBall.forEach((ball) => {
    if (ball.batter !== lastBatter) {
      lastBatter = ball.batter;
      strikeCount = 0;
    }

    if (!!ball.batterResult && ball.batterResult !== 'NONE') {
      if (strikeCount === 2) {
        twoStrikePAList.push(ball);
      }
      return;
    }

    if (ball.judgeCall !== 'S') {
      return;
    }
    strikeCount++;
  });

  const twoStrikeBallTypePAList = !ballType
    ? twoStrikePAList
    : filter((ball) => ball.ballType === ballType, twoStrikePAList);

  return (
    (filter((ball) => ball.batterResult === 'SO', twoStrikeBallTypePAList).length / twoStrikeBallTypePAList.length) *
    100
  ).toFixed(1);
};

export const getAvg = (zone) => {
  const AB = zone.filter(({ batterResult }) => {
    switch (batterResult) {
      case '1B':
      case '2B':
      case '3B':
      case 'HR':
      case 'E':
      case 'FC':
      case 'GO':
      case 'FO':
      case 'GIDP':
      case 'SO':
        return true;
      default:
        return false;
    }
  }).length;

  const H = zone.filter(({ batterResult }) => {
    switch (batterResult) {
      case '1B':
      case '2B':
      case '3B':
      case 'HR':
        return true;
      default:
        return false;
    }
  }).length;

  if (H === 0 && AB === 0) return -1;

  return (H / AB).toFixed(3);
};

export const getFillOpacity = (avg) => {
  if (avg <= 1 && avg > 0.5) return { color: 'red', opacity: 1 };
  else if (avg <= 0.5 && avg > 0.4) return { color: 'red', opacity: 0.8 };
  else if (avg <= 0.4 && avg > 0.3) return { color: 'red', opacity: 0.6 };
  else if (avg <= 0.3 && avg > 0.25) return { color: 'red', opacity: 0.4 };
  else if (avg <= 0.25 && avg > 0.2) return { color: 'blue', opacity: 0.4 };
  else if (avg <= 0.2 && avg > 0.1) return { color: 'blue', opacity: 0.6 };
  else if (avg <= 0.1 && avg > 0) return { color: 'blue', opacity: 0.8 };
  else if (avg <= 0.0 && avg > -1) return { color: 'blue', opacity: 1 };
  else return { color: 'white', opacity: 1 };
};

export const getOpponents = (playersMap, side, position) => {
  if (side === 'home') {
    return position === 'fielder' ? playersMap.away.pitcher : playersMap.away.fielder;
  }

  return position === 'fielder' ? playersMap.home.pitcher : playersMap.home.fielder;
};
