import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import Multilingual from '~~utils/Multilingual';

const StyledDiv = styled.div`
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  .wording {
    z-index: 1002;
    background-color: ${COLOR.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fafafa;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const NoPermissionNotification = ({ notInSubscription }) => {
  if (notInSubscription) {
    return (
      <StyledDiv>
        <div className="wording">
          <span>{Multilingual(`REQUIRED_SUBSCRIPTION`)}</span>
          <a href={`${process.env.REACT_APP_ACCOUNT_URL}/shop/plugin`}>
            <Button color="blue">{Multilingual(`GO_TO_PLUGIN_SHOP`)}</Button>
          </a>
        </div>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <div className="wording">
        <span>{`${Multilingual(`PLAYER_ANALYTICS.WORDING`)} ${Multilingual(`REQUIRED_LOGIN`)}`}</span>
        <a href={`${process.env.REACT_APP_ACCOUNT_URL}/login?done_url=${encodeURIComponent(window.location.href)}`}>
          <Button color="blue">{Multilingual(`LOGIN`)}</Button>
        </a>
      </div>
    </StyledDiv>
  );
};

export default NoPermissionNotification;
