import React, { createContext, useReducer, useContext } from 'react';
import { getRealtimeDB } from './Firebase';

const RootStateContext = createContext();
const RootDispatchContext = createContext();

const initLoginUser = {
  isLogin: false,
  isAdmin: false,
  isAdminManager: false,
  firstName: '',
  lastName: '',
  username: '',
  avatarUrl: '',
};

const initRootStates = {
  realtimeDB: getRealtimeDB(),
  loginUser: { ...initLoginUser },
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'setLoginUser': {
      const newLoginUser = { ...initLoginUser, isLogin: action.payload.isLogin };
      if (action.payload.isLogin) {
        newLoginUser.firstName = action.payload.first_name;
        newLoginUser.lastName = action.payload.last_name;
        newLoginUser.username = action.payload.user_name;
        newLoginUser.avatarUrl = action.payload.avatar_url;
      }

      return {
        ...state,
        loginUser: newLoginUser,
      };
    }
    case 'setPermission': {
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
        isAdminManager: action.payload.isAdminManager,
      };
    }
    default:
      throw new Error('Invalid action type');
  }
};

function RootProvider({ children }) {
  const [state, dispatch] = useReducer(rootReducer, { ...initRootStates });

  return (
    <RootStateContext.Provider value={state}>
      <RootDispatchContext.Provider value={dispatch}>{children}</RootDispatchContext.Provider>
    </RootStateContext.Provider>
  );
}

function useRootState() {
  const context = useContext(RootStateContext);

  if (context === undefined) {
    throw new Error('useRootState must be used within a RootProvider');
  }
  return context;
}

function useRootDispatch() {
  const context = useContext(RootDispatchContext);

  if (context === undefined) {
    throw new Error('useRootDispatch must be used within a RootProvider');
  }
  return context;
}

export { RootProvider, useRootState, useRootDispatch };
