import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserResource } from '~~apis/resource';
import toast from '~~elements/Toast';
import Button from '~~elements/Button';
import { useRootState } from '../../../useRoot';
import { sort } from 'ramda';
import { Fragment } from 'react';
import Multilingual from '../../../utils/Multilingual';

const StyledDiv = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  .comments {
    width: 540px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .actions {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }

  .leave-comment {
    &--name {
      font-size: 0.9rem;
      margin: 0.25rem 0;
      padding: 0 0.5rem 0 0;
      border-right: 1px solid gray;
      white-space: nowrap;
    }
    &--comment {
      width: 100%;
      height: 5rem;
      resize: none;
      border-radius: 5px;
    }
    &--submit {
      align-self: flex-end;
    }
  }
`;

const CommentUserSpan = styled.span`
  margin-top: 0.5rem;
  ${(props) => (!props.mine ? '' : 'align-self: flex-end;')}
  font-size: 1rem;
`;
const CommentDiv = styled.div`
  ${(props) => (!props.mine ? '' : 'align-self: flex-end;')}
  background-color: ${(props) => (!props.mine ? 'aliceblue' : '#b7e9fb')};
  border-radius: ${(props) => (!props.mine ? '0 5px 5px 5px' : '5px 0 5px 5px')};
  text-align: start;
  box-shadow: 1px 1px 2px 0 black;
  width: fit-content;
  padding: 0.5rem 1rem;
  white-space: pre-line;
  margin: 0.3rem 0;
`;

const Index = ({ strikezoneUniqid, comments }) => {
  const {
    loginUser: { isLogin, username },
  } = useRootState();

  const [accountEmailVerified, setAccountEmailVerified] = useState(-1);
  const [leavingComment, setLeavingComment] = useState('');

  const [sortedComments, setSortedComments] = useState([]);

  useEffect(() => {
    if (!isLogin) {
      return;
    }

    UserResource.getAccountInfo()
      .then(({ data }) => {
        const isEmailVerified = !!data.email_verified;
        setAccountEmailVerified(isEmailVerified);

        if (!isEmailVerified) {
          return;
        }
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      });
  }, [isLogin]);

  useEffect(() => {
    setSortedComments(
      sort((a, b) => {
        return new Date(b.commentedAt).getTime() - new Date(a.commentedAt).getTime();
      }, Object.values(comments)),
    );
  }, [comments]);

  const handleSubmit = () => {
    const trimComment = leavingComment.trim();
    if (!trimComment) {
      return;
    }

    UserResource.addGameComment({ strikezoneUniqid, data: { comment: trimComment } })
      .then(() => {
        setLeavingComment('');
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      });
  };

  return (
    <StyledDiv>
      <div className="comments">
        {!isLogin && (
          <div className="actions">
            <a href={`${process.env.REACT_APP_ACCOUNT_URL}/login?done_url=${encodeURIComponent(window.location.href)}`}>
              <Button color="blue">{Multilingual(`COMMENT.HINT`)}</Button>
            </a>
          </div>
        )}
        {accountEmailVerified === false && (
          <div className="actions">
            <a href={`${process.env.REACT_APP_ACCOUNT_URL}/settings/user_profile`}>
              <Button color="blue">{Multilingual(`FEED.EMAIL_NOT_VERIFIED_YET`)}</Button>
            </a>
          </div>
        )}
        {isLogin && username && accountEmailVerified === true && (
          <div className="actions">
            <span className="leave-comment--name">
              {Multilingual(`COMMENT.AS_PREFIX`)} {username} {Multilingual(`COMMENT.AS_SUFFIX`)}
            </span>
            <textarea
              className="leave-comment--comment"
              value={leavingComment}
              textalign="left"
              onChange={(e) => setLeavingComment(e.target.value)}
            />
            <Button
              className="leave-comment--submit"
              width="unset"
              disabled={!leavingComment.trim()}
              onClick={handleSubmit}
            >
              {Multilingual(`COMMENT.WORDING`)}
            </Button>
          </div>
        )}
        {sortedComments.map((comment, i) => (
          <Fragment key={i}>
            <CommentUserSpan mine={comment.userUserName === username}>{comment.userUserName}</CommentUserSpan>
            <CommentDiv mine={comment.userUserName === username}>{comment.comment}</CommentDiv>
          </Fragment>
        ))}
      </div>
    </StyledDiv>
  );
};

export default Index;
