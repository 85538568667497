import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { strikeZoneConfig } from '../constants/base';
import Multilingual from '../../../utils/Multilingual';
const { height } = strikeZoneConfig;

const StyledDiv = styled.div`
  // 放這邊才不會跟列印時衝突
  #dropmap-img-4-print {
    display: none;
  }

  .percentage-detail {
    transform: translateX(-50%) translateY(-50%);
    top: 85%;
    left: 85%;
    position: absolute;
    > label {
      margin: 0;
      padding: 0;
      font-size: 1rem;
    }
    > p {
      margin: 0.1rem 0 0 0;
      padding: 0;
      font-size: 1.2rem;
    }
  }
`;

const StyledLocationCodeSection = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  height: fit-content;
  pointer-events: none;
  &.section-1 {
    width: 11.7%;
    top: 69.4%;
    left: 50%;
  }
  &.section-2 {
    width: 18.7%;
    top: 80.5%;
    left: 50%;
  }
  &.section-3 {
    width: 17.7%;
    top: 65.1%;
    left: 60.4%;
  }
  &.section-4 {
    width: 12.7%;
    top: 58.5%;
    left: 55.5%;
  }
  &.section-5 {
    width: 17.1%;
    top: 65.4%;
    left: 39.7%;
  }
  &.section-6 {
    width: 12.9%;
    top: 59%;
    left: 44.5%;
  }
  &.section-7 {
    width: 36.4%;
    top: 38.2%;
    left: 25.2%;
  }
  &.section-8 {
    width: 38.6%;
    top: 29.5%;
    left: 50%;
  }
  &.section-9 {
    width: 36.5%;
    top: 38.7%;
    left: 75.2%;
  }
  > object {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &.section--hover {
    transform: translateX(-50%) translateY(calc(-50% - 2px)) scale(1.05);
    z-index: 3;
  }
  &.section--focus {
    transform: translateX(-50%) translateY(calc(-50% - 2px)) scale(1.1);
    z-index: 2;
  }
`;

const getColorSeq = (all, target) => {
  const percent = (target / all) * 100;
  if (all === 0 || percent === 0) {
    return '0';
  }
  if (percent <= 5) {
    return '1';
  }
  if (percent <= 10) {
    return '2';
  }
  if (percent <= 15) {
    return '3';
  }
  if (percent <= 20) {
    return '4';
  }
  if (percent <= 25) {
    return '5';
  }
  return '6';
};

const sectionNames = ['2', '3', '1', '4', '6', '5', '9', '8', '7'];

const prefixID = 'strikezone-locationcode';

const Index = ({ resolvedPerBall }) => {
  const [mouseOverSection, setMouseOverSection] = useState('');
  const [sectionMapping, setSectionMapping] = useState({});

  const [locationCodes, setLocationCodes] = useState({});

  useEffect(() => {
    const codes = {
      all: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    };

    R.filter(
      (b) =>
        b.batterResult &&
        b.batterResult !== 'NONE' &&
        ((!!b.ballDroppoint && b.ballDroppoint !== 'NONE') || !!b.ballDroppointXY),
      resolvedPerBall,
    ).forEach(({ ballDroppoint }) => {
      const numberOnly = ballDroppoint.replace(/[^1-9]*/, '');
      const numbers = numberOnly.split('');
      codes.all++;
      numbers.forEach((num) => {
        codes[num] += 1 / numbers.length;
      });
    });

    setLocationCodes(codes);
  }, [resolvedPerBall]);

  /**
   * 讓 SVG 的點擊範圍符合顯示的區塊
   */
  const getSVG = (section) => {
    if (Object.keys(locationCodes).length < 10) {
      return;
    }

    const colorSeq = getColorSeq(locationCodes.all, locationCodes[section]);
    if (sectionMapping[section] && sectionMapping[section] === colorSeq) {
      return;
    }

    fetch(`/images/first-base/droppoint-section-${section}-${colorSeq}.svg`)
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
      .then((svgHTML) => {
        const sectionWrapper = document.querySelector(`#${prefixID}locationcode-section-${section}`);
        if (!sectionWrapper) {
          return;
        }

        const svg = svgHTML.querySelector('svg');
        svg.style.cssText = `
            pointer-events: none;
          `;

        svgHTML.querySelectorAll('path').forEach((path) => {
          path.style.cssText = `
              pointer-events: fill;
              cursor: pointer;
            `;

          path.onmouseover = () => {
            setMouseOverSection(section);
          };
          path.onmouseout = () => {
            setMouseOverSection((pre) => (pre === section ? '' : pre));
          };
        });

        const styles = svgHTML.querySelector('style');
        const sectionColor = styles.innerHTML.match(/\.([\w-]+){fill:(#[\w]+);}/);
        svgHTML.querySelectorAll(`.${sectionColor[1]}`).forEach((el) => {
          el.style.fill = sectionColor[2];
        });

        sectionWrapper.innerHTML = '<object></object>';
        sectionWrapper.querySelector('object').appendChild(svgHTML.documentElement);

        setSectionMapping((pre) => {
          pre[section] = colorSeq;
          return pre;
        });
      });
  };

  const getDropPointDetail = () => {
    if (!mouseOverSection) {
      return <></>;
    }

    return (
      <div className="percentage-detail">
        <label>{Multilingual(`POSITION.${mouseOverSection}`)}</label>
        <p>{`${((locationCodes[mouseOverSection] / locationCodes.all) * 100).toFixed(1)}%`}</p>
      </div>
    );
  };

  return (
    <div>
      <h3>{Multilingual(`GAME.LOCATION_CODE`)}</h3>
      <StyledDiv style={{ position: 'relative', width: height, height, margin: '0 auto' }}>
        {sectionNames.map((section) => (
          <StyledLocationCodeSection
            key={section}
            id={`${prefixID}locationcode-section-${section}`}
            className={`
              section-${section}
              ${mouseOverSection === section ? 'section--hover' : ''}
            `}
          >
            {getSVG(section)}
          </StyledLocationCodeSection>
        ))}
        <div className="section-cover" />
        {getDropPointDetail()}
      </StyledDiv>
    </div>
  );
};

export default Index;
