import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserResource, StrikezoneGameEditorResource } from '~~apis/resource';
import Modal from '~~elements/Modal';
import toast from '~~elements/Toast';
import Input from '~~elements/Input';
import Loading from '~~elements/Loading';
import DefaultAvatar from '~~elements/static/DefaultAvatar.jpg';
import { getNameDisplay, FONT_COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import { PremiumEditorLimit } from '../../../Const';
import { useRootState } from '../../../useRoot';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: ${FONT_COLOR};
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid lightgray;
    .user {
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: start;
      &--avatar {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        margin-right: 0.2rem;
      }
      &--name {
        font-size: 1rem;
        display: block;
      }
      &--username {
        font-size: 0.8rem;
        color: gray;
      }
    }
    &:first-child {
      border-top: 0;
    }
  }
`;

const Cooperator = ({ isOpen, toClose, strikezoneUniqid, ownerUniqid, editorUniqids }) => {
  const { loginUser } = useRootState();
  const [apiInProgress, setApiInProgress] = useState(false);
  const [owner, setOwner] = useState({
    avatar_url: '',
    first_name: '',
    last_name: '',
    user_name: '',
  });
  const [editors, setEditors] = useState([]);

  const [newEditorValue, setNewEditorValue] = useState('');

  useEffect(() => {
    if (!isOpen || !ownerUniqid) {
      return;
    }

    setApiInProgress(true);

    let apiCountDown = editorUniqids.length + 1;
    const newEditors = [];
    const apiDone = () => {
      apiCountDown--;
      if (apiCountDown > 0) {
        return;
      }

      setApiInProgress(false);
      setEditors(newEditors);
    };

    UserResource.getEditorInfo(ownerUniqid)
      .then(({ data }) => {
        setOwner(data);
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      })
      .finally(() => {
        apiDone();
      });

    editorUniqids.forEach((editorUniqid) => {
      UserResource.getEditorInfo(editorUniqid)
        .then(({ data }) => {
          newEditors.push(data);
        })
        .catch((e) => {
          console.error(e);
          toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
        })
        .finally(() => {
          apiDone();
        });
    });
  }, [isOpen, ownerUniqid, editorUniqids]);

  const handleNewEditorValueChange = (e) => {
    setNewEditorValue(e.target.value);
  };

  const handleAddEditor = () => {
    const value = newEditorValue.trim();
    if (!value) {
      return;
    }

    const notFoundError = () => {
      toast(Multilingual(`COOPERATE.USER_NOT_FOUND`), { status: 'error', second: 1 });
    };

    if (value.match(/ /)) {
      notFoundError();
      return;
    }

    setApiInProgress(true);
    UserResource.checkUserExist(value)
      .then(({ data }) => {
        if (!data.exists) {
          notFoundError();
          setApiInProgress(false);
          return;
        }

        const editorUniqid = data.uniqid;
        if (data.user_name === loginUser.username) {
          toast(Multilingual(`COOPERATE.ALREADY_EDITOR`), { status: 'error', second: 1 });
          setApiInProgress(false);
          return;
        }

        if (ownerUniqid === editorUniqid || editorUniqids.includes(editorUniqid)) {
          toast(Multilingual(`COOPERATE.USER_ALREADY_EDITOR`), { status: 'error', second: 1 });
          setApiInProgress(false);
          return;
        }

        StrikezoneGameEditorResource.addEditor({ strikezoneUniqid, data: { uniqid: editorUniqid } })
          .then(() => {
            toast(Multilingual(`COOPERATE.ADD_SUCCESSFULLY`), { status: 'success', second: 1 });
            setNewEditorValue('');
          })
          .catch((e) => {
            console.error(e);
            toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
          })
          .finally(() => {
            setApiInProgress(false);
          });
      })
      .catch(() => {
        notFoundError();
        setApiInProgress(false);
        return;
      });
  };

  const handleRemoveEditor = (editorUniqid) => () => {
    setApiInProgress(true);
    StrikezoneGameEditorResource.removeEditor({ strikezoneUniqid, editorUniqid })
      .then(() => {
        toast(Multilingual(`COOPERATE.REMOVE_SUCCESSFULLY`), { status: 'success', second: 1 });
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      })
      .finally(() => {
        setApiInProgress(false);
      });
  };

  return (
    <Modal.Modal isOpen={isOpen} onClickBack={toClose}>
      <Modal.ModalHeader hasBack={true} onClickBack={toClose}>
        {Multilingual(`INFO.COOPERATE`)}
      </Modal.ModalHeader>
      <Modal.ModalBody>
        {apiInProgress ? (
          <StyledDiv>
            <Loading width="10rem" />
          </StyledDiv>
        ) : (
          <StyledDiv>
            <div className="row">
              <div className="user">
                <img className="user--avatar" src={!owner.avatar_url ? DefaultAvatar : owner.avatar_url} alt="avatar" />
                <div>
                  <span className="user--name">{getNameDisplay(owner.first_name, owner.last_name)}</span>
                  <span className="user--username">{owner.user_name}</span>
                </div>
              </div>
              <label>{Multilingual(`COOPERATE.OWNER`)}</label>
            </div>
            <div className="row">
              {Multilingual(`COOPERATE.LIMIT_HINT_PREFIX`)}
              {PremiumEditorLimit - 1}
              {Multilingual(`COOPERATE.LIMIT_HINT_SUFFIX`)}
            </div>
            {editors.map((editor) => (
              <div className="row">
                <div className="user">
                  <img
                    className="user--avatar"
                    src={!editor.avatar_url ? DefaultAvatar : editor.avatar_url}
                    alt="avatar"
                  />
                  <div>
                    <span className="user--name">{getNameDisplay(editor.first_name, editor.last_name)}</span>
                    <span className="user--username">{editor.user_name}</span>
                  </div>
                </div>
                <Button
                  width="unset"
                  mini
                  color="red"
                  disabled={apiInProgress}
                  onClick={handleRemoveEditor(editor.uniqid)}
                >
                  {Multilingual(`REMOVE`)}
                </Button>
              </div>
            ))}
            {editors.length < PremiumEditorLimit - 1 && (
              <div className="row">
                <Input
                  placeholder={Multilingual(`COOPERATE.INPUT_PLACEHOLDER`)}
                  textAlign="left"
                  value={newEditorValue}
                  onChange={handleNewEditorValueChange}
                  disabled={apiInProgress}
                />
                <Button width="unset" mini color="red" nowrap disabled={apiInProgress} onClick={handleAddEditor}>
                  {Multilingual(`CREATE`)}
                </Button>
              </div>
            )}
          </StyledDiv>
        )}
      </Modal.ModalBody>
      <Modal.ModalFooter>
        <Button width="5rem" color="blue" onClick={toClose} disabled={apiInProgress}>
          {Multilingual(`CLOSE`)}
        </Button>
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

export default Cooperator;
