import { Fragment } from 'react';
import styled from 'styled-components';
import Modal from '~~elements/Modal';
import IconCircleChecked from '~~elements/static/IconCircleChecked.svg';
import { PHONE_WIDTH, COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import Tooltip from '~~elements/Tooltip';
import { MonthlyQuota, AnnualQuota, PremiumBallLimit, PremiumEditorLimit } from '../../Const';

const StyledDiv = styled.div`
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  color: black;
  .intro {
    letter-spacing: 1px;
    line-height: 1.3;
    font-size: 0.9rem;
    white-space: pre-line;
    margin-bottom: 0.5rem;
    text-align: start;
    width: 100%;
    background-color: ${COLOR.secondary};
    color: white;
    padding: 0.3rem;
    border-radius: 10px 0 10px 0;
    text-indent: 2rem;
  }
  .description {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: center;
    align-items: center;
    border: 1px solid ${COLOR.secondary}60;
    border-radius: 0 10px 0 0;
    border-bottom: 0;
  }
  .price {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: center;
    align-items: center;
    border: 1px solid ${COLOR.primary}60;
    border-radius: 0 0 0 10px;
    border-top: 0;
  }

  .plan-name {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0.25rem 0;
  }
  .feature-name {
    font-size: 1.1rem;
    letter-spacing: 1px;
    width: 100%;
    > span {
      width: 100%;
      height: 100%;
      border-right: 1px solid ${COLOR.secondary}60;
    }
  }
  .feature-row {
    height: 100%;
    padding: 0.5rem 0;
    border-top: 1px solid ${COLOR.secondary}60;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${PHONE_WIDTH}) {
    padding: 0.1rem;
    .plan-name {
      font-size: 1rem;
      letter-spacing: 1px;
    }
    .feature-name {
      font-size: 0.9rem;
      letter-spacing: 0px;
    }
    .feature-row {
      font-size: 0.8rem;
      padding: 0.25rem 0;
    }
  }
`;

const CircleChecked = () => <img src={IconCircleChecked} alt="checked" style={{ width: '1.1rem', height: '1.1rem' }} />;

const planFeatures = [
  {
    name: '逐球內容',
  },
  {
    name: '打席結果',
  },
  {
    name: '擊球內容',
  },
  // {
  //   name: '偏移校正',
  //   tooltip: '依照電子進壘增加人眼進壘的準確性',
  // },
  {
    name: '分析報告',
  },
  {
    name: '進壘視角',
    premiumContent: '投手/捕手',
  },
  {
    name: '球數上限',
    premiumContent: PremiumBallLimit,
  },
  {
    name: '瀏覽權限',
    premiumContent: '公開/非公開',
    tooltip: '非公開僅能透過連結開啟',
  },
  {
    name: '資料匯出',
  },
  {
    name: '共同編輯',
    premiumContent: `最多${PremiumEditorLimit}人`,
    tooltip: '編輯者需同為訂閱用戶',
  },
  {
    name: '場次上限',
    monthly: MonthlyQuota,
    annual: AnnualQuota,
    unlimited: '-',
  },
];

const StrikezoneGameCompare = ({ isOpen, toClose }) => {
  return (
    <Modal.Modal isOpen={isOpen} onClickBack={toClose} noPadding={true}>
      <Modal.ModalHeader hasBack={true} onClickBack={toClose}>
        好球帶方案
      </Modal.ModalHeader>
      <Modal.ModalBody>
        <StyledDiv>
          <div className="intro">
            {`透過 Rebas 好球帶，記錄球速、球種、進壘點等資訊，分析投手投球、打者進攻、甚至捕手配球的各項優劣。`}
            {`假使沒有電子好球帶，依然能透過九宮格看出內外高低的配球內容。活用好球帶，發表你/妳最獨到的見解吧！`}
          </div>
          <div className="description">
            <div>功能/方案</div>
            <div className="plan-name">單月</div>
            <div className="plan-name">年度</div>
            <div className="plan-name">無上限</div>
            {planFeatures.map((feature) => (
              <Fragment key={feature.name}>
                <div className="feature-row feature-name">
                  <span>
                    {feature.name}
                    {feature.tooltip && <Tooltip width="0.9rem" height="0.9rem" content={feature.tooltip} />}
                  </span>
                </div>
                {['monthly', 'annual', 'unlimited'].map((planType) => (
                  <div key={planType} className="feature-row">
                    {feature[planType]
                      ? feature[planType]
                      : feature.premiumContent
                      ? feature.premiumContent
                      : CircleChecked()}
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
          <div className="price">
            <div className="feature-row feature-name">
              <span>費用</span>
            </div>
            <div className="feature-row">899 NT$/月</div>
            <div className="feature-row">10,700 NT$/年</div>
            <div className="feature-row">19,999 NT$/年</div>
          </div>
        </StyledDiv>
      </Modal.ModalBody>
      <Modal.ModalFooter>
        <a href={`${process.env.REACT_APP_ACCOUNT_URL}/shop/plugin`} style={{ width: '100%' }}>
          <Button>前往訂閱頁面</Button>
        </a>
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

export default StrikezoneGameCompare;
