import { useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import Button from '~~elements/Button';
import Select from '~~elements/Select';
import { getOpponents } from '../utils/base';
import { useFilterState, useFilterDispatch } from '../hooks/useFilter';
import Multilingual from '~~utils/Multilingual';

const StyledDiv = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  .panel {
    width: 960px;
    max-width: 96%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: flex-start;
    justify-content: start;
  }
  .filter {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 1rem;
    &--name {
      text-align: start;
      width: 5rem;
      white-space: nowrap;
      font-size: 1.1rem;
      margin: 0.2rem 0;
      padding: 0 0.3rem 0 0;
      border-right: 1px solid gray;
    }
    .options {
      width: calc(100% - 6rem);
      display: flex;
      justify-content: start;
      align-items: center;
      row-gap: 0.8rem;
      column-gap: 0.5rem;
      flex-wrap: wrap;

      input {
        margin-right: 4px;
      }
    }
  }
`;

const zoneWording = {
  shadow: {
    'zh-TW': '邊線區',
    en: 'Shadow',
  },
  chase: {
    'zh-TW': '追打區',
    en: 'Chase',
  },
  waste: {
    'zh-TW': '超級遠',
    en: 'Waste',
  },
};

const batterActionWording = {
  NONE: {
    'zh-TW': '無',
    en: 'None',
  },
  SWMISS: {
    'zh-TW': '揮空',
    en: 'Swing Miss',
  },

  contact: {
    'zh-TW': '擊球',
    en: 'Contact',
  },
  // SWTIP: {
  //   'zh-TW': '擦棒球',
  //   en: 'Swing Tip',
  // },
  // INFIELD: {
  //   'zh-TW': '球入場',
  //   en: 'Inplay',
  // },
  // OUTFIELD: {
  //   'zh-TW': '球界外',
  //   en: 'Foul',
  // },
  // others: {
  //   'zh-TW': '其他',
  //   en: 'Other',
  // },
};

const batterResultWording = {
  NONE: {
    'zh-TW': '無',
    en: 'None',
  },
  '1B': {
    'zh-TW': '1B',
    en: '1B',
  },
  '2B': {
    'zh-TW': '2B',
    en: '2B',
  },
  '3B': {
    'zh-TW': '3B',
    en: '3B',
  },
  HR: {
    'zh-TW': 'HR',
    en: 'HR',
  },
  SO: {
    'zh-TW': 'SO',
    en: 'SO',
  },
  BB: {
    'zh-TW': '保送',
    en: 'BB',
  },
  outs: {
    'zh-TW': '出局',
    en: 'Outs',
  },
  others: {
    'zh-TW': '其他',
    en: 'Others',
  },
  // E: 'E',
  // FC: 'FC',
  // GO: 'GO',
  // FO: 'FO',
  // GIDP: 'GIDP',
  // SH: 'SH',
  // SF: 'SF',
  // uBB: 'uBB',
  // IBB: 'IBB',
  // HBP: 'HBP',
  // IR: 'IR',
  // IH: 'IH',
};

const contactWording = {
  S: {
    'zh-TW': '弱',
    en: 'Soft',
  },
  H: {
    'zh-TW': '強',
    en: 'Hard',
  },
};

const ballCountWording = {
  first: {
    'zh-TW': '首球',
    en: 'First',
  },
  twoStrike: {
    'zh-TW': '兩好球',
    en: '2 Strikes',
  },
  threeBall: {
    'zh-TW': '三壞球',
    en: '3 Balls',
  },
  full: {
    'zh-TW': '滿球數',
    en: 'Full',
  },
  pitcherLead: {
    'zh-TW': '投手領先',
    en: 'Pitcher Lead',
  },
  batterLead: {
    'zh-TW': '打者領先',
    en: 'Batter Lead',
  },
  even: {
    'zh-TW': '持平（非首球）',
    en: 'Even(Not First)',
  },
};

const optionConditionColumns = [
  'GAME.PLAYER_FACING',
  'GAME.COMMON_HAND',
  'GAME.INNING',
  'BASE.WORDING',
  'GAME.OUT',
  'GAME.ZONE',
  'GAME.BATTER_ACTION',
  'JUDGE_CALL.WORDING',
  'PITCH_TYPE.WORDING',
  'PITCH_DETAIL_TYPE.WORDING',
  'GAME.BATTER_RESULT',
  'GAME.CONTACT',
  'GAME.PA_BALL_COUNT',
];

export default function Filter({ gameInfo }) {
  const [tmpCondition, setTmpCondition] = useState('');
  const [conditions, setConditions] = useState([]);

  const {
    side,
    position,
    playersMap,
    liveMode,
    selectedPlayers,
    selectedOpponents,
    stickPlayer,
    innings,
    selectedInnings,
    bases,
    selectedBases,
    outs,
    selectedOuts,
    zones,
    selectedZones,
    hands,
    selectedHands,
    batterActions,
    selectedBatterActions,
    judgeCalls,
    selectedJudgeCalls,
    ballTypes,
    selectedBallTypes,
    ballDetailTypes,
    selectedBallDetailTypes,
    batterResults,
    selectedBatterResults,
    contacts,
    selectedContacts,
    ballCounts,
    selectedBallCounts,
  } = useFilterState();
  const dispatch = useFilterDispatch();
  const players = playersMap[side][position];
  const opponents = getOpponents(playersMap, side, position);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_PERBALL',
      perBall: gameInfo.perBall,
    });
  }, [dispatch, gameInfo.perBall]);

  const handleLiveCheckBoxChange = (e) => {
    dispatch({
      type: 'CHANGE_LIVE_MODE',
      liveMode: e.target.value === 'On',
    });
  };

  const handlePlayerCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_PLAYER',
      selectedPlayer: e.target.value,
      selectedIndex,
    });

  const handleOpponentCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_OPPONENT',
      selectedOpponent: e.target.value,
      selectedIndex,
    });

  const handleInningCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_INNING',
      selectedInning: e.target.value,
      selectedIndex,
    });

  const handleBaseCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_BASE',
      selectedBase: e.target.value,
      selectedIndex,
    });

  const handleOutCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_OUT',
      selectedOut: e.target.value,
      selectedIndex,
    });

  const handleZoneCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_ZONE',
      selectedZone: e.target.value,
      selectedIndex,
    });

  const handleHandCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_HAND',
      selectedHand: e.target.value,
      selectedIndex,
    });

  const handleBatterActionCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_BATTER_ACTION',
      selectedBatterAction: e.target.value,
      selectedIndex,
    });

  const handleJudgeCallCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_JUDGE_CALL',
      selectedJudgeCall: e.target.value,
      selectedIndex,
    });

  const handlePitchTypeCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_BALL_TYPE',
      selectedBallType: e.target.value,
      selectedIndex,
    });

  const handlePitchDetailTypeCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_BALL_DETAIL_TYPE',
      selectedBallDetailType: e.target.value,
      selectedIndex,
    });

  const handleBatterResultCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_BATTER_RESULT',
      selectedBatterResult: e.target.value,
      selectedIndex,
    });

  const handleContactCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_CONTACT',
      selectedContact: e.target.value,
      selectedIndex,
    });

  const handleBallCountCheckBoxChange = (selectedIndex) => (e) =>
    dispatch({
      type: 'CHANGE_SELECTED_BALL_COUNT',
      selectedBallCount: e.target.value,
      selectedIndex,
    });

  const handlePlayersSelectAll = () => dispatch({ type: 'SELECT_ALL_PLAYERS' });
  const handlePlayersEmptyAll = () => dispatch({ type: 'EMPTY_ALL_PLAYERS' });

  const handleOpponentSelectAll = () => dispatch({ type: 'SELECT_ALL_OPPONENTS' });
  const handleOpponentEmptyAll = () => dispatch({ type: 'EMPTY_ALL_OPPONENTS' });

  const handleInningSelectAll = () => dispatch({ type: 'SELECT_ALL_INNINGS' });
  const handleInningEmptyAll = () => dispatch({ type: 'EMPTY_ALL_INNINGS' });

  const handleBaseSelectAll = () => dispatch({ type: 'SELECT_ALL_BASES' });
  const handleBaseEmptyAll = () => dispatch({ type: 'EMPTY_ALL_BASES' });

  const handleOutSelectAll = () => dispatch({ type: 'SELECT_ALL_OUTS' });
  const handleOutEmptyAll = () => dispatch({ type: 'EMPTY_ALL_OUTS' });

  const handleHandSelectAll = () => dispatch({ type: 'SELECT_ALL_HANDS' });
  const handleHandEmptyAll = () => dispatch({ type: 'EMPTY_ALL_HANDS' });

  const handleZoneSelectAll = () => dispatch({ type: 'SELECT_ALL_ZONES' });
  const handleZoneEmptyAll = () => dispatch({ type: 'EMPTY_ALL_ZONES' });

  const handleBatterActionSelectAll = () => dispatch({ type: 'SELECT_ALL_BATTER_ACTIONS' });
  const handleBatterActionEmptyAll = () => dispatch({ type: 'EMPTY_ALL_BATTER_ACTIONS' });

  const handleJudgeCallSelectAll = () => dispatch({ type: 'SELECT_ALL_JUDGE_CALLS' });
  const handleJudgeCallEmptyAll = () => dispatch({ type: 'EMPTY_ALL_JUDGE_CALLS' });

  const handlePitchTypeSelectAll = () => dispatch({ type: 'SELECT_ALL_BALL_TYPES' });
  const handlePitchTypeEmptyAll = () => dispatch({ type: 'EMPTY_ALL_BALL_TYPES' });

  const handlePitchDetailTypeSelectAll = () => dispatch({ type: 'SELECT_ALL_BALL_DETAIL_TYPES' });
  const handlePitchDetailTypeEmptyAll = () => dispatch({ type: 'EMPTY_ALL_BALL_DETAIL_TYPES' });

  const handleBatterResultSelectAll = () => dispatch({ type: 'SELECT_ALL_BATTER_RESULTS' });
  const handleBatterResultEmptyAll = () => dispatch({ type: 'EMPTY_ALL_BATTER_RESULTS' });

  const handleContactSelectAll = () => dispatch({ type: 'SELECT_ALL_CONTACTS' });
  const handleContactEmptyAll = () => dispatch({ type: 'EMPTY_ALL_CONTACTS' });

  const handleBallCountSelectAll = () => dispatch({ type: 'SELECT_ALL_BALL_COUNTS' });
  const handleBallCountEmptyAll = () => dispatch({ type: 'EMPTY_ALL_BALL_COUNTS' });

  const optionConditionName = (col, selectAll, nameDisplay = '') => (
    <span key={col} className="filter--name">
      {nameDisplay !== '' ? nameDisplay : Multilingual(col)}
      <br />
      <Button
        mini
        nowrap
        color="transparentWithBorder"
        onClick={() => {
          selectAll();
          setConditions((pre) => pre.filter((c) => c !== col));
        }}
      >
        {Multilingual(`GAME.REMOVE_FILTER_COLUMN`)}
      </Button>
    </span>
  );

  return (
    <StyledDiv>
      <div className="panel">
        {!!gameInfo.isLive && (
          <div className="filter">
            <span className="filter--name">{Multilingual(`GAME.LIVE_MODE`)}</span>
            <div className="options">
              <label htmlFor={`hand-selected-live-on`}>
                <input
                  id={`hand-selected-live-on`}
                  type="checkbox"
                  name="hand"
                  value="On"
                  onChange={handleLiveCheckBoxChange}
                  checked={liveMode}
                />
                On
              </label>
              <label htmlFor="hand-selected-live-off">
                <input
                  id="hand-selected-live-off"
                  type="checkbox"
                  name="hand"
                  value="Off"
                  onChange={handleLiveCheckBoxChange}
                  checked={!liveMode}
                />
                Off
              </label>
            </div>
          </div>
        )}
        {!stickPlayer && (
          <Fragment>
            <div className="filter">
              <Button
                width="unset"
                nowrap
                color={side === 'away' ? 'red' : 'blue'}
                value="away"
                onClick={(e) => dispatch({ type: 'SELECT_SIDE', side: e.target.value })}
                disabled={liveMode}
              >
                {gameInfo.away}
              </Button>
              <Button
                width="unset"
                nowrap
                color={side === 'home' ? 'red' : 'blue'}
                value="home"
                onClick={(e) => dispatch({ type: 'SELECT_SIDE', side: e.target.value })}
                disabled={liveMode}
              >
                {gameInfo.home}
              </Button>
            </div>
            <div className="filter">
              <span className="filter--name">{Multilingual(`GAME.ANALYTICAL_DIRECTION`)}</span>
              <div className="options">
                <label htmlFor="filter-pitcher">
                  <input
                    id="filter-pitcher"
                    type="radio"
                    name="filter"
                    value="pitcher"
                    onChange={(e) => dispatch({ type: 'SELECT_POSITION', position: e.target.value })}
                    checked={position === 'pitcher'}
                    disabled={liveMode}
                  />
                  {Multilingual(`PLAYER_ANALYTICS.TYPE_PITCHER`)}
                </label>
                <label htmlFor="filter-fielder">
                  <input
                    id="filter-fielder"
                    type="radio"
                    name="filter"
                    value="fielder"
                    checked={position === 'fielder'}
                    onChange={(e) => dispatch({ type: 'SELECT_POSITION', position: e.target.value })}
                    disabled={liveMode}
                  />
                  {Multilingual(`PLAYER_ANALYTICS.TYPE_BATTER`)}
                </label>
              </div>
            </div>
            <div className="filter">
              <span className="filter--name">{Multilingual(`GAME.ANALYTICAL_PLAYER`)}</span>
              <div className="options">
                {players.map((player, index) => (
                  <label key={player} htmlFor={`player-selected-${side}-${position}-${index}`}>
                    <input
                      id={`player-selected-${side}-${position}-${index}`}
                      key={`player-selected-${side}-${position}-${index}`}
                      type="checkbox"
                      name="player"
                      value={player}
                      onChange={handlePlayerCheckBoxChange(index)}
                      checked={selectedPlayers.includes(player)}
                      disabled={liveMode}
                    />
                    {player || Multilingual(`NULL`)}
                  </label>
                ))}
                <Button
                  mini
                  width="unset"
                  color="transparentWithBorder"
                  onClick={handlePlayersSelectAll}
                  disabled={liveMode}
                >
                  {Multilingual(`GAME.SELECT_ALL`)}
                </Button>
                <Button
                  mini
                  width="unset"
                  color="transparentWithBorder"
                  onClick={handlePlayersEmptyAll}
                  disabled={liveMode}
                >
                  {Multilingual(`GAME.EMPTY_ALL`)}
                </Button>
              </div>
            </div>
          </Fragment>
        )}
        <div className="filter">
          <span className="filter--name">{Multilingual(`GAME.COLUMN`)}</span>
          <div className="options">
            <Select value={tmpCondition} onChange={(e) => setTmpCondition(e.target.value)}>
              <option value="">{Multilingual(`NONE`)}</option>
              {optionConditionColumns
                .filter((col) => !conditions.includes(col))
                .map((col) => (
                  <option key={col} value={col}>
                    {Multilingual(col)}
                  </option>
                ))}
            </Select>
            <Button
              width="unset"
              color="blue"
              onClick={() => {
                setConditions((pre) => [...pre, tmpCondition]);
                setTmpCondition('');
              }}
              disabled={!tmpCondition}
            >
              {Multilingual(`GAME.ADD_FILTER_COLUMN`)}
            </Button>
          </div>
        </div>
        {conditions.includes('GAME.PLAYER_FACING') && (
          <div className="filter">
            {optionConditionName('GAME.PLAYER_FACING', handleOpponentSelectAll)}
            <div className="options">
              {opponents.map((opponent, index) => (
                <label key={opponent} htmlFor={`opponent-selected-${side}-${position}-${index}`}>
                  <input
                    id={`opponent-selected-${side}-${position}-${index}`}
                    key={`opponent-selected-${side}-${position}-${index}`}
                    type="checkbox"
                    name="opponent"
                    value={opponent}
                    onChange={handleOpponentCheckBoxChange(index)}
                    checked={selectedOpponents.includes(opponent)}
                    disabled={liveMode}
                  />
                  {opponent || Multilingual(`NULL`)}
                </label>
              ))}
              <Button
                mini
                width="unset"
                color="transparentWithBorder"
                onClick={handleOpponentSelectAll}
                disabled={liveMode}
              >
                {Multilingual(`GAME.SELECT_ALL`)}
              </Button>
              <Button
                mini
                width="unset"
                color="transparentWithBorder"
                onClick={handleOpponentEmptyAll}
                disabled={liveMode}
              >
                {Multilingual(`GAME.EMPTY_ALL`)}
              </Button>
            </div>
          </div>
        )}
        {!liveMode && (
          <Fragment>
            {conditions.includes('GAME.COMMON_HAND') && (
              <div className="filter">
                {optionConditionName(
                  'GAME.COMMON_HAND',
                  handleHandSelectAll,
                  Multilingual(`GAME.${position === 'pitcher' ? 'BATTING' : 'PITCHING'}_HAND`),
                )}
                <div className="options">
                  {hands.map((hand, index) => (
                    <label key={hand} htmlFor={`hand-selected-${side}-${position}-${index}`}>
                      <input
                        id={`hand-selected-${side}-${position}-${index}`}
                        key={`hand-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="hand"
                        value={hand}
                        onChange={handleHandCheckBoxChange(index)}
                        checked={selectedHands.includes(hand)}
                      />
                      {hand || Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleHandSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleHandEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.INNING') && (
              <div className="filter">
                {optionConditionName('GAME.INNING', handleInningSelectAll)}
                <div className="options">
                  {innings.map((inning, index) => (
                    <label key={inning} htmlFor={`inning-selected-${side}-${position}-${index}`}>
                      <input
                        id={`inning-selected-${side}-${position}-${index}`}
                        key={`inning-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="inning"
                        value={inning}
                        onChange={handleInningCheckBoxChange(index)}
                        checked={selectedInnings.includes(inning)}
                      />
                      {inning || Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleInningSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleInningEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('BASE.WORDING') && (
              <div className="filter">
                {optionConditionName('BASE.WORDING', handleBaseSelectAll)}
                <div className="options">
                  {bases.map((base, index) => (
                    <label key={base} htmlFor={`base-selected-${side}-${position}-${index}`}>
                      <input
                        id={`base-selected-${side}-${position}-${index}`}
                        key={`base-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="base"
                        value={base}
                        onChange={handleBaseCheckBoxChange(index)}
                        checked={selectedBases.includes(base)}
                      />
                      {base ? Multilingual(`BASE.${base}`) : Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBaseSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBaseEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.OUT') && (
              <div className="filter">
                {optionConditionName('GAME.OUT', handleOutSelectAll)}
                <div className="options">
                  {outs.map((out, index) => (
                    <label key={out} htmlFor={`out-selected-${side}-${position}-${index}`}>
                      <input
                        id={`out-selected-${side}-${position}-${index}`}
                        key={`out-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="out"
                        value={out}
                        onChange={handleOutCheckBoxChange(index)}
                        checked={selectedOuts.includes(out)}
                      />
                      {out || Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleOutSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleOutEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.ZONE') && (
              <div className="filter">
                {optionConditionName('GAME.ZONE', handleZoneSelectAll)}
                <div className="options">
                  {zones.map((zone, index) => (
                    <label key={zone} htmlFor={`zone-selected-${side}-${position}-${index}`}>
                      <input
                        id={`zone-selected-${side}-${position}-${index}`}
                        key={`zone-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="zone"
                        value={zone}
                        onChange={handleZoneCheckBoxChange(index)}
                        checked={selectedZones.includes(zone)}
                      />
                      {zoneWording[zone] ? Multilingual(zone, zoneWording) : zone || Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleZoneSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleZoneEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.BATTER_ACTION') && (
              <div className="filter">
                {optionConditionName('GAME.BATTER_ACTION', handleBatterActionSelectAll)}
                <div className="options">
                  {batterActions.map((batterAction, index) => (
                    <label key={batterAction} htmlFor={`batteraction-selected-${side}-${position}-${index}`}>
                      <input
                        id={`batteraction-selected-${side}-${position}-${index}`}
                        key={`batteraction-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="batteraction"
                        value={batterAction}
                        onChange={handleBatterActionCheckBoxChange(index)}
                        checked={selectedBatterActions.includes(batterAction)}
                      />
                      {batterActionWording[batterAction]
                        ? Multilingual(batterAction, batterActionWording)
                        : batterAction || Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBatterActionSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBatterActionEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('JUDGE_CALL.WORDING') && (
              <div className="filter">
                {optionConditionName('JUDGE_CALL.WORDING', handleJudgeCallSelectAll)}
                <div className="options">
                  {judgeCalls.map((judgeCall, index) => (
                    <label key={judgeCall} htmlFor={`judgeCall-selected-${side}-${position}-${index}`}>
                      <input
                        id={`judgeCall-selected-${side}-${position}-${index}`}
                        key={`judgeCall-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="judgeCall"
                        value={judgeCall}
                        onChange={handleJudgeCallCheckBoxChange(index)}
                        checked={selectedJudgeCalls.includes(judgeCall)}
                      />
                      {judgeCall ? Multilingual(`JUDGE_CALL.${judgeCall}`) : Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleJudgeCallSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleJudgeCallEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('PITCH_TYPE.WORDING') && (
              <div className="filter">
                {optionConditionName('PITCH_TYPE.WORDING', handlePitchTypeSelectAll)}
                <div className="options">
                  {ballTypes.map((ballType, index) => (
                    <label key={ballType} htmlFor={`ballType-selected-${side}-${position}-${index}`}>
                      <input
                        id={`ballType-selected-${side}-${position}-${index}`}
                        key={`ballType-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="ballType"
                        value={ballType}
                        onChange={handlePitchTypeCheckBoxChange(index)}
                        checked={selectedBallTypes.includes(ballType)}
                      />
                      {ballType ? Multilingual(`PITCH_TYPE.${ballType}`) : Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handlePitchTypeSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handlePitchTypeEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('PITCH_DETAIL_TYPE.WORDING') && (
              <div className="filter">
                {optionConditionName('PITCH_DETAIL_TYPE.WORDING', handlePitchDetailTypeSelectAll)}
                <div className="options">
                  {ballDetailTypes.map((ballDetailType, index) => (
                    <label key={ballDetailType} htmlFor={`ballDetailType-selected-${side}-${position}-${index}`}>
                      <input
                        id={`ballDetailType-selected-${side}-${position}-${index}`}
                        key={`ballDetailType-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="ballDetailType"
                        value={ballDetailType}
                        onChange={handlePitchDetailTypeCheckBoxChange(index)}
                        checked={selectedBallDetailTypes.includes(ballDetailType)}
                      />
                      {ballDetailType ? Multilingual(`PITCH_DETAIL_TYPE.${ballDetailType}`) : Multilingual(`NULL`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handlePitchDetailTypeSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handlePitchDetailTypeEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.BATTER_RESULT') && (
              <div className="filter">
                {optionConditionName('GAME.BATTER_RESULT', handleBatterResultSelectAll)}
                <div className="options">
                  {batterResults.map((batterResult, index) => (
                    <label key={batterResult} htmlFor={`batterResult-selected-${side}-${position}-${index}`}>
                      <input
                        id={`batterResult-selected-${side}-${position}-${index}`}
                        key={`batterResult-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="batterResult"
                        value={batterResult}
                        onChange={handleBatterResultCheckBoxChange(index)}
                        checked={selectedBatterResults.includes(batterResult)}
                      />
                      {batterResultWording[batterResult]
                        ? Multilingual(batterResult, batterResultWording)
                        : batterResult || Multilingual(`GAME.NO_RESULT`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBatterResultSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBatterResultEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.CONTACT') && (
              <div className="filter">
                {optionConditionName('GAME.CONTACT', handleContactSelectAll)}
                <div className="options">
                  {contacts.map((contact, index) => (
                    <label key={contact} htmlFor={`contact-selected-${side}-${position}-${index}`}>
                      <input
                        id={`contact-selected-${side}-${position}-${index}`}
                        key={`contact-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="contact"
                        value={contact}
                        onChange={handleContactCheckBoxChange(index)}
                        checked={selectedContacts.includes(contact)}
                      />
                      {contactWording[contact]
                        ? Multilingual(contact, contactWording)
                        : contact || Multilingual(`GAME.NO_RESULT`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleContactSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleContactEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
            {conditions.includes('GAME.PA_BALL_COUNT') && (
              <div className="filter">
                {optionConditionName('GAME.PA_BALL_COUNT', handleBallCountSelectAll)}
                <div className="options">
                  {ballCounts.map((ballCount, index) => (
                    <label key={ballCount} htmlFor={`ballCount-selected-${side}-${position}-${index}`}>
                      <input
                        id={`ballCount-selected-${side}-${position}-${index}`}
                        key={`ballCount-selected-${side}-${position}-${index}`}
                        type="checkbox"
                        name="ballCount"
                        value={ballCount}
                        onChange={handleBallCountCheckBoxChange(index)}
                        checked={selectedBallCounts.includes(ballCount)}
                      />
                      {ballCountWording[ballCount]
                        ? Multilingual(ballCount, ballCountWording)
                        : ballCount || Multilingual(`GAME.NO_RESULT`)}
                    </label>
                  ))}
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBallCountSelectAll}>
                    {Multilingual(`GAME.SELECT_ALL`)}
                  </Button>
                  <Button mini width="unset" color="transparentWithBorder" onClick={handleBallCountEmptyAll}>
                    {Multilingual(`GAME.EMPTY_ALL`)}
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </StyledDiv>
  );
}
