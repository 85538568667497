import { Fragment } from 'react';
import { getZonemap, getZoneStat } from '../utils/zone';
import { BallTypeWording, BallTypeENWording, getBallTypeStyle } from '../constants/base';

const getBatterTable = (resolvedPerBall) => {
  const { heart, shadow, chase, waste } = getZonemap(resolvedPerBall);
  const zoneMap = {
    ...heart,
    shadow: shadow.inside,
    chase: chase.inside,
    waste,
  };

  return getZoneStat(resolvedPerBall, zoneMap);
};

const renderStatsRow = (stat, ballType, split, isPreHalf) => {
  const preHalf = (
    <Fragment>
      <td>{stat['zone']}</td>
      <td>{stat['zoneSwing']}</td>
      <td>{stat['zoneContact']}</td>
      <td>{stat['chase']}</td>
      <td>{stat['chaseContact']}</td>
    </Fragment>
  );

  const postHalf = (
    <Fragment>
      <td>{stat['swing']}</td>
      <td>{stat['whiff']}</td>
    </Fragment>
  );

  return (
    <tr className={ballType === 'all' ? 'total' : ''}>
      <td style={getBallTypeStyle(ballType)}>
        <span>{BallTypeWording[ballType ? ballType : 'NONE']}</span>
        <span>{BallTypeENWording[ballType ? ballType : 'NONE']}</span>
      </td>
      {!split ? (
        <Fragment>
          {preHalf}
          {postHalf}
        </Fragment>
      ) : isPreHalf ? (
        preHalf
      ) : (
        postHalf
      )}
    </tr>
  );
};

const THPreHalf = (
  <Fragment>
    <th>
      <span>好球率</span>
      <span>In Zone %</span>
    </th>
    <th>
      <span>好球帶出棒率</span>
      <span>In Zone Swing %</span>
    </th>
    <th>
      <span>好球帶擊球率</span>
      <span>Zone Contact %</span>
    </th>
    <th>
      <span>追打區</span>
      <span>Chase %</span>
    </th>
    <th>
      <span>追打區擊球率</span>
      <span>Chase Contact %</span>
    </th>
  </Fragment>
);

const THPostHalf = (
  <Fragment>
    <th>
      <span>出棒率</span>
      <span>Swing %</span>
    </th>
    <th>
      <span>揮空率</span>
      <span>Whiff%</span>
    </th>
  </Fragment>
);

export default function BatterTable({ resolvedPerBall, stats, split }) {
  return (
    <Fragment>
      <table>
        <thead>
          <tr>
            <th>
              <span>球種</span>
              <span>Pitch Type</span>
            </th>
            {THPreHalf}
            {!split && THPostHalf}
          </tr>
        </thead>
        <tbody>
          {renderStatsRow(getBatterTable(stats['F']), 'F', split, true)}
          {renderStatsRow(getBatterTable(stats['B']), 'B', split, true)}
          {renderStatsRow(getBatterTable(stats['C']), 'C', split, true)}
          {renderStatsRow(getBatterTable(stats['others'], ''), '', split, true)}
          {renderStatsRow(getBatterTable(stats['all'], ''), 'all', split, true)}
        </tbody>
      </table>
      {split && (
        <table>
          <thead>
            <tr>
              <th>
                <span>球種</span>
                <span>Pitch Type</span>
              </th>
              {THPostHalf}
            </tr>
          </thead>
          <tbody>
            {renderStatsRow(getBatterTable(stats['F']), 'F', split, false)}
            {renderStatsRow(getBatterTable(stats['B']), 'B', split, false)}
            {renderStatsRow(getBatterTable(stats['C']), 'C', split, false)}
            {renderStatsRow(getBatterTable(stats['others'], ''), '', split, false)}
            {renderStatsRow(getBatterTable(stats['all'], ''), 'all', split, false)}
          </tbody>
        </table>
      )}
    </Fragment>
  );
}
