import styled from 'styled-components';
import { strikeZoneConfig } from '../../StrikezoneGame/constants/base';
import { useLocalState } from '../useLocal';

const StyledDiv = styled.div.attrs((props) => ({
  style: {
    cursor: props.resizable ? 'grab' : props.clickable ? 'crosshair' : 'default',
  },
}))`
  display: block;
  background: rgba(255, 255, 255, 0.3);
  width: ${strikeZoneConfig.width / 3}px;
  height: ${strikeZoneConfig.height / 3}px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`;

const StrikeStrikeZone = ({ id, resizable }) => {
  const { pitchIndex } = useLocalState();

  return <StyledDiv id={id} resizable={resizable} clickable={pitchIndex >= 0} />;
};

export default StrikeStrikeZone;
