import React, { useState } from 'react';
import styled from 'styled-components';
import heat from 'simpleheat';
import Button from '~~elements/Button';
import CHomePlate from '../../static/HomePlate.png';
import PHomePlate from '../../static/PitcherAngle-HomePlate.png';
import { CSVToArray } from '~~commonUtils';

const StyledDiv = styled.div`
  padding: 1.2rem;
`;

// # 以毫米為單位
// # ref: http://tangotiger.net/strikezone/zone%20chart.png
// # 以 Strike Zone 減去球體直徑換算而成（上下範圍以大聯盟平均標準而定）
const HOMEPLATE_WIDTH = 431.8;
const STRIKEZONE_TOP = 1028.7;
const STRIKEZONE_BOTTOM = 495.3;
const STRIKEZONE_RATIO = (STRIKEZONE_TOP - STRIKEZONE_BOTTOM) / HOMEPLATE_WIDTH;

const HEATMAP_HEIGHT = HOMEPLATE_WIDTH * STRIKEZONE_RATIO + STRIKEZONE_BOTTOM * 2;
const HEATMAP_WIDTH = HEATMAP_HEIGHT / STRIKEZONE_RATIO;
const HEATMAP_STRIKE_DELTA_X = (HEATMAP_WIDTH - HOMEPLATE_WIDTH) / 2;
const HEATMAP_STRIKE_DELTA_Y = (HEATMAP_HEIGHT - HOMEPLATE_WIDTH * STRIKEZONE_RATIO) / 2;

const CANVAS_WIDTH = HEATMAP_WIDTH;
const CANVAS_HEIGHT = HEATMAP_HEIGHT + 70; // 留一點給本壘板
const PADDING = 10;

const TO_HEATMAP_DELTA_X = HEATMAP_WIDTH / 2;

const HeatmapAdaptor = () => {
  const [csvRefresh, setCSVRefresh] = useState(0);
  const [firstRowHeading, setFirstRowHeading] = useState(true);

  const [csvData, setCSVData] = useState([]);
  const [xRow, setXRow] = useState('');
  const [yRow, setYRow] = useState('');

  const handleCSVRead = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const CSVRows = CSVToArray(event.target.result);
      if (CSVRows.length <= 1) {
        return;
      }

      setCSVData(CSVRows);
    };
    reader.readAsText(file, 'BIG5');
    setCSVRefresh((pre) => pre + 1);
  };

  const validCSV = () => {
    if (csvData.length === 0) {
      return false;
    }
    const xRowInt = parseInt(xRow);
    const yRowInt = parseInt(yRow);
    if (Number.isNaN(xRowInt) || Number.isNaN(yRowInt)) {
      return false;
    }

    if (xRowInt >= csvData[0].length || yRowInt >= csvData[0].length) {
      return false;
    }

    return true;
  };

  const drawLine = (ctx) => {
    // 不用畫外框
    // ctx.beginPath();
    // ctx.rect(0, 0, HEATMAP_WIDTH - 1, HEATMAP_HEIGHT - 1);
    // ctx.stroke();

    ctx.beginPath();
    ctx.rect(
      HEATMAP_STRIKE_DELTA_X,
      HEATMAP_STRIKE_DELTA_Y,
      HOMEPLATE_WIDTH - 1,
      HOMEPLATE_WIDTH * STRIKEZONE_RATIO - 1,
    );
    ctx.stroke();
  };

  const drawHomeplate = (ctx, viewPort) => {
    const homeplate = document.querySelector(`#${viewPort}-homeplate`);
    const ratio = homeplate.naturalHeight / homeplate.naturalWidth;
    ctx.drawImage(
      homeplate,
      (HEATMAP_WIDTH - HOMEPLATE_WIDTH) / 2,
      HEATMAP_HEIGHT,
      HOMEPLATE_WIDTH,
      HOMEPLATE_WIDTH * ratio,
    );
  };

  const addPadding = (canvas, img) => {
    const ctx = canvas.getContext('2d');
    img.onload = (e) => {
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      ctx.beginPath();
      ctx.rect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      ctx.fillStyle = '#ffffff';
      ctx.fill();

      ctx.drawImage(e.target, PADDING, PADDING, CANVAS_WIDTH - PADDING * 2, CANVAS_HEIGHT - PADDING);
      img.onload = () => {};
      img.src = canvas.toDataURL('image/png');
    };
    img.src = canvas.toDataURL('image/png');
  };

  const toHeatmap = () => {
    const xRowIndex = parseInt(xRow);
    const yRowIndex = parseInt(yRow);

    const cRows = [];
    const pRows = [];
    const shrink = csvData.length <= 100 ? 1 : 100 / csvData.length;
    csvData.slice(firstRowHeading ? 1 : 0).forEach((row) => {
      let cX = parseFloat(row[xRowIndex]) + TO_HEATMAP_DELTA_X;
      let pX = parseFloat(row[xRowIndex] * -1) + TO_HEATMAP_DELTA_X;
      let y = parseFloat(row[yRowIndex]) * -1 + HEATMAP_HEIGHT;
      cRows.push([cX, y, shrink]);
      pRows.push([pX, y, shrink]);
    });
    console.log(cRows);
    console.log(pRows);

    [
      ['c', cRows],
      ['p', pRows],
    ].forEach(([viewPort, rows]) => {
      const heatmap = heat(`heatmap-${viewPort}-canvas`).data(rows).max(1);
      heatmap.radius(HOMEPLATE_WIDTH / 6, HOMEPLATE_WIDTH / 3);
      heatmap.drawWithBG(undefined, [255, 255, 255]);

      const heatmapCanvas = document.getElementById(`heatmap-${viewPort}-canvas`);
      const heatmapCtx = heatmapCanvas.getContext('2d');
      drawLine(heatmapCtx);
      drawHomeplate(heatmapCtx, viewPort);

      document.getElementById(`heatmap-${viewPort}-img-4-print`).src = heatmapCanvas.toDataURL('image/png');
      // addPadding(heatmapCanvas, document.getElementById(`heatmap-${viewPort}-img-4-print`));

      const dotmapCanvas = document.getElementById(`dotmap-${viewPort}-canvas`);
      const dotmapCtx = dotmapCanvas.getContext('2d');

      dotmapCtx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      dotmapCtx.beginPath();
      dotmapCtx.rect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      dotmapCtx.fillStyle = '#ffffff';
      dotmapCtx.fill();
      rows.forEach((xy) => {
        dotmapCtx.beginPath();
        dotmapCtx.arc(xy[0], xy[1], 16, 0, 2 * Math.PI, false);
        dotmapCtx.fillStyle = '#ffaa00';
        dotmapCtx.lineWidth = 3;
        dotmapCtx.strokeStyle = '#000000';
        dotmapCtx.fill();
        dotmapCtx.stroke();
      });
      drawLine(dotmapCtx);
      drawHomeplate(dotmapCtx, viewPort);

      document.getElementById(`dotmap-${viewPort}-img-4-print`).src = dotmapCanvas.toDataURL('image/png');
      // addPadding(dotmapCanvas, document.getElementById(`dotmap-${viewPort}-img-4-print`));
    });
  };

  return (
    <StyledDiv>
      <input key={`csv-${csvRefresh}`} id="csv-input" type="file" accept=".csv" onChange={handleCSVRead} />
      <p>
        <label>
          首列為標題
          <input type="checkbox" checked={firstRowHeading} onChange={() => setFirstRowHeading((pre) => !pre)} />
        </label>
      </p>
      <p>資料筆數：{firstRowHeading ? csvData.length - 1 : csvData.length}</p>
      {csvData.length > 0 && <p>{csvData[0].map((col, index) => `${index}. ${col}`).join(`｜`)}</p>}
      <p>
        <label>進壘點X 第幾行</label>
        <input value={xRow} onChange={(e) => setXRow(e.target.value)} />
      </p>
      <p>
        <label>進壘點Y 第幾行</label>
        <input value={yRow} onChange={(e) => setYRow(e.target.value)} />
      </p>
      <p>
        <Button width="auto" disabled={!validCSV()} onClick={toHeatmap}>
          產出熱區圖
        </Button>
      </p>
      {['dotmap-c', 'heatmap-c', 'dotmap-p', 'heatmap-p'].map((key) => (
        <div
          key={key}
          style={{ position: 'relative', width: CANVAS_WIDTH, height: CANVAS_HEIGHT, marginBottom: '1rem' }}
        >
          <img
            id={`${key}-img-4-print`}
            src=""
            alt={`${key}-img`}
            style={{ position: 'absolute', zIndex: 99, top: 0, left: 0, border: '1px solid #000000' }}
            width={CANVAS_WIDTH}
            height={CANVAS_HEIGHT}
          />
          <canvas
            className="noprint"
            style={{ position: 'absolute', zIndex: 100, top: 0, left: 0, display: 'none', background: 'white' }}
            id={`${key}-canvas`}
            width={CANVAS_WIDTH}
            height={CANVAS_HEIGHT}
          />
        </div>
      ))}
      <img id="c-homeplate" style={{ visibility: 'hidden' }} src={CHomePlate} alt="CHomePlate" />
      <img id="p-homeplate" style={{ visibility: 'hidden' }} src={PHomePlate} alt="PHomePlate" />
    </StyledDiv>
  );
};

export default HeatmapAdaptor;
