import { useEffect } from 'react';
import heat from 'simpleheat';
import { filter } from 'ramda';
import styled from 'styled-components';
import { strikeZoneConfig } from '../constants/base';
import { HomePlate } from '../styled';
import Multilingual from '../../../utils/Multilingual';

const { width, height } = strikeZoneConfig;

const HeatmapStyledDiv = styled.div`
  // 放這邊才不會跟列印時衝突
  #heatmap-img-4-print {
    display: none;
  }
`;

export default function Heatmap({ resolvedPerBall }) {
  const ballData = filter((b) => !!b.eyeZoneResult || !!b.eZoneResult, resolvedPerBall).map(
    ({ eZoneResult, eyeZoneResult }) => {
      if (eZoneResult) {
        return [eZoneResult.x, eZoneResult.y, 1];
      }

      if (eyeZoneResult) {
        return [eyeZoneResult.x, eyeZoneResult.y, 1];
      }

      throw new Error('Something went wrong');
    },
  );

  useEffect(() => {
    const shrink = ballData.length <= 100 ? 1 : 100 / ballData.length;
    const heatmap = heat('heatmap-canvas')
      .data(
        ballData.map((data) => {
          data[2] *= shrink;
          return data;
        }),
      )
      .max(5);
    heatmap.draw();

    // 轉換成顯示 img 方便出報告時顯示
    const heatmapCanvas = document.getElementById('heatmap-canvas');
    const heatmapImg = document.getElementById('heatmap-img-4-print');
    heatmapImg.src = heatmapCanvas.toDataURL('image/png');
  }, [ballData]);

  return (
    <div style={{ padding: 4 }}>
      <svg width={width / 2} height={height / 2}>
        <g>
          <rect width={width / 2} height={height / 2} stroke="black" fill="none"></rect>
        </g>
        <g>
          <rect x={32} y={16} width={width / 12} height={(height / 2 - 32) / 5} fill="red"></rect>
          <text x={60} y={20}>
            {'1.0'}
          </text>
          <rect
            x={32}
            y={16 + (height / 2 - 32) / 5}
            width={width / 12}
            height={(height / 2 - 32) / 5}
            fill="yellow"
          ></rect>
          <text x={60} y={20 + (height / 2 - 32) / 5}>
            {'.8'}
          </text>
          <rect
            x={32}
            y={16 + (2 * (height / 2 - 32)) / 5}
            width={width / 12}
            height={(height / 2 - 32) / 5}
            fill="lime"
          ></rect>
          <text x={60} y={20 + (2 * (height / 2 - 32)) / 5}>
            {'.6'}
          </text>
          <rect
            x={32}
            y={16 + (3 * (height / 2 - 32)) / 5}
            width={width / 12}
            height={(height / 2 - 32) / 5}
            fill="cyan"
          ></rect>
          <text x={60} y={20 + (3 * (height / 2 - 32)) / 5}>
            {'.4'}
          </text>
          <rect
            x={32}
            y={16 + (4 * (height / 2 - 32)) / 5}
            width={width / 12}
            height={(height / 2 - 32) / 5}
            fill="blue"
          ></rect>
          <text x={60} y={20 + (4 * (height / 2 - 32)) / 5}>
            {'.2'}
          </text>
        </g>
      </svg>
      <h3>{Multilingual(`GAME.HEAT_MAP`)}</h3>
      <HeatmapStyledDiv style={{ position: 'relative', width, height }}>
        <svg width={width} height={height}>
          <g>
            <rect width={width} height={height} stroke="black" fill="none"></rect>
          </g>
          <g>
            <rect x={width / 4} y={height / 4} width={width / 2} height={height / 2} stroke="black" fill="none"></rect>
          </g>
        </svg>
        <img
          id="heatmap-img-4-print"
          src=""
          alt="heatmap-img"
          style={{ position: 'absolute', zIndex: 99, top: 0, left: 0 }}
          width={width}
          height={height}
        />
        <canvas
          className="noprint"
          style={{ position: 'absolute', zIndex: 100, top: 0, left: 0 }}
          id="heatmap-canvas"
          width={width}
          height={height}
        />
      </HeatmapStyledDiv>
      <HomePlate className="home-plate-4-print" width={width / 2} />
    </div>
  );
}
