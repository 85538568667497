import { useFilterState } from '../hooks/useFilter';
import { getBallTypeStats } from '../utils/base';
import BatterTable from './BatterTable';
import { StyledTableArea } from '../styled';
import Multilingual from '../../../utils/Multilingual';

export default function AtBatTable({ resolvedPerBall, split = false }) {
  const { position } = useFilterState();
  const stats = getBallTypeStats(resolvedPerBall);

  return (
    <StyledTableArea className="tablearea-4-print">
      <h3 className="title">{Multilingual(`GAME.ATBAT`)}</h3>
      <div className="table-wrapper">
        {position !== 'pitcher' && <BatterTable stats={stats} resolvedPerBall={resolvedPerBall} split={split} />}
      </div>
    </StyledTableArea>
  );
}
