import MultilingualCommon from '~~utils/Multilingual';

const localMapping = {
  SETTING_PANEL: {
    'zh-TW': '小幫手',
    en: 'Setting',
  },
  ONSITE: {
    'zh-TW': '現場模式',
    en: 'On Site',
  },
  VIDEO: {
    'zh-TW': '影片模式',
    en: 'Video',
  },
  OPEN_SETTING_PANEL: {
    'zh-TW': '開啟小幫手面板',
    en: 'Open Setting Panel',
  },
  NAV: {
    LIST: {
      'zh-TW': '回到列表',
      en: 'Back to List',
    },
    ANALYTICS: {
      'zh-TW': '分析結果',
      en: 'Analytics',
    },
  },
  CAMERA_ANGLE: {
    WORDING: {
      'zh-TW': '影片視角',
      en: 'Camera Angle',
    },
    PITCHER: {
      'zh-TW': '投手視角',
      en: 'Pitcher Angle',
    },
    CATCHER: {
      'zh-TW': '捕手視角',
      en: 'Catcher Angle',
    },
  },
  CURRENT_SIDE: {
    WORDING: {
      'zh-TW': '進攻方',
      en: 'Batting Side',
    },
    AWAY: {
      'zh-TW': '客場',
      en: 'Away',
    },
    HOME: {
      'zh-TW': '主場',
      en: 'Home',
    },
    INNING: {
      'zh-TW': '局',
      en: 'Inning',
    },
  },
  NULL: {
    'zh-TW': '無紀錄',
    en: 'No Record',
  },
  NULL_SHORT: {
    'zh-TW': '無',
    en: 'N',
  },
  RECORDED: {
    'zh-TW': '已記錄',
    en: 'Recorded',
  },
  RECORDED_SHORT: {
    'zh-TW': '有',
    en: 'Y',
  },
  GAME_STATUS: {
    'zh-TW': '狀態',
    en: 'Status',
  },
  PITCHER: {
    WORDING: {
      'zh-TW': '投手',
      en: 'Pitcher',
    },
    R: {
      'zh-TW': '右投',
      en: 'Righty',
    },
    L: {
      'zh-TW': '左投',
      en: 'Lefty',
    },
  },
  BATTER: {
    WORDING: {
      'zh-TW': '打者',
      en: 'Batter',
    },
    R: {
      'zh-TW': '右打',
      en: 'Righty',
    },
    L: {
      'zh-TW': '左打',
      en: 'Lefty',
    },
  },
  SPEED: {
    WORDING: {
      'zh-TW': '球速',
      en: 'Velo',
    },
    UNIT: {
      'zh-TW': '球速單位',
      en: 'Velo Unit',
    },
    KM: {
      'zh-TW': '公里',
      en: 'Km',
    },
    MI: {
      'zh-TW': '英哩',
      en: 'Mile',
    },
    RESET: {
      'zh-TW': '重設',
      en: 'Reset',
    },
    UPDATE: {
      'zh-TW': '更新球速',
      en: 'Velo Update',
    },
  },
  BASES: {
    WORDING: {
      'zh-TW': '壘包',
      en: 'Bases',
    },
    FIRST: {
      'zh-TW': '一壘',
      en: 'First',
    },
    SECOND: {
      'zh-TW': '二壘',
      en: 'Second',
    },
    THIRD: {
      'zh-TW': '三壘',
      en: 'Third',
    },
  },
  OUTS: {
    WORDING: {
      'zh-TW': '出局數',
      en: 'Outs',
    },
  },
  ENTRY_POINT: {
    WORDING: {
      'zh-TW': '進壘點',
      en: 'Entry Point',
    },
    RESET: {
      'zh-TW': '重設',
      en: 'Reset',
    },
    ELECTRONIC: {
      'zh-TW': '電子',
      en: 'Electronic',
    },
    WIDTH: {
      'zh-TW': '寬',
      en: 'Width',
    },
    HEIGHT: {
      'zh-TW': '高',
      en: 'Height',
    },
  },
  BATTER_ACTION: {
    WORDING: {
      'zh-TW': '打者動作',
      en: 'Batter Action',
    },
    SHORT: {
      'zh-TW': '動作',
      en: 'Action',
    },
    NONE: {
      'zh-TW': '無',
      en: 'NONE',
    },
    SWMISS: {
      'zh-TW': '揮空',
      en: 'SwingMiss',
    },
    SWTIP: {
      'zh-TW': '擦棒',
      en: 'SwingTip',
    },
    INFIELD: {
      'zh-TW': '球入場',
      en: 'Infield',
    },
    OUTFIELD: {
      'zh-TW': '球出界',
      en: 'Foul',
    },
  },
  BATTER_RESULT: {
    WORDING: {
      'zh-TW': '打席結果',
      en: 'PA Result',
    },
    SHORT: {
      'zh-TW': '結果',
      en: 'PA',
    },
    NONE: {
      'zh-TW': '無(換人)',
      en: 'NONE(Pinch)',
    },
    '1B': {
      'zh-TW': '一安',
      en: '1B',
    },
    '2B': {
      'zh-TW': '二安',
      en: '2B',
    },
    '3B': {
      'zh-TW': '三安',
      en: '3B',
    },
    HR: {
      'zh-TW': '全壘打',
      en: 'HR',
    },
    E: {
      'zh-TW': '失誤上壘',
      en: 'E',
    },
    FC: {
      'zh-TW': '野手選擇',
      en: 'FC',
    },
    out: {
      'zh-TW': '出局',
      en: 'Out',
    },
    GO: {
      'zh-TW': '滾地出局',
      en: 'GO',
    },
    FO: {
      'zh-TW': '飛球出局',
      en: 'FO',
    },
    GIDP: {
      'zh-TW': '滾地雙殺',
      en: 'GIDP',
    },
    SH: {
      'zh-TW': '犧牲觸擊',
      en: 'SH',
    },
    SF: {
      'zh-TW': '犧牲飛球',
      en: 'SF',
    },
    uBB: {
      'zh-TW': '保送',
      en: 'BB',
    },
    IBB: {
      'zh-TW': '故意四壞',
      en: 'IBB',
    },
    HBP: {
      'zh-TW': '觸身球',
      en: 'HBP',
    },
    SO: {
      'zh-TW': '三振',
      en: 'SO',
    },
    IR: {
      'zh-TW': '妨礙跑壘',
      en: 'IR',
    },
    IH: {
      'zh-TW': '妨礙打擊',
      en: 'IH',
    },
    other: {
      'zh-TW': '其他',
      en: 'Other',
    },
  },
  TRAJECTORY: {
    WORDING: {
      'zh-TW': '彈道',
      en: 'Trajectory',
    },
    NONE: {
      'zh-TW': '無',
      en: 'None',
    },
    G: {
      'zh-TW': '滾',
      en: 'Ground',
    },
    L: {
      'zh-TW': '平',
      en: 'LineDrive',
    },
    P: {
      'zh-TW': '內飛',
      en: 'Pop',
    },
    F: {
      'zh-TW': '飛',
      en: 'Fly',
    },
    BG: {
      'zh-TW': '觸滾',
      en: 'BuntGround',
    },
    BP: {
      'zh-TW': '觸飛',
      en: 'BuntFly',
    },
  },
  CONTACT: {
    WORDING: {
      'zh-TW': '擊球',
      en: 'Contact',
    },
    S: {
      'zh-TW': '弱',
      en: 'Soft',
    },
    H: {
      'zh-TW': '強',
      en: 'Hard',
    },
  },
  FIELDER: {
    WORDING: {
      'zh-TW': '處理野手',
      en: 'Fielder',
    },
  },
  DROPPOINT: {
    WORDING: {
      'zh-TW': '落點',
      en: 'Droppoint',
    },
  },
  BALL_HINT: {
    WORDING: {
      'zh-TW': '完整度',
      en: 'Hint',
    },
    PENDING: {
      'zh-TW': '待補',
      en: 'Pending',
    },
    COMPLETE: {
      'zh-TW': '完整',
      en: 'Complete',
    },
  },
  ON_SITE: {
    CREATE_BALL: {
      'zh-TW': '新增',
      en: 'Create',
    },
    DELETE_BALL: {
      'zh-TW': '刪除',
      en: 'Delete',
    },
    BACK_BALL: {
      'zh-TW': '返回當下球',
      en: 'Back to Current',
    },
  },
  ON_SITE_MODAL: {
    NEXT: {
      'zh-TW': '下個欄位',
      en: 'Next Column',
    },
    SKIP: {
      'zh-TW': '跳過',
      en: 'Skip',
    },
  },
  HISTORY_LIST: {
    'zh-TW': '已記錄逐球內容',
    en: 'Back to Current',
  },
  MOBILE_EDIT_ENTRY: {
    'zh-TW': '長按進入編輯模式',
    en: 'Long Press to Edit',
  },
  PC_EDIT_ENTRY: {
    'zh-TW': '點兩下進入編輯模式',
    en: 'DBClick to Edit',
  },
  VIDEO_MODE: {
    YOUTUBE: {
      'zh-TW': 'Youtube 嵌入',
      en: 'Youtube embed',
    },
    SCREEN_SHARE: {
      'zh-TW': '視窗分享',
      en: 'Screen Share',
    },
    VIDEO_WIDTH: {
      'zh-TW': '影片寬度',
      en: 'Video Width',
    },
    YOUTUBE_HINT: {
      'zh-TW': 'Youtube 視窗 > 分享 > 複製 > 貼上',
      en: 'Youtube Screen > Share > Copy > Paste',
    },
    YOUTUBE_FORMAT_ERROR: {
      'zh-TW': '影片格式不正確，請參考說明步驟執行',
      en: 'Wrong video format, please follow the description',
    },
    YOUTUBE_RESIZING_HINT: {
      'zh-TW': '調整好球帶時無法操控影片',
      en: 'Can not control video when strikezone resizing',
    },
    SCREEN_SHARE_CAPTURE: {
      'zh-TW': '抓取視窗',
      en: 'Capture Screen',
    },
    SCREEN_SHARE_STOP: {
      'zh-TW': '停止分享',
      en: 'Stop sharing',
    },
    ADJUST_ZONE: {
      'zh-TW': '調整好球帶位置',
      en: 'Adjust zone position',
    },
    LOAD_ZONE_POSITION: {
      'zh-TW': '讀取裝置好球帶位置',
      en: 'Load device zone position',
    },
    SAVE_ZONE_POSITION: {
      'zh-TW': '儲存裝置好球帶位置',
      en: 'Save device zone position',
    },
    E_ZONE_HINT: {
      'zh-TW': '電子：紅框是好球帶，藍框是有效點擊範圍',
      en: 'Electronic：Orange for Strikezone; Green for Valid',
    },
    EYE_ZONE_HINT: {
      'zh-TW': '橘框是好球帶，綠框是有效點擊範圍',
      en: 'Orange for Strikezone; Green for Valid',
    },
  },
  EDITING_HISTORY: {
    'zh-TW': '編輯紀錄中',
    en: 'Editing Histiry',
  },
  BALL_SEQ: {
    'zh-TW': '編號',
    en: 'Seq',
  },
  SYNC: {
    LOADING: {
      'zh-TW': '同步中請稍後',
      en: 'Syncing, please wait',
    },
    ERROR: {
      'zh-TW': '連線中斷，請重新整理',
      en: 'Disconnect, please refresh',
    },
    ERROR_WARNING: {
      'zh-TW': '同步暫時錯誤，如持續發生請重新整理頁面',
      en: 'Temporary error, please refresh if keep happens',
    },
  },
  IMPORT_PLAYER: {
    FROM_RECENT_GAMES: {
      'zh-TW': '從近期比賽匯入',
      en: 'Import from recent games',
    },
    GAME: {
      'zh-TW': '比賽',
      en: 'Game',
    },
    FROM: {
      'zh-TW': '來源方',
      en: 'From',
    },
    TO: {
      'zh-TW': '目標方',
      en: 'To',
    },
    IMPORT: {
      'zh-TW': '匯入',
      en: 'Import',
    },
    ERROR_GAME: {
      'zh-TW': '找不到來源',
      en: 'Game not found',
    },
    ERROR_RESOURCE: {
      'zh-TW': '來源無任何球員可匯入',
      en: 'No player can import',
    },
  },
  INSERT_BALL: {
    WORDING: {
      'zh-TW': '中間新增',
      en: 'Insert Ball',
    },
    WARNING: {
      'zh-TW': '注意：請暫停編輯避免資料覆蓋',
      en: 'Warning: Please pause editing to prevent data cover',
    },
    INSERT_AMOUNT: {
      'zh-TW': '新增球數',
      en: 'Insert Amount',
    },
    ERROR_INDEX: {
      PREFIX: {
        'zh-TW': '編號應介於',
        en: 'Ball Seq should between',
      },
      SUFFIX: {
        'zh-TW': '之間',
        en: '',
      },
    },
    ERROR_AMOUNT: {
      PREFIX: {
        'zh-TW': '新增球數應介於',
        en: 'Ball Amount should between',
      },
      SUFFIX: {
        'zh-TW': '之間',
        en: '',
      },
    },
  },
  ALL_PLAYERS: {
    WORDING: {
      'zh-TW': '兩隊球員',
      en: 'All Players',
    },
  },
  PER_BALL: {
    WORDING: {
      'zh-TW': '逐球內容',
      en: 'Per Ball',
    },
  },
  SHORTCUT: {
    WORDING: {
      'zh-TW': '電腦快捷',
      en: 'PC Shortcut',
    },
    EDITING_ENTER: {
      'zh-TW': '編輯狀態下, Enter 會回到當下球',
      en: 'Back to current ball when editing history',
    },
  },
  GLOSSARY: {
    WORDING: {
      'zh-TW': '詞彙',
      en: 'Glossary',
    },
  },
  INFO: {
    WORDING: {
      'zh-TW': '好球帶資訊',
      en: 'Strikezone Info',
    },
    ERROR_REQUIRED: {
      'zh-TW': '必填項目不得為空',
      en: 'Required can not be empty',
    },
    SUCCESSFULLY_UPGRADE: {
      'zh-TW': '升級成功！如各項限制未解除請重新整理頁面',
      en: 'Successfully upgrade! please refresh page if the condition does not unlocked',
    },
    TITLE: {
      'zh-TW': '標題',
      en: 'Title',
    },
    COMBINATION: {
      'zh-TW': '對戰組合',
      en: 'Combination',
    },
    DATE: {
      'zh-TW': '比賽日期',
      en: 'Game Date',
    },
    REF: {
      'zh-TW': '參考影片連結',
      en: 'Reference Link',
    },
    DESCRIPTION: {
      'zh-TW': '內容說明',
      en: 'Description',
    },
    GAME_STATUS: {
      'zh-TW': '比賽狀態',
      en: 'Game Status',
    },
    GAME_STATUS_LIVE: {
      'zh-TW': '進行中',
      en: 'Live',
    },
    GAME_STATUS_ENDED: {
      'zh-TW': '已結束',
      en: 'Ended',
    },
    BALL_LIMIT: {
      'zh-TW': '球數上限',
      en: 'Ball Limit',
    },
    BALL_LIMIT_COUNT: {
      'zh-TW': '球',
      en: 'Count',
    },
    REACH_BALL_LIMIT: {
      'zh-TW': '球數已達上限！ 請另開新的紀錄',
      en: 'Reach Ball Limit! Please create another game',
    },
    READ_PRIVACY: {
      'zh-TW': '瀏覽權限',
      en: 'Read Privacy',
    },
    READ_PRIVACY_PUBLIC: {
      'zh-TW': '公開',
      en: 'Public',
    },
    READ_PRIVACY_UNLISTED: {
      'zh-TW': '非公開',
      en: 'Unlisted',
    },
    COOPERATE: {
      'zh-TW': '共同編輯',
      en: 'Cooperate',
    },
    SETTING: {
      'zh-TW': '設定',
      en: 'Setting',
    },
  },
  READ_PRIVACY: {
    HINT: {
      'zh-TW': '切換瀏覽權限提示',
      en: 'Switching Read Privacy Hint',
    },
    DECLARE: {
      'zh-TW': `切換瀏覽權限時，所有編輯動作將中斷，
      並且目前的編輯/分析網址將失效。
      確認切換瀏覽權限後系統將協助導轉至新的編輯頁面，
      如未成功導轉請回列表頁重新整理。`,
      en: `All of the editing/analysing link will become invalid after privacy switch.
      We will help to redirect to new edit page after privacy switch.
      Please back to home page if the redirect is not success.`,
    },
    GOING_TO: {
      'zh-TW': '即將執行：切換為',
      en: 'You are going to: Switch to',
    },
    PUBLIC_FEATURE: {
      'zh-TW': '用戶可以透過好球帶列表共同討論分析內容',
      en: 'All users can discuss the content through the public list',
    },
    UNLISTED_FEATURE: {
      'zh-TW': '用戶僅能透過連結進入分析頁面',
      en: 'User can only access the content through the link',
    },
    CONFIRM: {
      'zh-TW': '確認執行',
      en: 'Confirm',
    },
  },
  COOPERATE: {
    USER_NOT_FOUND: {
      'zh-TW': '找不到該用戶，請確認輸入資料無誤',
      en: 'User not found, please check your input again',
    },
    ALREADY_EDITOR: {
      'zh-TW': '您已有編輯權限',
      en: 'You are already an editor',
    },
    USER_ALREADY_EDITOR: {
      'zh-TW': '此用戶已有編輯權限',
      en: 'The user is already an editor',
    },
    ADD_SUCCESSFULLY: {
      'zh-TW': '新增編輯成功！',
      en: 'Add an editor successfully!',
    },
    REMOVE_SUCCESSFULLY: {
      'zh-TW': '移除編輯成功！',
      en: 'Remove an editor successfully!',
    },
    OWNER: {
      'zh-TW': '擁有者',
      en: 'Owner',
    },
    LIMIT_HINT_PREFIX: {
      'zh-TW': '最多可與',
      en: 'At most editing with ',
    },
    LIMIT_HINT_SUFFIX: {
      'zh-TW': '位夥伴共同編輯',
      en: ' users',
    },
    INPUT_PLACEHOLDER: {
      'zh-TW': 'email / 用戶名稱',
      en: 'email / username',
    },
  },
  DATA_EXPORT: {
    WORDING: {
      'zh-TW': '資料匯出',
      en: 'Data Export',
    },
    PER_BALL: {
      'zh-TW': '逐球匯出',
      en: 'Per Ball Export',
    },
    PLAYER: {
      'zh-TW': '選手',
      en: 'Player',
    },
  },
  NO_PERMISSION: {
    SUBSCRIBE_FIRST: {
      'zh-TW': '請先訂閱方案',
      en: 'Please Subscribe first',
    },
    GO_BUY_IT: {
      'zh-TW': '前往訂閱頁面',
      en: 'Plugin Shop',
    },
    GAME_NOT_FOUND: {
      'zh-TW': '查無此好球帶，請確認連結無誤',
      en: 'Game not found. Please check you have correct link',
    },
    BACK_TO_LIST: {
      'zh-TW': '回到好球帶列表',
      en: 'Back to List',
    },
    NOT_EDITOR_PREFIX: {
      'zh-TW': '您並非此好球帶',
      en: 'You are not the owner/editor of this game ',
    },
    NOT_EDITOR_SUFFIX: {
      'zh-TW': '的擁有/編輯者，請登入或更換帳號',
      en: ', please login or change another account',
    },
  },
  DEVICE_NOTIFICATION: {
    NOT_SUPPORT_MOBILE: {
      'zh-TW': '很抱歉，目前手機不支援編輯模式',
      en: 'Sorry, editor mode is not support in mobile currently',
    },
    ROTATE_TABLET: {
      'zh-TW': '請橫放平板以使用',
      en: 'Please put your tablet horizontally',
    },
    NOT_SUPPORT_SIZE: {
      'zh-TW': '很抱歉，您的裝置大小不支援編輯模式',
      en: 'Sorry, editor mode is not support in your device',
    },
    IGNORE: {
      'zh-TW': '我已理解將操作不便，仍要繼續編輯',
      en: 'I understand, ignore the warning',
    },
  },
  RESIZE_STRIKEZONE: {
    SAVE_SUCCESSFULLY: {
      'zh-TW': '儲存裝置好球帶位置成功！',
      en: 'Save the strikezone position successfully',
    },
    READ_SUCCESSFULLY: {
      'zh-TW': '讀取裝置好球帶位置成功！',
      en: 'Read the strikezone position successfully',
    },
    TURN_OFF_HINT: {
      'zh-TW': '請關閉 調整好球帶位置 方能開始紀錄',
      en: 'Please turn off "Adjust zone position" before you start it',
    },
  },
};

const Multilingual = (textCode, lang = '') => {
  return MultilingualCommon(textCode, localMapping, lang);
};
export default Multilingual;
