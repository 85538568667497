import styled from 'styled-components';
import Button from '~~elements/Button';
import Input from '~~elements/Input';
import { COLOR } from '~~elements/Const';
import { ACTIONS } from '../useLocal';
import KeyHint from './KeyHint';
import LocationCodeClick from '~~components/LocationCode/Click';
import { PITCH_TYPE, PITCH_TYPE_NAME } from '~~commonStats/pitch';
import { Fragment } from 'react';
import { KEY_PITCH_MAPPING } from '../services/pitchType';
import { useState } from 'react';

const StyledDiv = styled.div`
  width: 100%;
  height: 240px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  text-align: center;
  overflow-x: hidden;
  text-overflow: clip;
  white-space: nowrap;
  background-color: #eeeeee;
  border: 2px solid ${COLOR.secondary};
  span {
    overflow: hidden;
    &.value {
      font-weight: 600;
    }
  }
  label {
    padding: 0 2px;
    button {
      padding: 0 2px;
    }
  }
`;

const TimingCtrl = ({ currentPitch, column, dispatch }) => (
  <>
    <br />
    {!currentPitch[column] ? (
      <span>-</span>
    ) : (
      <>
        <span className="value">
          <Button
            mini
            color="transparent"
            onClick={(e) => {
              e.target.blur();
              dispatch({ type: ACTIONS.FOCUS_PITCH_TIMING, payload: currentPitch[column] });
            }}
          >
            {currentPitch[column]}
          </Button>
        </span>
        <br />
        <Button
          mini
          color="red"
          width="auto"
          onClick={() => dispatch({ type: ACTIONS.DELETE_PITCH, payload: { timeType: column } })}
        >
          刪
        </Button>
      </>
    )}
  </>
);

const PitchTypeHint = ({ pitchTypes, children }) => {
  return (
    <span>
      {children}
      {pitchTypes.map((pitchType, i) => (
        <Fragment key={pitchType}>
          {i !== 0 && <br />}
          <span>
            {PITCH_TYPE_NAME[pitchType]}
            <KeyHint>{KEY_PITCH_MAPPING[pitchType].display}</KeyHint>
          </span>
        </Fragment>
      ))}
    </span>
  );
};

const PitchDetail = ({ currentPitch, dispatch, pitchIndex }) => {
  const [lcIsOpen, setLcIsOpen] = useState(false);

  const handlePitchColumnChange = (e) => {
    dispatch({ type: ACTIONS.UPDATE_PITCH_COLUMN, payload: { column: e.target.name, value: e.target.value } });
  };

  const setLocationCode = (locationCode, xy) => {
    dispatch({ type: ACTIONS.UPDATE_PITCH_LOCATION_CODE, payload: { locationCode, xy } });
    setLcIsOpen(false);
  };

  return (
    <StyledDiv className="pitch-detail">
      {currentPitch && (
        <>
          {/* First Row */}
          <strong>
            流水號
            <br />
            {pitchIndex + 1}
          </strong>
          <span>
            捕手SignIn<KeyHint>1</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_signin_at" dispatch={dispatch} />
          </span>
          <span>
            投球啟動<KeyHint>2</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_started_at" dispatch={dispatch} />
          </span>
          <span>
            擊球瞬間<KeyHint>3</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_contact_at" dispatch={dispatch} />
          </span>
          <span>
            飛球接/落<KeyHint>4</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_fly_catch_at" dispatch={dispatch} />
          </span>
          <span>
            滾地接捕<KeyHint>5</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_ground_catch_at" dispatch={dispatch} />
          </span>
          <span>
            盜壘捕手<KeyHint>6</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_sb_throw_at" dispatch={dispatch} />
          </span>
          <span>
            盜壘野手<KeyHint>7</KeyHint>
            <TimingCtrl currentPitch={currentPitch} column="video_sb_catch_at" dispatch={dispatch} />
          </span>
          {/* Second Row */}
          <label>
            球速 KPH
            <br />
            <Input name="velocity" value={currentPitch.pitch.velocity} onChange={handlePitchColumnChange} />
          </label>
          <label>
            轉速 RPM
            <br />
            <Input name="RPM" value={currentPitch.pitch.RPM} onChange={handlePitchColumnChange} />
          </label>
          <span>
            進壘
            <br />
            {`${currentPitch.pitch.coord_x}, ${currentPitch.pitch.coord_y}`}
            {currentPitch.pitch.coord_x && currentPitch.pitch.coord_y && (
              <>
                <br />
                <Button mini color="red" width="auto" onClick={() => dispatch({ type: ACTIONS.DELETE_COORD })}>
                  刪
                </Button>
              </>
            )}
          </span>
          <label>
            球種
            <br />
            {PITCH_TYPE_NAME[currentPitch.pitch.type] || currentPitch.pitch.type}
          </label>
          <PitchTypeHint pitchTypes={[PITCH_TYPE.FF, PITCH_TYPE.SL]} />
          <PitchTypeHint pitchTypes={[PITCH_TYPE.SI, PITCH_TYPE.CU]} />
          <PitchTypeHint pitchTypes={[PITCH_TYPE.FC, PITCH_TYPE.CH]} />
          <PitchTypeHint pitchTypes={[PITCH_TYPE.FO]}>
            <span />
            <br />
          </PitchTypeHint>
          {/* Third Row */}
          <label>
            擊初 EV
            <br />
            <Input name="EV" value={currentPitch.pitch.EV} onChange={handlePitchColumnChange} />
          </label>
          <span>
            擊球仰角
            <br />
            {[
              ['G', '滾'],
              ['L', '平'],
              ['F', '飛'],
              ['P', '內飛'],
            ].map((values, i) => (
              <Fragment key={values[0]}>
                {i === 2 && <br />}
                <label>
                  <input
                    type="radio"
                    name="trajectory"
                    checked={currentPitch.pitch.trajectory === values[0]}
                    value={values[0]}
                    onChange={handlePitchColumnChange}
                  />
                  {values[1]}
                </label>
              </Fragment>
            ))}
            <span style={{ visibility: currentPitch.pitch.trajectory ? 'visible' : 'hidden' }}>
              <br />
              <Button
                mini
                color="red"
                width="auto"
                onClick={() =>
                  dispatch({ type: ACTIONS.UPDATE_PITCH_COLUMN, payload: { column: 'trajectory', value: '' } })
                }
              >
                刪
              </Button>
            </span>
          </span>
          <span>
            擊球強勁
            <br />
            {[
              ['S', '弱'],
              ['M', '中'],
              ['H', '強'],
            ].map((values, i) => (
              <Fragment key={values[0]}>
                {i === 2 && <br />}
                <label>
                  <input
                    type="radio"
                    name="hardness"
                    checked={currentPitch.pitch.hardness === values[0]}
                    value={values[0]}
                    onChange={handlePitchColumnChange}
                  />
                  {values[1]}
                </label>
              </Fragment>
            ))}
            <span style={{ visibility: currentPitch.pitch.hardness ? 'visible' : 'hidden' }}>
              <br />
              <Button
                mini
                color="red"
                width="auto"
                onClick={() =>
                  dispatch({ type: ACTIONS.UPDATE_PITCH_COLUMN, payload: { column: 'hardness', value: '' } })
                }
              >
                刪
              </Button>
            </span>
          </span>
          <span>
            擊球落點
            <br />
            <Button mini color="blue" width="auto" onClick={() => setLcIsOpen(true)}>
              {currentPitch.pitch.location_code || '-'}
            </Button>
            <span
              style={{
                visibility:
                  currentPitch.pitch.location_coord_x && currentPitch.pitch.location_coord_y ? 'visible' : 'hidden',
              }}
            >
              <br />
              {`${currentPitch.pitch.location_coord_x}, ${currentPitch.pitch.location_coord_y}`}
              <br />
              <Button
                mini
                color="red"
                width="auto"
                onClick={() =>
                  dispatch({ type: ACTIONS.UPDATE_PITCH_LOCATION_CODE, payload: { locationCode: '', xy: ['', ''] } })
                }
              >
                刪
              </Button>
            </span>
          </span>
          <span>
            防守順序
            <br />
            <Input
              name="fielder_position"
              value={currentPitch.pitch.fielder_position}
              onChange={handlePitchColumnChange}
              maxLength={10}
            />
          </span>
          <span />
          <span />
          <span />
          <span />
          <LocationCodeClick
            isOpen={lcIsOpen}
            toClose={() => setLcIsOpen(false)}
            currentPoint={[
              parseFloat(currentPitch.pitch.location_coord_x),
              parseFloat(currentPitch.pitch.location_coord_y),
            ]}
            savePoint={setLocationCode}
          />
        </>
      )}
    </StyledDiv>
  );
};

export default PitchDetail;
