import { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router';
import { ref } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import styled from 'styled-components';
import { PHONE_WIDTH } from '~~elements/Const';
import Loading from '~~elements/Loading';
import Button from '~~elements/Button';
import toast from '~~elements/Toast';
import IconDownloadSVG from '~~elements/static/IconDownload.svg';
import { FilterProvider } from './hooks/useFilter';
import Filter from './Filter';
import Header from './Header';
import StrikeZone from './StrikeZone';
import Donate from './Donate';
import Comments from './Comments';
import { parseAPIGame } from './constants/base';
import Report from '../Report';
import { getRealtimeDB } from '../../Firebase';
import Multilingual from '../../utils/Multilingual';

const StyledDiv = styled.div`
  padding: 2rem 5rem;

  .action-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .report-comment {
    margin: 0 auto;
    display: block;
    width: 20rem;
    max-width: 95%;
    height: 5rem;
    resize: none;
    border-radius: 5px;
  }

  .report-download-link {
    display: block;
    margin: 1rem 0;
    font-weight: bold;
    > img {
      height: 1.2rem;
    }
  }

  @media (max-width: ${PHONE_WIDTH}) {
    padding: 1rem 0.5rem;
  }
`;

const StrikezoneGame = ({ unlisted = false }) => {
  const { strikezoneUniqid } = useParams();
  const [gameSnapshot, gameLoading, gameError] = useObject(
    ref(getRealtimeDB(), `${unlisted ? 'unlistedGames' : 'publicGames'}/${strikezoneUniqid}`),
  );
  const [parsedGameState, setParsedGameState] = useState(null);

  const [writingComment, setWritingComment] = useState(false);
  const [reportComment, setReportComment] = useState('');
  const [printMode, setPrintMode] = useState(false);
  const [pdfPreparing, setPDFPreparing] = useState('');

  useEffect(() => {
    // console.log(gameSnapshot, gameLoading, gameError);
    if (!gameSnapshot || gameLoading || gameError) {
      return;
    }

    const gameSnapshotValue = gameSnapshot.val();
    // console.log(gameSnapshotValue);
    if (!gameSnapshotValue) {
      return;
    }

    setParsedGameState(parseAPIGame(gameSnapshot.val()));
  }, [gameSnapshot, gameLoading, gameError]);

  const startWritingComment = () => {
    setWritingComment(true);
    setReportComment('');
  };

  const handlePrintReport = () => {
    setPrintMode(true);
    setPDFPreparing('');
  };

  const handleReportSubmitted = (title, callback) => {
    setPrintMode(false);
    setWritingComment(false);
    toast(Multilingual(`PLAYER_ANALYTICS.START_PREPARING`), { status: 'info', second: 3 });

    setPDFPreparing('PREPARING');
    callback
      .then((blobData) => {
        setPDFPreparing({
          title: `${title}.pdf`,
          href: window.URL.createObjectURL(blobData),
        });
        toast(Multilingual(`PLAYER_ANALYTICS.REPORT_COMPLETED`), { status: 'success', second: 5 });
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
        setPDFPreparing('');
      });
  };

  return (
    <StyledDiv>
      <div className="action-btns">
        <a href="/">
          <Button width="unset" color="transparentWithBorder">
            {Multilingual(`BACK_TO_LIST`)}
          </Button>
        </a>
        <a href="/analytics">
          <Button width="unset" color="transparentWithBorder">
            {Multilingual(`PLAYER_ANALYTICS.WORDING`)}
          </Button>
        </a>
      </div>
      {parsedGameState ? (
        <FilterProvider gameInfo={parsedGameState}>
          {printMode && (
            <Report
              perBall={parsedGameState.perBall}
              player={parsedGameState.title}
              playerType={Multilingual(`PLAYER_ANALYTICS.TYPE_GAME`)}
              dateStart={parsedGameState.gameDate}
              dateEnd={parsedGameState.gameDate}
              gameAmount={1}
              reportComment={reportComment}
              submitted={handleReportSubmitted}
            />
          )}
          <Header gameInfo={parsedGameState} />
          <Filter gameInfo={parsedGameState} />
          {!writingComment && (
            <Fragment>
              <span>{Multilingual(`PLAYER_ANALYTICS.SCROLL_DOWN_VIEW_ANALYSIS`)}</span>
              <span>&nbsp;-or-&nbsp;</span>
              <Button
                width="unset"
                color="transparentWithBorder"
                onClick={startWritingComment}
                disabled={pdfPreparing === 'PREPARING'}
              >
                {pdfPreparing === 'PREPARING'
                  ? Multilingual(`PLAYER_ANALYTICS.REPORT_PREPARING`)
                  : Multilingual(`PLAYER_ANALYTICS.PRINT_REPORT`)}
              </Button>
            </Fragment>
          )}
          {writingComment && (
            <Fragment>
              <p>{Multilingual(`PLAYER_ANALYTICS.PRINT_REPORT`)}</p>
              <label>{Multilingual(`PLAYER_ANALYTICS.PROVIDE_REPORT_COMMENT`)}</label>
              <textarea
                className="report-comment"
                placeholder={Multilingual(`PLAYER_ANALYTICS.REPORT_COMMENT_HINT`)}
                value={reportComment}
                textalign="left"
                onChange={(e) => setReportComment(e.target.value)}
              />
              <Button width="unset" color="blue" onClick={() => setWritingComment(false)}>
                {Multilingual(`CANCEL`)}
              </Button>
              <Button width="unset" color="red" onClick={handlePrintReport}>
                {Multilingual(`PLAYER_ANALYTICS.PRINT`)}
              </Button>
            </Fragment>
          )}
          {pdfPreparing && pdfPreparing !== 'PREPARING' && (
            <a
              className="report-download-link"
              href={pdfPreparing.href}
              download={pdfPreparing.title}
              target="_blank"
              rel="noreferrer"
            >
              <img src={IconDownloadSVG} alt="download" />
              {pdfPreparing.title}
            </a>
          )}
          <StrikeZone perBall={parsedGameState.perBall} />
          {/* {parsedGameState.privacy === 'PUBLIC' && <Donate />}
          <Comments
            strikezoneUniqid={strikezoneUniqid}
            comments={!parsedGameState.comments ? {} : parsedGameState.comments}
          /> */}
        </FilterProvider>
      ) : (
        <div>{!!gameLoading ? <Loading width="10rem" margin="2rem auto" /> : Multilingual(`NOT_FOUND`)}</div>
      )}
    </StyledDiv>
  );
};

export default StrikezoneGame;
