import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { saveAs } from 'file-saver';
import Button from '~~elements/Button';
import Input from '~~elements/Input';
import toast from '~~elements/Toast';
import Loading from '~~elements/Loading';
import { CSVToArray } from '~~commonUtils';
import { useQuery } from '~~utils/CommonUtils';
import { FormalGameResource, LocalResource } from '~~apis/resource';
import { videoID, timeDisplayReverse } from '../Const';
import { ACTIONS, useLocalDispatch, useLocalState } from '../useLocal';
import Prepare from './Prepare';
import Pitches from './Pitches';
import ManagerForm from './ManagerForm';
import moment from 'moment';
import KeyHint from './KeyHint';
import { exportPitchRows, parseInputStrikezoneCSV } from '../utils/pitches';

const StyledDiv = styled.div`
  text-align: start;
  font-size: 12px;
  .btns {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    input {
      overflow: hidden;
      width: 100%;
    }
    input[type='checkbox'] {
      width: 20px;
    }
    input[type='radio'] {
      width: 20px;
    }
  }
  .reset-btns {
    padding-bottom: 7.5px;
    margin-bottom: 7.5px;
    border-bottom: 2px solid #eaeaea;
    .video-loaded-true {
      text-align: center;
      color: darkgreen;
    }
    .video-loaded-false {
      text-align: center;
      color: red;
    }
  }
  .update-btns {
    padding-top: 7.5px;
    margin-top: 7.5px;
    border-top: 2px solid #eaeaea;
  }
  .btns.update-btns.manager {
    input[type='text'] {
      width: 60%;
    }
  }
  .keys-explain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    label {
      text-align: center;
      margin-right: 5px;
    }
  }

  #csv-input,
  #entry-point-input {
    display: none;
  }
`;

const sortPAList = R.sort((a, b) => {
  if (a.inning !== b.inning) {
    return a.inning - b.inning;
  }

  if (a.side !== b.side) {
    return a.side === 'AWAY' ? -1 : 1;
  }

  if (a.PA_round !== b.PA_round) {
    return a.PA_round - b.PA_round;
  }

  return a.PA_order - b.PA_order;
});

const Control = ({ resizeZone, setResizeZone }) => {
  const dispatch = useLocalDispatch();
  const { scoutingMode, durationPerPitch, muted, pitches } = useLocalState();
  const [videoFile, setVideoFile] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const [csvRefresh, setCSVRefresh] = useState(0);
  const [peIndex, setPEIndex] = useState(-1);

  const query = useQuery();
  const [seasonTitle, setSeasonTitle] = useState('');
  const [seasonUniqid, setSeasonUniqid] = useState('');
  const [lockGame, setLockGame] = useState(false);

  const [loading, setLoading] = useState(false);

  const [managerForm, setManagerForm] = useState({});

  useEffect(() => {
    if (!lockGame) {
      dispatch({ type: ACTIONS.SET_PITCHES, payload: [] });
      return;
    }

    dispatch({
      type: ACTIONS.SET_PITCHES,
      payload: R.flatten(
        sortPAList(lockGame.PA_list).map((PA, PAIndex) => {
          let s = 0;
          let b = 0;
          const pitchEvents = R.filter((e) => !!e.pitch, PA.events);
          return pitchEvents.map((event, eventIndex) => {
            if (eventIndex > 0) {
              const preEvent = pitchEvents[eventIndex - 1];
              if (preEvent.is_strike && s < 2) {
                s++;
              } else if (preEvent.is_ball && b < 3) {
                b++;
              }
            }
            return {
              PAIndex,
              eventIndex: PA.events.indexOf(event),
              side: PA.side,
              inning: PA.inning,
              startOuts: PA.start_outs,
              startAwayScore: PA.start_away_score,
              startHomeScore: PA.start_home_score,
              s,
              b,
              outs: PA.outs,
              result: eventIndex === pitchEvents.length - 1 ? PA.result : '',
              awayScore: PA.away_score,
              homeScore: PA.home_score,
              video_signin_at: '',
              video_contact_at: '',
              video_fly_catch_at: '',
              video_ground_catch_at: '',
              video_sb_throw_at: '',
              video_sb_catch_at: '',
              ...event,
            };
          });
        }),
      ),
    });

    const publicVideoInfo = lockGame.info.public_video_url.split(';');
    setManagerForm({
      strikezone_completeness: lockGame.info.strikezone_completeness,
      public_video_shift: publicVideoInfo[0],
      public_video_url: publicVideoInfo.length > 1 ? publicVideoInfo[1] : '',
    });
  }, [dispatch, lockGame]);

  const handleLockGame = ({ seasonUniqid: newSeasonUniqid, game }) => {
    setSeasonTitle(game.season.title);
    setSeasonUniqid(newSeasonUniqid);
    setLockGame(game);

    const gameDate = moment(game.info.started_at);
    const videoPath = `${gameDate.format('YYYY')}/G${game.seq}_${gameDate.format('YYYYMMDD')}.mp4`;
    LocalResource.getVideoURL(videoPath)
      .then(({ data: videoURL }) => {
        const video = document.querySelector(`#${videoID}`);
        video.onloadedmetadata = () => {
          dispatch({ type: ACTIONS.UPDATE_VIDEO, payload: video.duration });
          setVideoLoaded(true);
        };
        video.addEventListener('error', () => {
          setVideoLoaded(false);
        });
        video.setAttribute('src', videoURL);
        video.load();
        // video.playbackRate = 1.25;
        video.volume = 0;
      })
      .catch((e) => {
        toast(`無對應影片 ${e}`, { status: 'error', second: 5 });
      });
    // const videoURL =
    //   process.env.REACT_APP_CPBL_BUCKET_URL +
    //   `/${gameDate.format('YYYY')}/G${game.seq}_${gameDate.format('YYYYMMDD')}.mp4`;
  };

  const handleVideoPlaybackRate = (e) => {
    const video = document.querySelector(`#${videoID}`);
    if (!video) {
      return;
    }

    video.playbackRate = parseFloat(e.target.value);
  };

  const handleVideoSelect = (e) => {
    const file = e.target.files?.[0];
    const video = document.querySelector(`#${videoID}`);
    if (!file || !video) {
      return;
    }

    setVideoFile(file);
    video.onloadedmetadata = () => {
      dispatch({ type: ACTIONS.UPDATE_VIDEO, payload: video.duration });
      setVideoLoaded(true);
    };
    video.addEventListener('error', () => {
      setVideoLoaded(false);
    });
    video.setAttribute('src', URL.createObjectURL(file));
    video.load();
    // video.playbackRate = 1.25;
    video.volume = 0;
  };

  const handleMuted = (e) => {
    e.target.blur();
    const video = document.querySelector(`#${videoID}`);
    if (!video) {
      return;
    }

    video.volume = video.volume <= 0 ? 1.0 : 0.0;
  };

  const handleEntryPointSelect = () => {
    setTimeout(() => {
      document.querySelector('#entry-point-input').click();
    }, 200);
  };

  const handleCSVSelect = (peIndex) => () => {
    setPEIndex(peIndex);
    setTimeout(() => {
      document.querySelector('#csv-input').click();
    }, 200);
  };

  const handleEntryPointRead = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const CSVRows = CSVToArray(event.target.result);
      const toUpdateRows = parseInputStrikezoneCSV(pitches, CSVRows);
      if (toUpdateRows.length !== pitches.length) {
        toast('進壘點球數與比賽球數不符', { status: 'error', second: 5 });
        return;
      }

      console.log(toUpdateRows);
      dispatch({
        type: ACTIONS.SET_PITCHES,
        payload: pitches.map((p, i) => ({
          ...p,
          ...toUpdateRows[i],
          pitch: {
            ...p.pitch,
            ...toUpdateRows[i].pitch,
          },
        })),
      });
    };
    reader.readAsText(file, 'utf8');
    setCSVRefresh(csvRefresh + 1);
  };

  const handleCSVRead = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const CSVRows = CSVToArray(event.target.result);
      // [seq, timestamp, Time, STATUS, VELO, RPM, EV, AWAY_SCORE, HOME_SCORE, INNING, SIDE, S, B, O, BASE1, BASE2, BASE3]
      if (CSVRows.length <= 1) {
        return;
      }

      const timeline = R.filter((r) => r.length >= 17 && r[3] !== 'INVALID', CSVRows.slice(1)).map((row) => ({
        startTime: row[2],
        start: timeDisplayReverse(row[2]),
        velocity: row[4],
        RPM: row[5] !== '0' ? row[5] : '',
        EV: row[6] !== '0' ? row[6] : '',
        awayScore: parseInt(row[7]),
        homeScore: parseInt(row[8]),
        inning: parseInt(row[9]),
        side: row[10].toUpperCase() === 'TOP' ? 'AWAY' : 'HOME',
        S: parseInt(row[11]),
        B: parseInt(row[12]),
        O: parseInt(row[13]),
        first: row[14] === 'true',
        second: row[15] === 'true',
        third: row[16] === 'true',
      }));
      console.log(timeline);
      dispatch({ type: ACTIONS.TIMELINE_OVERWRITE, payload: { startIndex: peIndex, timeline } });
    };
    reader.readAsText(file, 'BIG5');
    setCSVRefresh(csvRefresh + 1);
  };

  const handleCSVWrite = () => {
    const PAList = sortPAList(lockGame.PA_list);
    const pitchRows = exportPitchRows(PAList, pitches);
    pitchRows.forEach((row) => {
      row.batter = row.batter.name;
      return;
    });
    saveAs(
      new Blob(
        [
          convertArrayToCSV(pitchRows, {
            header: [
              // 基本資訊
              '局數',
              '主客',
              '打擊輪',
              '打序',
              '打者',
              '流水號',
              // 時間軸
              '捕手SignIn',
              '投球啟動',
              '擊球瞬間',
              '飛球接/落',
              '滾地接捕',
              '盜壘捕手',
              '盜壘野手',
              // 投球相關
              '球速 KPH',
              '球種',
              '轉速 RPM',
              '進壘x',
              '進壘y',
              // 打擊相關
              '擊初 EV',
              '擊球仰角',
              '擊球強勁',
              '擊球落點代號',
              '擊球落點x',
              '擊球落點y',
              '防守順序',
            ],
          }),
        ],
        { type: 'text/plain;charset=utf-8' },
      ),
      `G${lockGame.seq}_${moment(lockGame.info.started_at).format('YYYY-MM-DD')}_STRIKEZONE-SNAPSHOT.csv`,
    );
  };

  const handleGameUpdate = (e) => {
    e.target.blur();
    setLoading(true);
    const PAList = sortPAList(lockGame.PA_list);
    const pitchRows = exportPitchRows(PAList, pitches);
    FormalGameResource.updateGameStrikezone({ seasonUniqid, gameUniqid: lockGame.uniqid, pitches: pitchRows })
      .then(() => {
        toast('更新成功', { status: 'success', second: 5 });
      })
      .catch((e) => {
        toast(`更新失敗｜${e.toString()}，請與工程師確認`, { status: 'error', second: 5 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleManagerUpdate = (e) => {
    e.target.blur();
    setLoading(true);
    FormalGameResource.updateGamePublicStrikezone({ seasonUniqid, gameUniqid: lockGame.uniqid, managerForm })
      .then(() => {
        toast('更新成功', { status: 'success', second: 5 });
      })
      .catch((e) => {
        toast('更新失敗，請與工程師確認', { status: 'error', second: 5 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StyledDiv id="control">
      <input
        key={`entry-point-${csvRefresh}`}
        id="entry-point-input"
        type="file"
        accept=".csv"
        onChange={handleEntryPointRead}
      />
      <input key={`csv-${csvRefresh}`} id="csv-input" type="file" accept=".csv" onChange={handleCSVRead} />
      <div className="btns reset-btns">
        {!lockGame ? (
          <Fragment>
            <span></span>
            <span></span>
            <span></span>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <Button
                width="auto"
                mini
                color="transparentWithBorder"
                onClick={() => {
                  setLoading(true);
                  query.delete('s');
                  query.delete('g');
                  setTimeout(() => {
                    setLockGame(null);
                    setLoading(false);
                  }, 300);
                }}
              >
                重設比賽
              </Button>
              <Button mini width="auto" color="transparentWithBorder" onClick={handleCSVSelect(0)}>
                載入分析檔案
              </Button>
              <Button mini width="auto" color="transparentWithBorder" onClick={handleEntryPointSelect}>
                使用CSV覆蓋
              </Button>
            </div>
            {
              <span className={`video-loaded-${videoLoaded ? 'true' : 'false'}`}>
                {seasonTitle}
                <br />
                {`${moment(lockGame.info.started_at).format('YYYYMMDD')} G${lockGame.seq}`}
                <br />
                {videoLoaded ? '成功讀取，嚴禁私自下載檔案' : '無法讀取影片檔'}
              </span>
            }
            {/* {!videoFile ? (
              <label>
                選擇影片
                <input id="video-input" type="file" accept="video/*" onChange={handleVideoSelect} />
              </label>
            ) : (
              <Button
                width="auto"
                mini
                color="transparentWithBorder"
                onClick={(e) => {
                  e.target.blur();
                  setVideoFile(null);
                  dispatch({ type: ACTIONS.RESET_GAME });
                }}
              >
                重設影片
              </Button>
            )} */}
            <div>
              <label>
                調整好球帶
                <input
                  type="checkbox"
                  onChange={(e) => {
                    e.target.blur();
                    setResizeZone(!resizeZone);
                  }}
                  checked={resizeZone}
                />
              </label>
              <label>
                靜音
                <input type="checkbox" onClick={handleMuted} checked={muted} />
              </label>
              <br />
              <span>播放速度</span>
              {[0.5, 1, 1.5, 2, 4].map((r, i) => (
                <Fragment key={r}>
                  {(i + 1) % 4 === 0 && <br />}
                  <label>
                    <input type="radio" name="playback-rate" value={r} onClick={handleVideoPlaybackRate} />
                    {r}
                  </label>
                </Fragment>
              ))}
            </div>
          </Fragment>
        )}
      </div>
      {!lockGame ? (
        <Prepare setLockGame={handleLockGame} />
      ) : loading ? (
        <Loading width="100%" />
      ) : (
        <Fragment>
          <div className="keys-explain">
            <div>
              <label>
                開始/暫停
                <KeyHint>Space</KeyHint>
              </label>
              <label>
                時間插入
                <KeyHint>I</KeyHint>
              </label>
            </div>
            <div>
              <strong>需指定球｜</strong>
              <label>
                整體前移
                <KeyHint>&#8592;</KeyHint>
              </label>
              <label>
                整體後移
                <KeyHint>Enter</KeyHint>
              </label>
              <label>
                刪除
                <KeyHint>Del</KeyHint>
              </label>
            </div>
          </div>
          <Pitches handleCSVSelect={handleCSVSelect} handleEntryPointSelect={handleEntryPointSelect} />
          <div className="btns update-btns">
            {scoutingMode && (
              <Fragment>
                <span>逐球秒數</span>
                <Input
                  value={durationPerPitch}
                  onChange={(e) => dispatch({ type: ACTIONS.UPDATE_PITCH_DURATION, payload: e.target.value })}
                />
                <span></span>
              </Fragment>
            )}
            <label>
              標記模式
              <input
                type="checkbox"
                onChange={(e) => {
                  e.target.blur();
                  dispatch({ type: ACTIONS.SCOUT });
                }}
                checked={scoutingMode}
              />
            </label>
            <Button width="auto" mini color="transparentWithBorder" onClick={handleCSVWrite}>
              下載 CSV 檔
            </Button>
            <Button width="auto" mini color="transparentWithBorder" onClick={handleGameUpdate}>
              更新系統資料
            </Button>
          </div>
          <ManagerForm
            pitches={pitches}
            managerForm={managerForm}
            setManagerForm={setManagerForm}
            handleManagerUpdate={handleManagerUpdate}
          />
        </Fragment>
      )}
    </StyledDiv>
  );
};

export default Control;
