import { flatten } from 'ramda';
import styled from 'styled-components';
import { useFilterState } from '../hooks/useFilter';
import { getZonemap } from '../utils/zone';
import AtBatTable from '../AtBatTable';
import PitchTable from '../PitchTable';
import StatsTable from '../StatsTable';
import ZoneTable from '../ZoneTable';
import Droppoint from '../Droppoint';
import LocationCode from '../LocationCode';
import JudgeCall from './JudgeCall';
import BallType from './BallType';
import Heatmap from './Heatmap';
import AvgZonemap from './AvgZonemap';
import Multilingual from '../../../utils/Multilingual';
import Zonemap from './Zonemap';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  h3 {
    margin: 1rem 0 0.5rem;
  }
`;

export const matchBallCount = {
  first: (ball) => ball.ballCountB4Pitch === 0,
  twoStrike: (ball) => ball.strikeB4Pitch === 2,
  threeBall: (ball) => ball.ballB4Pitch === 3,
  full: (ball) => ball.strikeB4Pitch === 2 && ball.ballB4Pitch === 3,
  pitcherLead: (ball) => ball.strikeB4Pitch - ball.ballB4Pitch > 0,
  batterLead: (ball) => ball.ballB4Pitch - ball.strikeB4Pitch > 0,
  even: (ball) => ball.ballCountB4Pitch !== 0 && ball.ballB4Pitch === ball.strikeB4Pitch,
};

export const transform4Display = ({
  perBall,

  side,
  position,
  selectedPlayers,
  selectedOpponents,
  selectedInnings,
  selectedBases,
  selectedOuts,
  selectedZones,
  selectedHands,
  selectedBatterActions,
  selectedJudgeCalls,
  selectedBallTypes,
  selectedBallDetailTypes,
  selectedBatterResults,
  selectedContacts,
  selectedBallCounts,
}) => {
  const perBallCommonFilter = (perBallInProcess) => {
    return perBallInProcess
      .filter(({ inning }) => selectedInnings.includes(inning))
      .filter(({ bases }) => selectedBases.includes(bases))
      .filter(({ outs }) => selectedOuts.includes(outs))
      .filter(({ batterAction }) => {
        switch (batterAction) {
          case 'SWTIP':
          case 'INFIELD':
          case 'OUTFIELD':
            return selectedBatterActions.includes('contact');
          default:
            return selectedBatterActions.includes(batterAction);
        }
      })
      .filter(({ judgeCall }) => selectedJudgeCalls.includes(judgeCall))
      .filter(({ ballType }) => selectedBallTypes.includes(ballType))
      .filter(({ ballDetailType }) => selectedBallDetailTypes.includes(ballDetailType))
      .filter(({ batterResult }) => {
        switch (batterResult) {
          case '1B':
          case '2B':
          case '3B':
          case 'HR':
          case '':
          case 'SO':
            return selectedBatterResults.includes(batterResult);
          case 'uBB':
          case 'IBB':
          case 'HBP':
            return selectedBatterResults.includes('BB');
          case 'FO':
          case 'GO':
          case 'GIDP':
            return selectedBatterResults.includes('outs');
          default:
            return selectedBatterResults.includes('others');
        }
      })
      .filter(({ ballContact }) => selectedContacts.includes(ballContact))
      .filter((ball) => {
        for (let ballCountKey of selectedBallCounts.filter((key) => !!matchBallCount[key])) {
          if (!matchBallCount[ballCountKey](ball)) {
            continue;
          }
          return true;
        }
        return false;
      });
  };
  const resolvedPerBall =
    position === 'fielder'
      ? perBallCommonFilter(
          perBall
            .filter(({ currentSide }) => currentSide === side)
            .filter((ball) => selectedPlayers.includes(ball.batter) && selectedOpponents.includes(ball.pitcher))
            .filter(({ pHand }) => selectedHands.includes(pHand)),
        )
      : perBallCommonFilter(
          perBall
            .filter(({ currentSide }) => currentSide !== side)
            .filter((ball) => selectedPlayers.includes(ball.pitcher) && selectedOpponents.includes(ball.batter))
            .filter(({ bHand }) => selectedHands.includes(bHand)),
        );
  const { heart, shadow, shadows, chase, chases, waste } = getZonemap(resolvedPerBall);
  const zoneMap = {
    ...heart,
    shadow: shadow.inside,
    shadows,
    chase: chase.inside,
    chases,
    waste,
  };
  const resolvedZonemap = flatten(selectedZones.filter((zone) => zone !== '0').map((zone) => [...zoneMap[zone]]));
  const batterResultOnly = getZonemap(
    resolvedZonemap.filter(
      ({ batterResult }) => batterResult !== 'NONE' && batterResult !== undefined && batterResult !== '',
    ),
  );

  // console.log(resolvedPerBall);
  return {
    resolvedZonemap,
    batterResultOnly,
    zoneMap,
  };
};

export default function StrikeZone({ perBall }) {
  const {
    side,
    position,
    selectedPlayers,
    selectedOpponents,
    selectedInnings,
    selectedBases,
    selectedOuts,
    selectedZones,
    selectedHands,
    selectedBatterActions,
    selectedJudgeCalls,
    selectedBallTypes,
    selectedBallDetailTypes,
    selectedBatterResults,
    selectedContacts,
    selectedBallCounts,
  } = useFilterState();
  const { resolvedZonemap, batterResultOnly, zoneMap } = transform4Display({
    perBall,

    side,
    position,
    selectedPlayers,
    selectedOpponents,
    selectedInnings,
    selectedBases,
    selectedOuts,
    selectedZones,
    selectedHands,
    selectedBatterActions,
    selectedJudgeCalls,
    selectedBallTypes,
    selectedBallDetailTypes,
    selectedBatterResults,
    selectedContacts,
    selectedBallCounts,
  });

  return (
    <div>
      <h3>{Multilingual(`GAME.BATTER_ANGLE`)}</h3>
      <StyledDiv>
        <JudgeCall resolvedPerBall={resolvedZonemap} />
        <BallType resolvedPerBall={resolvedZonemap} />
        <Zonemap zoneMap={zoneMap} total={resolvedZonemap.length} />
        <Heatmap resolvedPerBall={resolvedZonemap} />
      </StyledDiv>
      <StyledDiv>
        <AvgZonemap zoneMap={batterResultOnly} />
        <Droppoint resolvedPerBall={resolvedZonemap} />
        <LocationCode resolvedPerBall={resolvedZonemap} />
      </StyledDiv>
      {position === 'pitcher' ? (
        <PitchTable resolvedPerBall={resolvedZonemap} />
      ) : (
        <AtBatTable resolvedPerBall={resolvedZonemap} />
      )}
      <StatsTable resolvedPerBall={resolvedZonemap} />
      <ZoneTable resolvedPerBall={resolvedZonemap} zoneMap={zoneMap} />
    </div>
  );
}
