import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FORMAL_NAV_BAR_INFO } from './FormalNavBarConsts';
import HamburgerMenu from './HamburgerMenu';
import HamburgerIcon from './HamburgerIcon';
import { TABLET_WIDTH, MIN_XL_WIDTH, COLOR } from '../../elements/Const';
import logoSVG from '../../elements/static/logo.svg';
import IconNavAvatarSVG from '../../elements/static/IconNavAvatar.svg';

const StyledNavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  letter-spacing: 1px;
  background: ${COLOR.home};
  min-height: 4rem;

  .left-box {
    display: flex;
    align-items: center;
    .current-item {
      color: #fcfcfc;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 3px;
      &:hover {
        opacity: 0.8;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      &--pic {
        width: 2rem;
        margin-right: 1rem;
        &:hover {
          opacity: 0.8;
        }
      }
      &--text {
        color: rgb(10, 74, 154, 1);
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 3px;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .link-btn {
      margin: 0 0.2rem;
    }
  }

  .right-box {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    .link-btn {
      margin: 0 0.2rem;
    }

    .greet {
      display: flex;
      padding-left: 1rem;
      border-left: 1px solid rgb(10, 74, 154, 0.3);
      color: #fff;
      justify-content: center;
      align-items: center;
      position: relative;
      &--name {
        margin-left: 0.5rem;
      }
      &--pic {
        margin-left: 0.5rem;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: block;
      }

      &--btn-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: rgba(2, 27, 66, 1);

        &:last-child {
          border-radius: 0 0 4px 4px;
        }
      }
      &--btn {
        margin: 0.5rem 0.2rem;
        padding: 0.2rem 1.3rem;
        color: #fff;
        font-weight: bold;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      &:hover,
      &:focus {
        .greet--btn-list {
          z-index: 10000;
          display: flex;
        }
      }
    }
  }

  .nav-items {
    display: flex;
    .link-btn {
      display: block;
      position: relative;
      color: #fcfcfc;
      padding: 0.2rem 1.3rem;
      font-weight: bold;
      width: 100px;

      .link-list {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 4px;
        background: rgba(2, 27, 66, 1);
        padding: 0 5px;
        .link-btn {
          width: 100%;
          margin: 0.5rem 0;
          padding: 0.2rem 0.5rem;
          border-top: 1px solid rgb(10, 74, 154, 0.3);
        }
      }

      &:hover,
      &:focus {
        cursor: pointer;
        color: ${COLOR.primary};
        .link-list {
          z-index: 10000;
          display: block;
          box-shadow: 1px 1px 1px black;
        }
      }

      &.list-btn:hover {
        color: #fcfcfc;
      }

      > span {
        white-space: nowrap;
      }
    }
  }

  .link-btn.main {
    white-space: nowrap;
  }

  @media (max-width: ${MIN_XL_WIDTH}) {
    .left-box .link-btn {
      display: none;
    }
  }

  @media (max-width: ${TABLET_WIDTH}) {
    .left-box {
      .logo,
      .current-item {
        display: none;
      }
      .nav-items {
        display: none;
      }
    }

    .right-box {
      .nav-items {
        display: none;
      }
      .greet {
        border: none;
      }
    }
  }
`;

const NavBarWrapper = styled.div`
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: transform 0.3s;
`;

let prevScrollpos = window.pageYOffset;

const FormalNavBar = ({ userInfo = {}, hideItems = false, current = {}, NavAppend = null }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isHambugerOpen, setIsHambugerOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const hasNavWrapper = !!document.getElementById('navbar-wrapper');
      if (prevScrollpos > currentScrollPos && hasNavWrapper) {
        document.getElementById('navbar-wrapper').style.transform = 'translateY(0)';
      } else if (currentScrollPos > 100 && hasNavWrapper) {
        document.getElementById('navbar-wrapper').style.transform = `translateY(-${
          document.getElementById('navbar').clientHeight
        }px)`;
      }
      prevScrollpos = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsLogin(!!userInfo.username);
  }, [userInfo]);

  const displayItem = (itemKey) => {
    if (FORMAL_NAV_BAR_INFO[itemKey].links && FORMAL_NAV_BAR_INFO[itemKey].links.length > 0) {
      return (
        <div key={itemKey} className="link-btn list-btn">
          {FORMAL_NAV_BAR_INFO[itemKey].text}
          <div className="link-list">
            {FORMAL_NAV_BAR_INFO[itemKey].links.map((link) => (
              <a
                key={link.url}
                className="link-btn"
                href={link.url}
                {...(link.isExternal ? { target: '_blank', rel: 'noreferrer noopenner' } : {})}
              >
                {link.text}
              </a>
            ))}
          </div>
        </div>
      );
    }

    return (
      <a key={itemKey} className="link-btn main" href={FORMAL_NAV_BAR_INFO[itemKey].url}>
        {FORMAL_NAV_BAR_INFO[itemKey].text}
      </a>
    );
  };

  return (
    <NavBarWrapper id="navbar-wrapper">
      <StyledNavBar id="navbar">
        <div className="left-box">
          <a className="logo" href={FORMAL_NAV_BAR_INFO.LOGO.url}>
            <img className="logo--pic" src={logoSVG?.src ?? logoSVG} alt="logo" />
            <div className="logo--text"> {FORMAL_NAV_BAR_INFO.LOGO.text} </div>
          </a>
          {current?.url && (
            <a className="current-item" href={current.url}>
              {current.text}
            </a>
          )}
        </div>
        <div className="right-box">
          <div className="nav-items">
            {!hideItems && ['ESHOP', 'ENT', 'GAME', 'LEADERBOARD', 'TOOL', 'NEW_STATS', 'MEMBER'].map(displayItem)}
            {!isLogin && (
              <a className="link-btn" href={FORMAL_NAV_BAR_INFO.LOG_IN.url}>
                {FORMAL_NAV_BAR_INFO.LOG_IN.text}
              </a>
            )}
          </div>
          {isLogin && userInfo && (
            <div className="greet">
              <div className="greet--name">Hi, {!userInfo.firstName ? '' : userInfo.firstName}</div>
              <img
                className="greet--pic"
                src={userInfo.avatarUrl ? userInfo.avatarUrl : IconNavAvatarSVG?.src ?? IconNavAvatarSVG}
                alt=""
              />
              <div className="greet--btn-list">
                <a className="greet--btn" href={FORMAL_NAV_BAR_INFO.ACCOUNT_MANAGEMENT.url}>
                  {FORMAL_NAV_BAR_INFO.ACCOUNT_MANAGEMENT.text}
                </a>
                {userInfo.isAdmin === 'ADMIN' && (
                  <a className="greet--btn" href={FORMAL_NAV_BAR_INFO.MEMBER_MANAGEMENT.url}>
                    {FORMAL_NAV_BAR_INFO.MEMBER_MANAGEMENT.text}
                  </a>
                )}
                <a className="greet--btn" href={FORMAL_NAV_BAR_INFO.LOG_OUT.url}>
                  {FORMAL_NAV_BAR_INFO.LOG_OUT.text}
                </a>
              </div>
            </div>
          )}
        </div>
      </StyledNavBar>
      <HamburgerIcon isHambugerOpen={isHambugerOpen} setIsHambugerOpen={setIsHambugerOpen} />
      <HamburgerMenu
        isHambugerOpen={isHambugerOpen}
        isLogin={isLogin}
        userInfo={userInfo}
        hideItems={hideItems}
        current={current}
      />
      {NavAppend ? <NavAppend /> : <></>}
    </NavBarWrapper>
  );
};

FormalNavBar.defaultProps = {};

export default FormalNavBar;
