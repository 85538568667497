import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { fullZoneScale, eFullZoneDefaultXY } from '../../Const';
import { getResizerCommonStyle } from '../../styled';
import { usePerBallState } from '../../useResultPanel';

const StyledDiv = styled.div`
  z-index: 999;
  .e-full-zone {
    background: ${(props) => (props.resizable ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.3)')};
    width: ${eFullZoneDefaultXY.width}px;
    height: ${eFullZoneDefaultXY.height}px;
    position: absolute;
    top: ${eFullZoneDefaultXY.y};
    left: ${eFullZoneDefaultXY.x};

    ${getResizerCommonStyle('blue')}
  }
`;

const ballWidth = 10;
const CurrentBall = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${ballWidth}px;
  height: ${ballWidth}px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}%;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  pointer-events: none;
`;

const zoneClass = '.e-full-zone';

const EStrikeFullZone = ({ isOpen, resizable, setPitchPosition, eFullZoneXY }) => {
  const { perBall, editingIndex } = usePerBallState();

  const fullZoneRef = useRef(null);
  const [refWaiting, setRefWaiting] = useState(0);
  const [ballPosition, setBallPosition] = useState(null);

  useEffect(() => {
    const element = document.querySelector(zoneClass);
    element.style.width = eFullZoneXY.width + 'px';
    element.style.height = eFullZoneXY.height + 'px';
    element.style.left = eFullZoneXY.x + 'px';
    element.style.top = eFullZoneXY.y + 'px';
  }, [eFullZoneXY]);

  useEffect(() => {
    if (!fullZoneRef || !fullZoneRef.current) {
      setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    const el = document.getElementById('e-full-zone-div');
    const setPitch = setPitchPosition('E');
    el.addEventListener('click', setPitch);
    return () => {
      el.removeEventListener('click', setPitch);
    };
  }, [refWaiting, setPitchPosition]);

  useEffect(() => {
    if (!perBall[editingIndex]) {
      setBallPosition(null);
      return;
    }

    const eZoneResult = perBall[editingIndex].eZoneResult;
    console.log(eZoneResult);
    if (!eZoneResult || eZoneResult.width === 0 || eZoneResult.height === 0) {
      setBallPosition(null);
      return;
    }

    const delta = ((fullZoneScale - 1) / 2) * 100;
    // 要把好球帶的比例換成是有效區域的比例
    setBallPosition({
      left: (delta + parseFloat(eZoneResult.x)) / fullZoneScale,
      top: (delta + parseFloat(eZoneResult.y)) / fullZoneScale,
    });
  }, [perBall, editingIndex]);

  return (
    <StyledDiv
      ref={fullZoneRef}
      id="e-full-zone-div"
      style={{ display: isOpen ? 'block' : 'none' }}
      resizable={resizable}
    >
      <div className="e-full-zone">
        <div className="e-full-zone--resizers">
          <div className="e-full-zone--resizer e-full-zone--top-left" style={{ display: 'none' }}></div>
          <div className="e-full-zone--resizer e-full-zone--top-right" style={{ display: 'none' }}></div>
          <div className="e-full-zone--resizer e-full-zone--bottom-left" style={{ display: 'none' }}></div>
          <div className="e-full-zone--resizer e-full-zone--bottom-right" style={{ display: 'none' }}></div>
          {!!ballPosition && <CurrentBall top={ballPosition.top} left={ballPosition.left} />}
        </div>
      </div>
    </StyledDiv>
  );
};

export default EStrikeFullZone;
