// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA6rVkOgVUDM-qVOxKSKrTNqXW8m5pU1ZE',
  authDomain: 'rebas-tw.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: 'rebas-tw',
  storageBucket: 'rebas-tw.appspot.com',
  messagingSenderId: '226544147538',
  appId: '1:226544147538:web:373c826ce90d0dc695938b',
  measurementId: 'G-FWT8LP894Q',
};

export const getRealtimeDB = () => {
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  return database;
};
