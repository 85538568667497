export const MIN_XL_WIDTH = '1400px';
export const MIN_XL_WIDTH_PIXEL = 1400;
export const TABLET_WIDTH = '991.98px';
export const TABLET_WIDTH_PIXEL = 991.98;
export const TABLET_LANDSCAPE_WIDTH = '1023.98px';
export const TABLET_LANDSCAPE_WIDTH_PIXEL = 1023.98;
export const BEFORE_MEDIUM_WIDTH = '767.98px';
export const BEFORE_MEDIUM_WIDTH_PIXEL = 767.98;
export const PHONE_WIDTH = '575.98px';
export const PHONE_WIDTH_PIXEL = 575.98;

export const PROPER_CONTENT_WIDTH = '960px';

export const FONT_COLOR = '#4f4f4f';

export const COLOR = {
  home: '#021b42',
  primary: '#E8373D',
  secondary: '#0F1D50',

  darkred: '#7d1212',
  darkblue: '#0d1d50',
  green: '#38A169',

  BALL_BALL: '#19b158',
  BALL_STRIKE: '#c83820',
  BALL_IN_PLAY: '#387cde',

  WHITE: '#fafafa',

  LINEAR_BG: 'linear-gradient(135deg, rgb(125 18 18), rgb(6 43 90) 75%)',
};

export const rgba2rgb = (rgba, bg = '#fff') => {
  const getValues = (target) => {
    const hex = /^#([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{0,2})$/i.exec(target);
    const rgbaSplit = /^rgba\(([\d]{3}), *([\d]{3}), *([\d]{3}),? *([\d]{0,3})\)$/i.exec(target);
    const isHex = !!hex;
    return {
      r: isHex ? parseInt(hex[1].length === 2 ? hex[1] : `${hex[1]}${hex[1]}`, 16) : parseInt(rgbaSplit[1], 10),
      g: isHex ? parseInt(hex[2].length === 2 ? hex[2] : `${hex[2]}${hex[2]}`, 16) : parseInt(rgbaSplit[2], 10),
      b: isHex ? parseInt(hex[3].length === 2 ? hex[3] : `${hex[3]}${hex[3]}`, 16) : parseInt(rgbaSplit[3], 10),
      a: isHex
        ? hex[4] !== ''
          ? parseInt(hex[4].length === 2 ? hex[4] : `${hex[4]}${hex[4]}`, 16)
          : 255
        : rgbaSplit[4] !== ''
        ? parseInt(rgbaSplit[4], 10)
        : 255,
    };
  };

  const { r, g, b, a } = getValues(rgba);
  const alpha = a / 255;
  const { r: _r, g: _g, b: _b } = getValues(bg);

  const merge = (front, back) => {
    return Math.round((1 - alpha) * back + alpha * front);
  };

  return `rgb(${merge(r, _r)},${merge(g, _g)},${merge(b, _b)})`;
};

export const getNameDisplay = (firstName, lastName) => {
  if (firstName && lastName && firstName.match(/^[a-zA-Z-]*$/) && lastName.match(/^[a-zA-Z-]*$/)) {
    return `${firstName}, ${lastName}`;
  }

  return `${lastName}${firstName}`;
};

export const getPlayerLink = (playerUniqid, query = '') => {
  return `${process.env.REACT_APP_MAINPAGE_URL}/player/${playerUniqid}${!query ? '' : `?${query}`}`;
};

export const languagePreferKey = 'REBAS::LANGUAGE_PREFER';

const maxLength = 1920;
const maxSize = 1024 * 1024;
export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const { type, name } = file;
    const fileType = type.split('/')[0];
    if (fileType !== 'image') {
      reject('Wrong file type');
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = (fileInfo) => {
      const img = new Image();
      img.src = fileInfo.target.result;
      img.onload = function () {
        const originWidth = this.width;
        const originHeight = this.height;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        let newWidth = originWidth;
        let newHeight = originHeight;
        if (newWidth > maxLength) {
          newHeight = (maxLength * newHeight) / newWidth;
          newWidth = maxLength;
        }
        if (newHeight > maxLength) {
          newWidth = (maxLength * newWidth) / newHeight;
          newHeight = maxLength;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        context.clearRect(0, 0, newWidth, newHeight);
        context.drawImage(img, 0, 0, newWidth, newHeight);
        const toBlob = (currentQuality) => {
          canvas.toBlob(
            (blob) => {
              if (blob.size > maxSize && currentQuality > 0.5) {
                toBlob(currentQuality - 0.5);
                return;
              }
              resolve(new File([blob], name, { type: 'image/jpeg' }));
            },
            'image/jpeg',
            currentQuality,
          );
        };
        toBlob(1.0);
      };
      img.onerror = () => {
        reject('Unable to load image');
      };
    };
    fileReader.readAsDataURL(file);
  });
};
