import React from 'react';
import styled from 'styled-components';
import DefaultAvatar from '../static/DefaultAvatar.jpg';
import { COLOR, TABLET_WIDTH } from '../Const';

const StyledForm = styled.form`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;

  .icon {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--pic {
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
      display: block;
      margin-bottom: 0.5rem;
      object-fit: cover;
      &.default {
        background-image: url(${DefaultAvatar});
        background-size: cover;
        background-position: center;
      }
    }
    &--box {
      display: flex;
      justify-content: center;
    }
  }

  .cover {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    &--box {
      display: flex;
      justify-content: start;
    }
    &--pic {
      position: relative;
      z-index: 2;
      width: 80%;
      text-align: start;
      &:after {
        content: '';
        display: block;
        padding-bottom: 75%;
      }
      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.default {
        background-image: url(${DefaultAvatar});
        background-size: cover;
        background-position: center;
      }
    }
  }

  .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    label {
      display: block;
      > i {
        margin-left: 0.2rem;
        color: red;
        font-size: 0.8rem;
        white-space: normal;
        &.info {
          color: lightskyblue;
        }
        &.--same-size {
          font-size: inherit;
        }
      }
    }
    .row {
      width: 80%;
      .input-field {
        width: 100%;
      }

      &.--multi-input-field {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
      }
    }
    .input-field {
      width: 80%;
      &.lg {
        width: 100%;
      }

      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      font-size: 1rem;
      > div {
        margin-right: 0.5rem;
      }
      .input-box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        input:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
      .input-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > * {
          margin: 0 0.5rem 0.2rem 0;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .remove-icon-wrapper {
          padding: 0.2rem 0.3rem 0.2rem 0.2rem;
          background-color: rgb(10, 74, 154, 0.1);
          border-radius: 5px;
        }
      }
    }

    .description {
      border: 1px solid #eee;
      border-radius: 5px;
      background-color: white;
      color: ${COLOR.secondary};
      font-size: 0.9rem;
      padding: 0.5rem;
      margin: 0.5rem 0;
      white-space: pre-line;
    }

    .error-msg {
      font-size: 0.8rem;
      color: #e8196e;
    }

    .success-msg {
      font-size: 0.8rem;
      color: green;
    }
  }

  .divider {
    border: 1px #222 solid;
    width: 100%;
    height: 0px;
  }

  input[type='checkbox'] {
    margin: 0 0.1rem;
  }

  input {
    ${(props) => (!props.textAlign ? '' : `text-align: ${props.textAlign};`)}
  }

  @media (max-width: ${TABLET_WIDTH}) {
    flex-direction: column-reverse;
    .icon {
      width: 100%;
    }
    .cover {
      justify-content: center;
      align-items: center;
    }
    .box {
      width: 90%;
      .row {
        width: 100%;
        &.--multi-input-field {
          flex-wrap: wrap;
        }
      }
      .input-field {
        width: 100%;
        &.--multi-input {
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .input-box {
          flex-direction: column;
          input:not(:first-child) {
            margin-left: 0;
            margin-top: 5%;
          }
        }
      }
    }
    &.lg {
      .box {
        width: 100%;
      }
    }
  }
`;

const Form = ({ textAlign, ...restProps }) => {
  return <StyledForm textAlign={textAlign} {...restProps} />;
};

Form.defaultProps = {
  textAlign: '',
};

export default Form;
