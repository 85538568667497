import styled from 'styled-components';
import { useState } from 'react';
import { COLOR } from '~~elements/Const';
import Loading from '~~elements/Loading';
import UnknownError from '~~elements/UnknownError';
import StrikezoneHunterCoverPNG from '~~elements/static/StrikezoneHunterCover.png';
import { ref, query, limitToLast, orderByChild, equalTo } from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import { reverse } from 'ramda';
import MyStrikezoneGames from './components/MyStrikezoneGames';
import StrikezoneGame from './components/StrikezoneGame';
import StrikezoneGameCompare from '../StrikezoneGameCompare';
import FreeTrial from '../FreeTrial';
import Multilingual from '../../utils/Multilingual';

const CoverWidth = 360;

const CoverDiv = styled.div`
  width: ${CoverWidth}px;
  max-width: 66%;
  > img {
    width: 100%;
  }
  margin: 0 auto;
  padding: 1.5rem 0 0;
`;

const BackgroundDiv = styled.div`
  background: linear-gradient(45deg, ${COLOR.home}, ${COLOR.secondary});
  width: 100%;
  position: relative;

  > h2 {
    color: #fafafa;
  }
`;

const FeedDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  position: relative;
  margin: 2rem auto 0;

  > section {
    position: relative;
    margin: 0 auto;
    width: 480px;
    max-width: 95vw;
    height: calc(100vh - 6rem);
    overflow-y: auto;
    background-color: #fafafa;
    border-radius: 0 6px 0 0;
  }
`;

const Feed = ({ realtimeDB }) => {
  const [featuredStrikezoneGames] = useList(
    query(ref(realtimeDB, 'publicGames'), orderByChild('featured'), equalTo('true'), limitToLast(100)),
  );
  const [strikezoneGames, strikezoneGameLoading, strikezoneGameError] = useList(
    query(ref(realtimeDB, 'publicGames'), orderByChild('featured'), equalTo(null), limitToLast(100)),
  );

  const [gameCompareOpen, setGameCompareOpen] = useState(false);
  const [freeTrialOpen, setFreeTrialOpen] = useState(false);

  const closeGameCompare = () => {
    setGameCompareOpen(false);
  };

  const closeFreeTrial = () => {
    setFreeTrialOpen(false);
  };

  if (strikezoneGameError) {
    return (
      <BackgroundDiv>
        <FeedDiv>
          <UnknownError imgWidth="15vw" />
        </FeedDiv>
      </BackgroundDiv>
    );
  }

  if (strikezoneGameLoading) {
    return (
      <BackgroundDiv>
        <CoverDiv>
          <img src={StrikezoneHunterCoverPNG} alt="cover" />
        </CoverDiv>
        <FeedDiv>
          <Loading width="20vw" margin="1rem auto" />
        </FeedDiv>
      </BackgroundDiv>
    );
  }

  return (
    <BackgroundDiv>
      <CoverDiv>
        <img src={StrikezoneHunterCoverPNG} alt="cover" />
      </CoverDiv>
      <MyStrikezoneGames setGameCompareOpen={setGameCompareOpen} setFreeTrialOpen={setFreeTrialOpen} />
      <h2>{Multilingual(`FEED.PUBLIC_LIST`)}</h2>
      <FeedDiv>
        <section>
          <h3>{Multilingual(`FEED.FEATURED`)}</h3>
          {reverse(featuredStrikezoneGames).map((g) => (
            <StrikezoneGame key={g.key} game={g.val()} />
          ))}
        </section>
        <section>
          <h3>{Multilingual(`FEED.NEWEST`)}</h3>
          {reverse(strikezoneGames).map((g) => (
            <StrikezoneGame key={g.key} game={g.val()} />
          ))}
        </section>
        {/* <NoMoreResult /> */}
      </FeedDiv>
      <StrikezoneGameCompare isOpen={gameCompareOpen} toClose={closeGameCompare} />
      <FreeTrial isOpen={freeTrialOpen} toClose={closeFreeTrial} />
    </BackgroundDiv>
  );
};

export default Feed;
