import { Fragment } from 'react';
import { getPercentage, getFillOpacity } from '../Zonemap';

export default function ShadowZone({ x: defaultX, y: defaultY, shadows, total }) {
  const fullWidth = 4 * defaultX;
  const fullHeight = 4 * defaultY;

  let count = 1;
  let zone = [];

  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 2; j++) {
      const x = (j * fullWidth) / 2;
      const y = (i * fullHeight) / 2;
      const percentage = getPercentage(shadows[`${count++}`].length, total);
      const { color, opacity } = getFillOpacity(percentage);

      zone.push(
        <Fragment key={`zone-map-${i}-${j}`}>
          <rect
            x={defaultX + x}
            y={defaultY + y}
            width={fullWidth / 2}
            height={fullHeight / 2}
            stroke="cyan"
            fill={color}
            fillOpacity={opacity}
          ></rect>
          ,
          <text
            dx={defaultX + x + fullWidth / 4}
            dy={i === 0 ? defaultY + 15 : defaultY + fullHeight - 10}
            fontSize={8}
            fill={percentage >= 0 ? 'white' : 'black'}
            textAnchor="middle"
          >
            {percentage >= 0 ? Math.round(percentage * 100) : '-'}
          </text>
        </Fragment>,
      );
    }
  }

  return (
    <g>
      <rect x={defaultX} y={defaultY} width={fullWidth} height={fullHeight} stroke="cyan" fill="white"></rect>
      {zone}
      {/* <rect x={x} y={y} width={4 * x} height={4 * y} stroke="black" fill="cyan"></rect>
      <text dx={3 * x} dy={1.25 * y} fontSize={8} textAnchor="middle">
        {avg >= 0 ? avg : '-'}
      </text> */}
      {/* <g>
        <rect x={x} y={y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={2 * x} y={y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={3 * x} y={y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={4 * x} y={y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={x} y={2 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={4 * x} y={2 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={x} y={3 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={4 * x} y={3 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={x} y={4 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={2 * x} y={4 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={3 * x} y={4 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
        <rect x={4 * x} y={4 * y} width={x} height={y} stroke="black" fill="cyan"></rect>
      </g> */}
    </g>
  );
}
