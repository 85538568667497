import { strikeZoneConfig } from '../StrikezoneGame/constants/base';

export const fullZoneScale = 2.5;
const fullZoneDefaultWidth = 250;
const strikezoneDefaultWidth = fullZoneDefaultWidth / fullZoneScale;

export const eFullZoneDefaultXY = {
  x: 'calc(100vw - 700px)',
  y: '270px',
  width: fullZoneDefaultWidth,
  height: (fullZoneDefaultWidth / strikeZoneConfig.width) * strikeZoneConfig.height,
};
export const eZoneDefaultXY = {
  x: 'calc(100vw - 625px)',
  y: `${
    270 + (((fullZoneDefaultWidth - strikezoneDefaultWidth) / strikeZoneConfig.width) * strikeZoneConfig.height) / 2
  }px`,
  width: strikezoneDefaultWidth,
  height: (strikezoneDefaultWidth / strikeZoneConfig.width) * strikeZoneConfig.height,
};

export const eyeFullZoneDefaultXY = {
  x: 'calc(100vw - 350px)',
  y: '250px',
  width: fullZoneDefaultWidth,
  height: (fullZoneDefaultWidth / strikeZoneConfig.width) * strikeZoneConfig.height,
};
export const eyeZoneDefaultXY = {
  x: 'calc(100vw - 275px)',
  y: `${
    250 + (((fullZoneDefaultWidth - strikezoneDefaultWidth) / strikeZoneConfig.width) * strikeZoneConfig.height) / 2
  }px`,
  width: strikezoneDefaultWidth,
  height: (strikezoneDefaultWidth / strikeZoneConfig.width) * strikeZoneConfig.height,
};

export const ZoneDeviceKey = 'BALLHOUND::ZONE::PREFERENCE';

export const setPanelDraggable = (fullPanelRef) => {
  const fullPanelID = fullPanelRef.current.id;

  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  const el = document.getElementById(fullPanelID);

  const elementInit = (x, y) => {
    // get the mouse cursor position at startup:
    pos3 = x;
    pos4 = y;
  };

  const dragMouseDown = (e) => {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const dragTouchStart = (e) => {
    e = e || window.event;
    e.preventDefault();
    elementInit(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    document.ontouchend = closeDragElement;
    document.ontouchmove = elementTouchDrag;
  };

  const elementMove = (x, y) => {
    // calculate the new cursor position:
    pos1 = pos3 - x;
    pos2 = pos4 - y;
    pos3 = x;
    pos4 = y;
    // set the element's new position:
    const newTop = el.offsetTop - pos2;
    const newLeft = el.offsetLeft - pos1;
    el.style.top = (newTop > 0 ? newTop : 1) + 'px';
    el.style.left = (newLeft > 0 ? newLeft : 1) + 'px';
  };

  const elementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();
    elementMove(e.clientX, e.clientY);
  };

  const elementTouchDrag = (e) => {
    elementMove(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
  };

  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.ontouchend = null;
    document.onmousemove = null;
    document.ontouchmove = null;
  };

  const elDragBtn = document.getElementById(`${fullPanelID}-drag-btn`);
  elDragBtn.onmousedown = dragMouseDown;
  elDragBtn.ontouchstart = dragTouchStart;
};

export const setZoneResize = (zoneClass, classPrepand, debounce, setFullZone, setStrikeZone) => {
  const element = document.querySelector(zoneClass);
  const resizers = document.querySelectorAll(zoneClass + ` .${classPrepand}--resizer`);
  const minimum_size = 20;
  const resizeMousedown = (currentResizer) => (inMobile) => (e) => {
    e.preventDefault();
    const original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
    const original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
    const original_x = element.getBoundingClientRect().left;
    const original_y = element.getBoundingClientRect().top;
    const original_mouse_x = inMobile ? e.changedTouches[0].pageX : e.pageX;
    const original_mouse_y = inMobile ? e.changedTouches[0].pageY : e.pageY;

    const resize = (resizeEvent) => {
      const pageX = inMobile ? resizeEvent.changedTouches[0].pageX : resizeEvent.pageX;
      const pageY = inMobile ? resizeEvent.changedTouches[0].pageY : resizeEvent.pageY;
      if (!pageX || !pageY || pageX <= 0 || pageY <= 0) {
        return;
      }

      if (currentResizer.classList.contains(`${classPrepand}--bottom-right`)) {
        const width = original_width + (pageX - original_mouse_x);
        const height = original_height + (pageY - original_mouse_y);
        if (width > minimum_size) {
          element.style.width = width + 'px';
        }
        if (height > minimum_size) {
          element.style.height = height + 'px';
        }
      } else if (currentResizer.classList.contains(`${classPrepand}--bottom-left`)) {
        const height = original_height + (pageY - original_mouse_y);
        const width = original_width - (pageX - original_mouse_x);
        if (height > minimum_size) {
          element.style.height = height + 'px';
        }
        if (width > minimum_size) {
          element.style.width = width + 'px';
          element.style.left = original_x + (pageX - original_mouse_x) + 'px';
        }
      } else if (currentResizer.classList.contains(`${classPrepand}--top-right`)) {
        const width = original_width + (pageX - original_mouse_x);
        const height = original_height - (pageY - original_mouse_y);
        if (width > minimum_size) {
          element.style.width = width + 'px';
        }
        if (height > minimum_size) {
          element.style.height = height + 'px';
          element.style.top = original_y + (pageY - original_mouse_y) + 'px';
        }
      } else {
        const width = original_width - (pageX - original_mouse_x);
        const height = original_height - (pageY - original_mouse_y);
        if (width > minimum_size) {
          element.style.width = width + 'px';
          element.style.left = original_x + (pageX - original_mouse_x) + 'px';
        }
        if (height > minimum_size) {
          element.style.height = height + 'px';
          element.style.top = original_y + (pageY - original_mouse_y) + 'px';
        }
      }

      const elRect = element.getBoundingClientRect();
      if (!!debounce.value) {
        debounce.value = { x: elRect.left, y: elRect.top, width: elRect.width, height: elRect.height };
        return;
      }

      debounce.value = { x: elRect.left, y: elRect.top, width: elRect.width, height: elRect.height };
      setTimeout(() => {
        setStrikeZone({
          ...debounce.value,
        });

        setFullZone({
          x: debounce.value.x - ((fullZoneScale - 1) * debounce.value.width) / 2,
          y: debounce.value.y - ((fullZoneScale - 1) * debounce.value.height) / 2,
          width: debounce.value.width * fullZoneScale,
          height: debounce.value.height * fullZoneScale,
        });
        delete debounce.value;
      }, 50);
    };

    if (inMobile) {
      const stopResize = () => {
        window.removeEventListener('touchmove', resize);
      };
      window.addEventListener('touchmove', resize);
      window.addEventListener('touchend', stopResize);
    } else {
      const stopResize = () => {
        window.removeEventListener('mousemove', resize);
      };
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    }
  };

  const removeEvents = [];
  for (let i = 0; i < resizers.length; i++) {
    const currentResizer = resizers[i];
    const listenEvent = resizeMousedown(currentResizer);
    const mouseListenEvent = listenEvent(false);
    const touchListenEvent = listenEvent(true);
    currentResizer.addEventListener('mousedown', mouseListenEvent);
    currentResizer.addEventListener('touchstart', touchListenEvent);
    removeEvents.push(() => currentResizer.removeEventListener('mousedown', mouseListenEvent));
    removeEvents.push(() => currentResizer.removeEventListener('touchstart', touchListenEvent));
  }

  return removeEvents;
};

export const BasesOptions = {
  0: {
    value: '0',
    label: '___',
  },
  1: {
    value: '1',
    label: '1__',
  },
  2: {
    value: '2',
    label: '_2_',
  },
  4: {
    value: '4',
    label: '__3',
  },
  3: {
    value: '3',
    label: '12_',
  },
  5: {
    value: '5',
    label: '1_3',
  },
  6: {
    value: '6',
    label: '_23',
  },
  7: {
    value: '7',
    label: '123',
  },
};
