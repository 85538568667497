import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: ${(props) => props.width};
  /* height: 2rem; */
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid transparent;
  border-color: ${(props) => (props.isInvalid ? '#FA339D' : '')};
  border-radius: 5px;
  letter-spacing: 1px;
  padding: 5px 10px;
  font-size: 12px;
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.textColor};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};

  &.type-of-line {
    border-radius: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid ${(props) => props.lineColor};
  }

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => (props.isInvalid ? '#FA339D' : '#2dd1ff')};
  }

  ::placeholder {
    color: rgb(10, 74, 154, 0.5);
    font-size: 12px;
    visibility: ${(props) => (props.disabled ? 'hidden' : 'visibile')};
  }
`;

const Input = (props) => {
  const { placeholder, innerRef, styleType, ...restProps } = props;
  const className = styleType === 'box' ? '' : 'type-of-line';

  function onChange(e) {
    props.onChange(e);
  }

  return (
    <StyledInput
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      ref={innerRef}
      {...restProps}
    />
  );
};

Input.propTypes = {
  /* 框框還是線 */
  styleType: PropTypes.oneOf(['box', 'line']),
  /* 寬度設定 */
  width: PropTypes.string,
};

Input.defaultProps = {
  styleType: 'box',
  width: '100%',
  placeholder: null,
  onChange: () => {},
  innerRef: null,
  backgroundColor: 'rgb(10, 74, 154, 0.1);',
  lineColor: '#FFFFFF',
  textAlign: 'center',
  textColor: 'rgba(0, 0, 0, 1)',
};

export default Input;
