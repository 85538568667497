import { Fragment } from 'react';
import { getPitcherTable } from '../utils/base';
import { BallTypeWording, BallTypeENWording, getBallTypeStyle } from '../constants/base';

const renderStatsRow = (stat, ballType, split, isPreHalf) => {
  const preHalf = (
    <Fragment>
      <td>{stat['PA']}</td>
      <td>{stat['AB']}</td>
      <td>{stat['H']}</td>
      <td>{stat['BA']}</td>
      <td>{stat['SLG']}</td>
    </Fragment>
  );

  const postHalf = (
    <Fragment>
      <td>{stat['1B']}</td>
      <td>{stat['2B']}</td>
      <td>{stat['3B']}</td>
      <td>{stat['HR']}</td>
      <td>{stat['SO']}</td>
    </Fragment>
  );

  return (
    <tr className={ballType === 'all' ? 'total' : ''}>
      <td style={getBallTypeStyle(ballType)}>
        <span>{BallTypeWording[ballType ? ballType : 'NONE']}</span>
        <span>{BallTypeENWording[ballType ? ballType : 'NONE']}</span>
      </td>
      {!split ? (
        <Fragment>
          {preHalf}
          {postHalf}
        </Fragment>
      ) : isPreHalf ? (
        preHalf
      ) : (
        postHalf
      )}
      {/* <td>{stat['putAway']}</td> */}
    </tr>
  );
};

const THPreHalf = (
  <Fragment>
    <th>
      <span>打席</span>
      <span>PA</span>
    </th>
    <th>
      <span>打數</span>
      <span>AB</span>
    </th>
    <th>
      <span>安打</span>
      <span>H</span>
    </th>
    <th>
      <span>被打擊率</span>
      <span>BA</span>
    </th>
    <th>
      <span>長打率</span>
      <span>SLG</span>
    </th>
  </Fragment>
);

const THPostHalf = (
  <Fragment>
    <th>
      <span>一安</span>
      <span>1B</span>
    </th>
    <th>
      <span>二安</span>
      <span>2B</span>
    </th>
    <th>
      <span>三安</span>
      <span>3B</span>
    </th>
    <th>
      <span>全壘打</span>
      <span>HR</span>
    </th>
    <th>
      <span>三振</span>
      <span>SO</span>
    </th>
  </Fragment>
);

export default function PitcherTable({ resolvedPerBall, stats, split }) {
  return (
    <Fragment>
      <table>
        <thead>
          <tr>
            <th>
              <span>球種</span>
              <span>Pitch Type</span>
            </th>
            {THPreHalf}
            {!split && THPostHalf}
            {/* <th>
          <span>兩好球三振率</span>
          <span>PutAway%</span>
        </th> */}
          </tr>
        </thead>
        <tbody>
          {renderStatsRow(getPitcherTable(resolvedPerBall, stats['F'], 'F'), 'F', split, true)}
          {renderStatsRow(getPitcherTable(resolvedPerBall, stats['B'], 'B'), 'B', split, true)}
          {renderStatsRow(getPitcherTable(resolvedPerBall, stats['C'], 'C'), 'C', split, true)}
          {renderStatsRow(getPitcherTable(resolvedPerBall, stats['others'], ''), '', split, true)}
          {renderStatsRow(getPitcherTable(resolvedPerBall, stats['all'], ''), 'all', split, true)}
        </tbody>
      </table>
      {split && (
        <table>
          <thead>
            <tr>
              <th>
                <span>球種</span>
                <span>Pitch Type</span>
              </th>
              {THPostHalf}
              {/* <th>
          <span>兩好球三振率</span>
          <span>PutAway%</span>
        </th> */}
            </tr>
          </thead>
          <tbody>
            {renderStatsRow(getPitcherTable(resolvedPerBall, stats['F'], 'F'), 'F', split, false)}
            {renderStatsRow(getPitcherTable(resolvedPerBall, stats['B'], 'B'), 'B', split, false)}
            {renderStatsRow(getPitcherTable(resolvedPerBall, stats['C'], 'C'), 'C', split, false)}
            {renderStatsRow(getPitcherTable(resolvedPerBall, stats['others'], ''), '', split, false)}
            {renderStatsRow(getPitcherTable(resolvedPerBall, stats['all'], ''), 'all', split, false)}
          </tbody>
        </table>
      )}
    </Fragment>
  );
}
