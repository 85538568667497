import styled from 'styled-components';
import { COLOR, TABLET_WIDTH, PHONE_WIDTH } from '~~elements/Const';
import Button from '~~elements/Button';
import Multilingual from '../Multilingual';
import OnSiteStrikeZone from './OnSiteStrikeZone';
import OnSiteBallEdit from './OnSiteBallEdit';
import PerBall from './PerBall';
import { usePerBallState, usePerBallDispatch } from '../useResultPanel';

// 至少有 1024px，太小的會被直接蓋板警告
const StyledDiv = styled.div`
  z-index: 1003;
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0 0.5rem 0.5rem;
  top: 0;
  left: 0;
  background-color: ${COLOR.home};
  color: #fafafa;
  display: ${(props) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;

  .on-site {
    &--subtitle {
      position: absolute;
      top: 0;
      left: 1rem;
      font-size: 1.5rem;
      text-align: left;
      margin: 0.5rem 0;
    }
    &--header {
      font-size: 1.5rem;
      text-align: center;
      margin: 0.5rem 0;
      border-bottom: 1px solid white;
    }
    &--actions {
      position: absolute;
      height: 2rem;
      right: 1rem;
      top: 0;
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }

    &--content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
      width: 100%;
      height: 85%;
      overflow-y: hidden;
    }

    &--history {
      width: 100%;
      height: 15%;
      overflow-y: hidden;
    }
  }

  input {
    background-color: white;
  }
  select {
    background-color: white;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    .on-site {
      &--subtitle {
        font-size: 0.8rem;
        margin: 0.2rem 0;
      }
      &--header {
        font-size: 1rem;
        margin: 0.2rem 0;
      }
      &--actions {
        height: 1.4rem;
        right: 0.6rem;
        button {
          padding: 0.2rem 0.6rem;
        }
      }
    }
  }

  @media (max-width: ${PHONE_WIDTH}) {
    .on-site {
      &--content {
        height: 75%;
      }

      &--history {
        height: 25%;
      }
    }
  }
`;

const OnSite = ({
  isOpen,
  toClose,
  strikezoneUniqid,
  gameState,
  gameLoading,
  gameError,
  setPitchPosition,
  setOnSiteZoneXY,
}) => {
  const { perBall, editingIndex } = usePerBallState();
  const perBallDispatch = usePerBallDispatch();

  return (
    <StyledDiv isOpen={isOpen}>
      <h3 className="on-site--subtitle">{Multilingual(`CAMERA_ANGLE.${gameState.cameraAngle}`)}</h3>
      <h3 className="on-site--header">{gameState.title}</h3>
      <div className="on-site--actions">
        <Button color="red" onClick={toClose}>
          {Multilingual(`OPEN_SETTING_PANEL`)}
        </Button>
      </div>
      <div className="on-site--content">
        <OnSiteStrikeZone
          isOpen={isOpen}
          cameraAngle={gameState.cameraAngle}
          pitcher={perBall[editingIndex].pitcher}
          pHand={perBall[editingIndex].pHand}
          bHand={perBall[editingIndex].bHand}
          batter={perBall[editingIndex].batter}
          eyeZoneResult={perBall[editingIndex].eyeZoneResult}
          setPitchPosition={setPitchPosition}
          setOnSiteZoneXY={setOnSiteZoneXY}
        />
        <OnSiteBallEdit
          strikezoneUniqid={strikezoneUniqid}
          gameState={gameState}
          gameLoading={gameLoading}
          gameError={gameError}
        />
      </div>
      <div className="on-site--history">
        <PerBall
          perBall={perBall}
          editingIndex={editingIndex}
          setEditingIndex={(targetIndex) => perBallDispatch({ type: 'updateEditingIndex', payload: targetIndex })}
          wingman={false}
          onsite={true}
          video={false}
        />
      </div>
    </StyledDiv>
  );
};

export default OnSite;
