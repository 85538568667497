import { Fragment } from 'react';
import { getAvg, getFillOpacity } from '../../utils/base';

export default function AvgChaseZone({ x: defaultX, y: defaultY, chases }) {
  const fullWidth = 3 * defaultX;
  const fullHeight = 3 * defaultY;

  let count = 1;
  let zone = [];

  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 2; j++) {
      const x = (j * fullWidth) / 2;
      const y = (i * fullHeight) / 2;
      const avg = getAvg(chases[`${count++}`]);
      const { color, opacity } = getFillOpacity(avg);

      zone.push(
        <Fragment key={`zone-map-${i}-${j}`}>
          <rect
            x={x}
            y={y}
            width={fullWidth / 2}
            height={fullHeight / 2}
            stroke="lightgreen"
            fill={color}
            fillOpacity={opacity}
          ></rect>
          ,
          <text
            dx={x + fullWidth / 4}
            dy={i === 0 ? 15 : fullHeight - 10}
            fontSize={8}
            fill={avg >= 0 ? 'white' : 'black'}
            textAnchor="middle"
          >
            {avg >= 0 ? avg : '-'}
          </text>
        </Fragment>,
      );
    }
  }

  return (
    <g>
      <rect width={fullWidth} height={fullHeight} stroke="lightgreen" fill="white"></rect>
      {zone}
      {/* <rect width={fullWidth} height={fullHeight} stroke="black" fill="lightgreen"></rect>
      <text dx={1.5 * x} dy={y / 6} fontSize={8} textAnchor="middle">
        {avg >= 0 ? avg : '-'}
      </text> */}
      {/* <g>
        <rect width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect x={x} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect x={2 * x} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect y={y} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect x={x} y={y} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect x={2 * x} y={y} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect y={2 * y} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect x={x} y={2 * y} width={x} height={y} stroke="black" fill="lightgreen"></rect>
        <rect x={2 * x} y={2 * y} width={x} height={y} stroke="black" fill="lightgreen"></rect>
      </g> */}
    </g>
  );
}
