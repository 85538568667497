import styled from 'styled-components';
import { FONT_COLOR } from '~~elements/Const';
import Multilingual from '../Multilingual';
import { ballDetailTypes, ballTrajectories, ballContacts } from './ResultPanel/ResultPanel';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  .group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    &--name {
      width: 5rem;
      margin: 0.1rem 0;
      border-right: 1px solid gray;
    }
    &--key {
      border-radius: 2px;
      background-color: #eee;
      padding: 2px;
      color: ${FONT_COLOR};
    }
  }
  hr {
    width: 100%;
    height: 1px;
    border: 0px;
    margin: 0;
    background-color: gray;
  }
`;

const Glossary = () => {
  return (
    <StyledDiv>
      <div className="group">
        <span className="group--name">{Multilingual(`PITCH_DETAIL_TYPE.WORDING`)}</span>
        {ballDetailTypes.map((ballDetailType) => (
          <span key={ballDetailType} className="group--key">
            {`${ballDetailType}: ${Multilingual(`PITCH_DETAIL_TYPE.${ballDetailType}`)}`}
          </span>
        ))}
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`TRAJECTORY.WORDING`)}</span>
        {Object.keys(ballTrajectories).map((ballTrajectory) => (
          <span key={ballTrajectory} className="group--key">
            {`${ballTrajectory}: ${Multilingual(`TRAJECTORY.${ballTrajectory}`)}`}
          </span>
        ))}
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`CONTACT.WORDING`)}</span>
        {Object.keys(ballContacts).map((ballContact) => (
          <span key={ballContact} className="group--key">
            {`${ballContact}: ${Multilingual(`CONTACT.${ballContact}`)}`}
          </span>
        ))}
      </div>
      <hr />
      <div className="group">
        <span className="group--name">{Multilingual(`DROPPOINT.WORDING`)}</span>
        <span>Ref: Retrosheet Hit Location Diagram</span>
      </div>
    </StyledDiv>
  );
};

export default Glossary;
