import { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { filter, find, sort } from 'ramda';
import { isValid as isValidDate } from 'date-fns';
import IconWhiteBrushBaseball from '~~elements/static/IconWhiteBrushBaseball.svg';
import toast from '~~elements/Toast';
import Input from '~~elements/Input';
import Select from '~~elements/Select';
import Button from '~~elements/Button';
import { StrikezoneGameResource, UserResource } from '~~apis/resource';
import PlayerList from './PlayerList';
import BallEdit from './BallEdit';
import PerBall from './PerBall';
import { usePerBallState, usePerBallDispatch } from '../useResultPanel';
import { FreeBallLimit, PremiumBallLimit } from '../../../Const';
import Shortcuts from './Shortcuts';
import Glossary from './Glossary';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  overflow-y: auto;
  height: 100%;

  .section-name {
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0;
    margin: 0.5rem 0;
    cursor: pointer;
  }

  .collapses {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .players {
    &--both {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: start;
      > * {
        margin-right: 1rem;
      }
    }
    &--list {
      width: 50%;
      max-width: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      > p {
        margin: 0.5rem 0;
      }
    }

    &--from-recent {
      text-align: start;
      margin-top: 1rem;
      .options {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.8rem;
        margin: 0.5rem 0;
        label {
          font-size: 0.9rem;
        }
      }
    }
  }

  .perball {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    text-align: start;
    flex-direction: column;
    &--list {
      width: 100%;
      height: 40%;
      overflow-y: auto;
    }
  }
  .edit {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid white;
    .item {
      align-self: flex-end;
      > p {
        margin: 0;
      }
      .multi {
        display: flex;
        align-item: center;
        justify-content: start;
      }
    }
  }
`;

const SectionIcon = () => {
  return (
    <img src={IconWhiteBrushBaseball} alt="icon" style={{ width: '1.1rem', height: '1.1rem', marginRight: '0.5rem' }} />
  );
};

const debounce = { away: {}, home: {} };

const WingmanPerBall = ({ strikezoneUniqid, gameState, gameLoading, gameError, isOpen, isOpenOnSite, isOpenVideo }) => {
  const { editingIndex, perBall } = usePerBallState();
  const perBallDispatch = usePerBallDispatch();

  const [playerCollapse, setPlayerCollapse] = useState(false);
  const [perBallCollapse, setPerBallCollapse] = useState(false);

  const [insertBallCollapse, setInsertBallCollapse] = useState(false);
  const [insertIndex, setInsertIndex] = useState('1');
  const [insertAmount, setInsertAmount] = useState('1');

  const [shortcutCollapse, setShortcutCollapse] = useState(false);

  const [glossaryCollapse, setGlossaryCollapse] = useState(false);

  const [awayPlayers, setAwayPlayers] = useState([]);
  const [homePlayers, setHomePlayers] = useState([]);

  const [recentGames, setRecentGames] = useState([]);
  const [playerImportFrom, setPlayerImportFrom] = useState('');
  const [playerImportFromSide, setPlayerImportFromSide] = useState('AWAY');
  const [playerImportToSide, setPlayerImportToSide] = useState('AWAY');

  useEffect(() => {
    UserResource.getGamesInfo().then(({ data }) => {
      const sortedGames = sort(
        (a, b) => {
          if (!a.updatedAt || !isValidDate(new Date(a.updatedAt))) {
            return 1;
          }

          if (!b.updatedAt || !isValidDate(new Date(b.updatedAt))) {
            return -1;
          }

          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        },
        [...data.publicGames, ...data.unlistedGames],
      );

      setRecentGames(filter((g) => g.uniqid !== strikezoneUniqid, sortedGames).slice(0, 20));
    });
  }, [strikezoneUniqid]);

  useEffect(() => {
    if (Object.keys(debounce.away).length !== 0) {
      return;
    }
    setAwayPlayers(gameState.awayPlayers);
  }, [gameState.awayPlayers]);

  useEffect(() => {
    if (Object.keys(debounce.home).length !== 0) {
      return;
    }
    setHomePlayers(gameState.homePlayers);
  }, [gameState.homePlayers]);

  useEffect(() => {
    const parentEl = document.getElementById('wingman-perball');
    if (!parentEl) {
      return;
    }

    const anchorEl = document.getElementById('wingman-perball-list--anchor');
    const listEl = document.getElementById('wingman-perball-list');
    if (!anchorEl || !listEl) {
      return;
    }

    const leftHeight =
      parentEl.getBoundingClientRect().height -
      (anchorEl.getBoundingClientRect().y -
        parentEl.getBoundingClientRect().y +
        anchorEl.getBoundingClientRect().height) -
      10;
    listEl.style.height = leftHeight + 'px';
  }, [awayPlayers, homePlayers, playerCollapse, perBallCollapse]);

  const remotePlayersUpdate = (side) => (newPlayers) => {
    const value = newPlayers ? newPlayers : side === 'away' ? awayPlayers : homePlayers;
    debounce[side] = {};
    StrikezoneGameResource.updateStrikezoneGameBasic({
      strikezoneUniqid,
      data: {
        key: side === 'away' ? 'awayPlayers' : 'homePlayers',
        value,
      },
    }).catch((e) => {
      console.error(e);
      toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
    });
  };

  const handlePlayerImport = () => {
    const targetGame = find((g) => g.uniqid === playerImportFrom, recentGames);
    if (!targetGame) {
      toast(Multilingual(`IMPORT_PLAYER.ERROR_GAME`), { status: 'error', second: 1 });
      return;
    }

    const targetPlayers = playerImportFromSide === 'AWAY' ? targetGame.awayPlayers : targetGame.homePlayers;
    if (targetPlayers.length === 0) {
      toast(Multilingual(`IMPORT_PLAYER.ERROR_RESOURCE`), { status: 'error', second: 1 });
      return;
    }

    const sideMapping = {
      AWAY: {
        players: awayPlayers,
        setPlayers: setAwayPlayers,
        remotePlayersUpdate: remotePlayersUpdate('away'),
      },
      HOME: {
        players: homePlayers,
        setPlayers: setHomePlayers,
        remotePlayersUpdate: remotePlayersUpdate('home'),
      },
    };
    const originPlayers = sideMapping[playerImportToSide].players;
    const newPlayers = [...originPlayers, ...targetPlayers];
    sideMapping[playerImportToSide].setPlayers(newPlayers);
    sideMapping[playerImportToSide].remotePlayersUpdate(newPlayers);
    setPlayerImportFrom('');
  };

  const handleInsertBalls = () => {
    const ballIndex = parseInt(insertIndex);
    const ballAmount = parseInt(insertAmount);

    const currentBallLength = gameState.perBall.length - 1;
    if (Number.isNaN(ballIndex) || currentBallLength < ballIndex || ballIndex < 0) {
      toast(
        `${Multilingual(`INSERT_BALL.ERROR_INDEX.PREFIX`)} 0 - ${currentBallLength} ${Multilingual(
          `INSERT_BALL.ERROR_INDEX.SUFFIX`,
        )}`,
        {
          status: 'error',
          second: 1,
        },
      );
      return;
    }

    const maxAmount = gameState.isPremium ? PremiumBallLimit - currentBallLength : FreeBallLimit - currentBallLength;
    if (Number.isNaN(ballAmount) || ballAmount < 1 || ballAmount > maxAmount) {
      toast(
        `${Multilingual(`INSERT_BALL.ERROR_AMOUNT.PREFIX`)} 1 - ${maxAmount} ${Multilingual(
          `INSERT_BALL.ERROR_AMOUNT.SUFFIX`,
        )}`,
        {
          status: 'error',
          second: 1,
        },
      );
      return;
    }

    // 由 BallEdit 的 remoteCover 去自動更新編輯索引
    StrikezoneGameResource.insertStrikezoneGameBalls({
      strikezoneUniqid,
      data: {
        index: ballIndex,
        amount: ballAmount,
      },
    })
      .then(() => {
        setPerBallCollapse(true);
      })
      .catch((e) => {
        console.error(e);
        toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
      });
    setInsertIndex('1');
    setInsertAmount('1');
    setInsertBallCollapse(false);
  };

  const openSection = (name) => () => {
    const mapping = {
      player: setPlayerCollapse,
      perBall: setPerBallCollapse,
      insertBall: setInsertBallCollapse,
      shortcut: setShortcutCollapse,
      glossary: setGlossaryCollapse,
    };

    Object.keys(mapping).forEach((key) => {
      if (key !== name) {
        mapping[key](false);
        return;
      }
      mapping[name]((pre) => !pre);
    });
  };

  const displayNotification = () => {
    if (gameLoading) {
      return <span style={{ color: 'yellow' }}>！！！{Multilingual(`SYNC.LOADING`)}！！！</span>;
    }

    if (gameError) {
      return <span style={{ color: 'yellow' }}>！！！{Multilingual(`SYNC.ERROR`)}！！！</span>;
    }

    if (editingIndex !== perBall.length - 1) {
      return <span style={{ color: 'red' }}>！！！{Multilingual(`EDITING_HISTORY`)}！！！</span>;
    }

    return <Fragment></Fragment>;
  };

  return (
    <StyledDiv id="wingman-perball">
      <div className="collapses">
        <h4 className="section-name" onClick={openSection('player')}>
          {SectionIcon()}
          {Multilingual(`ALL_PLAYERS.WORDING`)}
          {playerCollapse ? <>&darr;</> : <>&rarr;</>}
        </h4>
        <h4 className="section-name" onClick={openSection('perBall')}>
          {SectionIcon()}
          {Multilingual(`PER_BALL.WORDING`)}
          {perBallCollapse ? <>&darr;</> : <>&rarr;</>}
        </h4>
        <h4 className="section-name" onClick={openSection('insertBall')}>
          {SectionIcon()}
          {Multilingual(`INSERT_BALL.WORDING`)}
          {insertBallCollapse ? <>&darr;</> : <>&rarr;</>}
        </h4>
        <h4 className="section-name" onClick={openSection('shortcut')}>
          {SectionIcon()}
          {Multilingual(`SHORTCUT.WORDING`)}
          {shortcutCollapse ? <>&darr;</> : <>&rarr;</>}
        </h4>
        <h4 className="section-name" onClick={openSection('glossary')}>
          {SectionIcon()}
          {Multilingual(`GLOSSARY.WORDING`)}
          {glossaryCollapse ? <>&darr;</> : <>&rarr;</>}
        </h4>
      </div>
      {playerCollapse && (
        <Fragment>
          <div className="players--both">
            <div className="players--list">
              <p>
                {Multilingual(`CURRENT_SIDE.AWAY`)} / {gameState.away}
              </p>
              <PlayerList
                debounce={debounce.away}
                players={awayPlayers}
                setPlayers={setAwayPlayers}
                remotePlayersUpdate={remotePlayersUpdate('away')}
              />
            </div>
            <div className="players--list">
              <p>
                {Multilingual(`CURRENT_SIDE.HOME`)} / {gameState.home}
              </p>
              <PlayerList
                debounce={debounce.home}
                players={homePlayers}
                setPlayers={setHomePlayers}
                remotePlayersUpdate={remotePlayersUpdate('home')}
              />
            </div>
          </div>
          {recentGames.length > 0 && (
            <div className="players--from-recent">
              <strong>{Multilingual(`IMPORT_PLAYER.FROM_RECENT_GAMES`)}</strong>
              <div className="options">
                <Select
                  label={Multilingual(`IMPORT_PLAYER.GAME`)}
                  value={playerImportFrom}
                  onChange={(e) => setPlayerImportFrom(e.target.value)}
                  mini
                >
                  <option value="">-</option>
                  {recentGames.map((recentGame) => (
                    <option key={recentGame.uniqid} value={recentGame.uniqid}>
                      {recentGame.title}
                    </option>
                  ))}
                </Select>
                <Select
                  label={Multilingual(`IMPORT_PLAYER.FROM`)}
                  value={playerImportFromSide}
                  onChange={(e) => setPlayerImportFromSide(e.target.value)}
                  mini
                >
                  <option value="AWAY">{Multilingual(`CURRENT_SIDE.AWAY`)}</option>
                  <option value="HOME">{Multilingual(`CURRENT_SIDE.HOME`)}</option>
                </Select>
                <Select
                  label={Multilingual(`IMPORT_PLAYER.TO`)}
                  value={playerImportToSide}
                  onChange={(e) => setPlayerImportToSide(e.target.value)}
                  mini
                >
                  <option value="AWAY">{Multilingual(`CURRENT_SIDE.AWAY`)}</option>
                  <option value="HOME">{Multilingual(`CURRENT_SIDE.HOME`)}</option>
                </Select>
              </div>
              <Button mini color="red" disabled={!playerImportFrom} width="5rem" onClick={handlePlayerImport}>
                {Multilingual(`IMPORT_PLAYER.IMPORT`)}
              </Button>
            </div>
          )}
        </Fragment>
      )}
      {insertBallCollapse && (
        <div>
          <p style={{ textAlign: 'start' }}>
            <i>{Multilingual(`INSERT_BALL.WARNING`)}</i>
          </p>
          <p style={{ textAlign: 'start', display: 'flex', gap: '1rem' }}>
            <span>
              {Multilingual(`BALL_SEQ`)}
              <Input width="5rem" value={insertIndex} onChange={(e) => setInsertIndex(e.target.value)} />
            </span>
            <span>
              {Multilingual(`INSERT_BALL.INSERT_AMOUNT`)}
              <Input width="5rem" value={insertAmount} onChange={(e) => setInsertAmount(e.target.value)} />
            </span>
            <Button width="unset" nowrap color="red" mini onClick={handleInsertBalls}>
              {Multilingual(`ON_SITE.CREATE_BALL`)}
            </Button>
          </p>
        </div>
      )}
      {perBallCollapse && (
        <div className="perball">
          <p>
            {Multilingual(`BALL_SEQ`)}：{editingIndex + 1}
            {displayNotification()}
          </p>
          <BallEdit
            strikezoneUniqid={strikezoneUniqid}
            gameState={gameState}
            keyboardEffect={!isOpenOnSite && !isOpenVideo && isOpen && perBallCollapse}
          />
          <p id="wingman-perball-list--anchor">{`${Multilingual(`HISTORY_LIST`)}, ${Multilingual(`PC_EDIT_ENTRY`)}`}</p>
          <div id="wingman-perball-list" className="perball--list">
            <PerBall
              perBall={perBall}
              editingIndex={editingIndex}
              setEditingIndex={(targetIndex) => perBallDispatch({ type: 'updateEditingIndex', payload: targetIndex })}
              wingman={true}
              onsite={false}
              video={false}
            />
          </div>
        </div>
      )}
      {shortcutCollapse && <Shortcuts />}
      {glossaryCollapse && <Glossary />}
    </StyledDiv>
  );
};

export default WingmanPerBall;
