import styled from 'styled-components';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import { useRootState } from '../../../useRoot';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  .wording {
    z-index: 1002;
    background-color: ${COLOR.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fafafa;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const NoPermissionNotification = ({ strikezoneGameNotExist, strikezoneGameTitle, notInSubscription }) => {
  const {
    loginUser: { isLogin },
  } = useRootState();

  if (isLogin && notInSubscription) {
    return (
      <StyledDiv>
        <div className="wording">
          <span>{Multilingual(`NO_PERMISSION.SUBSCRIBE_FIRST`)}</span>
          <a href={`${process.env.REACT_APP_ACCOUNT_URL}/shop/plugin`}>
            <Button color="blue">{Multilingual(`NO_PERMISSION.GO_BUY_IT`)}</Button>
          </a>
        </div>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      {strikezoneGameNotExist ? (
        <div className="wording">
          <span>{Multilingual(`NO_PERMISSION.GAME_NOT_FOUND`)}</span>
          <a href="/">
            <Button color="blue">{Multilingual(`NO_PERMISSION.BACK_TO_LIST`)}</Button>
          </a>
        </div>
      ) : (
        <div className="wording">
          <span>
            {Multilingual(`NO_PERMISSION.NOT_EDITOR_PREFIX`)}
            {isLogin && `[${strikezoneGameTitle}]`}
            {Multilingual(`NO_PERMISSION.NOT_EDITOR_SUFFIX`)}
          </span>
          <a
            href={`${process.env.REACT_APP_ACCOUNT_URL}/${isLogin ? 'logout' : 'login'}?done_url=${encodeURIComponent(
              window.location.href,
            )}`}
          >
            <Button color="blue">{isLogin ? Multilingual(`LOGOUT`) : Multilingual(`LOGIN`)}</Button>
          </a>
        </div>
      )}
    </StyledDiv>
  );
};

export default NoPermissionNotification;
