import styled from 'styled-components';
import Modal from '~~elements/Modal';
import DroppointClickingDiagramPNG from '~~elements/static/DroppointClickingDiagram.png';
import { getLocationCode } from '~~elements/LocationCodes';

const StyledDiv = styled.div`
  width: 25rem;
  height: 25rem;
  max-width: 60vh;
  max-height: 60vh;
  position: relative;
  margin: 1rem auto;
  cursor: crosshair;
  > img {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
`;

const CurrentPoint = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  left: ${(props) => props.x}%;
  top: ${(props) => props.y}%;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  pointer-events: none;
`;

const DroppointClick = ({ isOpen, toClose, currentPoint, savePoint }) => {
  const handleDroppointClick = (e) => {
    const cursorX = e.clientX;
    const cursorY = e.clientY;

    let diagram = null;
    const diagrams = document.querySelectorAll('.droppoint-clicking-diagram');
    diagrams.forEach((d) => {
      if (d.style.display !== 'block') {
        return false;
      }

      diagram = d;
      return true;
    });
    if (!diagram) {
      return;
    }

    const diagramRect = diagram.getBoundingClientRect();
    const xPercent = (100 * (cursorX - diagramRect.left)) / (diagramRect.right - diagramRect.left);
    const yPercent = (100 * (cursorY - diagramRect.top)) / (diagramRect.bottom - diagramRect.top);
    if (0 > xPercent || 100 < xPercent || 0 > yPercent || 100 < yPercent) {
      return;
    }

    const locationCode = getLocationCode([xPercent, yPercent]);
    console.log(locationCode);
    console.log(xPercent);
    console.log(yPercent);
    savePoint(locationCode, [xPercent, yPercent]);
  };

  return (
    <Modal.Modal size="sm" isOpen={isOpen} onClickBack={toClose} noPadding={true}>
      <Modal.ModalBody>
        <StyledDiv
          style={{ display: isOpen ? 'block' : 'none' }}
          className="droppoint-clicking-diagram"
          onClick={handleDroppointClick}
        >
          <img src={DroppointClickingDiagramPNG} alt="location" />
          {currentPoint && <CurrentPoint x={currentPoint[0]} y={currentPoint[1]} />}
        </StyledDiv>
      </Modal.ModalBody>
    </Modal.Modal>
  );
};

export default DroppointClick;
