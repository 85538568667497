import { Fragment } from 'react';
import styled from 'styled-components';
import Multilingual from '../Multilingual';
import { usePerBallState } from '../useResultPanel';
import BallEdit from './BallEdit';
import { PHONE_WIDTH } from '~~elements/Const';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  p {
    margin: 0.5rem 0;
  }

  .history-hint {
    text-align: start;
  }

  @media (max-width: ${PHONE_WIDTH}) {
    font-size: 12px !important;
    select,
    input,
    button {
      padding: 1px 2px;
      font-size: 12px !important;
    }
  }
`;

const OnSiteBallEdit = ({ strikezoneUniqid, gameState, gameLoading, gameError }) => {
  const { perBall, editingIndex } = usePerBallState();

  const displayNotification = () => {
    if (gameLoading) {
      return <span style={{ color: 'yellow' }}>！！！{Multilingual(`SYNC.LOADING`)}！！！</span>;
    }

    if (gameError) {
      return <span style={{ color: 'yellow' }}>！！！{Multilingual(`SYNC.ERROR`)}！！！</span>;
    }

    if (editingIndex !== perBall.length - 1) {
      return <span style={{ color: 'red' }}>！！！{Multilingual(`EDITING_HISTORY`)}！！！</span>;
    }

    return <Fragment></Fragment>;
  };

  return (
    <StyledDiv>
      <p>
        {Multilingual(`BALL_SEQ`)}：{editingIndex + 1}
        {displayNotification()}
      </p>
      <BallEdit strikezoneUniqid={strikezoneUniqid} gameState={gameState} keyboardEffect={false} radioMode={true} />
      <p className="history-hint">{`${Multilingual(`HISTORY_LIST`)}, ${Multilingual(`PC_EDIT_ENTRY`)}`}</p>
    </StyledDiv>
  );
};

export default OnSiteBallEdit;
