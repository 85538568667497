import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Form from '~~elements/Form';
import Input from '~~elements/Input';
import Button from '~~elements/Button';
import Select from '~~elements/Select';
import toast from '~~elements/Toast';
import IconWhiteBrushBaseball from '~~elements/static/IconWhiteBrushBaseball.svg';
import IconBlock from '~~elements/static/IconBlock.svg';
import { StrikezoneGameResource } from '~~apis/resource';
import { FreeBallLimit, PremiumBallLimit } from '../../../Const';
// import { usePerBallState } from '../useResultPanel';
import Cooperator from './Cooperator';
import toXlsx from '../toXlsx';
import { perBall2JsonFile } from '../service';
import PrivacyWarning from './PrivacyWarning';
import Multilingual from '../Multilingual';

const StyledDiv = styled.div`
  border-right: 1px solid white;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  overflow-y: auto;

  .section-name {
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0;
    margin: 0.5rem 0;
  }

  textarea {
    width: 100%;
    height: 5rem;
    resize: none;
    font-size: 12px;
  }

  .singleline-field {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: start;
  }

  .box .input-field {
    margin-bottom: 1rem;
  }
`;

const SectionIcon = () => {
  return (
    <img src={IconWhiteBrushBaseball} alt="icon" style={{ width: '1.1rem', height: '1.1rem', marginRight: '0.5rem' }} />
  );
};

const WingmanGame = ({ strikezoneUniqid, gameState }) => {
  // const { userInfo } = usePerBallState();

  const [title, setTitle] = useState('');
  const [away, setAway] = useState('');
  const [home, setHome] = useState('');
  const [gameDate, setGameDate] = useState('');
  const [reference, setReference] = useState('');
  const [description, setDescription] = useState('');
  const [cameraAngle, setCameraAngle] = useState('PITCHER');
  const [speedUnit, setSpeedUnit] = useState('KM');
  const [isLive, setIsLive] = useState(false);

  // const [premiumUpgrading, setPremiumUpgrading] = useState(false);

  const [isPrivacyWarningOpen, setIsPrivacyWarningOpen] = useState(false);
  const [targetPrivacy, setTargetPrivacy] = useState('');

  const [isCooperatorOpen, setIsCooperatorOpen] = useState(false);

  const [toJSONPlayer, setToJSONPlayer] = useState('');

  useEffect(() => {
    setTitle(gameState.title);
  }, [gameState.title]);
  useEffect(() => {
    setAway(gameState.away);
  }, [gameState.away]);
  useEffect(() => {
    setHome(gameState.home);
  }, [gameState.home]);
  useEffect(() => {
    setGameDate(gameState.gameDate);
  }, [gameState.gameDate]);
  useEffect(() => {
    setReference(gameState.reference);
  }, [gameState.reference]);
  useEffect(() => {
    setDescription(gameState.description);
  }, [gameState.description]);
  useEffect(() => {
    setCameraAngle(gameState.cameraAngle);
  }, [gameState.cameraAngle]);
  useEffect(() => {
    setSpeedUnit(gameState.speedUnit);
  }, [gameState.speedUnit]);
  useEffect(() => {
    setIsLive(gameState.isLive);
  }, [gameState.isLive]);

  const handleOnChange = (e) => {
    if (['SELECT'].includes(e.target.tagName.toUpperCase())) {
      remoteUpdate(e);
    }

    if (e.target.name === 'isLive') {
      setIsLive(e.target.value === 'true');
      return;
    }

    const mapping = {
      title: setTitle,
      away: setAway,
      home: setHome,
      gameDate: setGameDate,
      reference: setReference,
      description: setDescription,
      cameraAngle: setCameraAngle,
      speedUnit: setSpeedUnit,
    };
    mapping[e.target.name](e.target.value);
  };

  const remoteUpdate = (e) => {
    const noEmptyRequired = ['title', 'away', 'home'];
    if (noEmptyRequired.includes(e.target.name) && !e.target.value.trim()) {
      const mapping = {
        title: setTitle,
        away: setAway,
        home: setHome,
      };
      mapping[e.target.name](gameState[e.target.name]);
      toast(Multilingual(`INFO.ERROR_REQUIRED`), { status: 'error', second: 1 });
      return;
    }

    let value = e.target.value;
    if (e.target.name === 'isLive') {
      value = e.target.value === 'true';
    }
    StrikezoneGameResource.updateStrikezoneGameBasic({
      strikezoneUniqid,
      data: {
        key: e.target.name,
        value,
      },
    }).catch((e) => {
      console.error(e);
      toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
    });
  };

  // const upgradeToPremium = () => {
  //   setPremiumUpgrading(true);
  //   StrikezoneGameResource.upgradeStrikezoneGameToPremium({ strikezoneUniqid })
  //     .then(() => {
  //       toast(Multilingual(`INFO.SUCCESSFULLY_UPGRADE`), { status: 'success', second: 5 });
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       toast(Multilingual(`SYNC.ERROR_WARNING`), { status: 'error', second: 1 });
  //     })
  //     .finally(() => {
  //       setPremiumUpgrading(false);
  //     });
  // };

  const handlePrivacyChange = (e) => {
    setIsPrivacyWarningOpen(true);
    setTargetPrivacy(e.target.value);
  };
  const closePrivacyWarning = () => {
    setIsPrivacyWarningOpen(false);
  };

  const openCooperator = () => {
    setIsCooperatorOpen(true);
  };
  const closeCooperator = () => {
    setIsCooperatorOpen(false);
  };

  const exportToExcel = () => {
    toXlsx(gameState);
  };

  const exportPerBallToJSON = () => {
    perBall2JsonFile(gameState, toJSONPlayer.trim());
  };

  return (
    <StyledDiv>
      <h4 className="section-name">
        {SectionIcon()}
        {Multilingual(`INFO.WORDING`)}
      </h4>
      <Form onSubmit={(e) => e.preventDefault()}>
        <div className="box">
          <label>
            {Multilingual(`INFO.TITLE`)}
            <i>*</i>
          </label>
          <div className="input-field">
            <div className="input-box">
              <Input
                width="100%"
                placeholder={Multilingual(`INFO.TITLE`)}
                name="title"
                value={title}
                textAlign="left"
                onChange={handleOnChange}
                onBlur={remoteUpdate}
              />
            </div>
          </div>
          <label>
            {Multilingual(`INFO.COMBINATION`)}
            <i>*</i>
          </label>
          <div className="input-field --multi-input">
            <div className="input-box">
              <Input
                width="100%"
                placeholder={Multilingual(`CURRENT_SIDE.AWAY`)}
                name="away"
                value={away}
                onChange={handleOnChange}
                onBlur={remoteUpdate}
              />
              <Input
                width="100%"
                placeholder={Multilingual(`CURRENT_SIDE.HOME`)}
                name="home"
                value={home}
                onChange={handleOnChange}
                onBlur={remoteUpdate}
              />
            </div>
          </div>
          <label>{Multilingual(`INFO.DATE`)}</label>
          <div className="input-field">
            <div className="input-box">
              <Input
                width="100%"
                type="date"
                name="gameDate"
                value={gameDate}
                textAlign="left"
                onChange={handleOnChange}
                onBlur={remoteUpdate}
              />
            </div>
          </div>
          <label>{Multilingual(`INFO.REF`)}</label>
          <div className="input-field">
            <div className="input-box">
              <Input
                width="100%"
                placeholder={`${Multilingual(`INFO.REF`)}, ex: youtube...`}
                name="reference"
                value={reference}
                textAlign="left"
                onChange={handleOnChange}
                onBlur={remoteUpdate}
              />
            </div>
          </div>
          <label>{Multilingual(`INFO.DESCRIPTION`)}</label>
          <div className="input-field">
            <div className="input-box">
              <textarea
                width="100%"
                name="description"
                value={description}
                textalign="left"
                onChange={handleOnChange}
                onBlur={remoteUpdate}
              />
            </div>
          </div>
          <span className="singleline-field">
            {Multilingual(`SPEED.UNIT`)}：
            <Select
              mini
              minWidth="4rem"
              name="speedUnit"
              value={speedUnit}
              onChange={handleOnChange}
              onBlur={remoteUpdate}
            >
              <option value="KM">{Multilingual(`SPEED.KM`)}</option>
              {gameState.isPremium && <option value="MI">{Multilingual(`SPEED.MI`)}</option>}
            </Select>
          </span>
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`CAMERA_ANGLE.WORDING`)}：
            <Select
              mini
              minWidth="4rem"
              name="cameraAngle"
              value={cameraAngle}
              onChange={handleOnChange}
              onBlur={remoteUpdate}
            >
              <option value="PITCHER">{Multilingual(`CAMERA_ANGLE.PITCHER`)}</option>
              {gameState.isPremium && <option value="CATCHER">{Multilingual(`CAMERA_ANGLE.CATCHER`)}</option>}
            </Select>
          </span>
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`INFO.GAME_STATUS`)}：
            <Select mini minWidth="4rem" name="isLive" value={isLive} onChange={handleOnChange} onBlur={remoteUpdate}>
              <option value={true}>{Multilingual(`INFO.GAME_STATUS_LIVE`)}</option>
              <option value={false}>{Multilingual(`INFO.GAME_STATUS_ENDED`)}</option>
            </Select>
          </span>
          {/* 目前能使用的已經都是進階方案了 */}
          {/* <div className="input-field" />
          <span className="singleline-field">目前方案：{gameState.isPremium ? '進階' : '基本'}</span>
          {!gameState.isPremium && <span>進階方案額度：{userInfo.premiumQuota}場</span>}
          {!gameState.isPremium ? (
            userInfo.premiumQuota === 0 ? (
              <Button width="unset">購買進階額度</Button>
            ) : (
              <Button width="unset" onClick={upgradeToPremium} disabled={premiumUpgrading}>
                升級為進階方案
              </Button>
            )
          ) : (
            <Fragment></Fragment>
          )} */}
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`INFO.BALL_LIMIT`)}：{gameState.isPremium ? PremiumBallLimit : FreeBallLimit}
            {Multilingual(`INFO.BALL_LIMIT_COUNT`)}
          </span>
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`INFO.READ_PRIVACY`)}：
            <Select mini minWidth="4rem" value={gameState.privacy} onChange={handlePrivacyChange}>
              <option value="PUBLIC">{Multilingual(`INFO.READ_PRIVACY_PUBLIC`)}</option>
              {gameState.isPremium && <option value="UNLISTED">{Multilingual(`INFO.READ_PRIVACY_UNLISTED`)}</option>}
            </Select>
          </span>
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`INFO.COOPERATE`)}：
            {!gameState.isPremium ? (
              <img src={IconBlock} alt="block" style={{ width: '1.1rem', height: '1.1rem' }} />
            ) : (
              <Button width="unset" color="red" mini onClick={openCooperator}>
                {Multilingual(`INFO.SETTING`)}
              </Button>
            )}
          </span>
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`DATA_EXPORT.WORDING`)}：
            {!gameState.isPremium ? (
              <img src={IconBlock} alt="block" style={{ width: '1.1rem', height: '1.1rem' }} />
            ) : (
              <Button width="unset" color="red" mini disabled={gameState.perBall.length <= 1} onClick={exportToExcel}>
                Excel{gameState.perBall.length <= 1 && `(${Multilingual(`NULL`)})`}
              </Button>
            )}
          </span>
          <div className="input-field" />
          <span className="singleline-field">
            {Multilingual(`DATA_EXPORT.PER_BALL`)}：
            {!gameState.isPremium ? (
              <img src={IconBlock} alt="block" style={{ width: '1.1rem', height: '1.1rem' }} />
            ) : (
              <div>
                <Input
                  width="5rem"
                  placeholder={Multilingual(`DATA_EXPORT.PLAYER`)}
                  value={toJSONPlayer}
                  onChange={(e) => setToJSONPlayer(e.target.value)}
                />
                <Button
                  nowrap
                  width="unset"
                  color="red"
                  mini
                  disabled={gameState.perBall.length <= 1}
                  onClick={exportPerBallToJSON}
                >
                  JSON{gameState.perBall.length <= 1 && `(${Multilingual(`NULL`)})`}
                </Button>
              </div>
            )}
          </span>
        </div>
      </Form>
      <PrivacyWarning
        isOpen={isPrivacyWarningOpen}
        toClose={closePrivacyWarning}
        targetPrivacy={targetPrivacy}
        strikezoneUniqid={strikezoneUniqid}
      />
      <Cooperator
        isOpen={isCooperatorOpen}
        toClose={closeCooperator}
        strikezoneUniqid={strikezoneUniqid}
        ownerUniqid={gameState.ownerUniqid}
        editorUniqids={gameState.editorUniqids}
      />
    </StyledDiv>
  );
};

export default WingmanGame;
