import XLSX from 'xlsx';
import { format as formatDate } from 'date-fns';
import toast from '~~elements/Toast';
import Multilingual from './Multilingual';
import { BasesOptions } from './Const';

const stringCell = (v) => {
  return { t: 's', v };
};

const toXlsx = (gameState) => {
  const away = gameState.away;
  const home = gameState.home;
  const cameraAngle = gameState.cameraAngle;
  const perBall = gameState.perBall.slice(0, -1);
  if (perBall.length === 0) {
    toast(Multilingual(`NULL`), { status: 'error', second: 2 });
    return;
  }

  const exportRows = [
    [
      Multilingual(`CURRENT_SIDE.INNING`),
      Multilingual(`CURRENT_SIDE.AWAY`),
      Multilingual(`CURRENT_SIDE.HOME`),
      Multilingual(`CAMERA_ANGLE.WORDING`),
      Multilingual(`CURRENT_SIDE.WORDING`),
      Multilingual(`BASES.WORDING`),
      Multilingual(`OUTS.WORDING`),
      Multilingual(`PITCHER.WORDING`),
      'R/L',
      Multilingual(`BATTER.WORDING`),
      'R/L',
      `${Multilingual(`SPEED.WORDING`)}(${gameState.speedUnit})`,
      Multilingual(`PITCH_DETAIL_TYPE.WORDING`),
      Multilingual(`PITCH_TYPE.WORDING`),
      Multilingual(`JUDGE_CALL.WORDING`),
      `${Multilingual(`ENTRY_POINT.WORDING`)}(${Multilingual(`ENTRY_POINT.ELECTRONIC`)}) x%`,
      `${Multilingual(`ENTRY_POINT.WORDING`)}(${Multilingual(`ENTRY_POINT.ELECTRONIC`)}) y%`,
      `${Multilingual(`ENTRY_POINT.WORDING`)}(${Multilingual(`ENTRY_POINT.ELECTRONIC`)}) ${Multilingual(
        `ENTRY_POINT.WIDTH`,
      )}`,
      `${Multilingual(`ENTRY_POINT.WORDING`)}(${Multilingual(`ENTRY_POINT.ELECTRONIC`)}) ${Multilingual(
        `ENTRY_POINT.HEIGHT`,
      )}`,
      `${Multilingual(`ENTRY_POINT.WORDING`)} x%`,
      `${Multilingual(`ENTRY_POINT.WORDING`)} y%`,
      `${Multilingual(`ENTRY_POINT.WORDING`)} ${Multilingual(`ENTRY_POINT.WIDTH`)}`,
      `${Multilingual(`ENTRY_POINT.WORDING`)} ${Multilingual(`ENTRY_POINT.HEIGHT`)}`,
      Multilingual(`BATTER_ACTION.WORDING`),
      Multilingual(`BATTER_RESULT.WORDING`),
      Multilingual(`TRAJECTORY.WORDING`),
      Multilingual(`CONTACT.WORDING`),
      Multilingual(`DROPPOINT.WORDING`),
      Multilingual(`DROPPOINT.WORDING`) + ' x',
      Multilingual(`DROPPOINT.WORDING`) + ' y',
    ],
  ];

  for (let i = 0; i <= perBall.length - 1; i++) {
    const ball = perBall[i];
    exportRows.push([
      stringCell(ball.inning),
      stringCell(away),
      stringCell(home),
      stringCell(cameraAngle),
      stringCell(ball.currentSide === 'away' ? Multilingual(`CURRENT_SIDE.AWAY`) : Multilingual(`CURRENT_SIDE.HOME`)),
      stringCell(ball.bases !== '' ? BasesOptions[ball.bases].label : ''),
      stringCell(ball.outs),
      stringCell(ball.pitcher),
      stringCell(!ball.pHand ? '' : ball.pHand),
      stringCell(ball.batter),
      stringCell(!ball.bHand ? '' : ball.bHand),
      stringCell(ball.speed),
      stringCell(!ball.ballDetailType ? '' : ball.ballDetailType),
      stringCell(!ball.ballType ? '' : Multilingual(`PITCH_TYPE.${ball.ballType}`)),
      stringCell(!ball.judgeCall ? '' : Multilingual(`JUDGE_CALL.${ball.judgeCall}`)),
      ball.eZoneResult.x,
      ball.eZoneResult.y,
      ball.eZoneResult.width,
      ball.eZoneResult.height,
      ball.eyeZoneResult.x,
      ball.eyeZoneResult.y,
      ball.eyeZoneResult.width,
      ball.eyeZoneResult.height,
      stringCell(!ball.batterAction ? '' : Multilingual(`BATTER_ACTION.${ball.batterAction}`)),
      stringCell(!ball.batterResult ? '' : ball.batterResult),
      stringCell(!ball.ballTrajectory ? '' : ball.ballTrajectory),
      stringCell(!ball.ballContact ? '' : ball.ballContact),
      stringCell(!ball.ballDroppoint ? '' : ball.ballDroppoint),
      stringCell(!ball.ballDroppointXY ? '' : ball.ballDroppointXY[0]),
      stringCell(!ball.ballDroppointXY ? '' : ball.ballDroppointXY[1]),
    ]);
  }
  const sheet = XLSX.utils.aoa_to_sheet(exportRows);

  const title = `${Multilingual(`NAME`)}-${gameState.title}-${formatDate(new Date(), 'yyyyMMdd')}`;
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    Author: 'Rebas 野革',
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(Multilingual(`PER_BALL.WORDING`));
  wb.Sheets[Multilingual(`PER_BALL.WORDING`)] = sheet;

  XLSX.writeFile(wb, `${title}.xlsx`);
};

export default toXlsx;
