import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { COLOR } from '~~elements/Const';
import Button from '~~elements/Button';
import toast from '~~elements/Toast';
import Control from './components/Control';
import {
  videoID,
  timeToDisplay,
  getPitchIndexByTime,
  updatable,
  insertable,
  getPitchesInTimeRange,
  timeDisplayReverse,
  validTimeDisplay,
} from './Const';
import { ACTIONS, useLocalDispatch, useLocalState } from './useLocal';
import StrikeZone from './components/StrikeZone';
import { Download } from './tmp';
import { handleKeydown as handlePitchTypeKeydown } from './services/pitchType';

const controlWidth = 640;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px);
  #video-panel {
    margin: 1.5rem 0 0;
    box-shadow: 1px 1px 5px black;
  }
  #control {
    margin: 1.5rem 0 0;
    padding: 0.5rem;
    width: ${controlWidth}px;
    heigth: 100%;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #video-wrapper {
    position: relative;
    margin: auto;
    width: calc(100vw - ${controlWidth}px);
    height: calc(((100vw - ${controlWidth}px) / 16) * 9);
    max-height: calc(100vh - 320px);
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    #video-input {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    #video-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #trackers {
    margin: 1rem 0;
    height: 200px;
    background-color: #fafafa;
    box-shadow: 1px 1px 5px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tracker {
      cursor: pointer;
      position: relative;
      width: calc(100% - 40px);
      height: 50px;
      box-shadow: 1px 1px 2px black;
      margin: 10px 0;
      font-size: 12px;
      text-shadow: 1px 1px 1px #fafafa;
      &.one {
        background: #deecfa;
        &.paused {
          .insert {
            display: block;
          }
        }
      }
      &.five {
        background: #fcebe1;
      }
      &.full {
        background: #ddffd1;
        .pitch-stick {
          display: none;
        }
      }

      .insert {
        display: none;
      }

      * {
        position: absolute;
      }
    }
  }
`;

const TimeStick = styled.div.attrs((props) => ({
  style: {
    left: `${props.left}%`,
  },
}))`
  height: 100%;
  width: 1px;
  overflow: visible;
  text-align: center;
  transform: translateX(-50%);
  border-right: 1px dashed black;
  background-color: inherit;
  span {
    position: relative;
    transform: translateX(-50%);
    bottom: 0;
    background-color: inherit;
  }

  &.current {
    top: -5px;
    height: calc(100% + 10px);
    border-right: 1px solid ${COLOR.primary};
    border-left: 1px solid ${COLOR.primary};
    span {
      transform: translate(-50%, 50%);
    }
  }

  .insert {
    position: relative;
    transform: translate(-50%, -50%);
    top: 0;
    &:hover {
      box-shadow: 0px 0px 5px 2px #9dfafc;
    }
  }
`;

const PitchStick = styled.div.attrs((props) => ({
  style: {
    left: `${props.left}%`,
  },
}))`
  height: 100%;
  width: 1px;
  overflow: visible;
  text-align: center;
  transform: translateX(-50%);
  border-right: 1px solid ${COLOR.secondary};
  button {
    position: relative;
    transform: translate(-50%, -50%);
    top: 0;
  }
`;

const Ranges = {
  ONE: 'one',
  FIVE: 'five',
  FULL: 'full',
};

const percentageToTime = (startEnd, percentage) => {
  const delta = startEnd[1] - startEnd[0];
  return startEnd[0] + delta * percentage;
};

let video = null;

const videoWheel = (range, forward) => {
  if (!video) {
    return;
  }

  let delta = 0;
  switch (range) {
    case Ranges.ONE:
      delta = 120;
      break;
    case Ranges.FIVE:
      delta = 300;
      break;
    case Ranges.FULL:
      delta = 3600;
      break;
    default:
      break;
  }
  delta /= 100;
  if (!forward) {
    delta *= -1;
  }
  video.currentTime += delta;
};

const Local = () => {
  const { duration, currentTime, pitches, pitchIndex, scoutingMode, durationPerPitch } = useLocalState();
  const dispatch = useLocalDispatch();

  const videoRef = useRef(null);

  const [resizeZone, setResizeZone] = useState(true);

  const [isPaused, setIsPaused] = useState(false);

  const [ctrl, setCTRL] = useState(false);

  const [trackerRefRefresh, setTrackerRefRefresh] = useState(0);
  const oneTrackerRef = useRef(null);
  const fiveTrackerRef = useRef(null);
  const fullTrackerRef = useRef(null);

  useEffect(() => {
    video = videoRef.current;
    let currentHandler = null;
    const timeTracker = () => {
      currentHandler = setTimeout(() => {
        setIsPaused(video.paused);
        dispatch({
          type: ACTIONS.SET_CURRENT_TIME,
          payload: { currentTime: video.currentTime, muted: video.volume <= 0 },
        });
        timeTracker();
      }, 1000 / 4);
    };
    timeTracker();

    return () => clearTimeout(currentHandler);
  }, [dispatch, videoRef]);

  useEffect(() => {
    let refRefreshID = null;
    if (!oneTrackerRef?.current || !fiveTrackerRef?.current || !fullTrackerRef?.current) {
      refRefreshID = setTimeout(() => {
        setTrackerRefRefresh(trackerRefRefresh + 1);
      }, 500);
      return;
    }

    const handlers = [];
    const refs = [oneTrackerRef.current, fiveTrackerRef.current, fullTrackerRef.current];
    const values = [Ranges.ONE, Ranges.FIVE, Ranges.FULL];
    refs.forEach((ref, i) => {
      const wheelHandle = (e) => {
        e.preventDefault();
        videoWheel(values[i], e.wheelDeltaY < 0);
      };
      handlers.push(wheelHandle);
      ref.addEventListener('wheel', wheelHandle, { passive: false });
      return;
    });

    return () => {
      clearTimeout(refRefreshID);
      refs.forEach((ref, i) => ref.removeEventListener('wheel', handlers[i]));
    };
  }, [oneTrackerRef, fiveTrackerRef, fullTrackerRef, trackerRefRefresh]);

  useEffect(() => {
    const keyrelease = (e) => {
      if (['BUTTON', 'INPUT'].includes(e.target.tagName.toUpperCase())) {
        return;
      }

      const keyCode = e.keyCode;
      // console.log(keyCode);

      switch (keyCode) {
        case 17:
          // CTRL
          setCTRL(false);
          break;
        default:
          break;
      }
    };
    const keycontrol = (e) => {
      if (['BUTTON', 'INPUT'].includes(e.target.tagName.toUpperCase())) {
        return;
      }

      const keyCode = e.keyCode;
      console.log(keyCode);

      switch (keyCode) {
        case 37:
          // <- arrow
          e.preventDefault();
          videoWheel(Ranges.ONE, false);
          break;
        case 39:
          // -> arrow
          e.preventDefault();
          videoWheel(Ranges.ONE, true);
          break;
        case 32:
          // white space
          e.preventDefault();
          if (video.paused) {
            video.play();
            break;
          }
          video.pause();
          break;
        case 8:
          // 倒退鍵 <--
          // 全部往前移，自己是要有時間的
          dispatch({ type: ACTIONS.BACK_SPACE });
          break;
        case 13:
          // Enter
          // 全部往後移，自己是要有時間的
          dispatch({ type: ACTIONS.SHIFT });
          break;
        case 73:
          // i = Insert
          dispatch({ type: ACTIONS.QUICK_INSERT_PITCH_TIME });
          break;
        case 46:
          // DEL
          // 刪掉當下那顆
          dispatch({ type: ACTIONS.DELETE_PITCH });
          break;
        case 17:
          // CTRL
          setCTRL(true);
          break;
        case 49:
        case 97:
          // Num 1
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_signin_at' } });
          break;
        case 50:
        case 98:
          // Num 2
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_started_at' } });
          break;
        case 51:
        case 99:
          // Num 3
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_contact_at' } });
          break;
        case 52:
        case 100:
          // Num 4
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_fly_catch_at' } });
          break;
        case 53:
        case 101:
          // Num 5
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_ground_catch_at' } });
          break;
        case 54:
        case 102:
          // Num 6
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_sb_throw_at' } });
          break;
        case 55:
        case 103:
          // Num 7
          dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { timeType: 'video_sb_catch_at' } });
          break;
        default:
          break;
      }

      handlePitchTypeKeydown(dispatch, keyCode);
    };
    window.addEventListener('keyup', keyrelease);
    window.addEventListener('keydown', keycontrol);
    return () => {
      window.removeEventListener('keyup', keyrelease);
      window.removeEventListener('keydown', keycontrol);
    };
  }, [dispatch]);

  useEffect(() => {
    // 時間軸脫離當下編輯的球太遠時，解除鎖定
    const pitch = pitches[pitchIndex];
    if (!pitch || !validTimeDisplay(pitch.video_started_at) || video.classList.contains('pitch-protected')) {
      return;
    }

    const timeDelta = Math.abs(timeDisplayReverse(pitch.video_started_at) - currentTime);
    if (scoutingMode) {
      const maxDelta = durationPerPitch && !Number.isNaN(parseInt(durationPerPitch)) ? parseInt(durationPerPitch) : 3;
      if (timeDelta < maxDelta || pitches.length === pitchIndex + 1) {
        return;
      }

      const nextIndex = R.findIndex(
        (p) => validTimeDisplay(p.video_signin_at) || validTimeDisplay(p.video_started_at),
        pitches.slice(pitchIndex + 1),
      );
      if (nextIndex < 0) {
        return;
      }
      dispatch({ type: ACTIONS.FOCUS_PITCH, payload: nextIndex + pitchIndex + 1 });
      return;
    }

    if (timeDelta < 15) {
      return;
    }
    dispatch({ type: ACTIONS.FOCUS_PITCH, payload: -1 });
  }, [dispatch, currentTime, pitchIndex, pitches, durationPerPitch, scoutingMode]);

  const handleTimeInsert = (e) => {
    e.target.blur();
    if (pitches.length === 0) {
      toast('請先指定場次', { status: 'error', second: 1 });
      return;
    }

    let peIndex = getPitchIndexByTime(pitches, Math.floor(currentTime));
    if (Math.ceil(peIndex) === peIndex) {
      toast('此時間已有投球事件，不得插入', { status: 'error', second: 1 });
      return;
    }

    // 更新模式
    if (ctrl && pitchIndex >= 0) {
      if (!updatable(pitches, pitchIndex, peIndex)) {
        toast('更新時間應在合理範圍內', { status: 'error', second: 1 });
        return;
      }
      dispatch({ type: ACTIONS.UPDATE_PITCH_TIME, payload: { time: currentTime } });
      return;
    }

    // 插入時間
    peIndex = Math.ceil(peIndex);
    if (!insertable(pitches, peIndex)) {
      toast('時間軸已滿，不得插入', { status: 'error', second: 1 });
      return;
    }

    dispatch({ type: ACTIONS.INSERT_PITCH_TIME, payload: { peIndex, time: currentTime } });
  };

  const getStartEnd = (range) => {
    let startEnd = [0, 0];
    switch (range) {
      case Ranges.ONE: {
        const startDelta = currentTime % 60;
        startEnd = [currentTime - startDelta, currentTime + 60 - startDelta];
        break;
      }
      case Ranges.FIVE: {
        const startSecDelta = currentTime % 300;
        startEnd = [currentTime - startSecDelta, currentTime + 300 - startSecDelta];
        break;
      }
      case Ranges.FULL:
        startEnd = [0, duration];
        break;
      default:
        break;
    }

    if (startEnd[0] < 0) {
      startEnd[0] = 0;
    }
    if (startEnd[1] > duration) {
      startEnd[1] = duration;
    }
    return startEnd;
  };

  const Tracker = (range) => {
    const startEnd = getStartEnd(range);
    let splitAmount = 1;
    switch (range) {
      case Ranges.ONE:
        splitAmount = 6;
        break;
      case Ranges.FIVE:
        splitAmount = 5;
        break;
      case Ranges.FULL:
        splitAmount = 10;
        break;
      default:
        break;
    }

    if (startEnd[0] === startEnd[1]) {
      return <div className={`tracker ${range}`}></div>;
    }

    const startEndDelta = startEnd[1] - startEnd[0];
    const getTimePercentage = (t) => {
      return ((t - startEnd[0]) * 100) / startEndDelta;
    };

    const splitSize = startEndDelta / splitAmount;
    const sticks = [];
    let now = startEnd[0];
    while (now <= startEnd[1]) {
      sticks.push({
        value: getTimePercentage(now),
        label: timeToDisplay(now),
      });
      now += splitSize;
    }

    const handleTrackerClick = (e) => {
      const trackerDiv = document.querySelector(`.tracker.${range}`);
      if (!trackerDiv || e.target.tagName.toUpperCase() === 'BUTTON') {
        return;
      }

      const divRect = trackerDiv.getBoundingClientRect();
      const percentage = (e.clientX - divRect.left) / divRect.width;

      video.currentTime = percentageToTime(startEnd, percentage);
    };

    const refs = {
      [Ranges.ONE]: oneTrackerRef,
      [Ranges.FIVE]: fiveTrackerRef,
      [Ranges.FULL]: fullTrackerRef,
    };

    const filteredPitches = getPitchesInTimeRange(pitches, startEnd);

    return (
      <div
        key={range}
        ref={refs[range]}
        className={`tracker ${range} ${isPaused ? 'paused' : ''}`}
        onClick={handleTrackerClick}
      >
        {sticks.map((stick, stickIndex) => (
          <TimeStick key={stickIndex} left={stick.value}>
            <span>{stick.label}</span>
          </TimeStick>
        ))}
        <TimeStick className="current" left={getTimePercentage(currentTime)}>
          <span>{timeToDisplay(currentTime)}</span>
          <Button className="insert" mini width="auto" color="red" onClick={handleTimeInsert}>
            {ctrl && pitchIndex >= 0 ? '更' : '插'}
          </Button>
        </TimeStick>
        {filteredPitches.map((p, pIndex) => (
          <PitchStick key={pIndex} left={getTimePercentage(timeDisplayReverse(p.video_started_at))}>
            <Button
              className="pitch-stick"
              mini
              width="auto"
              color={pitchIndex === pitches.indexOf(p) ? 'red' : 'blue'}
              onClick={(e) => {
                e.target.blur();
                dispatch({ type: ACTIONS.FOCUS_PITCH, payload: pitches.indexOf(p) });
              }}
            >
              {pitches.indexOf(p) + 1}
            </Button>
          </PitchStick>
        ))}
      </div>
    );
  };

  return (
    <StyledDiv>
      <Control resizeZone={resizeZone} setResizeZone={setResizeZone} />
      <div id="video-panel">
        <div id="upper">
          <div id="video-wrapper">
            <video id={videoID} ref={videoRef} crossOrigin="anonymous" onFocus={(e) => e.target.blur()} />
            <div id="video-cover" />
            <StrikeZone parentID="video-wrapper" resizable={resizeZone} code="left" left="20%" top="70%" />
            <StrikeZone parentID="video-wrapper" resizable={resizeZone} code="right" left="60%" />
          </div>
        </div>
        <div id="trackers">
          {Tracker(Ranges.ONE)}
          {Tracker(Ranges.FIVE)}
          {Tracker(Ranges.FULL)}
        </div>
      </div>
    </StyledDiv>
  );
};

export default Local;
