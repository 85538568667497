import styled from 'styled-components';
import { formatDistanceToNow, isValid as isValidDate, format as formatDate } from 'date-fns';
import twLocale from 'date-fns/locale/zh-TW';
import Multilingual from '../../../utils/Multilingual';

const StyledDiv = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 0.8rem;
  }
  .description {
    width: 540px;
    max-width: 95%;
    font-size: 0.9rem;
    white-space: pre-line;
    margin: 0.5rem 0;
  }
  .comment {
    font-size: 0.8rem;
    color: gray;
  }
`;

const Index = ({ gameInfo }) => {
  return (
    <StyledDiv>
      <h1 className="title">{gameInfo.title}</h1>
      {gameInfo.description && <div className="description">{gameInfo.description}</div>}
      {gameInfo.gameDate && isValidDate(new Date(gameInfo.gameDate)) && (
        <i className="comment">
          {Multilingual(`GAME.DATE`)}：{formatDate(new Date(gameInfo.gameDate), 'yyyy/MM/dd')}
        </i>
      )}
      {gameInfo.updatedAt && isValidDate(new Date(gameInfo.updatedAt)) && (
        <i className="comment">
          {Multilingual(`GAME.UPDATED_AT`)}：
          {formatDistanceToNow(new Date(gameInfo.updatedAt), { addSuffix: true, locale: twLocale })}
        </i>
      )}
      {gameInfo.reference && (
        <i className="comment">
          {Multilingual(`GAME.REF`)}：
          {gameInfo.reference.match(/^https:\/\//) ? (
            <a href={gameInfo.reference} target="_blank" rel="noreferrer">
              {gameInfo.reference}
            </a>
          ) : (
            gameInfo.reference
          )}
        </i>
      )}
    </StyledDiv>
  );
};

export default Index;
