// import Tooltip from '~~elements/Tooltip';
import Input from '~~elements/Input';
import Select from '~~elements/Select';
import Button from '~~elements/Button';
import {
  ballDetailTypes,
  batterActions,
  batterResults,
  ballTrajectories,
  ballContacts,
} from './ResultPanel/ResultPanel';
import Multilingual from '../Multilingual';
import { usePerBallState } from '../useResultPanel';
import { useEffect, useState } from 'react';
import { BasesOptions } from '../Const';

const BallEditSelectMode = ({
  perBall,
  editingIndex,
  setCurrentSide,
  setCurrentPitcher,
  awayPlayers,
  homePlayers,
  setCurrentBall,
  setCurrentBatter,
  resetZoneResult,
  removeBall,
  perBallDispatch,
  addBall,
  addBallable,
  openDroppointDiagram,
  resetCurrentBallDroppoint,
}) => {
  const { showEZone } = usePerBallState();

  const [tmpSpeed, setTmpSpeed] = useState('');

  useEffect(() => {
    if (!perBall?.[editingIndex]) {
      return;
    }

    setTmpSpeed(perBall[editingIndex].speed);
  }, [perBall, editingIndex]);

  const handleTmpSpeedChange = (e) => {
    setTmpSpeed(e.target.value);
  };

  const handleSpeedUpdate = () => {
    setCurrentBall('speed')({
      target: {
        value: tmpSpeed,
      },
    });
  };

  return (
    <div className="edit">
      <div className="item">
        <p>
          {Multilingual(`CURRENT_SIDE.INNING`)}/{Multilingual(`CURRENT_SIDE.WORDING`)}
        </p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].inning} onChange={setCurrentBall('inning')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {new Array(18).fill('').map((_, index) => (
              <option key={index} value={`${index + 1}`}>{`${index + 1}`}</option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].currentSide} onChange={setCurrentSide}>
            <option value="away">{Multilingual(`CURRENT_SIDE.AWAY`)}</option>
            <option value="home">{Multilingual(`CURRENT_SIDE.HOME`)}</option>
          </Select>
        </div>
      </div>
      <div className="item">
        <p>{Multilingual(`PITCHER.WORDING`)}</p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].pitcher} onChange={setCurrentPitcher}>
            <option value="">{Multilingual(`NULL`)}</option>
            {(perBall[editingIndex].currentSide === 'home' ? awayPlayers : homePlayers).map((player) => (
              <option key={player.name}>{player.name}</option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].pHand} onChange={setCurrentBall('pHand')}>
            <option value="">{Multilingual(`NULL`)}</option>
            <option value="R">{Multilingual(`PITCHER.R`)}</option>
            <option value="L">{Multilingual(`PITCHER.L`)}</option>
          </Select>
        </div>
      </div>
      <div className="item">
        <p>{Multilingual(`BATTER.WORDING`)}</p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].batter} onChange={setCurrentBatter}>
            <option value="">{Multilingual(`NULL`)}</option>
            {(perBall[editingIndex].currentSide === 'away' ? awayPlayers : homePlayers).map((player) => (
              <option key={player.name}>{player.name}</option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].bHand} onChange={setCurrentBall('bHand')}>
            <option value="">{Multilingual(`NULL`)}</option>
            <option value="R">{Multilingual(`BATTER.R`)}</option>
            <option value="L">{Multilingual(`BATTER.L`)}</option>
          </Select>
        </div>
      </div>
      <div className="item">
        <p>
          {Multilingual(`BASES.WORDING`)}/{Multilingual(`OUTS.WORDING`)}
        </p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].bases} onChange={setCurrentBall('bases')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {Object.keys(BasesOptions).map((key) => (
              <option key={key} value={BasesOptions[key].value}>
                {BasesOptions[key].label}
              </option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].outs} onChange={setCurrentBall('outs')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {new Array(3).fill('').map((_, index) => (
              <option key={index} value={`${index}`}>{`${index}`}</option>
            ))}
          </Select>
        </div>
      </div>
      <div className="item">
        <p>
          {Multilingual(`SPEED.WORDING`)}
          <Button width="unset" color="blue" mini onClick={handleSpeedUpdate}>
            {Multilingual(`SPEED.UPDATE`)}
          </Button>
        </p>
        <Input
          type="text"
          inputmode="numeric"
          min="30"
          max="170"
          pattern="[0-9]*"
          textAlign="left"
          value={tmpSpeed}
          onChange={handleTmpSpeedChange}
        />
      </div>
      <div className="item">
        <p>{Multilingual(`PITCH_TYPE.WORDING`)}</p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].ballDetailType} onChange={setCurrentBall('ballDetailType')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {ballDetailTypes.map((ballDetailType) => (
              <option key={ballDetailType} value={ballDetailType}>
                {ballDetailType}
                {/* {Multilingual(`PITCH_DETAIL_TYPE.${ballDetailType}`)} */}
              </option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].ballType} onChange={setCurrentBall('ballType')}>
            <option value="">{Multilingual(`NULL`)}</option>
            <option value="F">{Multilingual(`PITCH_TYPE.F`)}</option>
            <option value="C">{Multilingual(`PITCH_TYPE.C`)}</option>
            <option value="B">{Multilingual(`PITCH_TYPE.B`)}</option>
          </Select>
        </div>
      </div>
      <div className="item">
        <p>{Multilingual(`JUDGE_CALL.WORDING`)}</p>
        <Select mini value={perBall[editingIndex].judgeCall} onChange={setCurrentBall('judgeCall')}>
          <option value="">{Multilingual(`NULL`)}</option>
          <option value="S">{Multilingual(`JUDGE_CALL.S`)}</option>
          <option value="B">{Multilingual(`JUDGE_CALL.B`)}</option>
        </Select>
      </div>
      {showEZone && (
        <div className="item">
          <p>
            {Multilingual(`ENTRY_POINT.ELECTRONIC`)}
            <Button width="unset" color="whiteWithBorder" mini onClick={resetZoneResult('eZoneResult')}>
              {Multilingual(`ENTRY_POINT.RESET`)}
            </Button>
          </p>
          <Input
            textAlign="left"
            value={`${perBall[editingIndex].eZoneResult.x},${perBall[editingIndex].eZoneResult.y}`}
            disabled
          />
        </div>
      )}
      <div className="item">
        <p>
          {Multilingual(`ENTRY_POINT.WORDING`)}
          <Button width="unset" color="whiteWithBorder" mini onClick={resetZoneResult('eyeZoneResult')}>
            {Multilingual(`ENTRY_POINT.RESET`)}
          </Button>
        </p>
        <Input
          textAlign="left"
          value={`${perBall[editingIndex].eyeZoneResult.x},${perBall[editingIndex].eyeZoneResult.y}`}
          disabled
        />
      </div>
      <div className="item">
        <p>
          {Multilingual(`BATTER_ACTION.WORDING`)}/{Multilingual(`BATTER_RESULT.SHORT`)}
        </p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].batterAction} onChange={setCurrentBall('batterAction')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {Object.keys(batterActions).map((action) => (
              <option key={action} value={action}>
                {Multilingual(`BATTER_ACTION.${action.toUpperCase()}`)}
              </option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].batterResult} onChange={setCurrentBall('batterResult')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {Object.keys(batterResults).map((result) => (
              <option key={result} value={result}>
                {Multilingual(`BATTER_RESULT.${result}`)}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className="item">
        <p>
          {Multilingual(`TRAJECTORY.WORDING`)}/{Multilingual(`CONTACT.WORDING`)}
        </p>
        <div className="multi">
          <Select mini value={perBall[editingIndex].ballTrajectory} onChange={setCurrentBall('ballTrajectory')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {Object.keys(ballTrajectories).map((trajectory) => (
              <option key={trajectory} value={trajectory}>
                {trajectory}
              </option>
            ))}
          </Select>
          <Select mini value={perBall[editingIndex].ballContact} onChange={setCurrentBall('ballContact')}>
            <option value="">{Multilingual(`NULL`)}</option>
            {Object.keys(ballContacts).map((ballContact) => (
              <option key={ballContact} value={ballContact}>
                {ballContact}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className="item">
        <p>{Multilingual(`DROPPOINT.WORDING`)}</p>
        <Button onClick={openDroppointDiagram} mini color="blue" width="unset">
          {!perBall[editingIndex].ballDroppoint ? Multilingual(`NULL`) : perBall[editingIndex].ballDroppoint}
        </Button>
        <Button width="unset" color="whiteWithBorder" mini onClick={resetCurrentBallDroppoint}>
          {Multilingual(`ENTRY_POINT.RESET`)}
        </Button>
        {/* <Tooltip content="ref: Retrosheet Hit Location Diagram" /> */}
      </div>
      <div className="item">
        {editingIndex !== perBall.length - 1 ? (
          <span>
            <Button width="unset" mini color="blue" onClick={removeBall}>
              {Multilingual(`ON_SITE.DELETE_BALL`)}
            </Button>
            <Button width="unset" mini color="red" onClick={() => perBallDispatch({ type: 'backFromNoneCurrentEdit' })}>
              {Multilingual(`ON_SITE.BACK_BALL`)}
            </Button>
          </span>
        ) : (
          <Button width="unset" mini color="red" onClick={addBall} disabled={!addBallable()}>
            {Multilingual(`ON_SITE.CREATE_BALL`)}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BallEditSelectMode;
