import { useState } from 'react';
import Modal from '~~elements/Modal';
import Button from '~~elements/Button';
import toast from '~~elements/Toast';
import { UserResource } from '~~apis/resource';
import Multilingual from '../../utils/Multilingual';

const FreeTrial = ({ isOpen, toClose }) => {
  const [apiInProgress, setApiInProgress] = useState(false);

  const handleUseFreeTrial = () => {
    setApiInProgress(true);
    UserResource.startFreeTrial()
      .then(() => {
        toast(Multilingual(`PLAN_COMPARE.SUCCESSFULLY_START_MY_FREE_TRIAL`), { status: 'success', second: 2 });
        window.location.reload();
      })
      .catch((e) => {
        setApiInProgress(false);
        if (e.message === 'ALREADY_USED') {
          toast(Multilingual(`PLAN_COMPARE.FREE_TRIAL_ALREADY_USED`), { status: 'error', second: 5 });
          return;
        }
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 2 });
      });
  };

  return (
    <Modal.Modal isOpen={isOpen} onClickBack={toClose}>
      <Modal.ModalHeader hasBack={true} onClickBack={toClose}>
        {Multilingual(`PLAN_COMPARE.FREE_TRIAL`)}
      </Modal.ModalHeader>
      <Modal.ModalBody>
        <p>{Multilingual(`PLAN_COMPARE.FREE_TRIAL_CONTENT`)}</p>（{Multilingual(`PLAN_COMPARE.FREE_TRIAL_HINT`)}）
      </Modal.ModalBody>
      <Modal.ModalFooter>
        <Button onClick={handleUseFreeTrial} disabled={apiInProgress}>
          {Multilingual(`PLAN_COMPARE.USE_MY_FREE_TRIAL`)}
        </Button>
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

export default FreeTrial;
