import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  #screen-share-embed-screen-placing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const VideoEmbedScreenShare = () => {
  return (
    <StyledDiv>
      <video id="screen-share-embed-screen-placing"></video>
    </StyledDiv>
  );
};

export default VideoEmbedScreenShare;
