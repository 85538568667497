import styled from 'styled-components';
import StrikeStrikeZone from './StrikeStrikeZone';
import { strikeZoneConfig } from '../../StrikezoneGame/constants/base';
import { ACTIONS, useLocalDispatch, useLocalState } from '../useLocal';
import { useEffect, useRef, useState } from 'react';

const StyledDiv = styled.div.attrs((props) => ({
  style: {
    cursor: props.resizable ? 'grab' : props.clickable ? 'crosshair' : 'default',
    left: props.left,
    top: props.top,
  },
}))`
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${strikeZoneConfig.width}px;
  height: ${strikeZoneConfig.height}px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ballWidth = 10;
const CurrentBall = styled.div.attrs((props) => ({
  style: {
    left: `${props.left}px`,
    top: `${props.top}px`,
  },
}))`
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${ballWidth}px;
  height: ${ballWidth}px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  pointer-events: none;
`;

const strikeZoneID = 'strike-zone';
const fullZoneID = 'full-zone';

const setZoneResize = (parentID, _strikeZoneID, _fullZoneID) => {
  const element = document.querySelector(`#${_strikeZoneID}`);
  const fullElement = document.querySelector(`#${_fullZoneID}`);
  const wrapElement = document.querySelector(`#${parentID}`);

  const draggable = (e) => {
    e.preventDefault();

    const dragging = (moveEvent) => {
      const fullRect = fullElement.getBoundingClientRect();
      const wrapRect = wrapElement.getBoundingClientRect();
      fullElement.style.left = fullRect.left - wrapRect.left + fullRect.width / 2 + moveEvent.movementX + 'px';
      fullElement.style.top = fullRect.top - wrapRect.top + fullRect.height / 2 + moveEvent.movementY + 'px';
    };

    const stopDrag = () => {
      window.removeEventListener('mousemove', dragging);
    };
    window.addEventListener('mousemove', dragging);
    window.addEventListener('mouseup', stopDrag);
  };

  const wheellable = (e) => {
    e.preventDefault();
    for (let el of [element, fullElement]) {
      const rect = el.getBoundingClientRect();
      const newWidth = e.wheelDeltaY < 0 ? rect.width * 0.98 : rect.width * 1.02;
      const newHeight = e.wheelDeltaY < 0 ? rect.height * 0.98 : rect.height * 1.02;
      if (newWidth < 20) {
        break;
      }
      el.style.width = newWidth + 'px';
      el.style.height = newHeight + 'px';
    }
  };

  fullElement.addEventListener('mousedown', draggable);
  fullElement.addEventListener('wheel', wheellable, { passive: false });

  const removeEvents = { draggable, wheellable };
  return removeEvents;
};

const getPercentagePerPixel = (_strikeZoneID) => {
  // 把本壘板的寬度設定為 100
  // 右上為正（等於要在網頁顯示時要記得反轉Y）
  const strikeEl = document.querySelector(`#${_strikeZoneID}`);
  const strikeElRect = strikeEl.getBoundingClientRect();
  const percentagePerPixel = (100 / strikeZoneConfig.width) * (strikeZoneConfig.width / strikeElRect.width);
  return percentagePerPixel;
};

const StrikeZone = ({ parentID, resizable, code, left = '50%', top = '50%' }) => {
  const _strikeZoneID = `${strikeZoneID}-${code}`;
  const _fullZoneID = `${fullZoneID}-${code}`;

  const strikeZoneRef = useRef(null);
  const [refWaiting, setRefWaiting] = useState(0);

  useEffect(() => {
    let refWaitingID = null;
    if (!strikeZoneRef || !strikeZoneRef.current) {
      refWaitingID = setTimeout(() => {
        setRefWaiting(refWaiting + 1);
      }, 200);
      return;
    }

    if (!resizable) {
      return;
    }

    const removeEvents = setZoneResize(parentID, _strikeZoneID, _fullZoneID);
    return () => {
      clearTimeout(refWaitingID);
      const element = document.querySelector(`#${_fullZoneID}`);
      element.removeEventListener('mousedown', removeEvents.draggable);
      element.removeEventListener('wheel', removeEvents.wheellable);
    };
  }, [resizable, refWaiting, parentID, _strikeZoneID, _fullZoneID]);

  const dispatch = useLocalDispatch();
  const { pitches, pitchIndex } = useLocalState();
  const [ballPosition, setBallPosition] = useState(null);

  useEffect(() => {
    if (!pitches[pitchIndex]) {
      setBallPosition(null);
      return;
    }

    const pitch = pitches[pitchIndex].pitch;
    if (pitch.coord_x === '' || pitch.coord_y === '') {
      setBallPosition(null);
      return;
    }

    const strikeElRect = document.querySelector(`#${_strikeZoneID}`).getBoundingClientRect();
    const fullRect = document.querySelector(`#${_fullZoneID}`).getBoundingClientRect();
    const strikeCenterX = strikeElRect.left + strikeElRect.width / 2;
    const strikeCenterY = strikeElRect.top + strikeElRect.height / 2;
    const percentagePerPixel = getPercentagePerPixel(_strikeZoneID);

    const deltaX = parseFloat(pitch.coord_x) / percentagePerPixel;
    const deltaY = (parseFloat(pitch.coord_y) / percentagePerPixel) * -1;
    setBallPosition({
      left: strikeCenterX - fullRect.left + deltaX,
      top: strikeCenterY - fullRect.top + deltaY,
    });
  }, [pitches, pitchIndex, _strikeZoneID, _fullZoneID]);

  const handlePitchCoord = (e) => {
    e.preventDefault();
    const clickX = e.clientX;
    const clickY = e.clientY;
    const strikeEl = document.querySelector(`#${_strikeZoneID}`);
    if (!strikeEl || !pitches[pitchIndex]) {
      return;
    }

    const strikeElRect = strikeEl.getBoundingClientRect();
    const strikeCenterX = strikeElRect.left + strikeElRect.width / 2;
    const strikeCenterY = strikeElRect.top + strikeElRect.height / 2;
    const percentagePerPixel = getPercentagePerPixel(_strikeZoneID);
    const x = (clickX - strikeCenterX) * percentagePerPixel;
    const y = (strikeCenterY - clickY) * percentagePerPixel;

    dispatch({ type: ACTIONS.SET_COORD, payload: { x, y } });
  };

  return (
    <StyledDiv
      id={_fullZoneID}
      ref={strikeZoneRef}
      left={left}
      top={top}
      onClick={resizable ? () => {} : handlePitchCoord}
      resizable={resizable}
      clickable={pitchIndex >= 0}
    >
      <StrikeStrikeZone id={_strikeZoneID} resizable={resizable} />
      {!!ballPosition && <CurrentBall top={ballPosition.top} left={ballPosition.left} />}
    </StyledDiv>
  );
};

export default StrikeZone;
