// 文字都已經轉去 Multilingual 了
export const ballTypes = {
  F: '直球',
  C: '變速',
  B: '變化',
  OT: '其他',
};

export const ballDetailTypes = ['FA', 'FT', 'FC', 'SL', 'CU', 'KC', 'SC', 'SI', 'CH', 'FS', 'FO', 'KN', 'EP', 'OT'];

export const judgeCalls = {
  S: '好球',
  B: '壞球',
};

export const hands = {
  R: '右',
  L: '左',
};

export const batterActions = {
  NONE: '無',
  SWMISS: '揮空',
  SWTIP: '擦棒球',
  INFIELD: '球入場',
  OUTFIELD: '球界外',
};

export const batterResults = {
  '1B': '1B',
  '2B': '2B',
  '3B': '3B',
  HR: 'HR',
  E: 'E',
  FC: 'FC',
  out: 'out',
  GIDP: 'GIDP',
  SH: 'SH',
  SF: 'SF',
  uBB: 'uBB',
  IBB: 'IBB',
  HBP: 'HBP',
  SO: 'SO',
  NONE: '無',
  other: '其他',
};

export const getBatterResultOptions = (batterAction, trajectory) => {
  if (batterAction !== 'INFIELD') {
    return ['uBB', 'IBB', 'HBP', 'SO', 'NONE', 'other'];
  }

  if (['BG', 'BP'].includes(trajectory)) {
    return ['FC', 'out', 'GIDP', 'SH', 'other'];
  }

  if (['G'].includes(trajectory)) {
    return ['1B', '2B', '3B', 'HR', 'E', 'FC', 'out', 'GIDP', 'other'];
  }

  if (['L', 'F'].includes(trajectory)) {
    return ['1B', '2B', '3B', 'HR', 'E', 'FC', 'out', 'SF', 'other'];
  }

  if (['P'].includes(trajectory)) {
    return ['1B', '2B', '3B', 'E', 'FC', 'out', 'SF', 'other'];
  }

  return ['other'];
};

export const resultFielders = {
  NONE: '無',
  1: 'P',
  2: 'C',
  3: '1B',
  4: '2B',
  5: '3B',
  6: 'SS',
  7: 'LF',
  8: 'CF',
  9: 'RF',
};

export const ballTrajectories = {
  NONE: '無',
  G: '滾',
  L: '平',
  P: '內野高飛',
  F: '飛',
  BG: '觸滾',
  BP: '觸飛',
};

export const ballContacts = {
  S: '弱',
  H: '強',
};

export const ballDroppoints = {
  NONE: '無',
  1: '1',
  2: '2',
  3: '3',
  34: '34',
  4: '4',
  5: '5',
  56: '56',
  6: '6',
  46: '46',
  7: '7',
  8: '8',
  9: '9',
};
