import { useState } from 'react';
import styled from 'styled-components';
import { StrikezoneGameResource } from '~~apis/resource';
import Modal from '~~elements/Modal';
import toast from '~~elements/Toast';
import Button from '~~elements/Button';
import Multilingual from '../Multilingual';
import { Fragment } from 'react';

const StyledDiv = styled.div`
  color: black;
  letter-spacing: 2px;
  line-height: 1.3;
  white-space: pre-line;

  .current-action {
    margin-top: 1rem;
  }
`;

const PrivacyWarning = ({ isOpen, toClose, targetPrivacy, strikezoneUniqid }) => {
  const [apiInProgress, setApiInProgress] = useState(false);

  const changePrivacy = () => {
    setApiInProgress(true);
    StrikezoneGameResource.changeStrikezoneGamePrivacy({ strikezoneUniqid, data: { privacy: targetPrivacy } })
      .then(({ data }) => {
        window.location.replace(`/${data.uniqid}/edit`);
        window.location.href = `/${data.uniqid}/edit`;
      })
      .catch((e) => {
        toast(Multilingual(`ERROR.UNKNOWN`), { status: 'error', second: 1 });
      })
      .finally(() => {
        setApiInProgress(false);
      });
  };

  return (
    <Modal.Modal isOpen={isOpen} onClickBack={apiInProgress ? () => {} : toClose}>
      <Modal.ModalHeader hasBack={!apiInProgress} onClickBack={apiInProgress ? () => {} : toClose}>
        {Multilingual(`READ_PRIVACY.HINT`)}
      </Modal.ModalHeader>
      <Modal.ModalBody>
        <StyledDiv>
          <div>{Multilingual(`READ_PRIVACY.DECLARE`)}</div>
          {targetPrivacy && (
            <Fragment>
              <div className="current-action">
                {`${Multilingual(`READ_PRIVACY.GOING_TO`)} ${Multilingual(`INFO.READ_PRIVACY_${targetPrivacy}`)}`}
              </div>
              <div className="current-action">{Multilingual(`READ_PRIVACY.${targetPrivacy}_FEATURE`)}</div>
            </Fragment>
          )}
        </StyledDiv>
      </Modal.ModalBody>
      <Modal.ModalFooter>
        <Button width="8rem" color="red" onClick={changePrivacy} disabled={apiInProgress}>
          {Multilingual(`READ_PRIVACY.CONFIRM`)}
        </Button>
        <Button width="8rem" color="blue" onClick={toClose} disabled={apiInProgress}>
          {Multilingual(`CANCEL`)}
        </Button>
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

export default PrivacyWarning;
